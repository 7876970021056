import React, { Fragment } from "react";
import { Button, Col, Row} from "reactstrap";
import { format } from 'date-fns'

const DocumentAccordianContent = ({ centerProfile, documents, section, showDocument }) => {
  const host = process.env.REACT_APP_BASE_URL;
  const displayDocument = (doc, idx) => {
    return (
      <tr key={idx + 1}>
      <td width={400}
        name="file_name"
      >
        {section === "myLittleLearner" && doc.file_name}
        {section === "myAccount" && 
          (doc.is_family_document ? doc.file_name : `${doc.file_name} (${doc.first_name} ${doc.last_name})`)
        }
      </td>
      <td name="upload_date">
        {format(new Date(doc.upload_date), 'MM/dd/yyyy')}
      </td>
      <td className="vertical-align-initial">
        <Row>
          <Col style={{textAlign: 'center'}}>
            <Button
            className="p-0 "
            color="link"
            onClick={() => showDocument(doc)}
            >
            View
            </Button>
          </Col>
          <Col style={{textAlign: 'center'}}>
            <Button
              className="p-0 "
              color="link"
              // style={{marginLeft: '10px'}}
            >
            <a href={`${host}/cdn/file/download/${centerProfile.id}/${doc.s3_file_path}/${doc.file_name}`} download={doc.file_name}>
              Download
            </a>
            </Button>
          </Col>
        </Row>
      </td>
    </tr>
    );
  };
  return (
    <Fragment>
      {documents && documents.map((doc, idx) => displayDocument(doc, idx))}
    </Fragment>
  );
};

export default DocumentAccordianContent;

import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import YesNoDropDownOptions from "../../SharedComponents/YesNoDropdownOptions";

const HealthItemModal = ({ pcp, deleteItem, updateItems, tabDataForModal }) => {
  const [modal, setModal] = useState(false);
  const [showOtherDescription, setShowOtherDescription] = useState(false);
  const [modalData, setModalData] = useState(null);
  const types = {
    allergy: {
      title: 'Allergy',
      message: 'Select an allergy',
      itemList: pcp.medicalAllergy,
    }, 
    dietary_preference: {
      title: 'Dietary Preference',
      message: 'Select a dietary preference',
      itemList: pcp.medicalDietaryPreferences
    }, 
    medical_condition: {
      title: 'Medical Condition',
      message: 'Select a medical condition',
      itemList: pcp.medicalConditions
    }
  }

  useEffect(() => {
    if (Object.keys(tabDataForModal).length) {
      setModalData(tabDataForModal);
      setModal(!modal);
    }
  }, [tabDataForModal]);

  const toggle = () => {
    setModal(!modal);
    setShowOtherDescription(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "allergy_id" || name === "medical_condition_id" || name === "dietary_preference_id") {
      if ((name === "allergy_id" && value === "1") || (name === "medical_condition_id" && value === "13") || (name === "dietary_preference_id" && value === "8")) {
        setShowOtherDescription(true);
      } else {
        setShowOtherDescription(false);
      }
    }
  };

  const beforeSubmit = (event) => {
    event.persist();
  };

  const handleSubmit = (e, errors, values) => {
    if (errors.length > 0) return;
    setModal(!modal);
    if(modalData.action === "delete") {
      deleteItem(modalData);
    } else {
      updateItems(values, modalData);
    }
  };

  const editing = modalData && modalData.items.find(el => (el.id || el.temporaryId) === modalData.itemId);
  const typeSelected = modalData && types[modalData.type];
  return (
    modalData && 
    <div>
      <Modal isOpen={modal} toggle={toggle} className="">
        <AvForm
          name={"healthModal"}
          onSubmit={handleSubmit}
          beforeSubmitValidation={beforeSubmit}
        >
        {/* deleting */}
        {modalData.action === "delete" && 
             <Fragment>
             <ModalHeader toggle={toggle}>Delete {typeSelected.title}</ModalHeader>
               <ModalBody>
               {`Are you sure you want to delete ${modalData.itemName}?`}
               </ModalBody>
             <ModalFooter>
               <Button color="secondary" onClick={toggle}>
                 Cancel
               </Button>
               <Button color="primary">Delete</Button>
             </ModalFooter>
             </Fragment>
        }
        {/*editing  */}
        {modalData.action !== 'delete' && 
        <Fragment>
        <ModalHeader toggle={toggle}>{editing ? 'Edit' : 'Add'} {typeSelected.title}</ModalHeader>
          <ModalBody>
          <AvField
            name={`${modalData.type}_id`}
            type="select"
            label={`${typeSelected.title}`}
            onChange={handleInputChange}
            value={editing ? editing[`${modalData.type}_id`] : ""}
            required
          >
            <option key="0" value="">
              {typeSelected.message}
            </option>
            {typeSelected.itemList.map((el) => (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            ))}
          </AvField>
          {showOtherDescription && (
            <AvField
              name={`other_description`}
              type="text"
              label="Other Description"
              onBlur={handleInputChange}
              value={editing && editing.other_description ? editing.other_description : ""}
              required
            />
          )}
          {modalData.type !== 'dietary_preference' && 
            <AvField
              name="severity_id"
              type="select"
              label="Severity"
              onChange={handleInputChange}
              value={editing ? editing.severity_id : ""}
              required
            >
              <option key="0" value="">
                Select a severity
              </option>
              {pcp.medicalSeverity.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </AvField>
          }
          {modalData.type === 'allergy' && 
            <AvField
              name="is_life_threatening"
              type="select"
              label="Is Life Threatening"
              onChange={handleInputChange}
              value={editing ? editing.is_life_threatening !== null ? editing.is_life_threatening.toString() : "" : ""}
              required
            >
              <YesNoDropDownOptions />
            </AvField>
          }
            <AvField
              name="incident_procedure"
              type="text"
              label={`${modalData.type !== 'dietary_preference' ? "Treatment & " : ""}Notes`}
              onBlur={handleInputChange}
              value={editing ? editing.incident_procedure : ""}
              required
            />
        </ModalBody>
        <ModalFooter>
          {/* <FormGroup> */}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary">Save</Button>
          {/* </FormGroup> */}
        </ModalFooter>
        </Fragment>
        }
        </AvForm>
      </Modal>
    </div>
  );
};

export default HealthItemModal;

import { Route, Redirect } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loadCenterInfo,
  loadGuardian,
  signout,
  loginSuccess,
  ping
} from "../../redux/actionCreators/auth";
import { loadData } from "../../redux/actionCreators/enrollment";
import { loadPcpData } from "../../redux/actionCreators/pcp";
import ErrorModal from "../../Containers/SharedComponents/ErrorModal";
import { ToastContainer } from "react-toastify";
import Home from "../../Containers/Home";
import MyAccount from "../../Containers/MyAccount";
import MyLittleLearner from "../../Containers/MyLittleLearner";
import EnrollmentForm from "../../Containers/EnrollmentForm";
import PcpForm from "../../Containers/PcpForm";
import Signin from "../../Containers/Auth/Signin";
import Signup from "../../Containers/Auth/Signup";
import VerifyEmail from "../../Containers/Auth/VerifyEmail";
import ForgotPassword from "../../Containers/Auth/ForgotPassword";
import ResetPassword from "../../Containers/Auth/ResetPassword";
import Spinner from "../../Containers/SharedComponents/Spinner";

const PublicRoute = ({ render: renderComponent, ...restProps }) => {
  return <Route {...restProps} render={(props) => renderComponent(props)} />;
};

const ProtectedRoute = ({ auth, render: renderComponent, ...restProps }) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const loggedIn = user.email && user.token && user.guardianId;
  return (
    <Fragment>
      {!loggedIn ? (
        <Route
          {...restProps}
          render={(props) => (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )}
        />
      ) : (
        <Route
          {...restProps}
          render={(props) =>
            auth.status === "succeeded" ? (
              renderComponent(props)
            ) : (
              <Spinner showSpinner={auth.status === "loading"} />
            )
          }
        />
      )}
    </Fragment>
  );
};

const AppMain = ({
  auth,
  centerInfo,
  enrollment,
  loadCenterInfo,
  loadData,
  loadPcpData,
  loadGuardian,
  location,
  loginSuccess,
  myAccount,
  myLittleLearner,
  pcp,
  ping,
  signout
}) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const errorModalClosed = () => {
    setErrorMessage("")
    setShowErrorModal(false);
    signout();
  };
  
  const setErrorMessageAndShowModal = (section) => {
    console.log("@@@ status failed, error detected", section.error);
    if(section.error === 'Invalid token') {
      signout();
    } else {
      setErrorMessage(section.error);
      setShowErrorModal(true);
    }
    return;
  }

  useEffect(() => {
    if (auth.status === "failed") {
      setErrorMessageAndShowModal(auth)
    }
  }, [auth.status]);

  useEffect(() => {
    if (enrollment.status === "failed") {
      setErrorMessageAndShowModal(enrollment)
    }
  }, [enrollment.status]);

  useEffect(() => {
    if (pcp.status === "failed") {
      setErrorMessageAndShowModal(pcp)
    }
  }, [pcp.status]);

  useEffect(() => {
    if (centerInfo.status === "failed") {
      setErrorMessageAndShowModal(centerInfo)
    }
  }, [centerInfo.status]);

  useEffect(() => {
    if (myAccount.status === "failed") {
      setErrorMessageAndShowModal(myAccount)
    }
  }, [myAccount.status]);

  useEffect(() => {
    if (myLittleLearner.status === "failed") {
      setErrorMessageAndShowModal(myLittleLearner)
    }
  }, [myLittleLearner.status]);

  useEffect(() => {
    (async () => {
      await ping();
      const user = JSON.parse(localStorage.getItem("user")) || {};
      if (user.email && user.token && user.guardianId) {
        const guardian = await loadGuardian({
          id: user.guardianId,
          token: user.token
        });
        if (guardian && guardian.center_id) {
          const centerInfo = await loadCenterInfo({
            centerId: guardian.center_id
          });
          console.log("Restored session, centerInfo: ", centerInfo);
          if (centerInfo && centerInfo.centerProfile.id) {
            await Promise.all([loadData({
              familyId: guardian.family_id
            }),
            loadPcpData({
              familyId: guardian.family_id
            })])
                loginSuccess();
                return
          }
        }
          await signout();
      }
      await signout();
    })();
  }, []);

  return (
    <Fragment>
      <PublicRoute path="/signin" render={() => <Signin />} />
      <PublicRoute path="/verifyEmail" render={() => <VerifyEmail />} />
      <PublicRoute path="/signup/:token" render={() => <Signup />} />
      <PublicRoute
        path="/signout"
        render={() => {
          signout();
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location }
              }}
            />
          );
        }}
      />
      <PublicRoute path="/forgotPassword" render={() => <ForgotPassword />} />
      <PublicRoute
        path="/resetPassword/:token"
        render={() => <ResetPassword />}
      />
      <ProtectedRoute
        auth={auth}
        path="/home"
        render={() => <Home enrollment={enrollment} pcp={pcp} auth={auth} />}
      />
      <ProtectedRoute
        auth={auth}
        path="/enrollmentform"
        render={() => <EnrollmentForm />}
      />
      <ProtectedRoute
        auth={auth}
        path="/personalcareplan"
        render={() => <PcpForm />}
      />
      <ProtectedRoute
        auth={auth}
        path="/myaccount"
        render={() => <MyAccount />}
      />
      <ProtectedRoute
        auth={auth}
        path="/contactinformation"
        render={() => <MyAccount navigateTo="contactInformation" />}
      />
      <ProtectedRoute
        auth={auth}
        path="/transactionsandpayments"
        render={() => <MyAccount navigateTo="transactionsAndPayments" />}
      />
      <ProtectedRoute
        auth={auth}
        path="/mydocuments"
        render={() => <MyAccount navigateTo="myDocuments" />}
      />
      <ProtectedRoute
        auth={auth}
        path="/mylittlelearner"
        render={() => <MyLittleLearner />}
      />
      <ProtectedRoute
        exact
        auth={auth}
        path="/"
        render={() => <Home enrollment={enrollment} pcp={pcp} />}
      />
      <PublicRoute
        path="/ping"
        render={() => <p>{JSON.stringify(auth.pingResult)}</p>}
      />
      <ToastContainer />
      <ErrorModal
        errorMessage={errorMessage}
        showErrorModal={showErrorModal}
        errorModalClosed={errorModalClosed}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  enrollment: state.enrollment,
  pcp: state.pcp,
  centerInfo: state.centerInfo,
  myAccount: state.myAccount,
  myLittleLearner: state.myLittleLearner
});

const mapDispatchToProps = (dispatch) => ({
  signout: bindActionCreators(signout, dispatch),
  loadData: bindActionCreators(loadData, dispatch),
  loadPcpData: bindActionCreators(loadPcpData, dispatch),
  loadGuardian: bindActionCreators(loadGuardian, dispatch),
  loadCenterInfo: bindActionCreators(loadCenterInfo, dispatch),
  loginSuccess: bindActionCreators(loginSuccess, dispatch),
  ping: bindActionCreators(ping, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null)(AppMain);

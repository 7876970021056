import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IdentificationTooltip from "../../EnrollmentForm/Components/IdentificationTooltip";

const fontLarge = {
  fontSize: "1rem",
};

const SectionHeaderContent = ({child, section, sectionAccordianIsOpen}) => {
  return (
    <Fragment>
      <Row
        className="m-0 p-2 he-100 w-100 align-items-center"
        style={{ alignItems: "center", cursor: "pointer" }}
        aria-expanded={sectionAccordianIsOpen}
        aria-controls={`${section}-${child.id}}-collapse`}
      >
        <Col xs={10}>
          <div style={fontLarge}>
            {section === 'emergencyContacts' ? 'Emergency Contacts' : section}
            {section === 'documents' && <IdentificationTooltip 
              id={`myLittleLearnerTooltip-${child.id}`}
              msg='A document reader may have to be used to view documents on an Android device.'
            />}
          </div>
        </Col>
        <Col xs={2} className="text-center">
          <FontAwesomeIcon
            icon={sectionAccordianIsOpen ? faChevronDown : faChevronRight}
            fixedWidth={false}
            className="mt-1 text-blue fa-2x"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default SectionHeaderContent;
import * as actionTypes from "../actionTypes";

export const loadMyLittleLearner = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const { center_id, family_id } = payload;
  if (!center_id || !family_id) return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__START,
  });
  try {
    const res = await api.get(
      `/myLittleLearner/getMyLittleLearner/${center_id}/${family_id}`,
      {
        Authorization: `Bearer ${getState().auth.token}`,
      }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__SUCCESS,
        payload: res.response,
      });
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__FAILURE,
        payload:
          res.error ||
          "Load My Little Learner error. There was no response or error returned from the server",
      });
      console.log('loadMyLittleLearner error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Load My Little Learner error. There was no response or error returned from the server",
    });
    console.log('loadMyLittleLearner exception', err);
  }
};

export const saveChildPersonalInfo = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || 
    !state.centerInfo.centerProfile.id || 
    !state.auth ||
    !state.auth.guardian ||
    !state.auth.guardian.center_id ||
    !state.auth.guardian.family_id) return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__START,
  });
  try {
    const res = await api.post(
      `/myLittleLearner/saveChildPersonalInfo/${state.centerInfo.centerProfile.id}`,
      payload,
      {
        Authorization: `Bearer ${getState().auth.token}`,
      }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__SUCCESS,
      });
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__FAILURE,
        payload:
          res.error ||
          "Save child personal info error. There was no response or error returned from the server",
      });
      console.log('saveChildPersonalInfo error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Save child personal info error. There was no response or error returned from the server",
    });
    console.log('saveChildPersonalInfo exception', err);
  }
};

export const saveChildImage = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || 
    !state.centerInfo.centerProfile.id) return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__START,
  });
  try {
      const res = await api.post(`/cdn/image/upload`, payload.file, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getState().auth.token}`,
      });
      if (res.success.key || res.success.Key) {
        payload = {
          ...payload,
          photoPath: res.success.key || res.success.Key,
        };
        const res2 = await api.post(
          `/myLittleLearner/saveChildImage`,
          payload,
          {
            Authorization: `Bearer ${getState().auth.token}`,
          }
        );
        if (res2.response) {
          dispatch({
            type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__SUCCESS
          });
        } else {
          dispatch({
            type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__FAILURE,
            payload:
              res2.error ||
              "Save child personal info error. There was no response or error returned from the server",
          });
          console.log('saveChildImage error', res);
        }
      }
      else {
        dispatch({
          type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__FAILURE,
          payload:
            res.error ||
            "Child image upload error. There was no response or error returned from the server",
        });
        console.log('saveChildImage error', res);
        return;
      }
    } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Save child image error. There was no response or error returned from the server",
    });
    console.log('saveChildImage exception', err);
  }
};

export const addEmergencyContact = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || !state.centerInfo.centerProfile.id) return;

  if (
    !state.auth ||
    !state.auth.guardian ||
    !state.auth.guardian.center_id ||
    !state.auth.guardian.family_id
  ) return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__START,
  });
  try {
    const res = await api.post(
      `/myLittleLearner/addEmergencyContact/${state.centerInfo.centerProfile.id}`,
      payload,
      { Authorization: `Bearer ${getState().auth.token}` }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__SUCCESS,
      });
      dispatch(
        loadEmergencyContacts({
          center_id: state.auth.guardian.center_id,
          family_id: state.auth.guardian.family_id,
        })
      );
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__FAILURE,
        payload:
          res.error ||
          "Save emergency contact error. There was no response or error returned from the server",
      });
      console.log('addEmergencyContact error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Save emergency contact error. There was no response or error returned from the server",
    });
    console.log('addEmergencyContact exception', err);
  }
};

export const editEmergencyContact = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || !state.centerInfo.centerProfile.id) return;

  if (
    !state.auth ||
    !state.auth.guardian ||
    !state.auth.guardian.center_id ||
    !state.auth.guardian.family_id
  ) return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__START,
  });
  try {
    const res = await api.post(
      `/myLittleLearner/editEmergencyContact/${state.centerInfo.centerProfile.id}`,
      payload,
      { Authorization: `Bearer ${getState().auth.token}` }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__SUCCESS,
      });
      dispatch(
        loadEmergencyContacts({
          center_id: state.auth.guardian.center_id,
          family_id: state.auth.guardian.family_id,
        })
      );
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__FAILURE,
        payload:
          res.error ||
          "Edit emergency contact error. There was no response or error returned from the server.",
      });
      console.log('editEmergencyContact error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Edit emergency contact error. There was no response or error returned from the server.",
    });
    console.log('editEmergencyContact exception', err);
  }
};

export const deleteEmergencyContact = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || !state.centerInfo.centerProfile.id) {
    //TODO: need to show error modal
    return;
  }

  if (
    !state.auth ||
    !state.auth.guardian ||
    !state.auth.guardian.center_id ||
    !state.auth.guardian.family_id
  ) {
    // TODO: error handling
    return;
  }

  dispatch({
    type: actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__START,
  });
  try {
    const res = await api.post(
      `/myLittleLearner/deleteEmergencyContact/${state.centerInfo.centerProfile.id}/`,
      payload,
      { Authorization: `Bearer ${getState().auth.token}` }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__SUCCESS,
      });
      dispatch(
        loadEmergencyContacts({
          center_id: state.auth.guardian.center_id,
          family_id: state.auth.guardian.family_id,
        })
      );
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__FAILURE,
        payload:
          res.error ||
          "Delete emergency contact error. There was no response or error returned from the server.",
      });
      console.log('myLittleLearner deleteEmergencyContact error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Delete emergency contact error. There was no response or error returned from the server.",
    });
    console.log('myLittleLearner deleteEmergencyContact exception', err);
  }
};

export const loadEmergencyContacts = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const { center_id, family_id } = payload;
  if (!center_id || !family_id)
    //TODO: need to show error modal
    return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__START,
  });
  try {
    const res = await api.get(
      `/myLittleLearner/getEmergencyContacts/${center_id}/${family_id}`,
      { Authorization: `Bearer ${getState().auth.token}` }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__SUCCESS,
        payload: res.response,
      });
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__FAILURE,
        payload:
          res.error ||
          "Load emergency contacts error. There was no response or error returned from the server.",
      });
      console.log('myLittleLearner loadEmergencyContacts error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Load emergency contacts error. There was no response or error returned from the server.",
    });
    console.log('myLittleLearner loadEmergencyContacts exception', err);
  }
};

export const loadChildMedical = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const { center_id, family_id } = payload;
  if (!center_id || !family_id)
    //TODO: need to show error modal
    return;

  dispatch({
    type: actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__START,
  });
  try {
    const res = await api.get(
      `/myLittleLearner/getMedical/${center_id}/${family_id}`,
      { Authorization: `Bearer ${getState().auth.token}` }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__SUCCESS,
        payload: res.response,
      });
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__FAILURE,
        payload:
          res.error ||
          "Load child medical error. There was no response or error returned from the server.",
      });
      console.log('myLittleLearner loadChildMedical error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Load child medical error. There was no response or error returned from the server.",
    });
    console.log('myLittleLearner loadChildMedical exception', err);
  }
};

export const updateChildMedical = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (
    !state.auth ||
    !state.auth.guardian ||
    !state.auth.guardian.center_id ||
    !state.auth.guardian.family_id
  ) {
    // TODO: error handling
    return;
  }

  dispatch({
    type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__START,
  });
  try {
    const res = await api.post(`/myLittleLearner/updateMedical`, payload, {
      Authorization: `Bearer ${getState().auth.token}`,
    });
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__SUCCESS,
      });
      dispatch(
        loadChildMedical({
          center_id: state.auth.guardian.center_id,
          family_id: state.auth.guardian.family_id,
        })
      );
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE,
        payload:
          res.error ||
          "Load child medical error. There was no response or error returned from the server.",
      });
      console.log('myLittleLearner updateChildMedical error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Load child medical error. There was no response or error returned from the server.",
    });
    console.log('myLittleLearner updateChildMedical exception', err);
  }
};

export const deleteChildMedical = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (
    !state.auth ||
    !state.auth.guardian ||
    !state.auth.guardian.center_id ||
    !state.auth.guardian.family_id
  ) {
    // TODO: error handling
    return;
  }

  dispatch({
    type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__START,
  });
  try {
    const res = await api.post(`/myLittleLearner/deleteMedical`, payload, {
      Authorization: `Bearer ${getState().auth.token}`,
    });
    if (res.response) {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__SUCCESS,
      });
      dispatch(
        loadChildMedical({
          center_id: state.auth.guardian.center_id,
          family_id: state.auth.guardian.family_id,
        })
      );
    } else {
      dispatch({
        type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE,
        payload:
          res.error ||
          "Delete child medical error. There was no response or error returned from the server.",
      });
      console.log('myLittleLearner deleteChildMedical error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Delete child medical error. There was no response or error returned from the server.",
    });
    console.log('myLittleLearner deleteChildMedical exception', err);
  }
};

export const fetchingData = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: actionTypes.MYLITTLELEARNER_FETCHING_DATA,
  });
};

export const receivedData = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: actionTypes.MYLITTLELEARNER_RECEIVED_DATA,
  });
};

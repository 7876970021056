import React, { useState, useEffect, useRef, Fragment } from "react";
import ImportantCareInfo from "./Forms/ImportantCareInfo";
import DevelopmentalHistory from "./Forms/DevelopmentalHistory";
import NutritionPracticesAndRoutines from "./Forms/NutritionPracticesAndRoutines";
import SleepingRoutines from "./Forms/SleepingRoutines";
import ComfortingChild from "./Forms/ComfortingChild";
import ToiletingRoutines from "./Forms/ToiletingRoutines";
import SocialRelationships from "./Forms/SocialRelationships";
import AdditionalInfo from "./Forms/AdditionalInfo";
import Signature from "./Forms/Signature";
import ErrorModal from "../../SharedComponents/ErrorModal";
import PcpStepWizard from "react-step-wizard";
import PcpStepWizardNav from "./PcpStepWizardNav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loadPcpData,
  loadPcpSubmissionStatuses,
  patchData,
  saveData,
  updateUnsavedChangesWarning
} from "../../../redux/actionCreators/pcp";
import { useHistory } from 'react-router-dom';
import UnsavedChangesModal from "../../SharedComponents/UnsavedChangesModal";


const PcpStepper = ({
  auth,
  centerInfo,
  enrollment,
  loadPcpData,
  loadPcpSubmissionStatuses,
  patchData,
  pcp,
  saveData,
  updateUnsavedChangesWarning
}) => {
  const { error, redirectTo, showModal, status, submissionStatuses, unsavedChanges } = pcp;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [stepObj, setStepObj] = useState({});
  const [step, setStep] = useState(null)
  const history = useHistory();
  const localStoragePCPForm = JSON.parse(localStorage.getItem("pcpFormData"));
  const comfortChildLength = Object.keys(pcp.comfortingChild).length;
  useEffect(() => {
    loadPcpData({
      familyId: auth.guardian.family_id
    });
  }, []);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (status === "submitted") {
      loadPcpSubmissionStatuses({ familyId: auth.guardian.family_id });
    }
    if (status === "saved") {
      loadPcpData({
        familyId: auth.guardian.family_id
      });
    }
  }, [status]);

  const errorModalClosed = () => {
    setShowErrorModal(false);
  };

  const patchForm = payload => {
    patchData(payload);
  };

  const saveForm = payload => {
    saveData(payload);
  };

  // Do something on step change
  const onStepChange = (stats) => {
  };

  const handleBackBtn = (e, previousStep)=> {
    if(showModal) {
      setShowUnsavedChangesModal(true);
      setStepObj({previousStep});
    } else {
      previousStep()
    }
  }

  const handleNavSetStep = (step, goToStep) => {
    setStep(step);
    setStepObj({goToStep});
  }

  const jumpToSectionFromModal = () => {
    if(stepObj.previousStep) {
      stepObj.previousStep()
    } else {
      stepObj.goToStep(step)
    }
  }

  const toggleUnsavedChangesModal = show => {
    setShowUnsavedChangesModal(show);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="wizard-container">
          <React.StrictMode>
            <PcpStepWizard
              onStepChange={onStepChange}
              nav={
                <PcpStepWizardNav
                  handleNavSetStep={handleNavSetStep}
                  pcp={pcp}
                  saveForm={saveForm}
                  setShowUnsavedChangesModal={setShowUnsavedChangesModal}
                  submissionStatuses={submissionStatuses}
                  comfortChildLength={comfortChildLength}
                />
              }>
              <ImportantCareInfo
                centerInfo={centerInfo}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              <DevelopmentalHistory
                centerInfo={centerInfo}
                handleBackBtn={handleBackBtn}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              <NutritionPracticesAndRoutines
                centerInfo={centerInfo}
                handleBackBtn={handleBackBtn}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              <SleepingRoutines
                centerInfo={centerInfo}
                handleBackBtn={handleBackBtn}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              {comfortChildLength ? (
                <ComfortingChild
                  centerInfo={centerInfo}
                  handleBackBtn={handleBackBtn}
                  pcp={pcp}
                  patchForm={patchForm}
                  saveForm={saveForm}
                  localStoragePCPForm={localStoragePCPForm}
                  updateUnsavedChangesWarning={updateUnsavedChangesWarning}
                />
              ) : null}
              <ToiletingRoutines
                centerInfo={centerInfo}
                handleBackBtn={handleBackBtn}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              <SocialRelationships
                centerInfo={centerInfo}
                handleBackBtn={handleBackBtn}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              <AdditionalInfo
                centerInfo={centerInfo}
                handleBackBtn={handleBackBtn}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                localStoragePCPForm={localStoragePCPForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              />
              <Signature
                centerInfo={centerInfo}
                comfortChildLength={comfortChildLength}
                pcp={pcp}
                patchForm={patchForm}
                saveForm={saveForm}
                submissionStatuses={submissionStatuses}
              />
            </PcpStepWizard>
          </React.StrictMode>
        </div>
      </div>
      <ErrorModal
        errorMessage={error}
        showErrorModal={showErrorModal}
        errorModalClosed={errorModalClosed}
      />
      <UnsavedChangesModal
        centerInfo={centerInfo}
        enrollment={enrollment}
        handleAddressSaveInUnsavedChangesModal={null}
        pcp={pcp}
        showUnsavedChangesModal={showUnsavedChangesModal}
        toggleUnsavedChangesModal={toggleUnsavedChangesModal}
        jumpToSectionFromModal={jumpToSectionFromModal}
        resetAddressValidationResponse={null}
        saveForm={saveForm}
        unsavedChanges={unsavedChanges}
        updateAddressValidationComingFromUnsavedChangesModal={null}
        updateUnsavedChangesWarning={updateUnsavedChangesWarning}
        verifyAddress={null}
      />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  auth: state.auth,
  pcp: state.pcp,
  centerInfo: state.centerInfo,
  enrollment: state.enrollment
});

const mapDispatchToProps = dispatch => ({
  loadPcpData: bindActionCreators(loadPcpData, dispatch),
  loadPcpSubmissionStatuses: bindActionCreators(loadPcpSubmissionStatuses, dispatch),
  patchData: bindActionCreators(patchData, dispatch),
  saveData: bindActionCreators(saveData, dispatch),
  updateUnsavedChangesWarning: bindActionCreators(updateUnsavedChangesWarning, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(PcpStepper);

import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const colorFromSubmissionStatus = (submissionStatus) => {
  switch (submissionStatus) {
    case true:
      return 'green';
    case false:
      return 'red';
    case 'optional':
      return '#00AEEF';
    case 'dirty':
      return 'orange';
    default:
      return 'red';
  }
}
const FormSubmitStatusIcon = ({ page, submissionStatus }) => {
  return (
    <Fragment>
      <span
        id={`formSubmitStatusIcon-${page}`}
        className="mr-2"
        style={{ color: colorFromSubmissionStatus(submissionStatus)}}
      >
        <FontAwesomeIcon
          icon={submissionStatus === true ? faCheckCircle : faExclamationCircle}
        />
      </span>
    </Fragment>
  );
};

export default FormSubmitStatusIcon;

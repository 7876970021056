import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ImageUploader from "../../SharedComponents/ImageUploader";
import Resizer from "react-image-file-resizer";

const ImageUploadModal = ({
  centerId,
  child,
  fetchingData,
  imageModalClosed,
  photoPath,
  saveChildImage,
  showImageModal,
}) => {
  const [fileObj, setFileObj] = useState({
    files: [],
    fileNames: [],
    hasError: false,
    isFileDropped: false,
    isModalOpen: showImageModal
  });

  const updateFileObj = (files, fileNames, isFileDropped, hasError, isModalOpen) => {
    setFileObj((prevValue) => {
      return { ...prevValue, files, fileNames, isFileDropped, hasError, isModalOpen}
    });
  }

  useEffect(
    () => () => {
      fileObj.files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileObj.files]
  );
  
  const resizeFile = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      200,
      200,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

  const handleDrop = useCallback(async acceptedFiles => {
    const compressedFiles = await Promise.all(acceptedFiles.map((file) => resizeFile(file)));
    const files = compressedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    const fileNames = acceptedFiles.map((file) => file.name);
    updateFileObj(files, fileNames, files.length > 0, files.length === 0, true)
  }, []);

  const toggle = () => {
    updateFileObj([], [], false, false, false)
    imageModalClosed();
  };

  const handleSubmit = () => {
    if (!fileObj.isFileDropped || fileObj.hasError) {
      updateFileObj([], [], false, true, true)
      return;
    }
    const formData = new FormData();
    formData.append("file", fileObj.files[0]);
    saveChildImage({ childId: child.id, file: formData });
    imageModalClosed();
  }

  return (
    <Fragment>
      <Modal isOpen={showImageModal} toggle={toggle} className="">
      <AvForm onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
          <ModalBody>
          <ImageUploader
            centerId={centerId}
            fetchingData={fetchingData}
            fileObj={fileObj}
            handleDrop={handleDrop}
            photoPath={photoPath}
            updateFileObj={updateFileObj}
          />
          </ModalBody>
          <ModalFooter>
          <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          <Button color="primary">Save</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

export default ImageUploadModal;

import React, {Fragment, useState, useEffect} from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "react-moment";
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
    forgotPassword,
    getCentersByEmail,
  } from "../../../redux/actionCreators/auth";
import SweetAlert from 'sweetalert-react';
import {version} from '../../../utils';
import Spinner from "../../SharedComponents/Spinner";

const ForgotPassword = (props) => {
    const thisYear = new Date();
    const [email, setEmail] = useState('')
    const history = useHistory();
    const [selectedCenterId, setSelectedCenterId] = useState(0);
    const [centers, setCenters] = useState([]);
    const [modal, setModal] = useState({
      show: false,
      title: '',
      message: '',
    });

    useEffect(() => {
        if (centers.length > 0) {
          setSelectedCenterId(centers[0].id);
        }
    }, [centers]);

    function validateForm() {
        return email.length > 0 && centers.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const res = await props.forgotPassword({
            email,
            centerId: selectedCenterId,
        });
        if(res.response) {
          setModal({
            show: true,
            title: '',
            message: 'A link for resetting your password has been sent to the email address.'
          });
        } else if (res.error) {
            setModal({
              show: true,
              title: '',
              message: res.error
            });
        } else {
            setModal({
              show: true,
              title: '',
              message: 'Something went wrong, please try again. If you continue to have an issue, please let your center know.'
            });
        }
    }

    return (<Fragment>
        <div className="h-100 bg-plum-plate bg-animation">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="6" className="mx-auto app-login-box">
                    <div className="app-logo-inverse mx-auto mb-3 mt-3"/>

                    <div className="modal-dialog w-100 mx-auto mb-3">
                        <div className="modal-content">
                            <div className="modal-body">
                            <div className="modal-title text-center">
                                <h4 className="mt-2">
                                    <div>Forgot your Password?</div>
                                    <span>Enter the email and select the center. We'll send a link to reset your password.</span>
                                </h4>
                            </div>
                                <div>
                                    <br />
                                    <Form>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Email Address</Label>
                                                    <Input 
                                                        type="email" 
                                                        name="email" 
                                                        id="email"
                                                        autoFocus
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                                        placeholder="Email"
                                                        onBlur={(e) => {
                                                            if (!e || !e.target || !e.target.value) return;
                                                            props
                                                              .getCentersByEmail({ email })
                                                              .then((res) => {
                                                                if (res && res.length > 0) setCenters(res);
                                                              });
                                                          }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Center</Label>
                                                    <Input
                                                        type={"select"}
                                                        value={selectedCenterId}
                                                        onChange={(e) =>
                                                            setSelectedCenterId(e.target.value)
                                                        }
                                                        disabled={centers.length === 0}
                                                    >
                                                        {centers.length &&
                                                            centers.map((c) => (
                                                            <option value={c.id} key={c.id}>
                                                                {c.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="divider"/>
                                <h6 className="mb-0 account-login">
                                    Already have an account?{" "}
                                    <button
                                    className="btn btn-link"
                                    onClick={() => history.push("/signin")}
                                    type="button"
                                    >
                                    Sign in
                                    </button>
                                </h6>
                                <h6 className="mb-0 account-login">
                                    No account?{" "}
                                    <button
                                    className="btn btn-link"
                                    onClick={() => history.push("/verifyEmail")}
                                    type="button"
                                    >
                                    Sign up now
                                    </button>
                                </h6>
                            </div>
                            <div className="modal-footer clearfix">
                                <div className="float-right">
                                    <Button 
                                        color="primary" 
                                        size="lg"
                                        disabled={!validateForm()}
                                        onClick={handleSubmit}
                                    >Recover Password</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-white opacity-8 mt-3">
                        Copyright &copy; <Moment format="YYYY">{thisYear}</Moment> The Learning Experience<sup>&reg;</sup>. All Rights Reserved<br/>
                        Product {version}
                    </div>
                    <SweetAlert
                      title={modal.title}
                      confirmButtonColor=""
                      show={modal.show}
                      text={modal.message}
                      onConfirm={() => {
                        setModal({show: false});
                        history.push("/signin");
                      }}
                    />
                </Col>
            </div>
        </div>
        <Spinner showSpinner={props.auth.status === "loading"} />
    </Fragment>)
}

const mapStateToProps = (state, props) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: bindActionCreators(forgotPassword, dispatch),
    getCentersByEmail: bindActionCreators(getCentersByEmail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(ForgotPassword);
import React, { Fragment, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { usePagination, useTable } from "react-table";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const TableStyles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    /* display: block; */
    /* max-width: 100%; */
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid black; */
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tbody {

      tr:nth-of-type(2n) {
        background-color: white;
    }
      tr:nth-of-type(2n+1) {
        background-color: rgb(249, 249, 249);
    }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      border-bottom: 3px solid #EC008C !important;
      background: aliceblue;
      color: black;
      font-weight: bold;
    }

    th,
    td {
      margin: 0;
      padding: 0.3rem;
      border-bottom: 1px solid #f4f4f4;;
      border-right: 1px solid #f4f4f4;;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  th,
  td:nth-child(1) {
    width: 1.5%;
  }
  th,
  td:nth-child(2) {
    width: 8%;
  }

  th,
  td:nth-child(3) {
    width: 4%;
  }

  th,
  td:nth-child(4) {
    width: 3%;
  }
  .pagination-section {
    padding: 0.5rem;
  }
`;

const CurrentBalance = ({ balance, countryId }) => {
  return (
    <h3 className="text-left text-black mb-0" style={{ fontSize: "1rem" }}>
      <span className=" text-pink mr-3">Current Balance:</span>{countryId === 230 ? '£' : '$' }{balance}
    </h3>
  );
};

const ReceivablesTable = ({
  centerProfile,
  receivables,
  setCurrentPageSize,
  updatedPageSize,
}) => {
  const {
    historicalData,
  } = receivables;

  const data = useMemo(() => historicalData, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Description",
        accessor: d => {
          return (
            <div>
              {d.isPayment ? `${d.paymentMethodName} Payment` + (d.paymentCardNumber ? ` - ${d.paymentCardNumber.slice(-8)}` : '') : d.memo}
            </div>
          )
        },
      },
      {
        Header: "Item",
        accessor: "billingItem",
      },
      {
        Header: "Child Name",
        accessor: d => {
          return (
            <div>
              {d.childFirstName} {d.childLastName}
            </div>
          );
        },
      },
      {
        Header: "Debits",
        accessor: d => {
          return d.entryTypeId === 1 && <div>{centerProfile.countryId === 230 ? '£' : '$' }{d.amount}</div>;
        },
      },
      {
        Header: "Credits",
        accessor: d => {
          return d.entryTypeId === 2 && <div>{centerProfile.countryId === 230 ? '£' : '$' }{d.amount}</div>;
        },
      },
      {
        Header: "Balance",
        accessor: d => {
          return <div>{centerProfile.countryId === 230 ? '£' : '$' }{d.balance}</div>;
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setCurrentPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    setPageSize(updatedPageSize);
  }, [updatedPageSize]);

  return (
    <TableStyles>
      <Row className="tableWrap">
        <Col>
          <table {...getTableProps()}>
            <thead>
              {// Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps({
                        className: column.collapse ? "collapse" : "",
                      })}
                    >
                      {// Render the header
                      column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {// Loop over the table rows
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? "collapse" : "",
                          })}
                        >
                          {// Render the cell contents
                          cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      <Container className="pagination-section">
        <Row>
          <Col xs={12} md={6}>
            <Form inline>
              <FormGroup className="mr-3">
                <Label className="mr-2" for="test">
                  Go to page:
                </Label>
                <Input
                  id="test"
                  type="number"
                  defaultValue={pageIndex + 1 || 1}
                  onChange={(e) => {
                    let num = e.target.value
                    const page = num
                      ? +num - 1
                      : 0;
                    e.target.value = num > 0 ? num : 1
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </FormGroup>
              <div>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </div>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Last
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Next
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              Previous
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              First
            </Button>
          </Col>
        </Row>
      </Container>
    </TableStyles>
  );
};

const TransactionsAndPayments = ({ centerInfo, guardian, primaryPaymentMethod, receivables }) => {
  const {
    finalAccountsReceivableBalance,
  } = receivables;
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [updatedPageSize, setUpdatedPageSize] = useState(10);
  const {centerProfile} = centerInfo;
  // const [showPaymentButton, setShowPaymentButton] = useState(false)
  
  // useEffect(() => {
  //   if(primaryPaymentMethod && primaryPaymentMethod.length) {
  //     setShowPaymentButton(true);
  //   }
  // }, [primaryPaymentMethod])

  return (
    <Fragment>
      {receivables && !!Object.keys(receivables).length && (
        <Fragment>
          <Row className="mb-3">
            <Col>
              <Row>
                <Col md={4}>
                  <Input
                    type="select"
                    value={currentPageSize}
                    onChange={(e) => {
                      setUpdatedPageSize(+e.target.value);
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row style={{ alignItems: "center" }}>
                <Col>
                  {/* TODO: ask Josh which receivable balance to show here */}
                  {/* {showPaymentButton && <MakeAPayment centerInfo={centerInfo} guardian={guardian}/>} */}
                </Col>
                <Col>
                  <CurrentBalance countryId={centerProfile.countryId} balance={finalAccountsReceivableBalance} />
                </Col>
              </Row>
            </Col>
          </Row>
          <ReceivablesTable
            receivables={receivables}
            setCurrentPageSize={setCurrentPageSize}
            updatedPageSize={updatedPageSize}
            centerProfile={centerProfile}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default TransactionsAndPayments;

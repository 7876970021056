import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, Nav, TabContent, TabPane } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import DisplayTab from "../DisplayTab";

const InformedConsentPermissions = ({
  enrollment,
  currentStep,
  nextStep,
  previousStep,
  saveForm
}) => {
  const { enrollmentConsents } = enrollment;
  // const [values, setValues] = useState({});
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (enrollmentConsents && enrollmentConsents.length > 0) {
      setTabs(enrollmentConsents);
      if (!activeTab) setActiveTab(enrollmentConsents[0].id);
    }
  }, [enrollmentConsents]);

  const handleSubmit = (event, errors) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) {
      const nextTabs = tabs.map(tab => ({
        ...tab,
        ...(errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.id) && { error: true })
      }));
      setTabs(nextTabs);
      return;
    }
    saveForm({ enrollmentConsents: tabs, section: "informedConsentPermissions" });
    nextStep();
  };

  const toggleTabs = id => {
    if (activeTab !== id) setActiveTab(id);
  };

  const updateInput = e => {
    let { name, value } = e.target;
    const id = +name.slice(name.indexOf("-") + 1);
    name = name.slice(0, name.indexOf("-"));
    if(name === "transportPermission") {
      value = value === "1" ? 1 : 0;
    } else {
      value = value === "true" ? 0 : 1
    }
    const nextTabs = tabs.map(el => {
      if (el.id === id) {
        return {
          ...el,
          [name]: value
        };
      }
      return el;
    });
    setTabs(nextTabs);
  };

  const displayTabInputs = (tab, idx) => {
    const { id, transportPermission, walkPermission } = tab;
    return (
      <TabPane key={id} tabId={id} style={{color: "#495057"}}>
        <h6>
          <Label>Walk Permission</Label>
        </h6>
        <p>
          The center may go on supervised walks with staff in the surrounding area. You will be
          notified in advance of all trips outside of our licensed premises. These include children
          taking walks and infants strolling in their buggy. Children may be taken to the areas
          listed below, which are not a part of our licensed premises:
        </p>
        <AvField
          type="checkbox"
          name={`walkPermission-${id}`}
          label="I give permission for my child to participate
                in walks."
          errorMessage="Please acknowledge the above statement in order to continue."
          onChange={updateInput}
          value={!!walkPermission}
          required
        />
        <br></br>
        <h6>
          <Label>Field Trip & Transportation</Label>
        </h6>
        <p>For Parents/Guardians of Children Ages 4 Years Old and Older Only</p>
        <p>
          I give the center permission to transport my child for the purposes of field trips that
          require bus transportation and/or transportation to or from his or her local school.
        </p>
        <AvRadioGroup
          className="fieldTripRadio"
          name={`transportPermission-${id}`}
          errorMessage="Please select one of the options."
          inline
          required
          value={transportPermission ? "1" : "0"}>
          <AvRadio label="Yes" value="1" onChange={updateInput} style={{ fontSize: "11px" }} />
          <br></br>
          <AvRadio label="No" value="0" onChange={updateInput} />
        </AvRadioGroup>
      </TabPane>
    );
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Informed Consent & Acknowledgments</h3>
      <Nav tabs>
        {tabs.map(tab => (
          <DisplayTab
            key={tab.id}
            activeTab={activeTab}
            tab={tab}
            tabId={tab.id}
            toggleTabs={toggleTabs}
            tabLabel={tab.firstName}
          />
        ))}
      </Nav>
      <AvForm onSubmit={handleSubmit}>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, idx) => displayTabInputs(tab, idx))}
        </TabContent>
        <FormGroup>
          <Button color="secondary" size="lg" block onClick={previousStep}>
            Back
          </Button>
          <Button color="primary" size="lg" block>
            Save and Continue
          </Button>
        </FormGroup>
      </AvForm>
    </div>
  );
};

export default InformedConsentPermissions;

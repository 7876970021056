import React from "react";
import { Button, Col, List, Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

const AddressValidationModal = ({
  modal,
  saveFromModal,
}) => {
  const {addressValidationResponse, isOpen, handleInvalidAddress, stateNameOrAbbrev, updatedContactInfo} = modal;
  return (
    <div>
      {modal.addressValidationResponse && modal.addressValidationResponse !== "INVALID_ADDRESS" && 
        <Modal isOpen={isOpen} className="">
        <AvForm>
          <ModalHeader>
          Our records could not verify the address you provided. Please double check the address you entered or use the suggested address provided below.'
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <List type="unstyled">
                  <li>{addressValidationResponse.street_line_1}</li>
                  <li>{addressValidationResponse.street_line_2 || ""}</li>
                  <li>{addressValidationResponse.city}</li>
                  <li>{addressValidationResponse.state_code}</li>
                  <li>{addressValidationResponse.postal_code}</li>
                </List>
                </Col>
                <Col>
                <List type="unstyled">
                  <li>{updatedContactInfo.address1}</li>
                  <li>{updatedContactInfo.address2}</li>
                  <li>{updatedContactInfo.city}</li>
                  <li>{stateNameOrAbbrev}</li>
                  <li>{updatedContactInfo.zipCode || updatedContactInfo.postalCode}</li>
                </List>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={() => saveFromModal(true)}>
              Use suggested address
            </Button>
            <Button color="danger"  onClick={() => saveFromModal()}>
              No, I want to use this address
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      }
      {modal.addressValidationResponse && modal.addressValidationResponse === "INVALID_ADDRESS" && 
        <Modal isOpen={isOpen} className="">
        <AvForm>
          <ModalHeader>
          Invalid address provided. Please double check the address information entered. If you feel this information is correct, please contact the site administrator.
          </ModalHeader>
          {/* <ModalBody>
            <Row>
              <Col>
                <List type="unstyled">
                  <li>{addressValidationResponse.street_line_1}</li>
                  <li>{addressValidationResponse.street_line_2 || ""}</li>
                  <li>{addressValidationResponse.city}</li>
                  <li>{addressValidationResponse.state_code}</li>
                  <li>{addressValidationResponse.postal_code}</li>
                </List>
                </Col>
                <Col>
                <List type="unstyled">
                  <li>{updatedContactInfo.address1}</li>
                  <li>{updatedContactInfo.address2}</li>
                  <li>{updatedContactInfo.city}</li>
                  <li>{stateNameOrAbbrev}</li>
                  <li>{updatedContactInfo.zipCode || updatedContactInfo.postalCode}</li>
                </List>
              </Col>
            </Row>
          </ModalBody> */}
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={() => handleInvalidAddress()}>
              Close
            </Button>
            <Button color="danger"  onClick={() => saveFromModal()}>
              Continue anyway
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      }
    </div>
  );
};

export default AddressValidationModal;

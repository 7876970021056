import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import UploadDocumentModal from "./UploadDocumentModal";
import ViewDocumentModal from "../../SharedComponents/ViewDocumentModal";
import DocumentAccordian from "../../SharedComponents/DocumentAccordian";

const MyDocuments = ({
  centerInfo,
  childs,
  documents,
  documentTypes,
  fetchingData,
  getImage,
  guardian,
  imageSrc,
  saveDocument,
  status,
  token
}) => {
  const [documentAccordianIsOpen, setdocumentAccordianIsOpen] = useState({});
  const [allDocuments, setAllDocuments] = useState({});
  const [documentToView, setDocumentToView] = useState(null);
  const [viewDocumentClicked, setViewDocumentClicked] = useState(false);
  const {centerProfile} = centerInfo;
  useEffect(() => {
    if (documentTypes.length) {
      documentTypes.forEach((type) => {
        updateDocumentAccordians(type, true);
        const filteredDocs = documents.filter((el) => (el.type_id === type.id) && (el.is_family_document === type.is_family_document_type)); 
        setAllDocuments((prevState) => ({
          ...prevState,
          [type.name]: filteredDocs,
        }));
      });
    }
  }, [documents]);

  const showDocument = (doc) => {
    setDocumentToView(doc);
    setViewDocumentClicked(true);
  };
  const resetViewDocumentClickEvent = (doc) => {
    setViewDocumentClicked(false);
  };

  // const findDocuments = (inputs) => {
  //   const currentDocsOfSelectedDocType = Object.values(
  //     allDocuments
  //   ).filter((type) =>
  //     type.some((doc) => doc.typeId === +inputs.documentTypeId)
  //   );
  //   const selectedChildrenDocs = inputs.children.filter((child) =>
  //     currentDocsOfSelectedDocType[0].some((el) => el.childId === +child)
  //   );
  //   return selectedChildrenDocs;
  // };

  const displayMyDocumentsAccordian = (type, index) => {
    return (
      <DocumentAccordian
        centerProfile={centerProfile}
        key={index + 1}
        heading={`${type.name}${type.note ? " ("+ type.note + ")" : ""}`}
        isOpen={documentAccordianIsOpen[type.name]}
        setIsOpen={updateDocumentAccordians}
        type={type}
        documents={allDocuments[type.name]}
        showDocument={showDocument}
        section={'myAccount'}
      />
    );
  };

  const updateDocumentAccordians = (type, status) => {
    setdocumentAccordianIsOpen((prevState) => ({
      ...prevState,
      [type.name]: status,
    }));
  };

  return (
    <Row className="flex-grow-1 m-1">
      <Col>
        <UploadDocumentModal
          childs={childs}
          // findDocuments={findDocuments}
          guardian={guardian}
          saveDocument={saveDocument}
          documentTypes={documentTypes}
          fetchingData={fetchingData}
          status={status}
        />
        <ViewDocumentModal
          centerProfile={centerProfile}
          documentToView={documentToView}
          getImage={getImage}
          imageSrc={imageSrc}
          viewDocumentClicked={viewDocumentClicked}
          resetViewDocumentClickEvent={resetViewDocumentClickEvent}
          token={token}
        />
        {documentTypes &&
          documentTypes.map((type, index) => displayMyDocumentsAccordian(type, index))}
      </Col>
    </Row>
  );
};

export default MyDocuments;

import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";

const CustomDropDown = ({ placeholder, values }) => {
  return (
    <Fragment>
      <option key="0" value={-1}>
        {placeholder}
      </option>
      {values &&
        values.map((e) => (
          <option key={e.id} value={e.id}>
            {e.name}
          </option>
        ))}
    </Fragment>
  );
};

const medicalTypes = [
  { id: 2, name: "Allergy" },
  { id: 3, name: "Dietary Preference" },
  { id: 1, name: "Medical Condition" },
];

const EditMedicalModal = ({
  editMedicalClicked,
  child,
  medical,
  medicalAllergy,
  medicalCondition,
  medicalDietaryPreferences,
  medicalSeverity,
  modalClosed,
  updateChildMedical,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [saveButtonIsDisabled, setSaveButtonIsDisabled] = useState(true);
  const [updatedMedical, setUpdatedMedical] = useState({});
  const [selectedType, setSelectedType] = useState(updatedMedical.typeId || -1);

  useEffect(() => {
    if (editMedicalClicked) {
      setIsOpen(true);
    }
  }, [editMedicalClicked]);

  useEffect(() => {
    const data = { ...medical };
    if (Object.keys(medical).length) {
      setUpdatedMedical((prevState) => ({ ...prevState, ...data }));
      setSelectedType((prevState) => medical.typeId);
    }
  }, [medical]);

  const getConditionsByType = (type) => {
    type = parseInt(type);
    switch (type) {
      case 1:
        return medicalCondition;
      case 2:
        return medicalAllergy;
      case 3:
        return medicalDietaryPreferences;
      default:
        return [];
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    modalClosed();
    setSaveButtonIsDisabled(true);
    setUpdatedMedical((prevState) => ({}));
  };

  const handleInputChange = (event) => {
    setUpdatedMedical((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    if (saveButtonIsDisabled) {
      setSaveButtonIsDisabled(false);
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(parseInt(event.target.value));
  };

  const handleSubmit = (event, errors, values) => {
    const data = {
      ...updatedMedical,
    };

    if (type === "Add") {
      data.childId = child.id;
      data.typeId = selectedType;
    }
    if (type === "Edit" && selectedType !== medical.typeId) {
      data.newTypeId = selectedType;
    }

    if (!errors.length) {
      if (child && child.id) {
        updateChildMedical(data);
        toggle();
      }
    }
  };

  const isOtherCategory = (issueId) => {
    const target = getConditionsByType(selectedType).find(
      (e) => e.id === issueId
    );
    if (!target) return false;
    return target.name && target.name.toLowerCase() === "other";
  };

  const renderConditional =
    type === "Edit"
      ? updatedMedical && Object.keys(updatedMedical).length
      : true;

  return (
    <Fragment>
      {renderConditional && (
        <Fragment>
          <Modal isOpen={isOpen} toggle={toggle} className="testing">
            <AvForm onSubmit={handleSubmit}>
              <ModalHeader toggle={toggle}>
                {type} Allergy/Medical Condition Details
              </ModalHeader>
              <ModalBody>
                <AvField
                  id="typeId"
                  name="typeId"
                  type="select"
                  label="Type"
                  onChange={handleTypeChange}
                  value={updatedMedical.typeId || -1}
                  validate={{
                    required: (val) =>
                      parseInt(val) > 0 ? true : "Please select a Type.",
                  }}
                >
                  <CustomDropDown
                    placeholder="Select a Type"
                    values={medicalTypes}
                  />
                </AvField>
                <AvField
                  id="issueId"
                  name="issueId"
                  type="select"
                  label="Name"
                  onChange={handleInputChange}
                  value={updatedMedical.issueId || -1}
                  validate={{
                    required: (val) =>
                      parseInt(val) > 0 ? true : "Please select a Type.",
                  }}
                >
                  <CustomDropDown
                    placeholder="Select a Type"
                    values={getConditionsByType(selectedType)}
                  />
                </AvField>
                <AvField
                  id="issue"
                  name="issue"
                  type="text"
                  label="Additional Information"
                  onChange={handleInputChange}
                  value={
                    isOtherCategory(updatedMedical.issueId)
                      ? updatedMedical.issue
                      : ""
                  }
                  validate={{
                    required: {
                      value: isOtherCategory(updatedMedical.issueId),
                      errorMessage: "Please enter an additional information.",
                    },
                  }}
                  disabled={!isOtherCategory(updatedMedical.issueId)}
                />
                <AvField
                  id="severityId"
                  name="severityId"
                  type="select"
                  label="Severity"
                  onChange={handleInputChange}
                  value={updatedMedical.severityId || -1}
                  disabled={selectedType === 3}
                  validate={{
                    required: (val) =>
                      selectedType === 3 || parseInt(val) > 0
                        ? true
                        : "Please select a Severity.",
                  }}
                >
                  <CustomDropDown
                    placeholder="Select a Severity"
                    values={medicalSeverity}
                  />
                </AvField>
                <AvField
                  id="treatment"
                  name="treatment"
                  type="text"
                  label="Treatment & Notes"
                  onChange={handleInputChange}
                  value={updatedMedical.treatment}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a treatment & note.",
                    },
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" disabled={saveButtonIsDisabled}>
                  Save
                </Button>
              </ModalFooter>
            </AvForm>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditMedicalModal;

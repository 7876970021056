import * as actionTypes from "../actionTypes";

export const loadData = payload => async (dispatch, getState, api) => {
  const { familyId } = payload;
  if (!familyId) return;

  dispatch({
    type: actionTypes.ENROLLMENT_LOADDATA__START
  });
  try {
    const res = await api.get(`/enrollment/${familyId}`, {
      Authorization: `Bearer ${getState().auth.token}`
    });
    console.log("@@@ redux - enrollment loadData", res);
    if (res.response) {
      dispatch({
        type: actionTypes.ENROLLMENT_LOADDATA__SUCCESS,
        payload: res.response
      });
      return res.response;
    } else {
      dispatch({
        type: actionTypes.ENROLLMENT_LOADDATA__FAILURE,
        payload: res.error || "There was an error loading your information. Please try again."
      });
      console.log("enrollment loadData error", res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.ENROLLMENT_LOADDATA__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "There was an error loading your information. Please try again."
    });
    console.log("enrollment loadData exception", err);
  }
};

export const loadEnrollmentSubmissionStatuses = payload => async (dispatch, getState, api) => {
  const { familyId } = payload;
  if (!familyId) {
    dispatch({
      type: actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__FAILURE,
      payload: "Load enrollment error. Enrollment id is missing."
    });
    return;
  }
  dispatch({
    type: actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__START
  });
  try {
    const res = await api.get(`/enrollment/getEnrollmentStatuses/${familyId}`, {
      Authorization: `Bearer ${getState().auth.token}`
    });
    if (res.response && res.response.length) {
      dispatch({
        type: actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__SUCCESS,
        payload: res.response.map(el => el.enrollment_status_id)
      });
    } else {
      dispatch({
        type: actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__FAILURE,
        payload: res.error || "There was an error loading your information. Please try again."
      });
      console.log("@@@ enrollment loadEnrollmentSubmissionStatuses error", res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "There was an error loading your information. Please try again."
    });
    console.log("@@@ enrollment loadEnrollmentSubmissionStatuses exception", err);
  }
};

export const updateFormStatus = (payload = {}) => async (dispatch, getState, api) => {
  console.log("updateformstatus");
  const { section, value } = payload;
  if (section === undefined || value === undefined) return;

  if (getState().enrollment.submissionStatuses[section] === value) return;
  dispatch({
    type: actionTypes.ENROLLMENT_UPDATE_FORM_STATUS,
    payload: { [section]: value }
  });
};

export const deleteGuardian = (payload = {}) => async (dispatch, getState, api) => {
  const { guardian } = payload;
  if (!guardian || !guardian.ppGuardianId) return;

  try {
    const res = await api.post(`/enrollment/deleteGuardian`, guardian, {
      Authorization: `Bearer ${getState().auth.token}`
    });
    if (res.response) {
      dispatch({
        type: actionTypes.ENROLLMENT_DELETEGUARDIAN__SUCCESS
      });
      dispatch(
        loadData({
          familyId: getState().auth.guardian.family_id
        })
      );
    } else {
      dispatch({
        type: actionTypes.ENROLLMENT_DELETEGUARDIAN__FAILURE,
        payload: "Can't remove the guardian from CORE"
      });
      console.log("enrollment deleteGuardian error", res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.ENROLLMENT_DELETEGUARDIAN__FAILURE,
      payload: "Can't remove the guardian from CORE"
    });
    console.log("deleteGuardian exception", err);
  }
};

export const saveData = (payload = {}) => async (dispatch, getState, api) => {
  const {section } = payload;
  const newData = {
    ...getState().enrollment,
    ...payload,
    currentUser: getState().auth.guardian,
    centerInfo: getState().centerInfo.centerProfile
  }

  console.log("redux - enrollment saveData", newData);

  dispatch({
    type: actionTypes.ENROLLMENT_SAVEDATA__START
  });

  try {
    const res = await api.post(`/enrollment`, newData, {
      Authorization: `Bearer ${getState().auth.token}`
    });
    if (res.response) {
      dispatch({
        type: actionTypes.ENROLLMENT_SAVEDATA__SUCCESS,
        payload: section,
      });
      if(section === "emergencyContacts") {
        const localStorageData = JSON.parse(localStorage.getItem("enrollmentFormData")) || {}
        if(localStorageData.emergencyContacts) {
          delete localStorageData.emergencyContacts
          localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageData));
        }
      }
    } else {
      dispatch({
        type: actionTypes.ENROLLMENT_SAVEDATA__FAILURE,
        payload:
          res.error || "There was an error saving your information to the server. Please try again."
      });
      console.log("@@@ enrollment saveData error", res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.ENROLLMENT_SAVEDATA__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "There was an error saving your information to the server. Please try again."
    });
    console.log("enrollment saveData exception", err);
  }
};

export const updateUnsavedChangesWarning = (payload = {}) => async (dispatch, getState, api) => {
  console.log('updateUnsavedChangesWarning')
  // const { section } = payload;
  dispatch({
    type: actionTypes.ENROLLMENT_UPDATE_UNSAVED_CHANGES_WARNING,
    payload: payload
  })
}

export const updateAddressValidationComingFromUnsavedChangesModal = (payload = {}) => async (dispatch, getState, api) => {
  console.log('updateAddressValidationComingFromUnsavedChangesModal')
  const { addressValidationComingFromModal } = payload;
  dispatch({
    type: actionTypes.ENROLLMENT_UPDATE_ADDRESS_VALIDATION_COMING_FROM_UNSAVED_CHANGES_MODAL,
    payload: addressValidationComingFromModal
  })
}

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Row, Button, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import TemporaryImageUploader from "./TemporaryImageUploader";
import AddressValidationModal from "./AddressValidationModal";
import Resizer from "react-image-file-resizer";
import { filterState, generateAddressLabels } from "../../../utils";

const ContactInformation = ({
  addressValidationResponse,
  centerInfo,
  contactInfo,
  fetchingData,
  receivedData,
  saveContactInfo,
  setCustomErrorMessage,
  setShowErrorModal,
  verifyAddress
}) => {
  const [enabled, setEnabled] = useState(false);
  const [modal, setModal] = useState({});
  const [updatedContactInfo, setUpdatedContactInfo] = useState(null);
  const [fileObj, setfileObj] = useState({
    files: [],
    fileNames: [],
    isFileDropped: false
  });

  const { centerProfile, states } = centerInfo;
  const addressLabels = generateAddressLabels(centerProfile.countryId);
  const saveFromModal = useCallback(
    (isSuggestedAddress = false) => {
      const formData = new FormData();
      if (fileObj.isFileDropped) {
        formData.append("file", fileObj.files[0]);
      }
      const state = filterState(addressValidationResponse.state_code, states, centerProfile.countryId !== 230, centerProfile.countryId === 230)
      if(isSuggestedAddress) {
        //sometimes county in UK not found in db collection
        if(!state) {
          setCustomErrorMessage('County not found. Please use a different address.')
          setShowErrorModal(true)
          return
        }
      }
        const contactInfo = {
          ...updatedContactInfo,
          ...(isSuggestedAddress && {
            address1: addressValidationResponse.street_line_1,
            address2: addressValidationResponse.street_line_2,
            city: addressValidationResponse.city,
            zipCode: addressValidationResponse.postal_code,
            stateId: state.id
          }),
          ...(fileObj.isFileDropped && { file: formData })
        };
        setCustomErrorMessage('')
        saveContactInfo(contactInfo);
        setModal({ ...modal, isOpen: false });
    },
    [modal]
  );

  useEffect(() => {
    setUpdatedContactInfo(contactInfo[0]);
  }, [contactInfo[0]]);

  // COMPARE USER INPUTED ADDRESS TO ADDRES VALIDATION RESPONSE
  useEffect(() => {
    if (addressValidationResponse && updatedContactInfo) {
      const selectedState = filterState(updatedContactInfo.stateId, states, false, false);
      const stateNameOrAbbrev =
        centerProfile.country_id === 230 ? selectedState.name.trim() : selectedState.abbrev.trim();
      if (
        updatedContactInfo.address1.trim() !== addressValidationResponse.street_line_1.trim() ||
        updatedContactInfo.city.trim() !== addressValidationResponse.city.trim() ||
        updatedContactInfo.zipCode.trim() !== addressValidationResponse.postal_code.trim() ||
        stateNameOrAbbrev !== addressValidationResponse.state_code
      ) {
        setModal({
          isOpen: true,
          updatedContactInfo,
          addressValidationResponse,
          stateNameOrAbbrev
        });
      } else {
        const formData = new FormData();
        if (fileObj.isFileDropped) formData.append("file", fileObj.files[0]);
        saveContactInfo({
          ...updatedContactInfo,
          ...(fileObj.isFileDropped && { file: formData })
        });
      }
    }
  }, [addressValidationResponse]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileObj.files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [fileObj]
  );

  const updateContactInfo = e => {
    setUpdatedContactInfo({
      ...updatedContactInfo,
      [e.target.id]: e.target.value
    });
  };

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "JPEG",
        100,
        0,
        uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0 || enabled ) return;
    verifyAddress({
      ...updatedContactInfo,
      countryCode: centerProfile.countryId === 230 ? "UK" : "US",
      stateName: filterState(updatedContactInfo.stateId, states, false, false).name
    });
  };

  const handleDrop = useCallback(async acceptedFiles => {
    const compressedFiles = await Promise.all(acceptedFiles.map(file => resizeFile(file)));
    const files = compressedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );

    const fileNames = acceptedFiles.map(file => file.name);
    setfileObj({ ...fileObj, files, fileNames, isFileDropped: true });
  }, []);

  const displayContactInfo = contact => {
    return (
      updatedContactInfo && (
        <Fragment key={contact.id}>
          <AddressValidationModal modal={modal} saveFromModal={saveFromModal} />
          <AvForm onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <AvField
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  type="text"
                  value={updatedContactInfo.firstName}
                  disabled
                />
              </Col>
              <Col md={6}>
                <AvField
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  type="text"
                  value={updatedContactInfo.lastName}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AvField
                  id="address1"
                  label="Address"
                  name="address1"
                  onChange={updateContactInfo}
                  placeholder="1234 Main St"
                  value={updatedContactInfo.address1}
                  type="text"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter an address."
                    },
                    maxLength: { value: 50 }
                  }}
                />
              </Col>
              <Col md={6}>
                <AvField
                  id="address2"
                  label="Address 2"
                  name="address2"
                  onChange={updateContactInfo}
                  placeholder="Apartment, studio, or floor"
                  value={updatedContactInfo.address2}
                  type="text"
                  validate={{
                    maxLength: { value: 50 }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AvField
                  id="city"
                  label={centerProfile.countryId === 230 ? "Town" : "City"}
                  name="city"
                  onChange={updateContactInfo}
                  type="text"
                  value={updatedContactInfo.city}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `Please enter a ${
                        centerProfile.countryId === 230 ? "town" : "city"
                      }.`
                    },
                    maxLength: { value: 50 }
                  }}
                />
              </Col>
              <Col md={4}>
                <AvField
                  id="stateId"
                  label={centerProfile.countryId === 230 ? "County" : "State"}
                  name="stateId"
                  onChange={updateContactInfo}
                  type="select"
                  value={updatedContactInfo.stateId}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `Please select a ${
                        centerProfile.countryId === 230 ? "county" : "state"
                      }.`
                    }
                  }}>
                  <option key="0" value="">
                    Select a state
                  </option>
                  {states.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </AvField>
              </Col>
              <Col md={2}>
                <AvField
                  id="zipCode"
                  label={centerProfile.countryId === 230 ? "Postal Code" : "Zip Code"}
                  name="zipCode"
                  onChange={updateContactInfo}
                  type="text"
                  value={updatedContactInfo.zipCode}
                  onKeyPress={e => {
                    if (
                      !(centerProfile.countryId === 230
                        ? /[\sa-zA-Z0-9]/.test(e.key)
                        : /[0-9]/.test(e.key))
                    )
                      e.preventDefault();
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
                    },
                    maxLength: {
                      value: `${centerProfile.countryId === 230 ? 8 : 5}`,
                      errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
                    },
                    minLength: {
                      value: `${centerProfile.countryId === 230 ? 6 : 5}`,
                      errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
                    },
                    pattern: {
                      value: `${
                        centerProfile.countryId === 230
                        ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                          : "^[0-9]{5}$"
                      }`,
                      errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AvField
                  id="primaryPhone"
                  label="Phone Number"
                  name="primaryPhone"
                  onChange={(e) => {setEnabled(true); updateContactInfo(e)}}
                  placeholder={centerProfile.countryId === 230 ? "XXXXX XXXXX" : "XXX-XXX-XXXX"}
                  type="text"
                  value={updatedContactInfo.primaryPhone}
                  disabled
                  // validate={{
                  //   minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
                  //   maxLength: { value: centerProfile.countryId === 230 ? 11 : 14 },
                  //   pattern: {
                  //     value: "^[0-9 ]+$",
                  //     errorMessage: 'Please enter a valid phone number.'
                  //   }
                  // }}
                />
              </Col>
              <Col md={6}>
                <AvField
                  id="email"
                  label="Email"
                  name="email"
                  onChange={updateContactInfo}
                  placeholder="JohnDoe@email.com"
                  type="email"
                  value={updatedContactInfo.email}
                  style={{ textTransform: "lowerCase" }}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Button className="mt-2" color="primary" size="lg">
                    Save
                  </Button>
                </FormGroup>
              </Col>
              <Col md={3}>
                <TemporaryImageUploader
                  fetchingData={fetchingData}
                  receivedData={receivedData}
                  handleDrop={handleDrop}
                  centerId={centerProfile.id}
                  files={fileObj.files}
                  fileNames={fileObj.fileNames}
                  photoPath={updatedContactInfo.photoPath}
                />
              </Col>
            </Row>
          </AvForm>
        </Fragment>
      )
    );
  };

  return (
    <Fragment>{contactInfo && contactInfo.map(contact => displayContactInfo(contact))}</Fragment>
  );
};

export default ContactInformation;

import * as authReducers from './auth';
import * as enrollmentReducers from './enrollment';
import * as pcpReducers from './pcp';
import * as centerInfoReducers from './centerInfo';
import * as myAccountReducers from './myAccount';
import * as myLittleLearnerReducers from './myLittleLearner';
import ThemeOptions from '../../reducers/ThemeOptions'

export default {
  ...authReducers,
  ...enrollmentReducers,
  ...pcpReducers,
  ...centerInfoReducers,
  ...myAccountReducers,
  ...myLittleLearnerReducers,
  ThemeOptions
}

import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import AppHeader from "../../Layout/AppHeader/";
import AppFooter from "../../Layout/AppFooter/";
import AppSections from "../../Layout/AppSections";
import MyLittleLearnerAccordian from "./Components/MyLittleLearnerAccordian";
import ErrorModal from "../SharedComponents/ErrorModal";
import Spinner from "../SharedComponents/Spinner";
import "../ContainersStyles.css";
import { connect, } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchingData,
  receivedData,
} from "../../redux/actionCreators/myAccount";
import {
  addEmergencyContact,
  deleteEmergencyContact,
  editEmergencyContact,
  loadMyLittleLearner,
  loadEmergencyContacts,
  saveChildImage,
  saveChildPersonalInfo,
  loadChildMedical,
  updateChildMedical,
  deleteChildMedical,
} from "../../redux/actionCreators/myLittleLearner";
const MyLittleLearner = ({
  addEmergencyContact,
  centerInfo,
  children,
  childMedical,
  deleteChildMedical,
  deleteEmergencyContact,
  documents,
  documentTypes,
  editEmergencyContact,
  emergencyContacts,
  error,
  immunizations,
  guardian,
  fetchingData,
  medicalAllergy,
  medicalCondition,
  medicalDietaryPreferences,
  medicalSeverity,
  receivedData,
  loadMyLittleLearner,
  relationships,
  updateChildMedical,
  saveChildImage,
  saveChildPersonalInfo,
  schedule,
  status,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const errorModalClosed = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    loadMyLittleLearner(guardian);
  }, [guardian]);

  useEffect(() => {
    setShowSpinner(status === "loading");
    if(status === "photo upload success") {
      loadMyLittleLearner(guardian);
    }
  }, [status]);

  const displayChildAccordian = (child) => {
    const childDocs = documents.filter((doc) => doc.child_id === child.id);
    const childImmunizations = immunizations.filter(
      (imm) => imm.childId === child.id
    )[0];
    const childEmergencyContacts = emergencyContacts.filter(
      (contact) => contact.child_id === child.id
    );
    const cm = childMedical.filter((medical) => medical.childId === child.id);
    const childSchedule = schedule.filter((el) => el.child_id === child.id)[0];
    const scheduleEvents = [
      ...childSchedule.absences,
      ...childSchedule.days,
      ...childSchedule.timeCard,
    ];

    return (
      <MyLittleLearnerAccordian
        addEmergencyContact={addEmergencyContact}
        centerInfo={centerInfo}
        child={child}
        childMedical={cm}
        deleteChildMedical={deleteChildMedical}
        deleteEmergencyContact={deleteEmergencyContact}
        documents={childDocs}
        documentTypes={documentTypes}
        editEmergencyContact={editEmergencyContact}
        emergencyContacts={childEmergencyContacts}
        fetchingData={fetchingData}
        id={child.id}
        immunizations={childImmunizations}
        // childAccordianIsOpen={childAccordianIsOpen[child.id]}
        medicalAllergy={medicalAllergy}
        medicalCondition={medicalCondition}
        medicalDietaryPreferences={medicalDietaryPreferences}
        medicalSeverity={medicalSeverity}
        key={child.id}
        receivedData={receivedData}
        relationships={relationships}
        // updateChildAccordians={updateChildAccordians}
        updateChildMedical={updateChildMedical}
        saveChildImage={saveChildImage}
        saveChildPersonalInfo={saveChildPersonalInfo}
        scheduleEvents={scheduleEvents}
        status={status}
      />
    );
  };
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <div className="app-main__outer">
          <div id="myLittleLearnerAccordians" className="app-main__inner">
            <AppSections section="my little learner" />
            <Row>
              <Col>
                {children &&
                  children.map((child) => displayChildAccordian(child))}
              </Col>
            </Row>
          </div>
          <AppFooter />
        </div>
      </div>
      <Spinner showSpinner={showSpinner} />
      <ErrorModal
        errorMessage={error}
        showErrorModal={showErrorModal}
        errorModalClosed={errorModalClosed}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  centerInfo: state.centerInfo.centerProfile,
  children: state.myLittleLearner.children,
  childMedical: state.myLittleLearner.childMedical,
  documents: state.myLittleLearner.documents,
  documentTypes: state.myLittleLearner.documentTypes,
  emergencyContacts: state.myLittleLearner.emergencyContacts,
  error: state.myLittleLearner.error,
  guardian: state.auth.guardian,
  immunizations: state.myLittleLearner.immunizations,
  medicalAllergy: state.myLittleLearner.medicalAllergy,
  medicalCondition: state.myLittleLearner.medicalCondition,
  medicalDietaryPreferences: state.myLittleLearner.medicalDietaryPreferences,
  medicalSeverity: state.myLittleLearner.medicalSeverity,
  relationships: state.myLittleLearner.relationships,
  schedule: state.myLittleLearner.schedule,
  status: state.myLittleLearner.status,
});

const mapDispatchToProps = (dispatch) => ({
  addEmergencyContact: bindActionCreators(addEmergencyContact, dispatch),
  deleteEmergencyContact: bindActionCreators(deleteEmergencyContact, dispatch),
  editEmergencyContact: bindActionCreators(editEmergencyContact, dispatch),
  fetchingData: bindActionCreators(fetchingData, dispatch),
  loadChildMedical: bindActionCreators(loadChildMedical, dispatch),
  loadEmergencyContacts: bindActionCreators(loadEmergencyContacts, dispatch),
  updateChildMedical: bindActionCreators(updateChildMedical, dispatch),
  deleteChildMedical: bindActionCreators(deleteChildMedical, dispatch),
  loadMyLittleLearner: bindActionCreators(loadMyLittleLearner, dispatch),
  receivedData: bindActionCreators(receivedData, dispatch),
  saveChildImage: bindActionCreators(saveChildImage, dispatch),
  saveChildPersonalInfo: bindActionCreators(saveChildPersonalInfo, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLittleLearner);

import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import { Alert, Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import {
  loadCenterInfo,
  loadGuardian,
  getCentersByEmail,
  signin,
  signup,
  loginSuccess
} from "../../../redux/actionCreators/auth";
import { loadData } from "../../../redux/actionCreators/enrollment";
import { useHistory, useParams } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import { loadPcpData } from "../../../redux/actionCreators/pcp";
import { version } from "../../../utils";
import Spinner from "../../SharedComponents/Spinner";
import {jwtDecode} from "jwt-decode";

const formGroupMarginBottom = {
  marginBottom: '0.7rem'
}

const Signup = ({
  auth,
  getCentersByEmail,
  loginSuccess,
  loadCenterInfo,
  loadData,
  loadGuardian,
  loadPcpData,
  signin,
  signup
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCenterId, setSelectedCenterId] = useState(0);
  const [centers, setCenters] = useState([]);
  const history = useHistory();
  const { token } = useParams();
  const decodedToken = jwtDecode(token); 
  if(!email && decodedToken && decodedToken.email) {
      setEmail(decodedToken.email)
  }
  const thisYear = new Date();
  const [modal, setModal] = useState({
    show: false,
    title: "",
    message: ""
  });

  function validateForm() {
    return email.length > 0 && password.length > 0 && centers.length > 0;
  }

  useEffect(() => {
    if (auth && auth.token) {
      history.push("/home");
    }
    if (auth && auth.error && password) {
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setCenters([]);
      setSelectedCenterId(0);
      setModal({
        show: true,
        title: "",
        message: auth.error
      });
    }
  }, [auth]);

  useEffect(() => {
    if (centers.length > 0) {
      setSelectedCenterId(centers[0].id);
    }
  }, [centers]);

  async function handleSubmit(event) {
    event.preventDefault();
    const strongPassword = RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
    if (!strongPassword.test(password)) {
      setModal({
        show: true,
        title: "",
        message: `Password is not strong enough. \n
                  Strong Password
                  - is at least 8 characters
                  - has at least 1 uppercase letter
                  - has at least 1 lowercase letter
                  - has at least 1 digit
                  - has at least 1 special character`
      });
      return;
    }
    if (password !== confirmPassword) {
      setModal({
        show: true,
        title: "",
        message: "Passwords do not match."
      });
      return;
    }
    const resp = await signup({
      email,
      password,
      centerId: selectedCenterId,
      token
    });
    if (resp.error) return;
    if (resp.response) {
      const { guardian, token } = await signin({
        email,
        password,
        centerId: selectedCenterId
      });
      if (guardian && guardian.id && guardian.center_id) {
        const loadGuardianRes = await loadGuardian({
          id: guardian.id,
          token
        });
        if (loadGuardianRes && loadGuardianRes.center_id) {
          const centerInfo = await loadCenterInfo({
            centerId: guardian.center_id
          });
          if (centerInfo && centerInfo.centerProfile.id) {
            await Promise.all([
              loadData({
                familyId: guardian.family_id
              }),
              loadPcpData({
                familyId: guardian.family_id
              })
            ]);
            loginSuccess();
          }
        }
      }
    }
  }

  return (
    <Fragment>
      <div className="h-100 bg-plum-plate bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <Alert color="primary" style={{ textAlign: "center" }}>
              Email address has been verified. You can now create your password to complete
              registration and then sign in to The Family Information Station.
            </Alert>
            <div className="app-logo-inverse mx-auto mb-3 mt-3" />
            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <Form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="modal-title text-center">
                      <h4 className="mt-1">
                        <div>Welcome to the Family Information Station</div>
                        <span>
                          Please fill out the information below and create a password to complete
                          registration
                        </span>
                      </h4>
                    </div>
                    <Row form>
                      <Col md={12}>
                        <FormGroup controlId="email"  style={formGroupMarginBottom}>
                          <Label>Email Address</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            autoFocus
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value.toLowerCase())}
                            onBlur={e => {
                              if (!e || !e.target || !e.target.value) return;
                              getCentersByEmail({ email }).then(res => {
                                if (res && res.length > 0) setCenters(res);
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup controlId="center" style={formGroupMarginBottom}>
                          <Label>Center</Label>
                          <Input
                            type={"select"}
                            value={selectedCenterId}
                            onChange={e => setSelectedCenterId(e.target.value)}
                            disabled={centers.length === 0}>
                            {centers.length &&
                              centers.map(c => (
                                <option value={c.id} key={c.id}>
                                  {c.name}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup controlId="password" style={formGroupMarginBottom}>
                          <Label>Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup controlId="confirmPassword" style={formGroupMarginBottom}>
                          <Label>Confirm Password</Label>
                          <Input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="divider" />
                    <h6 className="mb-0 account-login">
                      Already have an account?{" "}
                      <button
                        className="btn btn-link btn-padding"
                        onClick={() => history.push("/signin")}
                        type="button">
                        Sign in
                      </button>
                    </h6>
                    <h6 className="mb-0 account-login">
                      Can't login?{" "}
                      <button
                        className="btn btn-link btn-padding"
                        onClick={() => history.push("/forgotPassword")}
                        type="button">
                        Reset Password
                      </button>
                    </h6>
                    <h6 className="mb-0 account-login">
                      Verification Email Expired?
                      <button
                        className="btn btn-link btn-padding"
                        onClick={() => history.push("/verifyEmail")}
                        type="button">
                        Verify Email Address
                      </button>
                    </h6>
                  </div>
                  <div className="modal-footer clearfix">
                    <div className="float-right">
                      <Button color="primary" size="lg" disabled={!validateForm()} type="submit">
                      Register and Sign In
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className=" text-center text-white opacity-8">
              Copyright &copy; <Moment format="YYYY">{thisYear}</Moment> The Learning Experience<sup>&reg;</sup>.
              All Rights Reserved
              <br />
              Product {version}
            </div>
            <SweetAlert
              title={modal.title}
              confirmButtonColor=""
              show={modal.show}
              text={modal.message}
              onConfirm={() => setModal({ show: false })}
            />
          </Col>
        </div>
      </div>
      <Spinner showSpinner={auth.status === "loading"} />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  signin: bindActionCreators(signin, dispatch),
  signup: bindActionCreators(signup, dispatch),
  loadData: bindActionCreators(loadData, dispatch),
  getCentersByEmail: bindActionCreators(getCentersByEmail, dispatch),
  loadCenterInfo: bindActionCreators(loadCenterInfo, dispatch),
  loadGuardian: bindActionCreators(loadGuardian, dispatch),
  loadPcpData: bindActionCreators(loadPcpData, dispatch),
  loginSuccess: bindActionCreators(loginSuccess, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null)(Signup);

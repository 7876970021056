import * as actionTypes from "../actionTypes";

export const loadMyAccount = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const { center_id, family_id } = payload;
  if (!center_id || !family_id) return;

  dispatch({
    type: actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__START,
  });
  try {
    const res = await api.get(
      `/myAccount/getMyAccount/${center_id}/${family_id}`,
      {
        Authorization: `Bearer ${getState().auth.token}`,
      }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__SUCCESS,
        payload: res.response,
      });
    } else {
      dispatch({
        type: actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__FAILURE,
        payload:
          res.error || "Load My Account failed. There was no response or error",
      });
      console.log('myAccount loadMyAccount error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__FAILURE,
      payload: (err.data && err.data.error) || "Load My Account failed. There was no response or error",
    });
    console.log('myAccount loadMyAccount exception', err);
  }
};

export const saveContactInfo = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || !state.centerInfo.centerProfile.id) return;

  dispatch({
    type: actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__START,
  });
  try {
    if (payload.file) {
      const res = await api.post(`/cdn/image/upload`, payload.file, {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getState().auth.token}`,
      });
      if (res.success.key || res.success.Key) {
        payload = {
          ...payload,
          photoPath: res.success.key || res.success.Key,
        };
      } else {
        dispatch({
          type: actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__FAILURE,
          payload:
            res.error || "S3 upload failed. There was no response or error",
        });
        console.log('myAccount saveContactInfo image upload error', res);
        return;
      }
    }
    const res2 = await api.post(
      `/myAccount/editPrimaryGuardianContactInfo/${state.centerInfo.centerProfile.id}`,
      payload,
      {
        Authorization: `Bearer ${getState().auth.token}`,
      }
    );
    if (res2.response) {
      dispatch({
        type: actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__SUCCESS,
      });
      dispatch(
        loadMyAccount({
          center_id: state.auth.guardian.center_id,
          family_id: state.auth.guardian.family_id,
        })
      );
    } else {
      dispatch({
        type: actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__FAILURE,
        payload:
          res2.error ||
          "Save contact info failed. There was no response or error",
      });
      console.log('myAccount saveContactInfo error', res2);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__FAILURE,
      payload: (err.data && err.data.error) || "Save contact info failed. There was no response or error",
    });
    console.log("myAccount saveContactInfo exception", err);
  }
};

export const loadDocuments = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const { center_id, family_id } = payload;
  if (!center_id || !family_id) return;

  dispatch({
    type: actionTypes.MYACCOUNT_LOAD_DOCUMENTS__START,
  });
  try {
    const res = await api.get(
      `/myAccount/getAllDocuments/${center_id}/${family_id}`,
      {
        Authorization: `Bearer ${getState().auth.token}`,
      }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYACCOUNT_LOAD_DOCUMENTS__SUCCESS,
        payload: res.response,
      });
    } else {
      dispatch({
        type: actionTypes.MYACCOUNT_LOAD_DOCUMENTS__FAILURE,
        payload:
          res.error || "Load documents fail. There was no response or error",
      });
      console.log('myAccount loadDocuments error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYACCOUNT_LOAD_DOCUMENTS__FAILURE,
      payload: (err.data && err.data.error) || "Load documents fail. There was no response or error"
    });
    console.log('myAccount loadDocuments exception', err);
  }
};

export const saveDocument = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  if (!state.centerInfo.centerProfile || !state.centerInfo.centerProfile.id) return;

  dispatch({
    type: actionTypes.MYACCOUNT_SAVE_DOCUMENT__START,
  });
  try {
    const res = await api.post(`/cdn/image/upload`, payload.file, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getState().auth.token}`,
    });
    if (res.success && (res.success.key || res.success.Key)) {
      const dto = {
        ...payload,
        documentPath: res.success.key || res.success.Key,
        documentExpirationDate: null,
      };
      const type = payload.is_family_document_type
        ? "saveFamilyDocument"
        : "saveChildDocument";
      const res2 = await api.post(
        `/myAccount/${type}/${state.centerInfo.centerProfile.id}`,
        dto,
        {
          Authorization: `Bearer ${getState().auth.token}`,
        }
      );
      if (res2.response) {
        dispatch({
          type: actionTypes.MYACCOUNT_SAVE_DOCUMENT__SUCCESS,
        });
        dispatch(
          loadDocuments({
            center_id: state.auth.guardian.center_id,
            family_id: state.auth.guardian.family_id,
          })
        );
      } else {
        dispatch({
          type: actionTypes.MYACCOUNT_SAVE_DOCUMENT__FAILURE,
          payload:
            res2.error || "Save document fail. There was no response or error",
        });
        console.log('myAccount saveDocument error', res2);
      }
    } else {
      dispatch({
        type: actionTypes.MYACCOUNT_SAVE_DOCUMENT__FAILURE,
        payload:
          res.error || "Save document fail. There was no response or error",
      });
      console.log('myAccount saveDocument error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYACCOUNT_SAVE_DOCUMENT__FAILURE,
      payload: (err.data && err.data.error) || "Save document fail. There was no response or error"
    });
    console.log('myAccount saveDocument exception', err);
  }
};

export const getImage = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  const { host, centerId, filePath, token } = payload;
  if (!host || !centerId|| !filePath || !token) return;

  dispatch({
    type: actionTypes.MYACCOUNT_GET_IMAGE__START,
  });
  try {
    const res = await api.get(
      `${host}/cdn/image/inline/${centerId}/${filePath}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res) {
      dispatch({
        type: actionTypes.MYACCOUNT_GET_IMAGE__SUCCESS,
        payload: {imageSrc: res}
      });
    } else {
      dispatch({
        type: actionTypes.MYACCOUNT_GET_IMAGE__FAILURE,
        payload:
          res.error || "Get document failed. There was no response or error",
      });
      console.log('myAccount getImage error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYACCOUNT_GET_IMAGE__FAILURE,
      payload: (err.data && err.data.error) || "Get document failed. There was no response or error",
    });
    console.log('myAccount getImage exception', err);
  }
};

export const verifyAddress = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: actionTypes.MYACCOUNT_VERIFY_ADDRESS__START,
  });
  try {
    const res = await api.post(
      `/myAccount/verifyAddress/`,
      payload,
      {
        Authorization: `Bearer ${getState().auth.token}`,
      }
    );
    if (res.response) {
      dispatch({
        type: actionTypes.MYACCOUNT_VERIFY_ADDRESS__SUCCESS,
        payload: res.response
      });
    } else {
      dispatch({
        type: actionTypes.MYACCOUNT_VERIFY_ADDRESS__FAILURE,
        payload:
          res.error ||
          "Verify address failed. There was no response or error"
      });
    }
  } catch (err) {
    dispatch({
      type: actionTypes.MYACCOUNT_VERIFY_ADDRESS__FAILURE,
      payload: (err.data && err.data.error) || "Verify address failed. There was no response or error"
    });
    console.log("verify address error", err);
  }
};

export const fetchingData = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: actionTypes.MYACCOUNT_FETCHING_DATA,
  });
};

export const receivedData = (payload = {}) => async (
  dispatch,
  getState,
  api
) => {
  dispatch({
    type: actionTypes.MYACCOUNT_RECEIVED_DATA,
  });
};

import React, { Fragment, useEffect, useState } from "react";
import { TabPane } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import HealthItemModal from "../HealthItemModal";
import HealthTable from "../HealthTable";
import { compareLocalStorageToDb, utilsHandleInputChange } from "../../../../utils";
import YesNoDropDownOptions from "../../../SharedComponents/YesNoDropdownOptions";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import "../../../ContainersStyles.css";

const ImportantCareInfo = ({
  centerInfo,
  localStoragePCPForm,
  pcp,
  currentStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const [tabDataForModal, setTabDataForModal] = useState({});
  const { childrenInfo, importantCareInfo, status } = pcp;
  const { centerProfile } = centerInfo;
  const centerName = centerProfile.countryId === 230 ? "Centre" : "Center";
  const specialServicesLabel =
    centerProfile.countryId === 230
      ? "Explain special services or accommodations needed which are different from those provided by the Centre’s routine program (i.e., exercises, equipment, materials, or special services personnel):"
      : "Explain special services and accommodations which are different from those provided by the center’s routine program (i.e., exercises, equipment, materials, or special services personnel):";

  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ importantCareInfo: tabs, section: "importantCareInfo", showModal: true })
    }
  }, [tabs])

  useEffect(() => {
    if (status === "idle" && importantCareInfo && Object.keys(importantCareInfo).length) {
      if (!activeTab) setActiveTab(+Object.keys(importantCareInfo)[0]);
      const comparedImportantCareInfo = compareLocalStorageToDb(importantCareInfo);
      setTabs(comparedImportantCareInfo);
      setStartingState(comparedImportantCareInfo);
    }
  }, [importantCareInfo]);

  const handleSubmit = (e, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (e.target.name === "allergyModal") return;
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "importantCareInfo", importantCareInfo: tabs});
    nextStep();
  };

  const updateItems = (values, modalData) => {
    const { allergy_id, dietary_preference_id, medical_condition_id, severity_id } = values;
    let { action, items, itemId, pcpId, type } = modalData;
    let collectionName,
      allergy_name,
      dietary_preference_name,
      medical_condition_name,
      severity_name;

    const findItemNames = type => {
      switch (type) {
        case "allergy":
          allergy_name = pcp.medicalAllergy.find(el => el.id === +allergy_id).name;
          severity_name = pcp.medicalSeverity.find(el => el.id === +severity_id).name;
          collectionName = "pcpAllergies";
          break;
        case "dietary_preference":
          dietary_preference_name = pcp.medicalDietaryPreferences.find(
            el => el.id === +dietary_preference_id
          ).name;
          collectionName = "pcpDietaryPreferences";
          break;
        case "medical_condition":
          medical_condition_name = pcp.medicalConditions.find(el => el.id === +medical_condition_id)
            .name;
          severity_name = pcp.medicalSeverity.find(el => el.id === +severity_id).name;
          collectionName = "pcpMedicalConditions";
          break;
        default:
          break;
      }
    };
    if (action === "edit") {
      items = items.map(el => {
        if ((el.id || el.temporaryId) === itemId) {
          findItemNames(type);
          return {
            ...el,
            ...values,
            allergy_name,
            dietary_preference_name,
            medical_condition_name,
            severity_name
          };
        }
        return el;
      });
    } else {
      values.temporaryId = Math.random()
        .toString(16)
        .slice(2);
      findItemNames(type);
      values.personal_care_plan_id = pcpId;
      items = [
        ...items,
        { ...values, allergy_name, dietary_preference_name, medical_condition_name, severity_name }
      ];
    }

    setTabs(prevValue => {
      return {
        ...prevValue,
        [pcpId]: { ...prevValue[pcpId], [collectionName]: items }
      };
    });
  };

  const deleteItem = modalData => {
    let { items, itemId, pcpId, type } = modalData;
    setTabs(prevState => {
      let collectionName;
      if (type === "allergy") {
        collectionName = "pcpAllergies";
      }
      if (type === "dietary_preference") {
        collectionName = "pcpDietaryPreferences";
      }
      if (type === "medical_condition") {
        collectionName = "pcpMedicalConditions";
      }
      items = items.reduce((acc, val) => {
        if (val.temporaryId === itemId) {
          return acc;
        }
        if (val.id === itemId) {
          val = { ...val, isDeleted: 1, is_active: 0 };
        }
        return [...acc, val];
      }, []);

      return {
        ...prevState,
        [pcpId]: {
          ...prevState[pcpId],
          [collectionName]: items
        }
      };
    });
  };

  const showModal = (pcpId, items, action, type, itemId = null, itemName = null) => {
    setTabDataForModal(prevValue => {
      return { ...prevValue, pcpId, items, action, type, itemId, itemName };
    });
  };

  const displayTabInputs = tab => {
    const {
      ethnicity_id,
      eye_color_id,
      hair_color_id,
      identifying_marks,
      important_care_additional_information,
      is_court_ordered_custody,
      is_special_needs,
      pcpAllergies,
      pcpDietaryPreferences,
      pcpMedicalConditions,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId,
      special_services
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};
    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader
          text={
            childrenInfo[pcpId].transition_classroom_type_name ||
            childrenInfo[pcpId].classroom_type_name
          }
        />
        <AvField
          name={`eye_color_id-${pcpId}`}
          type="select"
          label={`${centerProfile.countryId === 230 ? "Eye colour" : "Eye color"} *`}
          onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={eye_color_id || tabLocalStorage.eye_color_id}
          required>
          <option key="0" value="">
            {`Select a eye ${centerProfile.countryId === 230 ? "colour" : "color"}`}
          </option>
          {pcp.eyeColors &&
            pcp.eyeColors.map(e => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
        </AvField>
        <AvField
          name={`hair_color_id-${pcpId}`}
          type="select"
          label={`${centerProfile.countryId === 230 ? "Hair colour" : "Hair color"} *`}
          data-id={pcpId}
          onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={hair_color_id || tabLocalStorage.hair_color_id}
          required>
          <option key="0" value="">
          {`Select a hair ${centerProfile.countryId === 230 ? "colour" : "color"}`}
          </option>
          {pcp.hairColors &&
            pcp.hairColors.map(e => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
        </AvField>
        <AvField
          name={`ethnicity_id-${pcpId}`}
          type="select"
          label="Ethnicity *"
          data-id={pcpId}
          onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={ethnicity_id || tabLocalStorage.ethnicity_id}
          required>
          <option key="0" value="">
            Select a ethnicity
          </option>
          {pcp.ethnicities &&
            pcp.ethnicities.map(e => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
        </AvField>
        <AvField
          name={`identifying_marks-${pcpId}`}
          type="text"
          label="Identifying marks"
          data-id={pcpId}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={identifying_marks || tabLocalStorage.identifying_marks}
        />
        <AvField
          name={`is_court_ordered_custody-${pcpId}`}
          type="select"
          label="Are there any court-ordered custody arrangements for your child? *"
          data-id={pcpId}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={
            is_court_ordered_custody === 0
              ? "0"
              : is_court_ordered_custody || tabLocalStorage.is_court_ordered_custody
          }
          required>
          <YesNoDropDownOptions />
        </AvField>
        <p className="font-italic">
          {`* If yes, you must provide a copy to the ${centerName}. The ${centerName} must comply with the order.`}
        </p>
        <p className="font-italic">
          {`* Please list any special medications or health care needs; please note that children with
          special health needs must have a current health care plan on file with the ${centerName} prior to
          the start of enrollment and it must be updated every six months. The health care plan will
          be required before starting and the child’s start date will be determined based on the
          ability to assess, prepare, and train for proper and safe care.`}
        </p>

        <HealthTable
          items={pcpAllergies}
          pcpId={pcpId}
          setTabs={setTabs}
          showModal={showModal}
          type="allergy"
        />
        <HealthTable
          items={pcpDietaryPreferences}
          pcpId={pcpId}
          setTabs={setTabs}
          showModal={showModal}
          type="dietary_preference"
        />
        <HealthTable
          items={pcpMedicalConditions}
          pcpId={pcpId}
          setTabs={setTabs}
          showModal={showModal}
          type="medical_condition"
        />
        <AvField
          name={`is_special_needs-${pcpId}`}
          type="select"
          label="Does your child have a special need? *"
          data-id={pcpId}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={
            is_special_needs === 0 ? "0" : is_special_needs || tabLocalStorage.is_special_needs
          }
          required>
          <YesNoDropDownOptions />
        </AvField>
        <AvField
          name={`special_services-${pcpId}`}
          type="text"
          label={specialServicesLabel}
          data-id={pcpId}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={special_services || tabLocalStorage.special_services}
        />
        <AvField
          name={`important_care_additional_information-${pcpId}`}
          type="text"
          label="Is there anything else we need to know about your child to ensure he or she can be well-supported by our staff?"
          data-id={pcpId}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={
            important_care_additional_information ||
            tabLocalStorage.important_care_additional_information
          }
        />
      </TabPane>
    );
  };

  return (
    <Fragment>
      <FormContainer
        activeTab={activeTab}
        childrenInfo={childrenInfo}
        displayTabInputs={displayTabInputs}
        handleSubmit={handleSubmit}
        previousStep={false}
        setActiveTab={setActiveTab}
        tabs={tabs}
        title={"Important Care Information"}
      />
      <HealthItemModal
        pcp={pcp}
        tabDataForModal={tabDataForModal}
        updateItems={updateItems}
        deleteItem={deleteItem}
      />
    </Fragment>
  );
};

export default ImportantCareInfo;

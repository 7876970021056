import produce from 'immer'
import * as actionTypes from '../actionTypes';
import initialState from './initialState';

export const centerInfo = produce((state = initialState.centerInfo, action) => {
  switch (action.type) {
    case actionTypes.CENTERINFO_LOAD_DATA__START:
      return {
        ...state,
        status: 'loading'
      }
    case actionTypes.CENTERINFO_LOAD_DATA__SUCCESS:
      // const localStorageData = JSON.parse(localStorage.getItem('centerName')) || {}
      return {
        ...state,
        ...action.payload,
        status: 'idle',
        error: ""
        // ...localStorageData
      }
      case actionTypes.CENTERINFO_LOAD_DATA__FAILURE:
        return {
          ...state,
          status: 'failed',
          error: action.payload
        }
    default:
      return state
  }
})

import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import  {formatPhoneNumber} from "../../../utils"

const RelationshipsDropDown = ({ relationships }) => {
  return (
    <Fragment>
      <option key="0" value="">
        Select a relationship
      </option>
      {relationships &&
        relationships.map((relationship) => (
          <option key={relationship.id} value={relationship.id}>
            {relationship.name}
          </option>
        ))}
    </Fragment>
  );
};

const PickupAndContactDropdown = () => {
  return (
    <Fragment>
      <option key="0" value="0">
        No
      </option>
      <option key="1" value="1">
        Yes
      </option>
    </Fragment>
  );
};

const contactInfoInputs = {
  first_name: "",
  is_authorized_contact: 0,
  is_authorized_pickup: 0,
  last_name: "",
  primary_phone: "",
  relationship_id: "",
};

const EditContactModal = ({
  addEmergencyContact,
  editContactClicked,
  child,
  contactInfo,
  countryId,
  modalClosed,
  editEmergencyContact,
  relationships,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [saveButtonIsDisabled, setSaveButtonIsDisabled] = useState(true);
  const [updatedContactInfo, setUpdatedContactInfo] = useState(
    contactInfoInputs
  );

  useEffect(() => {
    if (editContactClicked) {
      setIsOpen(true);
    }
  }, [editContactClicked]);

  useEffect(() => {
    let contactInfoNeeded = {};
    if (Object.keys(contactInfo).length) {
      contactInfoNeeded.id = contactInfo.id;
      contactInfoNeeded.first_name = contactInfo.first_name;
      contactInfoNeeded.is_authorized_contact =
        contactInfo.is_authorized_contact;
      contactInfoNeeded.is_authorized_pickup = contactInfo.is_authorized_pickup;
      contactInfoNeeded.last_name = contactInfo.last_name;
      contactInfoNeeded.primary_phone = contactInfo.primary_phone;
      contactInfoNeeded.relationship_id = contactInfo.relationship_id;
    }
    setUpdatedContactInfo((prevState) => ({
      ...prevState,
      ...contactInfoNeeded,
    }));
  }, [contactInfo]);

  useEffect(() => {
    setUpdatedContactInfo((prevState) => ({
      ...prevState,
      child_id: child.id,
      family_id: child.familyId,
    }));
  }, [child]);

  const toggle = () => {
    setIsOpen(!isOpen);
    modalClosed();
    setSaveButtonIsDisabled(true);
    setUpdatedContactInfo((prevState) => ({
      ...prevState,
      ...contactInfoInputs,
    }));
  };

  const handleInputChange = (e) => {
    let {name, value} = e.target
    if(name === 'primary_phone'){
      value = formatPhoneNumber(e)
    } 
    setUpdatedContactInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (saveButtonIsDisabled) {
      setSaveButtonIsDisabled(false);
    }
  };

  const handleSubmit = (event, errors, values) => {
    if (!errors.length) {
      if (child && child.id) {
        type === "Edit"
          ? editEmergencyContact(updatedContactInfo)
          : addEmergencyContact(updatedContactInfo);
        toggle();
      }
    }
  };

  const renderConditional =
    type === "Edit"
      ? updatedContactInfo && Object.keys(updatedContactInfo).length
      : true;
  return (
    <Fragment>
      {renderConditional && (
        <Fragment>
          <Modal isOpen={isOpen} toggle={toggle} className="testing">
            <AvForm onSubmit={handleSubmit}>
              <ModalHeader toggle={toggle}>{type} Contact</ModalHeader>
              <ModalBody>
                <AvField
                  id={`first_name`}
                  name={`first_name`}
                  type="text"
                  label="First Name"
                  onChange={handleInputChange}
                  value={updatedContactInfo.first_name}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a first name.",
                    },
                  }}
                ></AvField>
                <AvField
                  id={`last_name`}
                  name={`last_name`}
                  type="text"
                  label="Last Name"
                  onChange={handleInputChange}
                  value={updatedContactInfo.last_name}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a last name.",
                    },
                    pattern: {
                      value: '^[A-Za-z .-]+$',
                      errorMessage: "Please enter valid name"
                    },
                  }}
                ></AvField>
                <AvField
                  id={`relationship_id`}
                  name={`relationship_id`}
                  type="select"
                  label="Relationship"
                  onChange={handleInputChange}
                  value={updatedContactInfo.relationship_id}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a relationship.",
                    },
                  }}
                >
                  <RelationshipsDropDown relationships={relationships} />
                </AvField>
                <AvField
                  id={`primary_phone`}
                  name={`primary_phone`}
                  type="tel"
                  label="Primary Phone"
                  onChange={handleInputChange}
                  onKeyPress={e => {
                    const regex = countryId === 230 ? /[0-9 ]/ : /[0-9]/;
                    if (!regex.test(e.key)) e.preventDefault();
                  }}
                  value={updatedContactInfo.primary_phone}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a primary phone.",
                    },
                    minLength: {value: countryId === 230 ? 9 : 14},
                    maxLength: { value: countryId === 230 ? 12 : 14 }
                  }}
                ></AvField>
                <AvField
                  id={`is_authorized_pickup`}
                  name={`is_authorized_pickup`}
                  type="select"
                  label="AdditionalPickup"
                  onChange={handleInputChange}
                  value={updatedContactInfo.is_authorized_pickup}
                >
                  <PickupAndContactDropdown />
                </AvField>
                <AvField
                  id={`is_authorized_contact`}
                  name={`is_authorized_contact`}
                  type="select"
                  label="EmergencyContact"
                  onChange={handleInputChange}
                  value={updatedContactInfo.is_authorized_contact}
                >
                  <PickupAndContactDropdown />
                </AvField>
                {/* <DocumentUploader files={files} fileNames={fileNames} showErrorMsg={showErrorMsg} handleDrop={handleDrop} /> */}
              </ModalBody>
              <ModalFooter>
                {/* <FormGroup> */}
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button color="primary" disabled={saveButtonIsDisabled}>
                  Save
                </Button>
                {/* </FormGroup> */}
              </ModalFooter>
            </AvForm>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditContactModal;

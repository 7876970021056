import React from "react";
import Loader from "react-loaders";
import classNames from 'classnames'

const Spinner = ({showSpinner}) => {
 return (
    <div className={classNames("spinner", { showSpinner })}>
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse-rise" />
        </div>
      </div>
    </div>
    </div>
 )
}

export default Spinner

import React, { Fragment } from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import Spinner from "../SharedComponents/Spinner";
import { useSelector } from "react-redux";

import EnrollmentStepWizard from "./Components/EnrollmentStepWizard";

const EnrollmentForm = () => {
  const enrollmentStatus = useSelector((state) => state.enrollment.status)
  return (
    <Fragment>
    <AppHeader />
    <div className="app-main">
      <div className="app-main__outer">
        <div className="app-main__inner">
            <EnrollmentStepWizard/>
        </div>
        <AppFooter />
      </div>
    </div>
    <Spinner showSpinner={enrollmentStatus === "loading"} />
  </Fragment>
  )
};

export default EnrollmentForm;

import React, { useState } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, List, ListInlineItem } from 'reactstrap';

const Signature = ({ enrollment, previousStep, nextStep, saveForm, submissionStatuses }) => {
  const [name, setName] = useState('');
  const dpr = window.devicePixelRatio || 1;
  // change signature to true so the submit button will enable
  const updatedSubmissionStatuses = {...submissionStatuses, signature: true}

  const trimCanvas = (canvas) => {
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const length = pixels.data.length;
    let topCoord = null;
    let bottomCoord = null;
    let leftCoord = null;
    let rightCoord = null;
    let x = 0;
    let y = 0;
  
    // Iterate over every pixel to find the highest
    // and where it ends on the vertical axis
    // Each pixel is represented as RGBA
    for (let i = 0; i < length; i += 4) {
      // We inspect the alpha channel to check
      // if the pixel is fully transparent or not
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % canvas.width;
        y = Math.trunc(i / 4 / canvas.width);
  
        if (topCoord === null) {
          // Since we inspect from top to bottom,
          // the initial not-transparent pixel must
          // be the topBound one.
          topCoord = y;
        }
  
        if (leftCoord === null || x < leftCoord) {
          // Since we walk in the left-right top-bottom
          // direction, we need to find the lowest
          // x coordinate as the leftCoord
          leftCoord = x;
        }
  
        if (rightCoord === null || x > rightCoord) {
          // Since we walk in the left-right top-bottom
          // direction, we need to find the highest
          // x coordinate as the rightCoord
          rightCoord = x;
        }
  
        if (bottomCoord === null || bottomCoord < y) {
          bottomCoord = y;
        }
      }
    }
  
    // If some value was left as null we use 0
    topCoord = topCoord || 0;
    bottomCoord = bottomCoord || 0;
    leftCoord = leftCoord || 0;
    rightCoord = rightCoord || 0;
  
    // Calculate height and width. Add 20 pixels
    // for some negative space (i.e. padding) around
    // the canvas edges
    const trimHeight = bottomCoord - topCoord + 20;
    const trimWidth = rightCoord - leftCoord + 20;
    const trimmed = ctx.getImageData(leftCoord, topCoord, trimWidth, trimHeight);
  
    canvas.width = trimWidth;
    canvas.height = trimHeight;
    ctx.putImageData(trimmed, 10, 10);
  }

  const textToBitmap = async (text, font) => {
    let canvas;
    let convertToBlob;
  
    const FONT_SIZE = 100;
    const VERTICAL_EXTRA_SPACE = 5;
    const HORIZONTAL_EXTRA_SPACE = 2;
  
    if ('OffscreenCanvas' in window) {
      canvas = new window.OffscreenCanvas(200, 200);
      convertToBlob = canvas.convertToBlob.bind(canvas);
    } else {
      canvas = window.document.createElement('canvas');
  
      convertToBlob = () =>
        new Promise((resolve) => {
          if (canvas.msToBlob && !canvas.toBlob) {
            // Edge and IE11
  
            const blob = canvas.msToBlob();
  
            resolve(blob);
          } else {
            canvas.toBlob(resolve);
          }
        });
    }
  
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
  
    ctx.textBaseline = 'top';
  
    // scale up the font size by the DPR factor. When
    // rendering, we'll scale down the image by the same
    // amount.
    ctx.font = `${FONT_SIZE * dpr}px '${font}'`;
  
    // IE and Edge only returns width as part of measureText
    const {
      actualBoundingBoxLeft,
      actualBoundingBoxRight,
      fontBoundingBoxAscent,
      fontBoundingBoxDescent,
      actualBoundingBoxAscent,
      actualBoundingBoxDescent,
      width
    } = ctx.measureText(text);
  
    // Render a large canvas to handle edge cases with some cases with large
    // ascenders and descender strokes that otherwise could end up cropped out
    // 5 is chosen as a multiplier after trying out rendering multiple fonts on different
    // browser engines while keeping the necessary room before vertically trimming the
    // canvas to ensure proper rendering of text (VERTICAL_EXTRA_SPACE).
    // Horizontal trimming is also applied with a similar logic to handle edge cases there.
    const canvasHeight =
      Math.max(
        Math.abs(actualBoundingBoxAscent) + Math.abs(actualBoundingBoxDescent),
        (Math.abs(fontBoundingBoxAscent) || 0) +
          (Math.abs(fontBoundingBoxDescent) || 0)
      ) * VERTICAL_EXTRA_SPACE;
    canvas.height = canvasHeight;
  
    const canvasWidth =
      Math.max(width, Math.abs(actualBoundingBoxLeft) + actualBoundingBoxRight) *
      HORIZONTAL_EXTRA_SPACE;
    canvas.width = canvasWidth;
    ctx.textBaseline = 'top';
    ctx.font = `${FONT_SIZE * dpr}px '${font}'`;
    // Do not start rendering the text at the very top of the canvas to
    // prevent cutting out ascender strokes on certain fonts.
    // 4 is chosen so that text starts being rendered at the upper half of
    // the canvas
    ctx.fillText(text, canvasWidth / 4, canvasHeight / 4);
    trimCanvas(canvas);
  
    return convertToBlob({
      type: 'image/png'
    });
  }
  const handleInputChange = async (e) => {
    let signatureName = e.target.value;
    setName(signatureName);
    if (!signatureName) signatureName = ' ';
    const imageWrapper = document.getElementById('image-wrapper');
    const maxWidth = imageWrapper.getBoundingClientRect().width;
    const imageBlob = await textToBitmap(signatureName, 'Yellowtail');
    const imageUrl = URL.createObjectURL(imageBlob);

    const image = new Image();
    image.src = imageUrl;
    imageWrapper.replaceChildren(image);

    const drawImage = () => {
        const {width, height} = image.getBoundingClientRect();
        if(width >= maxWidth - 20) {
          image.style.width = `${maxWidth - 20}px`;
        }
        if(width === 0 && height !== 0) {
          requestAnimationFrame(drawImage)
        }
    }

     requestAnimationFrame(drawImage)
  };
  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0 || Object.values(updatedSubmissionStatuses).some(el => el === false)) return;
    saveForm({section: "signature", enrollments: enrollment.enrollments })
  };
  const current = new Date();
  const date = current.toLocaleDateString('en-US');
  
  return (
    <div className='form-container'>
      <h3 className='text-center'>Signature</h3>
      <AvForm onSubmit={handleSubmit}>
        <AvField
          id='name'
          name='name'
          type='text'
          label='Name'
          onChange={handleInputChange}
          value={name}
          validate={{
            required: {
              value: true,
              errorMessage: 'Please enter a name.',
            },
            pattern: {
              value: '^[A-Za-z .-]+$',
              errorMessage: 'Please enter valid name'
            },
            maxLength: { value: 50 },
          }}
        />
        <div
          id='image-wrapper'
        >
        </div>
        <List type='inline'>
          <ListInlineItem>Date: {date}</ListInlineItem>
        </List>
        <Button color='secondary' size='lg' block onClick={previousStep}>
          Back
        </Button>
        <Button color='primary' size='lg' block disabled={Object.values(updatedSubmissionStatuses).some(el => el === false)}>
          Submit
        </Button>
      </AvForm>
      <br></br>
    </div>
  );
};

export default Signature;

import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { useDropzone } from "react-dropzone";
import DocumentUploader from "./DocumentUploader";
import "../myAccountStyles.css";

const UploadDocumentModal = ({
  childs,
  documentTypes,
  guardian,
  saveDocument,
  status,
}) => {
  
  if (documentTypes.length > 0) {
    documentTypes =  documentTypes.filter(type => 
      type.name.toLowerCase() !=='pcp agreement' 
      && type.name.toLowerCase() !=='enrollment information'
    );
  }
  const [modal, setModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [files, setFiles] = useState([]);
  const [isValidSubmit, setIsValidSubmit] = useState(false);
  const [isFileDropped, setIsFileDropped] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [inputs, setInputs] = useState({});
  const [showChildrenInput, setShowChildrenInput] = useState(false);

  useEffect(() => {
    if (isValidSubmit && isFileDropped) {
      const formData = new FormData();
      formData.append("file", files[0]);
      saveDocument({
        ...inputs,
        file: formData,
        documentFilename: files[0].name,
      });
    }
  }, [isValidSubmit]);

  useEffect(() => {
    if (status !== "loading") {
      setModal(false);
      setIsValidSubmit(false);
    }
  }, [status]);

  const toggleAndClearDropzone = () => {
    setModal(!modal);
    setFileNames([]);
    setFiles([]);
    setIsFileDropped(false);
    setShowErrorMsg(false);
  };

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    setFiles(acceptedFiles.map((file) => file));
    setIsFileDropped(true);
    setShowErrorMsg(false);
  };

  const handleInputChange = (e) => {
    if (e.target.name === "documentType") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const docTypeSelected = documentTypes.filter((doc, index) => index === +e.target.value && `${doc.name}${doc.note ? " (" + doc.note + ")" : ""}` === selectedOption.text);
      setShowChildrenInput(!!docTypeSelected.length && !docTypeSelected[0].is_family_document_type)
      setInputs({
        ...inputs,
        ...docTypeSelected[0],
        familyId: guardian.family_id
      });
      return;
    }
    let id = [e.target.value];
    if (e.target.value === "0") {
      id = childs.map((child) => child.id.toString());
    }
    setInputs({ ...inputs, children: id });
  };

  const handleSubmit = (event, errors, values) => {
    setIsValidSubmit(!errors.length && isFileDropped);
    if (!isFileDropped) {
      setShowErrorMsg(true);
      return;
    }
    setShowErrorMsg(false);
  };

  // const {
  //   isDragAccept,
  //   isDragActive,
  //   getRootProps,
  //   getInputProps,
  //   isDragReject,
  //   acceptedFiles,
  //   rejectedFiles,
  // } = useDropzone({
  //   onDrop: handleDrop,
  //   // accept: 'image/png',
  //   // minSize: 0,
  //   // maxSize,
  //   multiple: false,
  // });

  return (
    <div>
      <Button
        className="mb-3"
        color="primary"
        size="lg"
        onClick={toggleAndClearDropzone}
      >
        <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
        Upload Document
      </Button>
      <Modal isOpen={modal} toggle={toggleAndClearDropzone} className="">
        <AvForm onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleAndClearDropzone}>
            Upload Document
          </ModalHeader>
          <ModalBody>
            <AvField
              id={`documentType`}
              name={`documentType`}
              type="select"
              label="Document Type"
              onChange={handleInputChange}
              value={""}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please select a document type.",
                },
              }}
            >
              <option key="0" value="">
                Select a document type
              </option>
              {documentTypes.map((type, index) => (
                <option
                  key={index}
                  value={index}
                >
                  {`${type.name}${type.note ? (" (" + type.note + ")") : ""}`}
                </option>
              ))}
            </AvField>
            {showChildrenInput && (
              <AvField
                id={`children`}
                name={`children`}
                type="select"
                label="Children"
                onChange={handleInputChange}
                value={""}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please select a child.",
                  },
                }}
              >
                <option key="0" value="">
                  Select a child
                </option>
                {childs &&
                  childs.map((child) => (
                    <option key={child.id} value={child.id}>
                      {child.firstName} {child.lastName}
                    </option>
                  ))}
                <option key="1000" value="0">
                  All
                </option>
              </AvField>
            )}
            <DocumentUploader
              files={files}
              fileNames={fileNames}
              showErrorMsg={showErrorMsg}
              handleDrop={handleDrop}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleAndClearDropzone}>
              Cancel
            </Button>
            <Button color="primary">Save</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </div>
  );
};

export default UploadDocumentModal;

import React, { Fragment, useEffect, useState } from "react";
import { TabPane } from "reactstrap";
import {
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import YesNoDropDownOptions from "../../../SharedComponents/YesNoDropdownOptions";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import {
  compareLocalStorageToDb,
  getActiveItems,
  updateCheckboxes,
  utilsHandleInputChange
} from "../../../../utils";

const trainingMonths = () => {
  const months = [];
  for (let i = 1; i < 49; i++) {
    months.push(i);
  }
  return months;
};

const enableDefaultCheckboxes = comparedToiletingRoutines => {
  return Object.keys(comparedToiletingRoutines).reduce((acc, val) => {
    const toiletMethods = getActiveItems(
      comparedToiletingRoutines,
      val,
      "pcpToiletMethods",
      "toilet_method_id"
    );
    return {
      ...acc,
      [val]: { toiletMethods }
    };
  }, {});
};

const ToiletingRoutines = ({
  centerInfo,
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});
  const [defaultCheckboxes, setDefaultCheckboxes] = useState(null);
  const { childrenInfo, toiletMethods, toiletingRoutines, status } = pcp;
  const { centerProfile } = centerInfo;

  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ toiletingRoutines: tabs, section: "toiletingRoutines", showModal: true })
    }
  }, [tabs])

  useEffect(() => {
    if (status === "idle" && toiletingRoutines && Object.keys(toiletingRoutines).length) {
      if (!activeTab) setActiveTab(+Object.keys(toiletingRoutines)[0]);
      const comparedToiletingRoutines = compareLocalStorageToDb(toiletingRoutines);
      setTabs(comparedToiletingRoutines);
      setStartingState(comparedToiletingRoutines);
      setDefaultCheckboxes(enableDefaultCheckboxes(comparedToiletingRoutines));
      const disabledInputs = Object.keys(comparedToiletingRoutines).reduce((acc, val) => {
        return {
          ...acc,
          [val]: {
            accidents: {
              disabled: !+comparedToiletingRoutines[val]["is_accidents"],
              required: +comparedToiletingRoutines[val]["is_accidents"]
            },
            bowel_movement_frequency: {
              disabled: !+comparedToiletingRoutines[val]["is_bowel_movement_regular"],
              required: +comparedToiletingRoutines[val]["is_bowel_movement_regular"]
            },
            bowel_training: {
              disabled:
                !+comparedToiletingRoutines[val]["is_toilet_trained"] ||
                !+comparedToiletingRoutines[val].bowel_training ||
                !+comparedToiletingRoutines[val].toilet_training_id,
              required:
                +comparedToiletingRoutines[val]["is_toilet_trained"] &&
                !!+comparedToiletingRoutines[val].bowel_training
            },
            constipation_problem: {
              disabled: !+comparedToiletingRoutines[val]["is_constipation_problem"],
              required: +comparedToiletingRoutines[val]["is_constipation_problem"]
            },
            diarrhea_problem: {
              disabled: !+comparedToiletingRoutines[val]["is_diarrhea"],
              required: +comparedToiletingRoutines[val]["is_diarrhea"]
            },
            handle_bathroom_reluctant: {
              disabled: !+comparedToiletingRoutines[val]["is_bathroom_reluctant"],
              required: +comparedToiletingRoutines[val]["is_bathroom_reluctant"]
            },
            toilet_training_id: {
              disabled: !+comparedToiletingRoutines[val]["is_toilet_trained"],
              required: +comparedToiletingRoutines[val]["is_toilet_trained"]
            },
            urination_training: {
              disabled:
                !+comparedToiletingRoutines[val]["is_toilet_trained"] ||
                !+comparedToiletingRoutines[val].urination_training ||
                !+comparedToiletingRoutines[val].toilet_training_id,
              required:
                +comparedToiletingRoutines[val]["is_toilet_trained"] &&
                !!+comparedToiletingRoutines[val].urination_training
            }
          }
        };
      }, {});
      setDisabledInputs(prevValue => {
        return { ...prevValue, ...disabledInputs };
      });
    }
  }, [toiletingRoutines]);

  const handleInputChange = (e, pcpId, type = null, item = null) => {
    let { name, value } = e.target;
    name = name.slice(0, name.indexOf("-"));
    if (type === "pcpToiletMethods") {
      value = updateCheckboxes(e, pcpId, type, "toilet_method_id", item, tabs);
    }

    setTabs(prevValue => {
      return {
        ...prevValue,
        [pcpId]: { ...prevValue[pcpId], [type || name]: value }
      };
    });
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    // setIsValidSubmit(!errors.length);
    saveForm({ section: "toiletingRoutines", toiletingRoutines: tabs });
    nextStep();
  };

  const displayTabInputs = tab => {
    const {
      accidents,
      bowel_movement,
      bowel_movement_frequency,
      bowel_training,
      constipation_problem,
      diarrhea_problem,
      handle_bathroom_reluctant,
      home_toileting,
      is_accidents,
      is_bathroom_reluctant,
      is_bowel_movement_regular,
      is_constipation_problem,
      is_diarrhea,
      is_toilet_trained,
      pcpToiletMethods,
      support_toilet_learning,
      toilet_training_id,
      urination,
      urination_training,
      wear_diapers_during_day,
      wear_diapers_during_nap,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};

    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader text={childrenInfo[pcpId].transition_classroom_type_name || childrenInfo[pcpId].classroom_type_name} />
        {typeId === 3 && (
          <Fragment>
            <AvField
              name={`is_bathroom_reluctant-${pcpId}`}
              type="select"
              label="Is your child reluctant to use the bathroom? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    handle_bathroom_reluctant: {
                      disabled: e.target.value !== "1",
                      required: e.target.value === "1"
                    }
                  },
                  setDisabledInputs
                )
              }
              value={
                is_bathroom_reluctant === 0
                  ? "0"
                  : is_bathroom_reluctant || tabLocalStorage.is_bathroom_reluctant
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`handle_bathroom_reluctant-${pcpId}`}
              type="text"
              label="If yes, how do you handle: *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].handle_bathroom_reluctant.disabled
                  ? ""
                  : handle_bathroom_reluctant || tabLocalStorage.handle_bathroom_reluctant
              }
              disabled={disabledInputs[pcpId].handle_bathroom_reluctant.disabled}
              required={disabledInputs[pcpId].handle_bathroom_reluctant.required}
            />
          </Fragment>
        )}
        {typeId !== 1 && (
          <Fragment>
            <AvField
              name={`is_toilet_trained-${pcpId}`}
              type="select"
              label="Is your child toilet trained? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    toilet_training_id: {
                      disabled: e.target.value !== "1",
                      required: e.target.value === "1"
                    },
                    bowel_training: {
                      disabled:
                        e.target.value === "1"
                          ? +toilet_training_id !== 2 && +toilet_training_id !== 3
                          : true,
                      required:
                        e.target.value === "1"
                          ? +toilet_training_id === 2 || +toilet_training_id === 3
                          : false
                    },
                    urination_training: {
                      disabled:
                        e.target.value === "1"
                          ? +toilet_training_id !== 1 && +toilet_training_id !== 3
                          : true,
                      required:
                        e.target.value === "1"
                          ? +toilet_training_id === 1 || +toilet_training_id === 3
                          : false
                    }
                  },
                  setDisabledInputs
                )
              }
              value={
                is_toilet_trained === 0
                  ? "0"
                  : is_toilet_trained || tabLocalStorage.is_toilet_trained
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`toilet_training_id-${pcpId}`}
              type="select"
              label="If yes, please select toilet training *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    bowel_training: {
                      disabled: e.target.value === "1" || e.target.value === "0",
                      required: e.target.value === "2" || e.target.value === "3"
                    },
                    urination_training: {
                      disabled: e.target.value === "2" || e.target.value === "0",
                      required: e.target.value === "1" || e.target.value === "3"
                    }
                  },
                  setDisabledInputs
                )
              }
              value={
                disabledInputs[pcpId].toilet_training_id.disabled
                  ? ""
                  : toilet_training_id || tabLocalStorage.toilet_training_id
              }
              disabled={disabledInputs[pcpId].toilet_training_id.disabled}
              required={disabledInputs[pcpId].toilet_training_id.required}>
              <option key="0" value=""></option>
              {pcp.toiletTraining.map((el, idx) => {
                return (
                  <option key={idx + 1} value={el.id}>
                    {el.name}
                  </option>
                );
              })}
            </AvField>
            <AvField
              name={`bowel_training-${pcpId}`}
              type="select"
              label="If yes, when did you begin bowel training? (In months) *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].bowel_training.disabled
                  ? ""
                  : bowel_training || tabLocalStorage.bowel_training
              }
              disabled={disabledInputs[pcpId].bowel_training.disabled}
              required={disabledInputs[pcpId].bowel_training.required}>
              {<option key={0} value=""></option>}
              {trainingMonths().map((el, idx) => {
                return (
                  <option key={idx + 1} value={el}>
                    {el}
                  </option>
                );
              })}
            </AvField>
            <AvField
              name={`urination_training-${pcpId}`}
              type="select"
              label="If yes, when did you begin urination training? (In months) *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].urination_training.disabled
                  ? ""
                  : urination_training || tabLocalStorage.urination_training
              }
              disabled={disabledInputs[pcpId].urination_training.disabled}
              required={disabledInputs[pcpId].urination_training.required}>
              {<option key={0} value=""></option>}
              {trainingMonths().map((el, idx) => {
                return (
                  <option key={idx + 1} value={el}>
                    {el}
                  </option>
                );
              })}
            </AvField>
            <AvField
              name={`is_accidents-${pcpId}`}
              type="select"
              label="Does your child have accidents? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    accidents: {
                      disabled: e.target.value !== "1",
                      required: e.target.value === "1"
                    }
                  },
                  setDisabledInputs
                )
              }
              value={is_accidents === 0 ? "0" : is_accidents || tabLocalStorage.is_accidents}
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`accidents-${pcpId}`}
              type="text"
              label="If yes, how often/when? *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].accidents.disabled
                  ? ""
                  : accidents || tabLocalStorage.accidents
              }
              disabled={disabledInputs[pcpId].accidents.disabled}
              required={disabledInputs[pcpId].accidents.required}
            />
            <AvField
              name={`wear_diapers_during_day-${pcpId}`}
              type="select"
              label={`Does your child wear ${centerProfile.countryId === 230 ? 'nappies' : 'diapers'} during the day? *`}
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                wear_diapers_during_day === 0
                  ? "0"
                  : wear_diapers_during_day || tabLocalStorage.wear_diapers_during_day
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`wear_diapers_during_nap-${pcpId}`}
              type="select"
              label={`Does your child wear ${centerProfile.countryId === 230 ? 'nappies' : 'diapers'} while napping? *`}
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                wear_diapers_during_nap === 0
                  ? "0"
                  : wear_diapers_during_nap || tabLocalStorage.wear_diapers_during_nap
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`is_bowel_movement_regular-${pcpId}`}
              type="select"
              label="Are bowel movements regular? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    bowel_movement_frequency: {
                      disabled: e.target.value !== "1",
                      required: e.target.value === "1"
                    }
                  },
                  setDisabledInputs
                )
              }
              value={
                is_bowel_movement_regular === 0
                  ? "0"
                  : is_bowel_movement_regular || tabLocalStorage.is_bowel_movement_regular
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`bowel_movement_frequency-${pcpId}`}
              type="text"
              label="If yes, how often/when? *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].bowel_movement_frequency.disabled
                  ? ""
                  : bowel_movement_frequency || tabLocalStorage.bowel_movement_frequency
              }
              disabled={disabledInputs[pcpId].bowel_movement_frequency.disabled}
              required={disabledInputs[pcpId].bowel_movement_frequency.required}
            />

            <AvField
              name={`is_constipation_problem-${pcpId}`}
              type="select"
              label="Is there a problem with constipation? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    constipation_problem: {
                      disabled: e.target.value !== "1",
                      required: e.target.value === "1"
                    }
                  },
                  setDisabledInputs
                )
              }
              value={
                is_constipation_problem === 0
                  ? "0"
                  : is_constipation_problem || tabLocalStorage.is_constipation_problem
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`constipation_problem-${pcpId}`}
              type="text"
              label="If yes, explain: *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].constipation_problem.disabled
                  ? ""
                  : constipation_problem || tabLocalStorage.constipation_problem
              }
              disabled={disabledInputs[pcpId].constipation_problem.disabled}
              required={disabledInputs[pcpId].constipation_problem.required}
            />
            <AvField
              name={`is_diarrhea-${pcpId}`}
              type="select"
              label="Is there a problem with diarrhea? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    diarrhea_problem: {
                      disabled: e.target.value !== "1",
                      required: e.target.value === "1"
                    }
                  },
                  setDisabledInputs
                )
              }
              value={is_diarrhea === 0 ? "0" : is_diarrhea || tabLocalStorage.is_diarrhea}
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`diarrhea_problem-${pcpId}`}
              type="text"
              label="If yes, explain: *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId].diarrhea_problem.disabled
                  ? ""
                  : diarrhea_problem || tabLocalStorage.diarrhea_problem
              }
              disabled={disabledInputs[pcpId].diarrhea_problem.disabled}
              required={disabledInputs[pcpId].diarrhea_problem.required}
            />
            <AvCheckboxGroup
              inline
              name={`toiletMethods-${pcpId}`}
              label="What is used at home for toileting? *"
              defaultValue={defaultCheckboxes[pcpId]["toiletMethods"]}
              required>
              {toiletMethods &&
                toiletMethods.map(method => {
                  const found = pcpToiletMethods.find(el => el.toilet_method_id === method.id);
                  return (
                    <AvCheckbox
                      key={method.id}
                      label={method.name}
                      value={method.id}
                      onChange={e => handleInputChange(e, pcpId, "pcpToiletMethods", found)}
                    />
                  );
                })}
            </AvCheckboxGroup>
            <AvField
              name={`home_toileting-${pcpId}`}
              type="text"
              label="Explain home toileting: *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={home_toileting || tabLocalStorage.home_toileting}
              required
            />
            <AvField
              name={`support_toilet_learning-${pcpId}`}
              type="text"
              label="How can we support toilet learning: *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={support_toilet_learning || tabLocalStorage.support_toilet_learning}
              required
            />
          </Fragment>
        )}
        <AvField
          name={`urination-${pcpId}`}
          type="text"
          label="Words used for urination *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={urination || tabLocalStorage.urination}
          required
        />
        <AvField
          name={`bowel_movement-${pcpId}`}
          type="text"
          label="Words used for bowel movement *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={bowel_movement || tabLocalStorage.bowel_movement}
          required
        />
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={centerProfile.countryId === 230 ? "Nappy/Toileting Routines": "Toileting Routines"}
    />
  );
};

export default ToiletingRoutines;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import AppHeader from "../../Layout/AppHeader/";
import AppFooter from "../../Layout/AppFooter/";
import AppSections from "../../Layout/AppSections";
import MyAccountAccordian from "./Components/MyAccountAccordian";
import ContactInformation from "./Components/ContactInformation";
import TransactionsAndPayments from "./Components/TransactionsAndPayments";
import MyDocuments from "./Components/MyDocuments";
import Spinner from "../SharedComponents/Spinner";
import ErrorModal from "../SharedComponents/ErrorModal";
import "../ContainersStyles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {getImage,
  saveContactInfo,
  saveDocument,
  loadMyAccount,
  loadDocuments,
  fetchingData,
  receivedData,
  verifyAddress
} from "../../redux/actionCreators/myAccount";

const MyAccount = ({
  addressValidationResponse,
  centerInfo,
  childs,
  contactInfo,
  // contactInfoChanged,
  documents,
  documentTypes,
  documentsChanged,
  error,
  fetchingData,
  getImage,
  guardian,
  imageSrc,
  loadMyAccount,
  // loadDocuments,
  navigateTo = "",
  primaryPaymentMethod,
  receivedData,
  receivables,
  saveContactInfo,
  saveDocument,
  // states,
  status,
  token,
  verifyAddress
}) => {
  const [contactInfoIsOpen, setContactInfoIsOpen] = useState(true);
  const [
    transactionsAndPaymentsIsOpen,
    setTransactionsAndPaymentsIsOpen,
  ] = useState(true);
  const [myDocumentsIsOpen, setmyDocumentsIsOpen] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState('');
  const contactInformationRef = useRef(null);
  const transactionsAndPaymentsRef = useRef(null);
  const myDocumentsRef = useRef(null);

  const errorModalClosed = () => {
    setShowErrorModal(false);
  };

  const scrollToSection = (section) => {
    switch (section) {
      case "contactInformation":
        contactInformationRef.current &&
          contactInformationRef.current.scrollIntoView();
        break;
      case "transactionsAndPayments":
        transactionsAndPaymentsRef.current &&
          transactionsAndPaymentsRef.current.scrollIntoView();
        break;
      case "myDocuments":
        myDocumentsRef.current && myDocumentsRef.current.scrollIntoView();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    loadMyAccount(guardian);
    if (navigateTo) {
      scrollToSection(navigateTo);
    }
  }, [guardian]);

  useEffect(() => {
    setShowSpinner(status === "loading");
  }, [status]);

  return (
    <Fragment>
      <div ref={contactInformationRef} className="sr-only" />
      <AppHeader />
      <div className="app-main">
        <div className="app-main__outer">
          <div id="myAccountAccordians" className="app-main__inner">
            <AppSections section="my account" />
            <Row>
              <Col>
                <MyAccountAccordian
                  accordianContent={
                    <ContactInformation
                      addressValidationResponse={addressValidationResponse}
                      centerInfo={centerInfo}
                      contactInfo={contactInfo}
                      fetchingData={fetchingData}
                      guardian={guardian}
                      receivedData={receivedData}
                      saveContactInfo={saveContactInfo}
                      setCustomErrorMessage={setCustomErrorMessage}
                      setShowErrorModal={setShowErrorModal}
                      verifyAddress={verifyAddress}
                    />
                  }
                  heading="Contact Information"
                  isOpen={contactInfoIsOpen}
                  setIsOpen={setContactInfoIsOpen}
                  type="contactInformation"
                />
                <div ref={transactionsAndPaymentsRef} className="sr-only" />
                <MyAccountAccordian
                  accordianContent={
                    <TransactionsAndPayments
                      guardian={guardian}
                      centerInfo={centerInfo}
                      primaryPaymentMethod={primaryPaymentMethod}
                      receivables={receivables}
                    />
                  }
                  heading="Transactions and Payments"
                  isOpen={transactionsAndPaymentsIsOpen}
                  setIsOpen={setTransactionsAndPaymentsIsOpen}
                  type="transactionsAndPayments"
                />
                <div ref={myDocumentsRef} className="sr-only" />
                <MyAccountAccordian
                  accordianContent={
                    <MyDocuments
                      centerInfo={centerInfo}
                      childs={childs}
                      documents={documents}
                      documentTypes={documentTypes}
                      fetchingData={fetchingData}
                      getImage={getImage}
                      guardian={guardian}
                      imageSrc={imageSrc}
                      saveDocument={saveDocument}
                      status={status}
                      token={token}
                    />
                  }
                  heading="My Documents"
                  isOpen={myDocumentsIsOpen}
                  setIsOpen={setmyDocumentsIsOpen}
                  type="myDocuments"
                />
              </Col>
            </Row>
          </div>
          <AppFooter />
        </div>
      </div>
      <Spinner showSpinner={showSpinner} />
      <ErrorModal
        errorMessage={error}
        customErrorMessage={customErrorMessage}
        showErrorModal={showErrorModal}
        errorModalClosed={errorModalClosed}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  addressValidationResponse: state.myAccount.addressValidationResponse,
  centerInfo: state.centerInfo,
  childs: state.myAccount.children,
  contactInfo: state.myAccount.contactInfo,
  documents: state.myAccount.documents,
  documentTypes: state.myAccount.documentTypes,
  documentsChanged: state.myAccount.documentsChanged,
  error: state.myAccount.error,
  guardian: state.auth.guardian,
  imageSrc: state.myAccount.imageSrc,
  primaryPaymentMethod: state.myAccount.primaryPaymentMethod,
  receivables: state.myAccount.receivables,
  // states: state.myAccount.states,
  status: state.myAccount.status,
  token: state.auth.token
});

const mapDispatchToProps = (dispatch) => ({
  getImage: bindActionCreators(getImage, dispatch),
  loadMyAccount: bindActionCreators(loadMyAccount, dispatch),
  loadDocuments: bindActionCreators(loadDocuments, dispatch),
  saveDocument: bindActionCreators(saveDocument, dispatch),
  saveContactInfo: bindActionCreators(saveContactInfo, dispatch),
  verifyAddress: bindActionCreators(verifyAddress, dispatch),
  fetchingData: bindActionCreators(fetchingData, dispatch),
  receivedData: bindActionCreators(receivedData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);

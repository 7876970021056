export const version = '1.9.4.0.262';

export function generateAddressLabels(countryId) {
  const addressLabels = {
    city: "City",
    zip: "Zip",
    state: "State",
    country: "US"
  }
  switch (countryId) {
    case 230:
      addressLabels.city = "Town"
      addressLabels.zip = "Postal"
      addressLabels.state = "County"
      addressLabels.country = "UK"
      break;
    default:
      break;
  }
  return addressLabels
}

export function getActiveItems(section, childId, type, typeId) {
  return section[childId][type].reduce((acc, val) => {
    if (val.is_active) {
      return [...acc, val[typeId]];
    }
    return acc;
  }, []);
}

export function utilsHandleInputChange(
  e,
  pcpId,
  setTabs,
  inputsToToggle = null,
  setDisabledInputs = null
) {
  let { name, value } = e.target;
  name = name.slice(0, name.indexOf("-"));
  const localStorageData = JSON.parse(localStorage.getItem("pcpFormData")) || {};
  setTabs(prevValue => {
    // empty any inputs when controlled select set to 'no'
    const inputsToEmpty = {
      is_accidents: {"accidents": ""},
      is_bathroom_reluctant: {"handle_bathroom_reluctant": ""},
      is_bowel_movement_regular: {"bowel_movement_frequency": ""},
      is_constipation_problem: {"constipation_problem": ""},
      is_diarrhea: {"diarrhea_problem": ""},
      is_pacifier_usage: {"pacifier_usage_explanation": ""},
      is_solid_food: {"eating_preference": ""},
      is_speech_difficult: {"speech_difficulties": ""},
      is_tired_nap_during_day: {"tired_nap_length": "", "nap_time": ""}
    }
    // ignore any selects that disable/enable multiple other inputs
    // ignore any inputs disabled/enabled by other selects (multiples)
    const inputsToIgnore = [
      "is_toilet_trained",
      "toilet_training_id",
      "bowel_training",
      "urination_training"
    ];
    if (!inputsToIgnore.some(el => el === name)) {
      localStorage.setItem(
        "pcpFormData",
        JSON.stringify({
          ...localStorageData,
          [pcpId]: {
            ...localStorageData[pcpId],
            [name]: value,
            ...(inputsToEmpty[name] && value === "0" && { ...inputsToEmpty[name] })
          }
        })
      );
    }
    return {
      ...prevValue,
      [pcpId]: { ...prevValue[pcpId], [name]: value }
    };
  });

  if (inputsToToggle) {
    setDisabledInputs(prevValue => {
      return {
        ...prevValue,
        [pcpId]: { ...prevValue[pcpId], ...inputsToToggle }
      };
    });
  }
}

export function utilsHandleMultipleSelect(e, pcpId, setTabs) {
  let { name, options } = e.target;
  name = name.slice(0, name.indexOf("-"));
  const selected = [...options].reduce((acc, el) => (el.selected ? [...acc, el.value] : acc), []);
  setTabs(prevValue => {
    const localStorageData = JSON.parse(localStorage.getItem("pcpFormData")) || {};
    localStorage.setItem(
      "pcpFormData",
      JSON.stringify({
        ...localStorageData,
        [pcpId]: { ...localStorageData[pcpId], [name]: selected }
      })
    );
    return {
      ...prevValue,
      [pcpId]: { ...prevValue[pcpId], [name]: selected }
    };
  });
}

export function updateCheckboxes(e, pcpId, type, property, item, tabs) {
  let { checked, value } = e.target;
  const dbData = tabs[pcpId][type];
  if (item) {
    // if checkbox deselected and not already in db, remove from collection
    if (!item.id && !checked) {
      value = dbData.filter(el => el[property] !== item[property]);
    } else {
      //otherwise add it to the collection and set to inactive
      value = dbData.map(el => {
        if (el[property] === +value) {
          return { ...el, is_active: +checked };
        }
        return el;
      });
    }
  } else {
    // checkbox never selected before, add it to collection
    if (value) {
      value = [
        ...dbData,
        {
          [property]: +value,
          personal_care_plan_id: pcpId,
          is_active: +checked
        }
      ];
    } else {
      value = [...dbData];
    }
  }

  return value;
}

export function updateInputGroup(data) {
  const { e, item, pcpId, property, propertyId, tabs, type } = data;
  let { name, value } = e.target;
  name = name.slice(0, name.indexOf("-"));
  const array = tabs[pcpId][type];
  if (item) {
    value = array.reduce((acc, val) => {
      if (val[property] === item[property]) {
        val = { ...val, [name]: value, is_active: 1 };
        // if the entry is blank
        const inputsEmpty =
          type === "pcpInfantFeedingDetails"
            ? !val.amount && !val.brand && !val.time_of_day
            : !val.time;
        if (inputsEmpty) {
          // and it doesn't exist in the db, remove it
          if (!val.id) {
            return acc;
          }
          // if it exists in the db, deactivate it
          val = { ...val, is_active: 0 };
        }
      }
      return [...acc, val];
    }, []);
  } else {
    if (value) {
      value = [
        ...array,
        {
          [name]: value,
          [property]: propertyId,
          personal_care_plan_id: pcpId
        }
      ];
    } else {
      value = [...array];
    }
  }
  return value;
}

export function updateSelect(e, pcpId, array, typeId) {
  let { value } = e.target;
  if (array.length) {
    array = [...array];
    array[0] = { ...array[0], [typeId]: value };
  } else {
    array = [
      {
        [typeId]: value,
        personal_care_plan_id: pcpId,
        is_active: 1
      }
    ];
  }
  return array;
}

export function formatPhoneNumber(e) {
  let formattedNumber;
  const { value } = e.target;
  const { length } = value;
  // Filter non numbers
  const regex = () => value.replace(/[^0-9.]+/g, "");
  // Set area code with parenthesis around it
  const areaCode = () => `(${regex().slice(0, 3)})`;

  // Set formatting for first six digits
  const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

  // Dynamic trail as user types
  const trailer = start => `${regex().slice(start, 10)}`;
  if (length <= 3) {
    // First 3 digits
    formattedNumber = regex();
  } else if (length === 4) {
    // After area code
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length === 5) {
    // When deleting digits inside parenthesis
    formattedNumber = `${areaCode().replace(")", "")}`;
  } else if (length > 5 && length <= 9) {
    // Before dash
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length >= 10) {
    // After dash
    formattedNumber = `${firstSix()}-${trailer(6)}`;
  }
  return formattedNumber;
}

export function filterState(value, states, isAbbreviation, isUKValidationResponse = false) {
  return states.filter(state => {
    if(isUKValidationResponse) {
      return state.name === value;
    }
    if (isAbbreviation) {
      return state.abbrev === value;
    } 
    else {
      return state.id === +value;
    }
  })[0];
}

export function compareLocalStorageToDb(dbData) {
  const localStorageData = JSON.parse(localStorage.getItem("pcpFormData"));
  return Object.keys(dbData).reduce(
    (acc, pcpId) => {
      Object.entries(dbData[pcpId]).forEach(([key, val]) => {
        if (
          val === null &&
          localStorageData &&
          localStorageData[pcpId] &&
          localStorageData[pcpId][key]
        ) {
          acc[pcpId] = { ...acc[pcpId], [key]: localStorageData[pcpId][key] };
        }
      });
      return acc;
    },
    { ...dbData }
  );
}

export   function checkForTabbedSection(section) {
  let isTabbed = true;
  switch (section) {
    case "primaryContactInfo":
    case "primaryContactAddress":
    case "secondaryContactInfo":
    case "secondaryContactAddress":
      isTabbed = false;
      break;
    default:
      break;
  }
  return isTabbed;
}

export function compareEnrollmentLocalStorageToDb(dbData, idName, section) {

  const compareDbEntryToLocalStorageEntry = (dbEntry, localStorageEntry, acc, idx = null) => {
    Object.entries(localStorageEntry).forEach(([key, val]) => {
      if (!dbEntry[key]) {
        if(idx === null) {
          acc = { ...acc, [key]: val };
        } else {
          acc[idx] = { ...acc[idx], [key]: val };
        }
      }
    });
    return acc;
  }

  const localStorageData = JSON.parse(localStorage.getItem("enrollmentFormData"));
  let localStorageSection = localStorageData && localStorageData[section];
  // section is tabbed so its an array
  if (dbData && dbData.length) {
    return dbData.reduce(
      (acc, dbEntry, idx) => {
        if (localStorageSection) {
             let localStorageEntryFound = localStorageSection.find(el => el[idName] === dbEntry[idName])
          if (localStorageEntryFound) {
            return compareDbEntryToLocalStorageEntry(dbEntry, localStorageEntryFound, acc, idx) ;
          }
        }
        return acc;
      },
      [...dbData]
    );
  }

  // data is an object because there can only be one entry. primaryContactInfo, secondaryContactInfo, etc.
  if(dbData && !Array.isArray(dbData)){
    if(localStorageSection) {
      return compareDbEntryToLocalStorageEntry(dbData, localStorageSection, dbData);
    }
    return dbData;
  } else {
  // there is no db entry but the user may have entered data that is in local storage
    return localStorageSection || {};
  }
}

export function utilsHandleEnrollmentFormInputChange(
  e,
  setterFunction,
  section,
  idName,
  optionalParams = {}
) {
  const isTabbedSection = checkForTabbedSection(section);
  const localStorageEnrollmentForm = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
  let id, checked, name, value;
  // need to make a special case for contact in emergency select
  if (optionalParams.isAuthorizedContact) {
    name = "contactInEmergency";
    value = e;
    id = optionalParams.id;
  } else {
    ({ checked, name, value } = e.target);
    //grab only sections with tabs to slice the id off
    if (isTabbedSection) {
      id = +name.slice(name.indexOf("-") + 1);
      name = name.slice(0, name.indexOf("-"));
    }
    switch (name) {
      case "primaryPhone":
      case "secondaryPhone":
      case "workPhone":
      case "phone":
        if(optionalParams.countryId === 232) {
          value = formatPhoneNumber(e);
        }
        break;
      case "email":
        value = value.toLowerCase();
        break;
      case "smsConsent":
        value = checked === true ? 1 : 0;
        break;
      default:
        break;
    }
  }

  // localStorageEnrollmentForm[section][id].ppGuardianId = optionalParams.ppGuardianId || null;
  setterFunction(prevValue => {
    // section is tabbed so use an array
    if(isTabbedSection) {
      const newTabs = prevValue.map(el => {
        if (el[idName] === id) {
          return { ...el, [name]: value };
        }
        return el;
      });
      localStorageEnrollmentForm[section] = newTabs;
      localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
      return newTabs;
    } 

    // section is not tabbed so use object (just one entry)
    if (!localStorageEnrollmentForm[section]) {
      localStorageEnrollmentForm[section] = {};
    }
    localStorageEnrollmentForm[section][name] = value;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
      return {
        ...prevValue,
        [name]: value
      };
  });
}

export function checkForMissingFields(
  unsavedChanges
) {
  let missingFieldFound = false;
  let forms, requiredFields;
switch (unsavedChanges.section) {
  case 'childInfo':
    forms = unsavedChanges.enrollments
    requiredFields = ["firstName", "lastName", "preferredName", "pickupPassword", "dob", "genderId", "maritalStatusId"]
    break;
  case 'childAddress':
    forms = unsavedChanges.enrollments
    requiredFields = ["address1", "city", "stateId", "postalCode"]
    break;
  case 'childMedical':
    forms = unsavedChanges.medicalContacts
    requiredFields = []
    if(forms.some(el => el.officeName)) {
        requiredFields = ["insuranceProviderName", "policyNumber", "officeName", "phone", "address1", "city", "stateId", "postalCode", "lastPhysicalDate"]
    }
    break;
  case 'childDental':
    forms = unsavedChanges.dentalContacts
    requiredFields = []
    if(forms.some(el => el.officeName)) {
        requiredFields = ["officeName", "phone", "address1", "city", "stateId", "postalCode",]
    }
    break;
    case 'primaryContactInfo':
      forms = [unsavedChanges.primaryContact]
      requiredFields = ["firstName", "lastName", "relationshipId", "primaryLanguageId", "email", "primaryPhone"]
      break;
    case 'primaryContactAddress':
      forms = [unsavedChanges.primaryContact]
      requiredFields = ["address1", "city", "stateId", "postalCode"]
      break;
    case 'secondaryContactInfo':
      forms = [unsavedChanges.secondaryContact]
      if(forms.some(el => el.firstName)) {
        requiredFields = ["firstName", "lastName", "relationshipId", "primaryLanguageId", "email", "primaryPhone"]
      }
      break;
    case 'secondaryContactAddress':
      forms = [unsavedChanges.secondaryContact]
      if(forms.some(el => el.firstName)) {
        requiredFields = ["address1", "city", "stateId", "postalCode"]
      }
      break;
    case 'emergencyContacts':
      forms = unsavedChanges.emergencyContacts
        requiredFields = ["firstName", "lastName", "relationshipId", "primaryPhone", "address1", "city", "stateId", "postalCode"]
      break;
    case 'informedConsentPage1':
      forms = unsavedChanges.enrollmentConsents
        requiredFields = ["medication", "healthImmunization", "healthConsultant", "illness", "injuries", "emergency", "studentInsurance"]
      break;
    case 'informedConsentPage2':
      forms = unsavedChanges.enrollmentConsents
        requiredFields = ["walkPermission"]
      break;
    case 'informedConsentPage3':
      forms = unsavedChanges.enrollmentConsents
        requiredFields = []
      break;
    case 'informedConsentPage4':
      forms = unsavedChanges.enrollmentConsents
        requiredFields = ["communication", "nutritionPlan", "behaviorGuidance"]
      break;
    case 'informedConsentPage5':
      forms = unsavedChanges.enrollmentConsents
        requiredFields = ["withdrawalAcknowledgement"]
      break;
    case 'importantCareInfo':
      forms = unsavedChanges.importantCareInfo
        requiredFields = ["eye_color_id, hair_color_id, ethnicity_id, is_court_ordered_custody, is_special_needs"]
      break;
    case 'developmentalHistory': {
      forms = unsavedChanges.developmentalHistory
      const types = Object.values(unsavedChanges.developmentalHistory).map(el => el.personal_care_plan_type_id);
      if(types.includes(1)) {
        requiredFields = ["infant_sitting", "infant_crawling", "infant_standing","infant_walking_with_support", "infant_walking_independently", "infant_cooing", "infant_babbling", "infant_saying_audible_words", "infant_saying_simple_sentences"];
      }
      if(types.includes(2)) {
        requiredFields = [...requiredFields, "is_crawling", "is_walking_with_support", "is_walking_without_support","is_saying_audible_words", "is_speaking_simple_language"];
      }
      if(types.includes(3)) {
        requiredFields = [...requiredFields, "is_speech_difficult", "speech_difficulties", "developmental_concerns","communication_of_needs"];
      }
      break;
    }
    case 'nutritionPracticesAndRoutines': {
      forms = unsavedChanges.nutritionPracticesAndRoutines;
      const types = Object.values(forms).map(el => el.personal_care_plan_type_id);
      if(types.includes(1)) {
        requiredFields = ["child_feeding", "is_solid_food" ,"infant_walking_with_support", "infant_walking_independently", "infant_cooing", "infant_babbling", "infant_saying_audible_words", "infant_saying_simple_sentences"];
      // TODO: eating_preference
      }
      if(types.includes(2) || types.includes(3)) {
        requiredFields = [...requiredFields, "eating_preference", "eating_method", "feeding_location"];
      }
      break;
    }
    case 'sleepingRoutines': {
      forms = unsavedChanges.sleepingRoutines
      const types = Object.values(forms).map(el => el.personal_care_plan_type_id);
      if(types.includes(1)) {
        requiredFields = ["sleepPositions", "is_can_rollover" ,"is_use_sleepsack", "infant_walking_independently", "infant_cooing", "infant_babbling", "infant_saying_audible_words", "infant_saying_simple_sentences"];
      }
      if(types.includes(2) || types.includes(3)) {
        requiredFields = [...requiredFields, "bedtime", "waketime", "sleepMethods", "waking_behavior", "sleeping_concerns"];
      }
      if(types.includes(3)) {
        requiredFields = [...requiredFields, "is_tired_nap_during_day"];
      //TODO: tired_nap_length, nap time
      }
      break;
    }
    case 'comfortingChild': {
      forms = unsavedChanges.comfortingChild
        requiredFields = ["child_held_position", "is_pacifier_usage" ,"comfort"];
      //TODO: pacifier_usage_explanation
        break;
    }
    case 'toiletingRoutines': {
      forms = unsavedChanges.toiletingRoutines
      const types = Object.values(forms).map(el => el.personal_care_plan_type_id);
        requiredFields = [ "urination", "bowel_movement"];
      if(types.includes(2) || types.includes(3)) {
        requiredFields = [...requiredFields, "is_toilet_trained", "is_accidents", "wear_diapers_during_day", "wear_diapers_during_nap", "is_bowel_movement_regular", "is_constipation_problem", "is_diarrhea", "toiletMethods", "home_toileting", "support_toilet_learning"];
      } //TODO: handle_bathroom_reluctant, toilet_training_id, bowel_training, urination_training, accidents, bowel_movement_frequency, constipation_problemm, diarrhea_problem
      if(types.includes(3)) {
        requiredFields = [...requiredFields, "is_bathroom_reluctant"];
      //TODO: handle_bathroom_reluctant, toilet_training_id, bowel_training, urination_training, accidents, bowel_movement_frequency, constipation_problemm, diarrhea_problem
      }
      break;
    }
    case 'socialRelationships': {
      forms = unsavedChanges.socialRelationships
      const types = Object.values(forms).map(el => el.personal_care_plan_type_id);
        requiredFields = ["new_situation_reaction", "favorite_activity", "favorite_toy", "fears"]
      if(types.includes(2) || types.includes(3)) {
        requiredFields = [...requiredFields, "play_preference", "play_preference_explanation", "pcp_personality", "personality"];
      } 
      break;
    }
    case 'additionalInfo':
      forms = unsavedChanges.additionalInfo
        requiredFields = []
      break;
  default:
    break;
}

// If array, is enrollment
if(Array.isArray(forms)) {
  for(const form of forms) {
    for(const requiredField of requiredFields) {
      if(!form[requiredField]) {
        missingFieldFound = true;
        break;
      }
    }
    if(missingFieldFound) break;
  }
} else {
  // otherwise pcp
  Object.entries(forms).forEach(([key, form]) => {
    for(const requiredField of requiredFields) {
      if(!form[requiredField]) {
        missingFieldFound = true;
        break;
      }
      if(missingFieldFound) break;
    }
  });
}
return missingFieldFound
}

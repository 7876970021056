import React, { Fragment, useEffect, useState } from "react";
import { Button, TabPane, Table, UncontrolledTooltip } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import { compareLocalStorageToDb, updateSelect, utilsHandleInputChange } from "../../../../utils";
import "../../../ContainersStyles.css";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";

const tableInputHeightAndWidth = {
  height: "65px",
  width: "200px"
};

const checkSocialRelationshipsLength = socialRelationships => {
  let relationshipsNotDeletedLength = socialRelationships.filter(el => !el.isDeleted).length;
  return relationshipsNotDeletedLength >= 4;
};

const copyFirstChildFamilyMembers = (pcpId, setTabs) => {
  setTabs(prevState => {
    const firstChildId = Object.keys(prevState)[0];
    let firstChildRelationships = prevState[firstChildId].pcpSocialRelationships;
    //only grab family members that have not been deleted. set the id to null to create an insert in db
    firstChildRelationships = firstChildRelationships.reduce((acc, val) => {
      if (!val.isDeleted && val.age && val.name && val.referenced_name) {
        return [
          ...acc,
          {
            ...val,
            id: null,
            temporaryId: Math.random()
              .toString(16)
              .slice(2),
            personal_care_plan_id: pcpId
          }
        ];
      }
      return acc;
    }, []);
    // need to set any pre-existing family members of current child to inactive in db
    let currentChildRelationshipsToSetInactive = prevState[pcpId].pcpSocialRelationships.reduce(
      (acc, val) => {
        if (val.id) {
          return [...acc, { ...val, isDeleted: 1, is_active: 0 }];
        }
        return acc;
      },
      []
    );
    return {
      ...prevState,
      [pcpId]: {
        ...prevState[pcpId],
        pcpSocialRelationships: [
          ...firstChildRelationships,
          ...currentChildRelationshipsToSetInactive
        ]
      }
    };
  });
};

const copyFirstChildFamilyCustoms = (pcpId, setTabs) => {
  setTabs(prevState => {
    const firstChildId = Object.keys(prevState)[0];
    return {
      ...prevState,
      [pcpId]: { ...prevState[pcpId], family_culture: prevState[firstChildId].family_culture }
    };
  });
};

const addFamilyMembers = (pcpId, setTabs) => {
  setTabs(prevState => {
    return {
      ...prevState,
      [pcpId]: {
        ...prevState[pcpId],
        pcpSocialRelationships: [
          ...prevState[pcpId]["pcpSocialRelationships"],
          {
            temporaryId: Math.random()
              .toString(16)
              .slice(2),
            name: "",
            referenced_name: "",
            age: "",
            personal_care_plan_id: pcpId,
            is_active: 1
          }
        ]
      }
    };
  });
};

const deleteFamilyMember = (pcpId, itemId, setTabs) => {
  setTabs(prevState => {
    const members = prevState[pcpId]["pcpSocialRelationships"].reduce((acc, val) => {
      if (val.temporaryId === itemId) {
        return acc;
      }
      if (val.id === itemId) {
        val = { ...val, isDeleted: 1, is_active: 0 };
      }
      return [...acc, val];
    }, []);

    return {
      ...prevState,
      [pcpId]: {
        ...prevState[pcpId],
        pcpSocialRelationships: members
      }
    };
  });
};

const FamilyMemberRows = ({ familyMembers, pcpId, handleInputChange, setTabs }) => {
  const activeFamilyMembers = familyMembers.filter(el => !el.isDeleted);
  return activeFamilyMembers.map((el, index) => {
    const id = el.id || el.temporaryId;
    return (
      <tr key={index + 1}>
        <td>
          <div style={tableInputHeightAndWidth}>
            <AvField
              name={`name-${id}-${pcpId}`}
              type="text"
              onBlur={e => handleInputChange(e, pcpId, el)}
              value={el.name}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter a name."
                },
                pattern: {
                  value: "^[A-Za-z .-]+$",
                  errorMessage: "Please enter valid name"
                },
                maxLength: { value: 50 }
              }}
            />
          </div>
        </td>
        <td>
          <div style={tableInputHeightAndWidth}>
            <AvField
              name={`referenced_name-${id}-${pcpId}`}
              type="text"
              onBlur={e => handleInputChange(e, pcpId, el)}
              value={el.referenced_name}
              validate={{
                pattern: {
                  value: "^[A-Za-z .-]+$",
                  errorMessage: "Please enter valid name"
                },
                maxLength: { value: 50 }
              }}
            />
          </div>
        </td>
        <td>
          <div style={tableInputHeightAndWidth}>
            <AvField
              name={`age-${id}-${pcpId}`}
              type="text"
              onBlur={e => handleInputChange(e, pcpId, el)}
              value={el.age}
              validate={{
                maxLength: { value: 50 }
              }}
            />
          </div>
        </td>
        <td className="vertical-align-initial">
          <Button
            id={`deleteBtn-${id}`}
            // style={}
            color="primary"
            size="sm"
            onClick={() => deleteFamilyMember(pcpId, id, setTabs)}>
            <FontAwesomeIcon icon={faTrash} fixedWidth={false} className={``} />
          </Button>
          <UncontrolledTooltip placement="bottom" target={`deleteBtn-${id}`}>
            Delete
          </UncontrolledTooltip>
        </td>
      </tr>
    );
  });
};

const updateSocialRelationships = (e, pcpId, el, tabs) => {
  let { name, value } = e.target;
  name = name.slice(0, name.indexOf("-"));
  const id = el.id || el.temporaryId;
  const familyMembers = tabs[pcpId]["pcpSocialRelationships"];
  return familyMembers.reduce((acc, val) => {
    if (val.id === id || val.temporaryId === id) {
      val = { ...val, [name]: value };
      // if the entry is blank
      if (!val.name && !val.referenced_name && !val.age) {
        // if it exists in the db, deactivate it
        val = { ...val, is_active: 0 };
      }
    }
    return [...acc, val];
  }, []);
};

const SocialRelationships = ({
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const {
    childrenInfo,
    personalities,
    playPreferences,
    socialRelationships,
    status,
  } = pcp;
  
  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ socialRelationships: tabs, section: "socialRelationships", showModal: true })
    }
  }, [tabs])

  
  useEffect(() => {
    if (status === "idle" && socialRelationships && Object.keys(socialRelationships).length) {
      if (!activeTab) setActiveTab(+Object.keys(socialRelationships)[0]);
      const comparedSocialRelationships = compareLocalStorageToDb(socialRelationships);
      setTabs(comparedSocialRelationships);
      setStartingState(comparedSocialRelationships);
    }
  }, [socialRelationships]);

  const handleInputChange = (e, pcpId, item) => {
    let { name, value } = e.target;
    name = name.slice(0, name.indexOf("-"));
    if (name === "name" || name === "referenced_name" || name === "age") {
      name = "pcpSocialRelationships";
      value = updateSocialRelationships(e, pcpId, item, tabs);
    }
    if (name === "play_preference") {
      name = "pcpPlayPreference";
      value = updateSelect(e, pcpId, item, "play_preference_id");
    }
    if (name === "pcp_personality") {
      name = "pcpPersonality";
      value = updateSelect(e, pcpId, item, "personality_id");
    }
    setTabs(prevValue => {
      return {
        ...prevValue,
        [pcpId]: { ...prevValue[pcpId], [name]: value }
      };
    });
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "socialRelationships", socialRelationships: tabs });
    nextStep();
  };

  const displayTabInputs = (tab, idx) => {
    const {
      family_culture,
      favorite_activity,
      favorite_toy,
      fears,
      group_care_experience,
      new_situation_reaction,
      pcpPersonality,
      pcpPlayPreference,
      pcpSocialRelationships,
      personality,
      play_preference_explanation,
      previous_childcare_experience,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};
    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader text={childrenInfo[pcpId].transition_classroom_type_name || childrenInfo[pcpId].classroom_type_name} />
        <div className="mb-sm">
          Please list the names of family members residing with the child. Please include siblings,
          extended relatives, and pets. For each person listed, provide the name the child uses to
          address the individual and include ages of siblings.
        </div>
        <Table responsive id={`family-members-${pcpId}`}>
          <thead>
            <tr>
              <th>Name</th>
              <th>How Child Addresses Them</th>
              <th>Age</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <FamilyMemberRows
              familyMembers={pcpSocialRelationships}
              handleInputChange={handleInputChange}
              pcpId={pcpId}
              setTabs={setTabs}
            />
          </tbody>
        </Table>
        <div id={`addFamilyMemberBtn-${pcpId}`} style={{ display: "inline-block" }}>
          <Button
            className="mb-3"
            color="primary"
            size="lg"
            onClick={() => addFamilyMembers(pcpId, setTabs)}
            style={{
              pointerEvents: checkSocialRelationshipsLength(pcpSocialRelationships)
                ? "none"
                : "auto"
            }}
            disabled={checkSocialRelationshipsLength(pcpSocialRelationships)}>
            <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
            Add Family Member
          </Button>
        </div>
        {checkSocialRelationshipsLength(pcpSocialRelationships) && (
          <UncontrolledTooltip placement="top" target={`addFamilyMemberBtn-${pcpId}`}>
            No More Than Four Family Members Allowed
          </UncontrolledTooltip>
        )}
        {idx > 0 && (
          <div
            id={`copyFirstChildFamilyMembers-${pcpId}`}
            style={{ display: "inline-block", marginLeft: "1rem" }}>
            <Button
              className="mb-3"
              color="primary"
              size="lg"
              onClick={() => copyFirstChildFamilyMembers(pcpId, setTabs)}>
              <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
              Copy family members from first child
            </Button>
          </div>
        )}
        <AvField
          name={`family_culture-${pcpId}`}
          type="text"
          label="Please tell us about cultural family customs, rituals or traditions that will help
           us make your child’s experience more meaningful, including languages spoken at home:"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={family_culture || tabLocalStorage.family_culture}
        />
        {idx > 0 && (
          <div id={`copyFirstChildFamilyCulture-${pcpId}`}>
            <Button
              className="mb-3"
              color="primary"
              size="lg"
              onClick={() => copyFirstChildFamilyCustoms(pcpId, setTabs)}>
              Copy family customs from first child
            </Button>
          </div>
        )}
        <AvField
          name={`group_care_experience-${pcpId}`}
          type="text"
          label="Has your child had any experience with group care? If yes, please describe:"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={group_care_experience || tabLocalStorage.group_care_experience}
        />
        <AvField
          name={`new_situation_reaction-${pcpId}`}
          type="text"
          label="How does your child react to new situations, new children, and adults? *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={new_situation_reaction || tabLocalStorage.new_situation_reaction}
          required
        />
        <AvField
          name={`previous_childcare_experience-${pcpId}`}
          type="text"
          label="Has your child had previous childcare experience? If yes, explain how it met, or did not meet your expectations?"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={previous_childcare_experience || tabLocalStorage.previous_childcare_experience}
        />
        <AvField
          name={`favorite_activity-${pcpId}`}
          type="text"
          label="Child’s favorite activities: *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={favorite_activity || tabLocalStorage.favorite_activity}
          required
        />
        <AvField
          name={`favorite_toy-${pcpId}`}
          type="text"
          label="Child’s favorite toys: *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={favorite_toy || tabLocalStorage.favorite_toy}
          required
        />
        <AvField
          name={`fears-${pcpId}`}
          type="text"
          label="Does your child have any fears? *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={fears || tabLocalStorage.fears}
          required
        />
        {typeId !== 1 && (
          <Fragment>
            <AvField
              name={`play_preference-${pcpId}`}
              type="select"
              label="How does your child prefer to play? *"
              onChange={e => handleInputChange(e, pcpId, pcpPlayPreference)}
              value={(pcpPlayPreference.length && pcpPlayPreference[0].play_preference_id) || ""}
              required>
              <option key="0" value="">
                Select a preference
              </option>
              {playPreferences.length &&
                playPreferences.map(preference => (
                  <option key={preference.id} value={preference.id}>
                    {preference.name}
                  </option>
                ))}
            </AvField>
            <AvField
              name={`play_preference_explanation-${pcpId}`}
              type="text"
              label="Please explain why your child prefers to play this way. *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={play_preference_explanation || tabLocalStorage.play_preference_explanation}
              required
            />
            <AvField
              name={`pcp_personality-${pcpId}`}
              type="select"
              label="Is your child: *"
              onChange={e => handleInputChange(e, pcpId, pcpPersonality)}
              value={(pcpPersonality.length && pcpPersonality[0].personality_id) || ""}
              required>
              <option key="0" value="">
                Select a personality type
              </option>
              {personalities.length &&
                personalities.map(personality => (
                  <option key={personality.id} value={personality.id}>
                    {personality.name}
                  </option>
                ))}
            </AvField>
            <AvField
              name={`personality-${pcpId}`}
              type="text"
              label="Please explain your childs personality *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={personality || tabLocalStorage.personality}
              required
            />
          </Fragment>
        )}
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={"Social Relationships"}
    />
  );
};

export default SocialRelationships;

import React, {Fragment} from 'react';
import {version} from '../../utils';

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              <small>
                <strong>Copyright &copy; 2022 <a href="https://thelearningexperience.com">The Learning Experience<sup>&reg;</sup></a>. </strong>
                All rights reserved.
              </small>
            </div>
            <div className="app-footer-right">
              <small>Product {version}</small>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppFooter;
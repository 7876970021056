import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

const DeleteContactModal = ({
  deleteContactClicked,
  deleteEmergencyContact,
  contactInfo,
  modalClosed,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteContactInfo, setDeleteContactInfo] = useState({});

  useEffect(() => {
    if (deleteContactClicked) {
      setIsOpen(true);
    }
  }, [deleteContactClicked]);

  useEffect(() => {
    let contactInfoNeeded = {};
    if(Object.keys(contactInfo).length) {
        contactInfoNeeded.child_id = contactInfo.child_id
        contactInfoNeeded.guardian_id = contactInfo.id
        contactInfoNeeded.first_name = contactInfo.first_name
        contactInfoNeeded.last_name = contactInfo.last_name
    }
    setDeleteContactInfo(prevState => ({ ...prevState, ...contactInfoNeeded }));
  }, [contactInfo]);

  const toggle = () => {
    setIsOpen(!isOpen);
    modalClosed();
    setDeleteContactInfo({});
  };

  const handleSubmit = (event, errors, values) => {
    deleteEmergencyContact(deleteContactInfo);
    toggle();
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="testing">
        <AvForm onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>Delete Emergency Contact</ModalHeader>
          <ModalBody>
              Are you sure you want to delete {deleteContactInfo.first_name} {deleteContactInfo.last_name}?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary">Delete</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

export default DeleteContactModal;

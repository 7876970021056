import React, { Fragment, useState } from "react";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const IdentificationTooltip = ({id, msg}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Fragment>
      <span
        id={id}
        className="ml-2"
        style={{ color: "#943885", cursor: "pointer" }}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </span>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}  
        target={id}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        {msg}
      </Tooltip>
    </Fragment>
  );
};

export default IdentificationTooltip;

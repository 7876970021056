import React, { Fragment, useState } from "react";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import { Col, Row, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import {
  resetPassword,
} from "../../../redux/actionCreators/auth";
import { useHistory, useParams } from "react-router-dom";
import {version} from '../../../utils';
import Spinner from "../../SharedComponents/Spinner";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const thisYear = new Date();
  const history = useHistory();
  const { token } = useParams();

  function validateForm() {
    return (
      password.length > 0 &&
      password === confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const result = await props.resetPassword({
      token,
      password,
    });
    if(result==='OK') {
      alert('Password has been changed successfully.');
      history.push("/signin")
    } else {
      alert('Something went wrong, please try again.  If you continue to have an issue, please let your center know.');
    }
  }

  return (
    <Fragment>
        <div className="h-100 bg-plum-plate bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <div className="app-logo-inverse mx-auto mb-3 mt-3" />

              <div className="modal-dialog w-100 mx-auto mb-3">
                <div className="modal-content">
                  <Form onSubmit={handleSubmit}>
                    <div className="modal-body">
                      <div className="modal-title text-center">
                        <h4 className="mt-2">
                          <div>Reset Password</div>
                          <span>Please input new password.</span>
                        </h4>
                      </div>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label>Password</Label>
                            <Input
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup controlId="confirmPassword">
                            <Label>Confirm Password</Label>
                            <Input
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="divider"/>
                      <h6 className="mb-0 account-login">
                          Already have an account?{" "}
                          <button
                          className="btn btn-link"
                          onClick={() => history.push("/signin")}
                          type="button"
                          >
                          Sign in
                          </button>
                      </h6>
                      <h6 className="mb-0 account-login">
                          No account?{" "}
                          <button
                          className="btn btn-link"
                          onClick={() => history.push("/verifyEmail")}
                          type="button"
                          >
                          Sign up now
                          </button>
                      </h6>
                    </div>
                    <div className="modal-footer clearfix">
                      <div className="float-right">
                        <Button
                          color="primary"
                          size="lg"
                          disabled={!validateForm()}
                          type="submit"
                        >
                          Reset Password
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className=" text-center text-white opacity-8 mt-3">
                Copyright &copy; <Moment format="YYYY">{thisYear}</Moment> The
                Learning Experience. All Rights Reserved<br/>
                Product {version}
              </div>
            </Col>
          </div>
        </div>
        <Spinner showSpinner={props.auth.status === "loading"} />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: bindActionCreators(resetPassword, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(ResetPassword);

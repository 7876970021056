import React, { Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const SignUpModal = ({
  message,
  showModal,
  onYes,
  onNo,
  onClose,
  yesTitle='Yes',
  noTitle='No'
}) => {
  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={onClose}>
          <ModalHeader toggle={onClose}></ModalHeader>
          <ModalBody>
              {message}
          </ModalBody>
          <ModalFooter>
            {/* <Button color="secondary" onClick={onNo}>
              {noTitle}
            </Button> */}
            <Button color="primary" onClick={onYes}>
              {/* {yesTitle} */}
              Ok
            </Button>
          </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default SignUpModal;

import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const imgContainer = {
  height: "75px",
  width: "75px",
  display: "inline-block"
};

const fontLarge = {
  fontSize: "1rem"
};

const img = {
  borderRadius: "50%",
  width: "100%",
  height: "100%"
};

const icon = {
  borderRadius: "50%",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#00AEEF"
};

const camera = {
  color: "white",
  width: "2em",
  height: "2em"
};

const rowStyles = {
  alignItems: "center",
  cursor: "pointer"
};

const ImageOrIcon = ({ child, documentSrc, receivedData, setShowImageModal }) => {
  return (
    <Fragment>
      <div
        id={`uploadDocumentBtn-${child.id}`}
        style={documentSrc ? img : icon}
        onClick={e => {
          e.stopPropagation();
          setShowImageModal(true);
        }}>
        {documentSrc ? (
          <img alt="" src={documentSrc} style={img} onLoad={() => receivedData()} />
        ) : (
          <FontAwesomeIcon icon={faCamera} style={camera} fixedWidth={false} />
        )}
      </div>
      <UncontrolledTooltip placement="right" target={`uploadDocumentBtn-${child.id}`}>
        Click to upload image
      </UncontrolledTooltip>
    </Fragment>
  );
};

const ChildPhotoAndName = ({
  centerId,
  child,
  fetchingData,
  receivedData,
  isOpen,
  setShowImageModal
}) => {
  const [documentSrc, setDocumentSrc] = useState(null);
  const host = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (child.photoPath && centerId) {
      setDocumentSrc(`${host}/cdn/image/inline/${centerId}/${child.photoPath}`);
    }
  }, [child.photoPath]);

  return (
    <Fragment>
      <Row className="m-0 p-2 he-100 w-100 align-items-center" style={rowStyles}>
        <Col xs={10}>
          <Row style={{ alignItems: "center" }}>
            <Col md={4}>
              <div style={imgContainer}>
                <ImageOrIcon
                  documentSrc={documentSrc}
                  receivedData={receivedData}
                  setShowImageModal={setShowImageModal}
                  child={child}
                />
              </div>
            </Col>
            <Col md={6}>
              <div style={{ wordBreak: "break-all" }}>
                <p className="m-0 text-blue" style={fontLarge}>
                  {child.firstName}
                </p>
                <p className="m-0 text-blue" style={fontLarge}>
                  {child.lastName}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={2} className="text-center">
          <FontAwesomeIcon
            icon={isOpen ? faChevronDown : faChevronRight}
            fixedWidth={false}
            className="mt-1 text-blue fa-2x"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default ChildPhotoAndName;

import produce from "immer";
import * as actionTypes from "../actionTypes";
import initialState from "./initialState";

export const pcp = produce((state = initialState.pcp, action) => {
  switch (action.type) {
    case actionTypes.PCP_LOAD_DATA__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.PCP_LOAD_DATA__SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "idle",
      };
    case actionTypes.PCP_LOAD_DATA__FAILURE:
      return {
        ...state,
        status: "failed", 
        error: action.payload,
      };
    case actionTypes.PCP_LOAD_SUBMISSION_STATUSES__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.PCP_LOAD_SUBMISSION_STATUSES__SUCCESS:
      return {
        ...state,
        pcpStatusIds: [...action.payload],
        redirectTo: '/home',
        status: "idle",
      };
    case actionTypes.PCP_LOAD_SUBMISSION_STATUSES__FAILURE:
      return {
        ...state,
        status: "failed", 
        error: action.payload,
      };
    case actionTypes.PCP_PATCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.PCP_SAVE_DATA__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.PCP_SAVE_DATA__SUCCESS: {
      const isLastStep = action.payload === 'signature'
      if (isLastStep) {
        localStorage.removeItem("pcpFormData");
      }
      return {
        ...state,
        status: isLastStep ? 'submitted' : 'saved',
        unsavedChanges: null,
        showModal: false,
      };
    }
    case actionTypes.PCP_SAVE_DATA__FAILURE:
      return {
        ...state,
        status: "failed", 
        error: action.payload,
      };
      case actionTypes.PCP_UPDATE_UNSAVED_CHANGES_WARNING:
        return {
          ...state,
          unsavedChanges: action.payload,
          showModal: action.payload.showModal
        };
    default:
      return state;
  }
});

import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducers";
import initialState from "./reducers/initialState";
import Api from "../servicies/api";
import actionCreators from "./actionCreators";
import * as actionTypes from "./actionTypes";

const composeEnhancers = composeWithDevTools({
  name: `${document.title}`,
  actionCreators: actionCreators,
});

const thunkWithApiFromState = (parameters) => {
  const { getState } = parameters;
  const apiInstance = new Api(getState);
  return ReduxThunk.withExtraArgument(apiInstance)(parameters);
};
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === actionTypes.RESET_STORE) {
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};

export const createStoreInstance = () =>
  createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkWithApiFromState))
  );

const store = createStoreInstance();

export default store;

import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { checkForMissingFields, filterState } from "../../utils";

const unsavedChangesModal = ({
  centerInfo,
  enrollment,
  handleAddressSaveInUnsavedChangesModal,
  jumpToSectionFromModal,
  pcp,
  resetAddressValidationResponse,
  saveForm,
  showUnsavedChangesModal,
  toggleUnsavedChangesModal,
  updateAddressValidationComingFromUnsavedChangesModal,
  updateUnsavedChangesWarning,
  unsavedChanges,
  verifyAddress
}) => {
  // const [modal, setModal] = useState({});
  const [guardian, setGuardian] = useState({});
  const [missingFieldFound, setMissingFieldFound] = useState(null);
  const {addressValidationComingFromUnsavedChangesModal, addressValidationResponse} = enrollment;
  const { centerProfile, states } = centerInfo;
  useEffect(() => {
    if(!addressValidationComingFromUnsavedChangesModal) return;
    if (addressValidationResponse === "INVALID_ADDRESS") {
      handleAddressSaveInUnsavedChangesModal({
        isOpen: true,
        updatedContactInfo: guardian,
        addressValidationResponse,
        missingFieldFound
      })
    } else if (addressValidationResponse) {
      const selectedState = states.filter(state => state.id === +guardian.stateId)[0];
      const stateNameOrAbbrev =
        centerProfile.country_id === 230 ? selectedState.name.trim() : selectedState.abbrev.trim();
      if (
        guardian.address1.trim() !== addressValidationResponse.street_line_1.trim() ||
        guardian.city.trim() !== addressValidationResponse.city.trim() ||
        guardian.postalCode.trim() !== addressValidationResponse.postal_code.trim() ||
        stateNameOrAbbrev !== addressValidationResponse.state_code
      ) {
        handleAddressSaveInUnsavedChangesModal({
          isOpen: true,
          updatedContactInfo: guardian,
          addressValidationResponse,
          stateNameOrAbbrev,
          missingFieldFound
        })
      } else {
        saveForm({...unsavedChanges, missingFieldFound})
        jumpToSectionFromModal();
      }
    }
  }, [addressValidationResponse]);

  const toggle = () => {
    toggleUnsavedChangesModal(!showUnsavedChangesModal);
  };
  
  
const deleteAndUndeleteLanguages = (tabsCopy) => {
  let deletedLanguages = []
  let {pcpLanguages, updatedPcpLanguages } = tabsCopy;
  if(pcpLanguages.length) {
    pcpLanguages.forEach(language => {
      const languageCopy = {...language}
      const languageFound = updatedPcpLanguages.some(el => el.language_id === language.language_id)
      if(!languageFound && !language.is_deleted) {
          languageCopy.delete = 1;
          deletedLanguages.push(languageCopy)
      }
      if(languageFound && language.is_deleted){
        languageCopy.delete = 0;
        deletedLanguages.push(languageCopy)
      }
    })
  }
  return deletedLanguages
}

  const handleClick = (e, yesClicked) => {
    if(!yesClicked) {
      updateUnsavedChangesWarning({showModal: false});
      jumpToSectionFromModal();
      toggle();
      return;
    }

    if(yesClicked && unsavedChanges) {
      const isFieldMissing = checkForMissingFields(unsavedChanges)
      if(["primaryContactAddress", "secondaryContactAddress"].includes(unsavedChanges.section)) {
        // skip the address validation until all required fields are filled out
        if(isFieldMissing) {
          saveForm({...enrollment.unsavedChanges, missingFieldFound: isFieldMissing})
          jumpToSectionFromModal();
          toggle()
          return;
        }
        const guardian = unsavedChanges.primaryContact || unsavedChanges.secondaryContact;
        setGuardian(guardian);
        setMissingFieldFound(isFieldMissing);
        updateAddressValidationComingFromUnsavedChangesModal({addressValidationComingFromModal: true})
        verifyAddress({
          ...guardian,
          section: "ENROLLMENT",
          zipCode: guardian.postalCode,
          countryCode: centerProfile.countryId === 230 ? "UK" : "US",
          stateName: filterState(guardian.stateId, states).name
        });
        return;
      } 
      if(unsavedChanges.section === "developmentalHistory") {
        const tabs = {...unsavedChanges["developmentalHistory"]}
        Object.keys(tabs).forEach(key => {
          const deletedAndUndeletedLanguages = deleteAndUndeleteLanguages(tabs[key]);
          const newLanguages = tabs[key].updatedPcpLanguages.filter(el => !el.id);
          tabs[key] = {
            ...tabs[key],
            error: false,
            updatedPcpLanguages: [...newLanguages, ...deletedAndUndeletedLanguages],
            childId: pcp.childrenInfo[key].pp_child_id
          }
        });
        saveForm({...unsavedChanges, developmentalHistory: tabs, missingFieldFound: isFieldMissing})
        jumpToSectionFromModal();
        toggle();
        return
      }
        saveForm({...unsavedChanges, missingFieldFound: isFieldMissing})
        jumpToSectionFromModal();
        toggle();
    }
  }

  return (
    <Fragment>
      <Modal isOpen={showUnsavedChangesModal} toggle={toggle} className="error">
        <ModalHeader toggle={toggle}>Warning</ModalHeader>
        <ModalBody>
          You have unsaved changes on this section. Do you want to save changes?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) =>handleClick(e, true)}>
            Yes
          </Button>
          <Button
            color="primary"
            onClick={(e) =>handleClick(e, false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default unsavedChangesModal;

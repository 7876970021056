import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import {
  loadCenterInfo,
  loadGuardian,
  getCentersByEmail,
  signin,
  signup,
  loginSuccess,
  verifyEmail
} from "../../../redux/actionCreators/auth";
import { loadData } from "../../../redux/actionCreators/enrollment";
import { useHistory } from "react-router-dom";
import { loadPcpData } from "../../../redux/actionCreators/pcp";
import { version } from "../../../utils";
import Spinner from "../../SharedComponents/Spinner";
import SignUpModal from "../../SharedComponents/SignUpModal";

const VerifyEmail = ({
  auth,
  getCentersByEmail,
  loginSuccess,
  loadCenterInfo,
  loadData,
  loadGuardian,
  loadPcpData,
  signin,
  signup,
  verifyEmail
}) => {
  const [email, setEmail] = useState("");
  const [selectedCenterId, setSelectedCenterId] = useState(0);
  const [centers, setCenters] = useState([]);
  const history = useHistory();
  const thisYear = new Date();
  const [modal, setModal] = useState({
    show: false,
    title: "",
    message: "",
    html: ""
  });

  function validateForm() {
    return email.length > 0 && centers.length > 0;
  }

  useEffect(() => {
    if (auth && auth.token) {
      history.push("/home");
    }
    if (auth && auth.error && email) {
      setEmail("");
      setCenters([]);
      setSelectedCenterId(0);
      setModal({
        show: true,
        title: "",
        message: auth.error
      });
    }
  }, [auth]);

  useEffect(() => {
    if (centers.length > 0) {
      setSelectedCenterId(centers[0].id);
    }
  }, [centers]);

  async function reSendEmail(e) {
    e.preventDefault();
    setModal({ showModal: false });
    await verifyEmail({ centerId: selectedCenterId, email });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await verifyEmail({ centerId: selectedCenterId, email });
    setModal({
      show: true,
      title: "",
      message: (
        <p>
          We just sent you an email. Please open email and verify your email address that will be
          used for registration. <br></br>
          <br></br> Didn’t get an email?{" "}
          <a href="#" onClick={e => reSendEmail(e)}>
            Resend the email
          </a>
        </p>
      )
    });
  }

  return (
    <Fragment>
      <div className="h-100 bg-plum-plate bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <div className="app-logo-inverse mx-auto mb-3 mt-3" />
            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <Form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="modal-title text-center">
                      <h4 className="mt-1">
                        <div>Welcome to the Family Information Station</div>
                        <span>Please fill out to verify your email address</span>
                      </h4>
                    </div>
                    <Row form>
                      <Col md={12}>
                        <FormGroup controlId="email">
                          <Label>Email Address</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            autoFocus
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value.toLowerCase())}
                            onBlur={e => {
                              if (!e || !e.target || !e.target.value) return;
                              getCentersByEmail({ email }).then(res => {
                                if (res && res.length > 0) setCenters(res);
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup controlId="center">
                          <Label>Center</Label>
                          <Input
                            type={"select"}
                            value={selectedCenterId}
                            onChange={e => setSelectedCenterId(e.target.value)}
                            disabled={centers.length === 0}>
                            {centers.length &&
                              centers.map(c => (
                                <option value={c.id} key={c.id}>
                                  {c.name}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="divider" />
                    <h6 className="mb-0 account-login">
                      Already have an account?{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => history.push("/signin")}
                        type="button">
                        Sign in
                      </button>
                    </h6>
                    <h6 className="mb-0 account-login">
                      Can't login?{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => history.push("/forgotPassword")}
                        type="button">
                        Reset Password
                      </button>
                    </h6>
                  </div>
                  <div className="modal-footer clearfix">
                    <div className="float-right">
                      <Button color="primary" size="lg" disabled={!validateForm()} type="submit">
                        Verify Email Address
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className=" text-center text-white opacity-8">
              Copyright &copy; <Moment format="YYYY">{thisYear}</Moment> The Learning Experience.
              All Rights Reserved
              <br />
              Product {version}
            </div>
            <SignUpModal
              message={modal.message}
              showModal={modal.show}
              onYes={() => setModal({ show: false })}
            />
          </Col>
        </div>
      </div>
      <Spinner showSpinner={auth.status === "loading"} />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  signin: bindActionCreators(signin, dispatch),
  signup: bindActionCreators(signup, dispatch),
  loadData: bindActionCreators(loadData, dispatch),
  getCentersByEmail: bindActionCreators(getCentersByEmail, dispatch),
  loadCenterInfo: bindActionCreators(loadCenterInfo, dispatch),
  loadGuardian: bindActionCreators(loadGuardian, dispatch),
  loadPcpData: bindActionCreators(loadPcpData, dispatch),
  loginSuccess: bindActionCreators(loginSuccess, dispatch),
  verifyEmail: bindActionCreators(verifyEmail, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null)(VerifyEmail);

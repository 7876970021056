import React, {Fragment} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HeaderLogo from '../AppLogo';
import {Button, NavItem, NavLink} from 'reactstrap';
import { withRouter } from 'react-router-dom'
import CenterName from "./Components/CenterName"

const Header = ({
    headerBackgroundColor,
    enableMobileMenuSmall,
    enableHeaderShadow,
    history,
    centerInfo
}) => {

    return (
        <Fragment>
            <ReactCSSTransitionGroup
                component="div"
                className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                transitionName="HeaderAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}>

                <HeaderLogo/>
                <div className={cx(
                    "app-header__content",
                    {'header-mobile-open': enableMobileMenuSmall},
                )}>
                    <NavItem className="app-header-left">
                        <NavLink href="#/home" style={{width: "100%", height: "100%"}} />
                    </NavItem>

                    <div className="app-header-right">
                        <CenterName centerInfo={centerInfo}/>
                        <Button className="btn-pill btn-shadow btn-shine"
                                color="focus"
                                onClick={()=> history.push('signout')}>
                            Logout
                        </Button>
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    centerInfo: state.centerInfo.centerProfile
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
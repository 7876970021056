import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, TabPane, Table } from "reactstrap";
import { AvField, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import {
  compareLocalStorageToDb,
  getActiveItems,
  updateCheckboxes,
  updateInputGroup,
  utilsHandleInputChange
} from "../../../../utils";
import YesNoDropDownOptions from "../../../SharedComponents/YesNoDropdownOptions";
import TimePickerDropDownOptions from "../../../SharedComponents/TimePickerDropDownOptions";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import "../../../ContainersStyles.css";
import InfantFeedingTimeTooltip from "../InfantFeedingTimeTooltip";

const feedingTimeSlots = [1, 2, 3, 4];

const enableDefaultCheckboxes = comparedNutritionPracticesAndRoutines => {
  return Object.keys(comparedNutritionPracticesAndRoutines).reduce((acc, val) => {
    const feedingMethods = getActiveItems(
      comparedNutritionPracticesAndRoutines,
      val,
      "pcpFeedingMethods",
      "feeding_id"
    );
    const eatingMethods = getActiveItems(
      comparedNutritionPracticesAndRoutines,
      val,
      "pcpEatingMethods",
      "eating_id"
    );
    const feedingLocations = getActiveItems(
      comparedNutritionPracticesAndRoutines,
      val,
      "pcpFeedingLocations",
      "feeding_location_id"
    );
    return {
      ...acc,
      [val]: { eatingMethods, feedingLocations, feedingMethods }
    };
  }, {});
};

const NutritionPracticesAndRoutines = ({
  centerInfo,
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});
  const [defaultCheckboxes, setDefaultCheckboxes] = useState(null);
  const { childrenInfo, nutritionPracticesAndRoutines, status } = pcp;
  const { centerProfile } = centerInfo;

  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if (!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({
        nutritionPracticesAndRoutines: tabs,
        section: "nutritionPracticesAndRoutines",
        showModal: true
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (
      status === "idle" &&
      nutritionPracticesAndRoutines &&
      Object.keys(nutritionPracticesAndRoutines).length
    ) {
      if (!activeTab) {
        setActiveTab(+Object.keys(nutritionPracticesAndRoutines)[0]);
        const comparedNutritionPracticesAndRoutines = compareLocalStorageToDb(
          nutritionPracticesAndRoutines
        );
        setTabs(comparedNutritionPracticesAndRoutines);
        setStartingState(comparedNutritionPracticesAndRoutines);
        setDefaultCheckboxes(enableDefaultCheckboxes(comparedNutritionPracticesAndRoutines));
        const disabledInputsObj = Object.keys(comparedNutritionPracticesAndRoutines).reduce(
          (acc, val) => {
            return {
              ...acc,
              [val]: {
                eating_preference: !comparedNutritionPracticesAndRoutines[val]["is_solid_food"]
              }
            };
          },
          {}
        );
        setDisabledInputs(prevValue => {
          return { ...prevValue, ...disabledInputsObj };
        });
      }
    }
  }, [nutritionPracticesAndRoutines]);

  const handleInputChange = (
    e,
    pcpId,
    type = null,
    item = null,
    property = null,
    propertyId = null
  ) => {
    let { name, value } = e.target;
    name = name.slice(0, name.indexOf("-"));

    const data = {
      e,
      item,
      pcpId,
      property,
      propertyId,
      tabs,
      type
    };

    if (type === "pcpInfantFeedingDetails" || type === "pcpFeedingTimes") {
      value = updateInputGroup(data);
    }

    if (type === "pcpFeedingMethods") {
      value = updateCheckboxes(e, pcpId, type, "feeding_id", item, tabs);
    }

    if (type === "pcpFeedingLocations") {
      value = updateCheckboxes(e, pcpId, type, "feeding_location_id", item, tabs);
    }

    if (type === "pcpEatingMethods") {
      value = updateCheckboxes(e, pcpId, type, "eating_id", item, tabs);
    }

    setTabs(prevValue => {
      return {
        ...prevValue,
        [pcpId]: { ...prevValue[pcpId], [type || name]: value }
      };
    });
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "nutritionPracticesAndRoutines", nutritionPracticesAndRoutines: tabs });
    nextStep();
  };

  const displayTabInputs = tab => {
    const {
      additional_feeding_information,
      breast_fed_daily_plan,
      eating_preference,
      feeding_support,
      is_solid_food,
      pcpEatingMethods,
      pcpFeedingLocations,
      pcpFeedingMethods,
      pcpFeedingTimes,
      pcpInfantFeedingDetails,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId,
      weaning_stage
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};

    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader
          text={
            childrenInfo[pcpId].transition_classroom_type_name ||
            childrenInfo[pcpId].classroom_type_name
          }
        />
        {typeId === 1 && (
          <Fragment>
            <AvCheckboxGroup
              inline
              name={`child_feeding-${pcpId}`}
              label="How is your child fed? Tick all that apply: *"
              defaultValue={defaultCheckboxes[pcpId]["feedingMethods"]}
              required>
              {pcp.feedingMethods &&
                pcp.feedingMethods.map(method => {
                  const item = pcpFeedingMethods.find(el => el.feeding_id === method.id);
                  return (
                    <AvCheckbox
                      key={method.id}
                      label={method.name}
                      value={method.id}
                      onChange={e => handleInputChange(e, pcpId, "pcpFeedingMethods", item)}
                    />
                  );
                })}
            </AvCheckboxGroup>
            <div className="mb-sm">
              In the corresponding table, please provide your child's feeding details:
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Brand</th>
                  <th>Amount (in ounces)</th>
                  <th>
                    Preferred time(s) of day given{" "}
                    <InfantFeedingTimeTooltip
                      id={"testing"}
                      msg={
                        "Define the preferred times (9am, 11am, 1pm, 3pm) and/or intervals (every 2 hours) for each item."
                      }
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {pcp.infantFeeding &&
                  pcp.infantFeeding.map(type => {
                    const item = pcpInfantFeedingDetails.find(
                      el => el.infant_feeding_id === type.id
                    );
                    return (
                      <tr key={type.id}>
                        <th scope="row">{type.name}</th>
                        <td>
                          <AvField
                            name={`brand-${pcpId}`}
                            type="text"
                            onBlur={e =>
                              handleInputChange(
                                e,
                                pcpId,
                                "pcpInfantFeedingDetails",
                                item,
                                "infant_feeding_id",
                                type.id
                              )
                            }
                            disabled={type.id === 2}
                            value={(item && item.is_active && item.brand) || ""}
                          />
                        </td>
                        <td>
                          <AvField
                            name={`amount-${pcpId}`}
                            type="text"
                            onBlur={e =>
                              handleInputChange(
                                e,
                                pcpId,
                                "pcpInfantFeedingDetails",
                                item,
                                "infant_feeding_id",
                                type.id
                              )
                            }
                            value={(item && item.is_active && item.amount) || ""}
                          />
                        </td>
                        <td>
                          {" "}
                          <AvField
                            name={`time_of_day-${pcpId}`}
                            type="text"
                            label=""
                            onBlur={e =>
                              handleInputChange(
                                e,
                                pcpId,
                                "pcpInfantFeedingDetails",
                                item,
                                "infant_feeding_id",
                                type.id
                              )
                            }
                            value={(item && item.is_active && item.time_of_day) || ""}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <AvField
              name={`breast_fed_daily_plan-${pcpId}`}
              type="text"
              label="If your baby is exclusively breast fed, please outline your daily plan:"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={breast_fed_daily_plan || tabLocalStorage.breast_fed_daily_plan}
            />
            <AvField
              name={`feeding_support-${pcpId}`}
              type="text"
              label="If your baby is breast fed or receiving expressed milk, how can we support you?"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={feeding_support || tabLocalStorage.feeding_support}
            />
            <AvField
              name={`is_solid_food-${pcpId}`}
              type="select"
              label="Have solid foods been introduced yet? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  { eating_preference: e.target.value === "0" },
                  setDisabledInputs
                )
              }
              value={is_solid_food === 0 ? "0" : is_solid_food || tabLocalStorage.is_solid_food}
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`eating_preference-${pcpId}`}
              type="text"
              label={
                centerProfile.countryId === 230
                  ? "If yes, please identify food the child has been introduced to:"
                  : "If yes, please identify food likes and eating preferences: *"
              }
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId]["eating_preference"]
                  ? ""
                  : eating_preference || tabLocalStorage.eating_preference
              }
              disabled={disabledInputs[pcpId]["eating_preference"]}
              required={!disabledInputs[pcpId]["eating_preference"]}
            />
          </Fragment>
        )}
        {typeId !== 1 && (
          <Fragment>
            <AvField
              name={`eating_preference-${pcpId}`}
              type="text"
              label="Food likes and eating preferences: *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={eating_preference || tabLocalStorage.eating_preference}
              required
            />
            <div className="mb-sm">Preferred time of day to feed child:</div>
            <Row>
              {feedingTimeSlots.map((slot, idx) => {
                const item = pcpFeedingTimes.find(el => el.pcp_feeding_time_slot_id === slot);
                const type = slot === 1 || slot === 2 ? "am" : "pm";
                let timeExists = (item && item.is_active && item.time) || "";
                if (timeExists.length > 6)
                  // convert time format from 12:00:00 to 12:00
                  timeExists = timeExists.slice(0, -3);
                return (
                  <Fragment key={idx}>
                    <Col>
                      <AvField
                        name={`time-${pcpId}`}
                        type="select"
                        label={slot === 1 || slot === 2 ? "AM" : "PM"}
                        onChange={e =>
                          handleInputChange(
                            e,
                            pcpId,
                            "pcpFeedingTimes",
                            item,
                            "pcp_feeding_time_slot_id",
                            slot
                          )
                        }
                        value={timeExists}>
                        <TimePickerDropDownOptions type={type} />
                      </AvField>
                    </Col>
                  </Fragment>
                );
              })}
            </Row>
          </Fragment>
        )}
        <AvCheckboxGroup
          inline
          name={`eating_method-${pcpId}`}
          label="Child eats with:"
          defaultValue={defaultCheckboxes[pcpId]["eatingMethods"]}>
          {pcp.eatingMethods &&
            pcp.eatingMethods.map(method => {
              const item = pcpEatingMethods.find(el => el.eating_id === method.id);
              return (
                <AvCheckbox
                  key={method.id}
                  label={method.name}
                  value={method.id}
                  onChange={e => handleInputChange(e, pcpId, "pcpEatingMethods", item)}
                />
              );
            })}
        </AvCheckboxGroup>
        <AvCheckboxGroup
          inline
          name={`feeding_location-${pcpId}`}
          label="Child is fed in: *"
          defaultValue={defaultCheckboxes[pcpId]["feedingLocations"]}
          required>
          {pcp.feedingLocations &&
            pcp.feedingLocations
              .filter(
                el =>
                  (typeId === 1 && el.id !== 1) ||
                  (typeId === 3 && el.id === 1) ||
                  (typeId === 3 && el.id === 6) ||
                  (typeId === 2 && el.id !== 5)
              )
              .map(location => {
                const item = pcpFeedingLocations.find(el => el.feeding_location_id === location.id);
                return (
                  <AvCheckbox
                    key={location.id}
                    label={location.name}
                    value={location.id}
                    onChange={e => handleInputChange(e, pcpId, "pcpFeedingLocations", item)}
                  />
                );
              })}
        </AvCheckboxGroup>
        <AvField
          name={`additional_feeding_information-${pcpId}`}
          type="text"
          label="Additional Information"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={additional_feeding_information || tabLocalStorage.additional_feeding_information}
        />
        {centerProfile.countryId === 230 && typeId === 1 && (
          <AvField
            name={`weaning_stage-${pcpId}`}
            type="text"
            label="Child's current weaning stage"
            onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
            value={weaning_stage || tabLocalStorage.weaning_stage}
          />
        )}
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={"Nutrition Practices and Routines"}
    />
  );
};

export default NutritionPracticesAndRoutines;

import produce from "immer";
import * as actionTypes from "../actionTypes";
import initialState from "./initialState";

export const myAccount = produce((state = initialState.myAccount, action) => {
  switch (action.type) {
    case actionTypes.MYACCOUNT_GET_IMAGE__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_GET_IMAGE__SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "idle",
      };
    case actionTypes.MYACCOUNT_GET_IMAGE__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "idle",
      };
    case actionTypes.MYACCOUNT_LOAD_MY_ACCOUNT__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };

    // CONTACT INFO ACTIONS START
    // case actionTypes.MYACCOUNT_LOAD_CONTACT_INFO__START:
    //   return {
    //     ...state,
    //     status: "loading",
    //   };
    // case actionTypes.MYACCOUNT_LOAD_CONTACT_INFO__SUCCESS:
    //   return {
    //     ...state,
    //     contactInfo: action.payload,
    //     status: "idle",
    //   };
    // case actionTypes.MYACCOUNT_LOAD_CONTACT_INFO__FAILURE:
    //   return {
    //     ...state,
    //     status: "failed",
    //     error: action.payload,
    //   };
    case actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__SUCCESS:
      return {
        ...state,
        status: "idle",
      };
    case actionTypes.MYACCOUNT_SAVE_CONTACT_INFO__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    // CONTACT INFO ACTIONS END

    // MY DOCUMENTS ACTIONS START
    case actionTypes.MYACCOUNT_LOAD_DOCUMENTS__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_LOAD_DOCUMENTS__SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "idle",
      };
    case actionTypes.MYACCOUNT_LOAD_DOCUMENTS__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case actionTypes.MYACCOUNT_SAVE_DOCUMENT__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_SAVE_DOCUMENT__SUCCESS:
      return {
        ...state,
        status: "idle",
      };
    case actionTypes.MYACCOUNT_SAVE_DOCUMENT__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case actionTypes.MYACCOUNT_VERIFY_ADDRESS__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_VERIFY_ADDRESS__SUCCESS:
      return {
        ...state,
        status: "idle",
        addressValidationResponse: action.payload
      };
    case actionTypes.MYACCOUNT_VERIFY_ADDRESS__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };

    // API CALLS ACTIONS START
    case actionTypes.MYACCOUNT_FETCHING_DATA:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.MYACCOUNT_RECEIVED_DATA:
      return {
        ...state,
        status: "idle",
      };
    // API CALLS ACTIONS END
    default:
      return state;
  }
});

import React, { Fragment } from "react";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import enrollmentFormPendingIcon from "../../../assets/images/enrollment_form_icon_pending.png";
import enrollmentFormSubmittedIcon from "../../../assets/images/enrollment_form_icon_submitted.png";
import pcpFormPendingIcon from "../../../assets/images/personal_care_plan_form_icon_pending.png";
import pcpFormSubmittedIcon from "../../../assets/images/personal_care_plan_form_icon_submitted.png";
// import bubblesAndFriendsSignUpIcon from "../../../assets/images/b&f-app-icon.png"
import bafIcon from "../../../assets/images/baf-logo.png";

import { connect } from "react-redux";

const fullWidthRow = {
  flexGrow: 1
};

const toDoBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "7rem",
  borderRadius: "5%",
  flexWrap: "wrap"
};

const toDoText = {
  margin: "0px",
  fontSize: "10px",
  fontWeight: "bold"
};

const link = {
  width: "100%",
  height: "100%",
  color: "black"
};

const generateStatusId = statusIds => {
  let statusId = null;
  if (statusIds.length) {
    statusId = 2;
    const pending = statusIds.some(el => el === 1 || el === 5);
    const allFormsApproved = statusIds.every(el => el === 3);
    const atLeastOneRejected = statusIds.some(el => el === 4);
    if (pending) {
      statusId = 1;
    }
    if (allFormsApproved) {
      statusId = 3;
    }
    if (atLeastOneRejected) {
      statusId = 4;
    }
  }
  return statusId;
};

const showIconBasedOnStatus = (statusId, type) => {
  let icon = null;
  switch (statusId) {
    case 1:
    case 4:
    case 5:
      icon = type === "pcp" ? pcpFormPendingIcon : enrollmentFormPendingIcon;
      break;
    case 2:
      icon = type === "pcp" ? pcpFormSubmittedIcon : enrollmentFormSubmittedIcon;
      break;
    case 3:
      icon = null;
      break;
    default:
      break;
  }
  return icon;
};

const TooltipText = ({ statusId }) => {
  let text = null;
  switch (statusId) {
    case 1:
    case 5:
      text = `Form Not Submitted. Please complete and submit.`;
      break;
    case 2:
      text = `Form Submitted and awaiting approval.`;
      break;
    case 3:
      text = `Form Approved`;
      break;
    case 4:
      text = `Please update with requested changes.`;
      break;
    default:
      break;
  }
  return text;
};

const Todos = ({ auth, enrollment, pcp }) => {
  const enrollmentStatusId = generateStatusId(enrollment.enrollmentStatusIds);
  const pcpStatusId = generateStatusId(pcp.pcpStatusIds);
  const enrollmentFormStatusIcon = showIconBasedOnStatus(enrollmentStatusId, "enrollment");
  const pcpFormStatusIcon = showIconBasedOnStatus(pcpStatusId, "pcp");
  const user = JSON.parse(localStorage.getItem("user")) || {};

  return (
    <ReactCSSTransitionGroup
      component="div"
      className={cx("app-todos")}
      transitionName="HeaderAnimation"
      transitionAppear={true}
      transitionAppearTimeout={1500}
      transitionEnter={false}
      transitionLeave={false}
      style={{ marginBottom: "30px" }}>
      <Row style={fullWidthRow}>
        <Col md="12">
          <Card style={{ backgroundColor: "gainsboro", borderRadius: "1rem" }}>
            <CardBody>
              <p className="toDoTitle">To-Do</p>
              <Row style={{ marginTop: "5px" }}>
                <Col className="toDoCol">
                  {enrollmentFormStatusIcon && (
                    <NavItem id="enrollmentToDo" style={{ listStyle: "none" }}>
                      <NavLink
                        href="#/enrollmentform"
                        style={link}
                        disabled={enrollmentStatusId === 2}>
                        <div style={toDoBox}>
                          <img
                            src={enrollmentFormStatusIcon}
                            alt="Enrollment Form"
                            style={{ width: "50%" }}></img>
                          <p style={toDoText}>
                            Enrollment Info <br></br>Form
                          </p>
                        </div>
                      </NavLink>
                    </NavItem>
                  )}
                  {pcpFormStatusIcon && (
                    <NavItem id="pcpToDo" style={{ listStyle: "none" }}>
                      <NavLink href="#/personalcareplan" style={link} disabled={pcpStatusId === 2}>
                        <div style={toDoBox}>
                          <img
                            src={pcpFormStatusIcon}
                            alt="Personal Care Plan Form"
                            style={{ width: "50%" }}></img>
                          <p style={toDoText}>Personal Care Plan Form</p>
                        </div>
                      </NavLink>
                    </NavItem>
                  )}
                  {user.showBafIcon && (
                    <NavItem id="bafToDo" style={{ listStyle: "none" }}>
                      <NavLink
                        href="https://www.bubblesandfriends.com/gettheapp"
                        style={link}
                        target="_blank">
                        <div style={{...toDoBox, width: "15rem"}}>
                          <img
                            src={bafIcon}
                            alt="Bubbles and Friends"
                            style={{ width: "50%" }}></img>
                          <p style={toDoText}>Download the Bubbles and Friends <br></br>app and Log in</p>
                        </div>
                      </NavLink>
                    </NavItem>
                  )}
                  {!enrollmentFormStatusIcon && !pcpFormStatusIcon && user.showBafIcon && (
                    <h5 style={{ marginLeft: "1rem" }}>
                      <span role="img" aria-label="Party popper emoji">
                        🎉
                      </span>
                      Hooray! There are no items pending!
                    </h5>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {enrollmentFormStatusIcon && (
        <UncontrolledTooltip
          placement="top"
          className="testing-tooltip"
          target="enrollmentToDo"
          fade={false}>
          <TooltipText statusId={generateStatusId(enrollment.enrollmentStatusIds)} />
        </UncontrolledTooltip>
      )}
      {pcpFormStatusIcon && (
        <UncontrolledTooltip
          placement="top"
          className="testing-tooltip"
          target="pcpToDo"
          fade={false}>
          <TooltipText statusId={generateStatusId(pcp.pcpStatusIds)} />
        </UncontrolledTooltip>
      )}
      {/* {user.showBafIcon && (
        <UncontrolledTooltip
          placement="top"
          className="testing-tooltip"
          target="bafToDo"
          fade={false}>
          “Our proprietary smartphone app Bubbles & Friends, is loaded with features that let
          parents check in on their children’s activities by receiving picture notifications during
          the day—an immense comfort to parents whose children are attending daycare for the very
          first time. It also gives parents the power to plan and stay organized. You can easily
          check your tuition balances and notify us of any upcoming absences, as well as stay on top
          of upcoming events and closures at your TLE® center.
        </UncontrolledTooltip>
      )} */}
    </ReactCSSTransitionGroup>
  );
};

const mapStateToProps = state => ({
  // enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  // closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  // headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  // enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Todos);

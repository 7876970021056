import React, {Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

import ChildPhotoAndName from "./ChildPhotoAndName";
import ChildPersonalInfo from "./ChildPersonalInfo";
import Documents from "./Documents";
import EmergencyContacts from "./EmergencyContacts";
import ChildMedical from "./ChildMedical";
import Immunizations from "./Immunizations";
import Schedule from "./Schedule";
import SectionAccordian from "./SectionAccordian";
import ImageUploadModal from "./ImageUploadModal";
const MemoizedSectionAccordian = React.memo(SectionAccordian);
const MemoizedChildPersonalInfo = React.memo(ChildPersonalInfo);
const MemoizedDocuments = React.memo(Documents);
const MemoizedEmergencyContacts = React.memo(EmergencyContacts);
const MemoizedChildMedical = React.memo(ChildMedical);
const MemoizedImmunizations = React.memo(Immunizations);
const MemoizedSchedule = React.memo(Schedule);

const MyLittleLearnerAccordian = ({
  addEmergencyContact,
  centerInfo,
  child,
  childMedical,
  deleteChildMedical,
  deleteEmergencyContact,
  documents,
  documentTypes,
  editEmergencyContact,
  emergencyContacts,
  fetchingData,
  immunizations,
  medicalAllergy,
  medicalCondition,
  medicalDietaryPreferences,
  medicalSeverity,
  receivedData,
  relationships,
  updateChildMedical,
  saveChildImage,
  saveChildPersonalInfo,
  scheduleEvents,
  status
}) => {
  // needed in order to stop re-rendering inside of the reactstrap collapse
  const [sectionAccordianIsOpen, setSectionAccordianIsOpen] = useState({});
  const [childAccordianIsOpen, setChildAccordianIsOpen] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  
  const updateSectionAccordians = (section, status) => {
    setSectionAccordianIsOpen((prevState) => ({
      ...prevState,
      [section]: status,
    }));
  };

  const imageModalClosed = () => {
    setShowImageModal(false);
  };

  return (
    <Fragment>
    <Card>
      <CardHeader
        id={`myLittleLearner-${child.id}`}
        className="light-gray-bg"
        style={{ height: "auto" }}
        onClick={() => setChildAccordianIsOpen(!childAccordianIsOpen)}
        // aria-expanded={childAccordianIsOpen}
        // aria-controls={`${child.id}-collapse`}
      >
        <ChildPhotoAndName
          isOpen={childAccordianIsOpen}
          child={child}
          // updateChildAccordians={updateChildAccordians}
          id={child.id}
          fetchingData={fetchingData}
          receivedData={receivedData}
          centerId={centerInfo.id}
          setShowImageModal={setShowImageModal}
        />
      </CardHeader>
      <Collapse
        isOpen={childAccordianIsOpen}
        // data-parent="#myLittleLearnerAccordians"
        id={`${child.id}-collapse`}
        // aria-labelledby="contactInfoHeading"
      >
        <CardBody>
          <MemoizedChildPersonalInfo
            centerInfo={centerInfo}
            fetchingData={fetchingData}
            receivedData={receivedData}
            child={child}
            saveChildPersonalInfo={saveChildPersonalInfo}
          />
          <MemoizedSectionAccordian
            child={child}
            section="documents"
            sectionAccordianIsOpen={
              sectionAccordianIsOpen["documents"] || false
            }
            updateSectionAccordians={updateSectionAccordians}
          >
            <MemoizedDocuments
              centerInfo={centerInfo}
              child={child}
              documents={documents}
              documentTypes={documentTypes}
            />
          </MemoizedSectionAccordian>
          <MemoizedSectionAccordian
            child={child}
            section="emergencyContacts"
            sectionAccordianIsOpen={
              sectionAccordianIsOpen["emergencyContacts"] || false
            }
            updateSectionAccordians={updateSectionAccordians}
          >
            <MemoizedEmergencyContacts
              centerInfo={centerInfo}
              child={child}
              emergencyContacts={emergencyContacts}
              editEmergencyContact={editEmergencyContact}
              addEmergencyContact={addEmergencyContact}
              deleteEmergencyContact={deleteEmergencyContact}
              relationships={relationships}
              status={status}
            />
          </MemoizedSectionAccordian>
          <MemoizedSectionAccordian
            child={child}
            section="medical"
            sectionAccordianIsOpen={
              sectionAccordianIsOpen["medical"] || false
            }
            updateSectionAccordians={updateSectionAccordians}
          >
            <MemoizedChildMedical
              child={child}
              childMedical={childMedical}
              deleteChildMedical={deleteChildMedical}
              medicalAllergy={medicalAllergy}
              medicalCondition={medicalCondition}
              medicalDietaryPreferences={medicalDietaryPreferences}
              medicalSeverity={medicalSeverity}
              updateChildMedical={updateChildMedical}
              status={status}
            />
          </MemoizedSectionAccordian>
          <MemoizedSectionAccordian
            child={child}
            section="immunizations"
            sectionAccordianIsOpen={
              sectionAccordianIsOpen["immunizations"] || false
            }
            updateSectionAccordians={updateSectionAccordians}
          >
            <MemoizedImmunizations
              centerInfo={centerInfo}
              child={child}
              immunizations={immunizations}
            />
          </MemoizedSectionAccordian>
          <MemoizedSectionAccordian
            child={child}
            section="schedule"
            sectionAccordianIsOpen={sectionAccordianIsOpen["schedule"] || false}
            updateSectionAccordians={updateSectionAccordians}
          >
            <MemoizedSchedule centerInfo={centerInfo} child={child} scheduleEvents={scheduleEvents} isOpen={sectionAccordianIsOpen["schedule"]}/>
          </MemoizedSectionAccordian>
        </CardBody>
      </Collapse>
    </Card>
    <ImageUploadModal
      child={child}
      showImageModal={showImageModal}
      imageModalClosed={imageModalClosed}
      fetchingData={fetchingData}
      receivedData={receivedData}
      centerId={centerInfo.id}
      photoPath={child.photoPath}
      saveChildImage={saveChildImage}
    />
    </Fragment>
  );
};

export default MyLittleLearnerAccordian;

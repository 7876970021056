import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import placeholderImage from "../../../assets/images/drag-drop-upload.jpg";

const thumbsContainer = {
  display: "flex",
  marginTop: 16
};

const thumb = {
  display: "inline-flex",
  marginBottom: 8,
  width: "auto",
  height: 100,
  boxSizing: "border-box",
  flexGrow: 1
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  flexGrow: 1
};

const img = {
  margin: "0 auto",
  maxWidth: "100px",
  maxHeight: "100px"
};

const TemporaryImageUploader = ({
  centerId,
  files,
  fetchingData,
  receivedData,
  handleDrop,
  photoPath
}) => {
  const [documentSrc, setDocumentSrc] = useState(placeholderImage);
  const host = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (photoPath && centerId) {
      setDocumentSrc(`${host}/cdn/image/inline/${centerId}/${photoPath}`);
    }
  }, [photoPath, centerId]);

  const { isDragAccept, isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: handleDrop
  });
  const additionalClass = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : isDragActive
    ? "active"
    : "";

  let thumbs;
  if (files && files.length) {
    thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} alt="" />
        </div>
      </div>
    ));
  } else {
    thumbs = (
      <img
        src={documentSrc}
        style={img}
        onLoad={() => receivedData()}
        onError={() => {
          receivedData();
          setDocumentSrc(placeholderImage);
        }}
        alt=""
      />
    );
  }

  return (
    <section className="container">
      <div {...getRootProps({ className: `dropzone ${additionalClass}` })}>
        <input {...getInputProps()} />
        <p>Drag image here or click to select files</p>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </div>
    </section>
  );
};

export default TemporaryImageUploader;

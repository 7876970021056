
import React, { Fragment } from "react";

const YesNoDropDownOptions = () => {
    return (
      <Fragment>
        <option key="0" value="">
          Select an option
        </option>
        <option key="1" value="0">
          No
        </option>
        <option key="2" value="1">
          Yes
        </option>
      </Fragment>
    );
  };

  export default YesNoDropDownOptions;
import * as actionTypes from "../actionTypes";

export const ping = (payload) => async (
  dispatch,
  getState,
  api
) => {
  try {
    const res = await api.get(`/ping`);
    console.log('ping ok', res);
    if (res) {
      dispatch({
        type: actionTypes.PING__SUCCESS,
        payload: res,
      });
    }
  } catch (e) {
    console.log('ping exception', e);
  }
};

export const getCentersByEmail = (payload) => async (
  dispatch,
  getState,
  api
) => {
  const { email } = payload;
  if (!email) return [];

  try {
    const res = await api.get(`/getCentersByEmail/${email}`);
    if (res && res.response) return res.response;
  } catch (e) {
    console.log('getCentersByEmail exception');
  }
  return [];
};

export const signin = (payload) => async (dispatch, getState, api) => {
  const { email, password, centerId } = payload;
  if (!email || !password || !centerId) return undefined;

  dispatch({
    type: actionTypes.SIGNIN__START,
  });

  try {
    const resp = await api.post("/login", payload);
    if (resp.response && resp.response.token) {
      const localStorageData = JSON.parse(localStorage.getItem("user")) || {};
      const lastUser = localStorageData.email;
      if (email !== lastUser) {
        dispatch({
          type: actionTypes.RESET_STORE,
          payload: resp.response,
        });
        localStorage.removeItem("enrollmentFormData");
        localStorage.removeItem("pcpFormData");
      }

      localStorage.setItem(
        "user",
        JSON.stringify({
          email,
          token: resp.response.token,
          guardianId: resp.response.guardian.id,
          showBafIcon: resp.response.showBafIcon
        })
      );
      dispatch({
        type: actionTypes.SIGNIN__SUCCESS,
        payload: resp.response,
      });
      return resp.response;
    }
  } catch (err) {
    dispatch({
      type: actionTypes.SIGNIN__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Sign in failed. There was no response or error returned from the server.",
    });
    console.log('signin exception', err);
    return err.data;
  }
};

export const signup = (payload) => async (dispatch, getState, api) => {
  const { email, password, centerId, token } = payload;
  if (!email || !password || !centerId || !token) return undefined;

  dispatch({
    type: actionTypes.SIGNUP__START,
  });

  try {
    const resp = await api.post("/register", payload);
    return resp;
  } catch (err) {
    dispatch({
      type: actionTypes.SIGNUP__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Sign up failed. There was no response or error returned from the server.",
    });
    console.log('sign up excpetion', err);
    return err.data;
  }
};

export const signout = (payload) => async (dispatch, getState, api) => {
  localStorage.setItem(
    "user",
    JSON.stringify({
      email: getState().auth.guardian.email,
      token: "",
      guardianId: 0,
    })
  );
  dispatch({
    type: actionTypes.SIGNOUT,
  });
};

export const forgotPassword = (payload) => async (dispatch, getState, api) => {
  const { email, centerId } = payload;
  if (!email || !centerId) return undefined;

  try {
    const resp = await api.post("/forgotPassword", payload);
    return resp;
  } catch (err) {
    console.log('forgotPassword exception', err);
    return err.data;
  }
};

export const resetPassword = (payload) => async (dispatch, getState, api) => {
  const { token, password } = payload;
  if (!token || !password) return undefined;

  try {
    const resp = await api.post("/resetPassword", payload);
    if (resp.response) {
      return "OK";
    }
  } catch (err) {
    console.log('resetPassword exception', err)
  }
  return undefined;
};

export const loadGuardian = (payload) => async (dispatch, getState, api) => {
  const { id } = payload;
  const token = getState().auth.token || payload.token;
  dispatch({
    type: actionTypes.AUTH_LOAD_GUARDIAN__START,
  });

  try {
    const resp = await api.get(`/getGuardianById/${id}`, {
      Authorization: `Bearer ${token}`,
    });
    if (resp.response && resp.response.length > 0) {
      dispatch({
        type: actionTypes.AUTH_LOAD_GUARDIAN__SUCCESS,
        payload: { guardian: resp.response[0], token },
      });
      return resp.response[0];
    } else {
      dispatch({
        type: actionTypes.AUTH_LOAD_GUARDIAN__FAILURE,
        payload:
          "Load guardian error. There was no response or error returned from the server",
      });
      console.log('loadGuardian error', resp);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.AUTH_LOAD_GUARDIAN__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Load guardian error. There was no response or error returned from the server",
    });
    console.log(`loadGuardian exception ${err}`);
  }
};

export const loadCenterInfo = (payload) => async (dispatch, getState, api) => {
  const { centerId } = payload;
  if (!centerId) return;
  dispatch({
    type: actionTypes.CENTERINFO_LOAD_DATA__START,
  });
  try {
    const res = await api.get(`/admin/getCenterInfo/${centerId}`, {
      Authorization: `Bearer ${getState().auth.token}`,
    });
    if (
      res.response &&
      res.response.centerProfile &&
      res.response.centerClassrooms
    ) {
      dispatch({
        type: actionTypes.CENTERINFO_LOAD_DATA__SUCCESS,
        payload: res.response,
      });
      return res.response;
    } else {
      dispatch({
        type: actionTypes.CENTERINFO_LOAD_DATA__FAILURE,
        payload: "Center info not found",
      });
      console.log('loadCenterInfo error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.CENTERINFO_LOAD_DATA__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Load center info error. There was no response or error returned from the server",
    });
    console.log('loadCenterInfo exception', err);
  }
};

export const loginSuccess = (payload) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.LOGIN__SUCCESS
  });
};


export const verifyEmail = (payload) => async (dispatch, getState, api) => {
  const { email, centerId } = payload;
  if (!email || !centerId) return undefined;

  dispatch({
    type: actionTypes.VERIFY_EMAIL__START,
  });

  try {
    const resp = await api.post("/verifyEmail", payload);
    dispatch({
      type: actionTypes.VERIFY_EMAIL__SUCCESS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: actionTypes.VERIFY_EMAIL__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "Verify email failed. There was no response or error returned from the server.",
    });
    console.log('verify email exception', err);
    return err.data;
  }
};

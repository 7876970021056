import React, { Fragment } from "react";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import tleHandbookIcon from "../../../assets/images/tle-handbook.png";
import bafIcon from "../../../assets/images/baf-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

const fullWidthRow = {
  flexGrow: 1
};

const toDoBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "6rem",
  borderRadius: "5%",
  flexWrap: "wrap"
};

const toDoText = {
  margin: "0px",
  fontSize: "10px",
  fontWeight: "bold"
};

const link = {
  width: "100%",
  height: "100%",
  color: "black"
};

const Resources = ({}) => {
  return (
    <ReactCSSTransitionGroup
      component="div"
      className={cx("app-todos")}
      transitionName="HeaderAnimation"
      transitionAppear={true}
      transitionAppearTimeout={1500}
      transitionEnter={false}
      transitionLeave={false}
      style={{ marginBottom: "30px" }}>
      <Row style={fullWidthRow}>
        <Col md="12">
          <Card style={{ backgroundColor: "aliceblue", borderRadius: "1rem" }}>
            <CardBody>
              <p className="toDoTitle">Resources</p>
              <Row style={{ marginTop: "5px" }}>
                <Col className="toDoCol">
                  <NavItem id="handbook" style={{ listStyle: "none" }}>
                    <NavLink href="https://cdn.thelearningexperience.com/uploads/TLE+Handbook+updated.pdf" style={link} target="_blank">
                      <div style={toDoBox}>
                        <img
                          src={tleHandbookIcon}
                          alt="TLE Handbook"
                          style={{ width: "60%" }}></img>
                        <p style={toDoText}>
                          TLE Handbook &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem id="baf" style={{ listStyle: "none" }}>
                    <NavLink href="https://www.bubblesandfriends.com/gettheapp" style={link} target="_blank">
                      <div style={toDoBox}>
                        <img
                          src={bafIcon}
                          alt="Bubbles and Friends"
                          style={{ width: "100%" }}></img>
                        <p style={toDoText}>
                        Download the Bubbles and Friends app
                        </p>
                      </div>
                    </NavLink>
                  </NavItem>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <UncontrolledTooltip
                    placement="top"
                    className="testing-tooltip"
                    target="handbook"
                    fade={false}
                >
                    Handbook
                </UncontrolledTooltip>             */}
      {/* <UncontrolledTooltip
                    placement="top"
                    className="testing-tooltip"
                    target="baf"
                    fade={false}
                    >
                    BAF
                </UncontrolledTooltip>             */}
    </ReactCSSTransitionGroup>
  );
};

const mapStateToProps = state => ({
  // enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  // closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  // headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  // enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Resources);

import React from "react";
import { Button } from "reactstrap";

const EmergencyRelease = ({
  currentStep,
  centerInfo,
  enrollment,
  goToStep,
  nextStep,
  previousStep,
  saveForm,
  setActiveChildInfoTab
}) => {
  const { enrollments } = enrollment;
  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    saveForm({ section: "emergencyReleaseInfo" });
    nextStep();
  };

  const handleChildClick = enrollmentId => {
    setActiveChildInfoTab(enrollmentId);
    goToStep(1);
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Emergency & Release Information</h3>
      {centerInfo.centerProfile.countryId === 230 && (
        <>
          <p>
            The safety of children in the Centre is our top priority. Centre staff will release
            your child only to the parents and guardians listed—or to the other individuals you
            authorise below. The Centre must have a complete copy of any court-ordered custody agreement
            and must comply accordingly. If anyone else is picking up your child, it is imperative that you
            notify the centre in writing, using the Child Release Form on or before the day in
            question. For your child’s safety, any time a person we do not recognise comes to pick
            up your child, we will ask for a photo ID. Each child shall be
            assigned a password to be used when parents/guardians call to give permission for anyone
            other than those listed on their child’s pick-up list.{" "}
          </p>
          <p>
            The Learning Experience<sup>&reg;</sup> will not release your child to anyone who appears impaired. If
            an impaired person attempts to pick up your child, pick-up will be refused, and we will
            attempt to contact the other parent/guardian or authorised persons. If alternative
            arrangements cannot be made, Social or Children Services and/ or the police will be called, as required.{" "}
          </p>
          <p>
            For all children’s safety, it is imperative to use your key fob to enter the building
            and sign in your child. To ensure the safety of our Centre's staff and child, please do not share 
            your key fob with anyone and do not open the door or hold the door open for anyone. If you must pick up your child
            after closing time, you will be assessed a late fee based on your tuition agreement. 
            We may be required to contact local authorities after a certain amount of time. 
            Please see the Centre Director for additional information.
          </p>
        </>
      )}
      {centerInfo.centerProfile.countryId === 232 && (
        <>
          <p>
            The safety of children in our centers is our top priority. Center staff will release
            your child only to the parents and guardians listed—or to the other individuals you
            authorize below. If anyone else is picking up your child, it is imperative that you
            notify the center in writing, using the Child Release Form on or before the day in
            question. For your child’s safety, any time a person we do not recognize comes to pick
            up your child, we will ask for a government-issued photo ID. Each child shall be
            assigned a password to be used when parents/guardians call to give permission for anyone
            other than those listed on their child’s pick-up list.{" "}
          </p>
          <p>
            The Learning Experience<sup style={{top: "0em", fontSize: "18px"}}>&reg;</sup> will not release your child to anyone who appears impaired. If
            an impaired person attempts to pick up your child, pick-up will be refused, and we will
            attempt to contact the other parent/guardian or authorized persons. If alternative
            arrangements cannot be made, the local child protective services agency and/or the local
            police will be called, as required by state licensing.{" "}
          </p>
          <p>
            For all children’s safety, it is imperative to use your key fob to enter the building
            and sign in your child according to state childcare licensing regulations. To ensure the
            safety of our center’s staff and child, please do not share your key fob with anyone and
            do not open the door or hold the door open for anyone. If you must pick up your child
            after closing time, you will be assessed a late fee based on your tuition agreement. Per
            state licensing regulations, we may be required to contact local authorities after a
            certain amount of time. Please see the Center Director for additional information.
          </p>
        </>
      )}
      <div style={{ padding: "30px 100px" }}>
        {enrollments.map((el, idx) => (
          <p
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={e => handleChildClick(el.enrollmentId)}
            key={el.id}>
            {el.firstName} {el.lastName}: {el.pickupPassword}
          </p>
        ))}
        <p style={{ fontWeight: "bold" }}>
          *Click on the child's name to update the pick-up password on the Child Information screen.
        </p>
      </div>
      <Button color="secondary" size="lg" block onClick={previousStep}>
        Back
      </Button>
      <Button color="primary" size="lg" block onClick={handleSubmit}>
        Save and Continue
      </Button>
    </div>
  );
};

export default EmergencyRelease;

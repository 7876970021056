import * as actionTypes from "../actionTypes";

export const loadPcpData = (payload) => async (dispatch, getState, api) => {
  const { familyId} = payload;
  if (!familyId) {
    dispatch({
      type: actionTypes.PCP_LOAD_DATA__FAILURE,
      payload: "Load PCP error. Family Id is missing.",
    });
    return;
  }
  dispatch({
    type: actionTypes.PCP_LOAD_DATA__START,
  });
  try {
    const res = await api.get(`/pcp/${familyId}`, {
      Authorization: `Bearer ${getState().auth.token}`,
    });
    if (res.response) {
      dispatch({
        type: actionTypes.PCP_LOAD_DATA__SUCCESS,
        payload: res.response,
      });
    return res.response;
    } else {
      dispatch({
        type: actionTypes.PCP_LOAD_DATA__FAILURE,
        payload:
          res.error ||
          "There was an error loading your information. Please try again."
      });
      console.log('loadPcpData error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.PCP_LOAD_DATA__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "There was an error loading your information. Please try again."
    });
    console.log('loadPcpData exception', err);
  }
};

export const loadPcpSubmissionStatuses = (payload) => async (dispatch, getState, api) => {
  const { familyId } = payload;
  if (!familyId) {
    dispatch({
      type: actionTypes.PCP_LOAD_SUBMISSION_STATUSES__FAILURE,
      payload: "Load PCP error. Enrollment id is missing.",
    });
    return;
  }
  dispatch({
    type: actionTypes.PCP_LOAD_SUBMISSION_STATUSES__START,
  });
  try {
    const res = await api.get(`/pcp/getChildren/${familyId}`, {
      Authorization: `Bearer ${getState().auth.token}`,
    });
    if (res.response && res.response.length) {
      dispatch({
        type: actionTypes.PCP_LOAD_SUBMISSION_STATUSES__SUCCESS,
        payload: res.response.map(el => el.pcp_status_id),
      });
    } else {
      dispatch({
        type: actionTypes.PCP_LOAD_SUBMISSION_STATUSES__FAILURE,
        payload:
          res.error ||
          "There was an error loading your information. Please try again."
      });
      console.log('loadPcpSubmissionStatuses error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.PCP_LOAD_SUBMISSION_STATUSES__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "There was an error loading your information. Please try again."
    });
    console.log('loadPcpSubmissionStatuses exception', err);
  }
};

export const patchData = (payload) => ({
  type: actionTypes.PCP_PATCH_DATA,
  payload,
});

export const saveData = (payload = {}) => async (dispatch, getState, api) => {
  // const { section, data } = payload;
  const {section, missingFieldFound } = payload;
  if (!section) {
    dispatch({
      type: actionTypes.PCP_SAVE_DATA__FAILURE,
      payload: "Save PCP error. Section is missing.",
    });
    return;
  }

  dispatch({
    type: actionTypes.PCP_SAVE_DATA__START,
  });
  try {    
    let formData = {
      [section]: payload[section],
      navigation: getState().pcp.navigation,
      missingFieldFound
    };
    if (section === 'signature') {
      const childNames = Object.values(getState().pcp.childrenInfo).map(child => (`${child.first_name} ${child.last_name}`)).join(', ');
      const currentUser = getState().auth.guardian;
      const guardianId = currentUser.id;
      const parentName = `${currentUser.first_name} ${currentUser.last_name}`;
      const centerInfo = getState().centerInfo.centerProfile;
      const email = getState().auth.guardian.email;
      formData = {
        ...formData,
        childNames,
        guardianId,
        parentName,
        centerInfo,
        email,
      }
    }

    const res = await api.post(`/pcp`, formData,
      {Authorization: `Bearer ${getState().auth.token}`}
    );
    if (res.response) {
      dispatch({
        type: actionTypes.PCP_SAVE_DATA__SUCCESS,
        payload: section,
      });
    } else {
      dispatch({
        type: actionTypes.PCP_SAVE_DATA__FAILURE,
        payload:
          res.error ||
          "There was an error saving your information to the server. Please try again.",
      });
      console.log('saveData error', res);
    }
  } catch (err) {
    dispatch({
      type: actionTypes.PCP_SAVE_DATA__FAILURE,
      payload:
        (err.data && err.data.error) ||
        "There was an error saving your information to the server. Please try again.",
    });
    console.log('saveData exception', err);
  }
};

export const updateUnsavedChangesWarning = (payload = {}) => async (dispatch, getState, api) => {
  // const { section } = payload;
  dispatch({
    type: actionTypes.PCP_UPDATE_UNSAVED_CHANGES_WARNING,
    payload: payload
  })
}

import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const filePreview = {
  width: "100%",
  height: "70vh",
};

const buttons = {
  margin: "5px"
}

const buttonsContainer = {
  display: "flex",
  justifyContent: "flex-end"
}

const ViewDocumentModal = ({
  centerProfile,
  documentToView,
  getImage,
  imageSrc,
  resetViewDocumentClickEvent,
  viewDocumentClicked,
  buttonLabel,
  className,
  documentTypes,
  saveDocument,
  token
}) => {
  const [currentDocument, setCurrentDocument] = useState(null);
  const [documentSrc, setDocumentSrc] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(false);
  const [wrongDocumentType, setWrongDocumentType] = useState(false);
  const [modal, setModal] = useState(false);
  const host = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (viewDocumentClicked) {
      setCurrentDocument(documentToView);
    }
  }, [viewDocumentClicked]);

  useEffect(() => {
    if (currentDocument) {
      if (currentDocument.s3_file_path && centerProfile.id) {
        const ext = currentDocument.file_name.split(".")[1];
        const previewable = [
          "jpg",
          "pdf",
          "png",
          "bpm",
          "jpeg",
          "gif",
          "tif",
          "tiff",
          "svg",
          "webp",
        ];
        if (previewable.filter((i) => i === ext).length) {

          setDocumentPreview(true)
          setDocumentSrc(`${host}/cdn/image/inline/${centerProfile.id}/${currentDocument.s3_file_path}`)
          setModal(!modal);
        } else {
          setWrongDocumentType(true);
          setModal(!modal);
        }
      }
    }
    resetViewDocumentClickEvent();
  }, [currentDocument]);

  const toggle = () => {
    setModal(!modal);
    setWrongDocumentType(false);
    setDocumentSrc(null);
    setCurrentDocument(null);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="" size="xl">
        <ModalHeader toggle={toggle}>
          {currentDocument && currentDocument.file_name}
        </ModalHeader>
        <ModalBody>
          {documentPreview && (
            <embed style={filePreview} src={documentSrc}></embed>
          )}
          {wrongDocumentType && (
            <Fragment>
            <h5>No preview available for {currentDocument.file_name}</h5>
            <div style={buttonsContainer}>
            <Button style={buttons} onClick={toggle}>Cancel</Button>
            <Button
              color="primary"
              style={buttons}
            >
              <a href={`${host}/cdn/file/download/${centerProfile.id}/${currentDocument.s3_file_path}/${currentDocument.file_name}`} download={currentDocument.file_name} style={{color: "white"}}>
                Download
              </a>
            </Button>
            </div>
            </Fragment>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewDocumentModal;

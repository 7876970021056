import * as actionTypes from "../actionTypes";

export const verifyAddress = (payload = {}) => async (
    dispatch,
    getState,
    api
  ) => {
    dispatch({
      type: actionTypes[`${payload.section}_VERIFY_ADDRESS__START`],
    });
    try {
      const res = await api.post(
        `/addressValidation/verifyAddress/`,
        payload,
        {
          Authorization: `Bearer ${getState().auth.token}`,
        }
      );
      if (res.response) {
        dispatch({
          type: actionTypes[`${payload.section}_VERIFY_ADDRESS__SUCCESS`],
          payload: res.response
        });
      } else {
        dispatch({
          type: actionTypes[`${payload.section}_VERIFY_ADDRESS__FAILURE`],
          payload:
            res.error ||
            "Verify address failed. There was no response or error"
        });
        console.log("verifyAddress response error", res);
      }
    } catch (err) {
      if (err.status && err.status === 400) {
        dispatch({
          type: actionTypes[`${payload.section}_VERIFY_ADDRESS__SUCCESS`],
          payload: 'INVALID_ADDRESS',
        });
      } else {
        dispatch({
          type: actionTypes[`${payload.section}_VERIFY_ADDRESS__FAILURE`],
          payload: err.data || "Verify address failed. There was no response or error"
        });
      }
      console.log("verify address exception", err);
    }
  };
export const resetAddressValidationResponse = (payload = {}) => async (
    dispatch,
    getState,
    api
  ) => {
    dispatch({
      type: actionTypes[`${payload.section}_RESET_ADDRESS_VALIDATION`]
    });
  };
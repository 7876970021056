import React, { useEffect, useState } from "react";
import { Col, Row, Button, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
const ChildPersonalInfo = ({
  child,
  saveChildPersonalInfo,
}) => {
  const [updatedPersonalInfo, setupdatedPersonalInfo] = useState({
    childId: child.id,
  });

  const updatePersonalInfo = (e) => {
    setupdatedPersonalInfo({
      ...updatedPersonalInfo,
      [e.target.id]: e.target.value.trim(),
    });
  };

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0 || Object.keys(updatedPersonalInfo).length < 2) return;
    saveChildPersonalInfo(updatedPersonalInfo);
  };

  return (
    <AvForm key={child.id} onSubmit={handleSubmit} className="mb-3">
      <Row>
        <Col md={6}>
          <AvField
            id="firstName"
            label="First Name"
            name="firstName"
            type="text"
            value={child.firstName}
            disabled
          />
        </Col>
        <Col md={6}>
          <AvField
            id="lastName"
            label="Last Name"
            name="lastName"
            type="text"
            value={child.lastName}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AvField
            id="preferredName"
            label="Preferred Name"
            name="preferredName"
            onChange={updatePersonalInfo}
            type="text"
            value={child.preferredName}
            validate={{
              required: {
                value: false,
                errorMessage: "Please enter a preferred name.",
              },
              maxLength: { value: 50 },
            }}
          />
        </Col>
        <Col md={6}>
          <AvField
            id="dob"
            label="Date of Birth"
            name="dob"
            type="date"
            value={child.dob}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AvField
            id="gender"
            label="Gender"
            name="gender"
            type="text"
            value={child.genderName}
            disabled
          />
        </Col>
        <Col md={6}>
          <AvField
            id="pickupPassword"
            label="Pick-Up Password"
            name="pickupPassword"
            onChange={updatePersonalInfo}
            type="text"
            value={child.pickupPassword}
            validate={{
              required: {
                value: false,
                errorMessage: "Please enter a pick-up password.",
              },
              maxLength: { value: 50 },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AvField
            id="classroom"
            label="Classroom"
            name="classroom"
            type="text"
            value={child.classroomName}
            disabled
          />
        </Col>
        <Col md={6}>
          <AvField
            id="primaryTeacher"
            label="Primary Teacher"
            name="primaryTeacher"
            type="text"
            value={`${child.teacherFirstName || ""} ${child.teacherLastName || ""}`}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AvField
            id="expectedStartDate"
            label="Expected Start Date"
            name="expectedStartDate"
            type="text"
            value={child.expectedStartDate}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Button className="mt-2" color="primary" size="lg">
              Save
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </AvForm>
  );
};

export default ChildPersonalInfo;

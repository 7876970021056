import React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import listPlugIn from "@fullcalendar/list"; // a plugin!
import bootstrapPlugin from "@fullcalendar/bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import useWindowDimensions from "../../SharedComponents/useWindowDimensions";

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useWindowDimensions();
    return <Component {...props} myHookValue={myHookValue} />;
  };
}

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarWeekends: false,
      closedSmallerSidebar: false,
    };
    this.changeCalendarView = this.changeCalendarView.bind(this);
  }
  calendarRef = React.createRef();
  componentDidUpdate() {
    this.changeCalendarView();
  }

  render() {
    return (
      this.props.scheduleEvents && (
        <FullCalendar
          events={this.props.scheduleEvents}
          plugins={[dayGridPlugin, listPlugIn, bootstrapPlugin]}
          height="auto"
          ref={this.calendarRef}
          weekends={this.state.calendarWeekends}
          eventOrder="-title"
          themeSystem="bootstrap"
          bootstrapFontAwesome={{ myCustomButton: "fa-th" }}
          customButtons={{
            myCustomButton: {
              hint: "Hide/show weekends",
              click: () => {
                this.toggleWeekends();
              },
            },
          }}
          headerToolbar={{
            right: "today myCustomButton prev next",
          }}
          eventContent={function(arg) {
            return {
              html: arg.event.title.replace(/\n/g, "<br>"),
            };
          }}
        />
      )
    );
  }

  toggleWeekends = () => {
    this.setState({
      calendarWeekends: !this.state.calendarWeekends,
    });
  };

  changeCalendarView() {
    if (this.calendarRef.current) {
      const width = this.props.myHookValue.width;
      const currentView = this.calendarRef.current._calendarApi.view.type;
      let calendarApi = this.calendarRef.current.getApi();
      if (width < 1024 && currentView === "dayGridMonth")
        calendarApi.changeView("listMonth");
      if (width > 1024 && currentView === "listMonth")
        calendarApi.changeView("dayGridMonth");
    }
  }
}

export default withMyHook(Schedule);

import React, { useEffect } from "react";
import FormSubmitStatusIcon from "../../SharedComponents/FormSubmitStatusIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const PcpStepWizardNav = ({
  currentStep,
  goToStep,
  comfortChildLength,
  handleNavSetStep,
  pcp,
  setShowUnsavedChangesModal,
  submissionStatuses
}) => {
  const { showModal } = pcp;
  
  const navbars = [
    "importantCareInfo",
    "developmentalHistory",
    "nutritionPracticesAndRoutines",
    "sleepingRoutines",
    ...(comfortChildLength ? ["comfortingChild"] : []),
    "toiletingRoutines",
    "socialRelationships",
    "additionalInfo",
    "signature"
  ];

  const navTitles = {
    importantCareInfo: `Important Care Information`,
    developmentalHistory: "Developmental History",
    nutritionPracticesAndRoutines: "Nutrition Practices and Routines",
    sleepingRoutines: "Sleeping Routines",
    ...(comfortChildLength && { comfortingChild: "Comforting Child" }),
    toiletingRoutines: "Toileting Routines",
    socialRelationships: "Social Relationships",
    additionalInfo: "Additional Pertinent Information",
    signature: "Signature"
  };

  const updateNavOffset = el => {
    const elWidth = el.offsetWidth;
    const elLeft = el.offsetLeft;
    const stepContainer = document.querySelector(".step-wizard");
    const scrollDistance = elLeft - stepContainer.offsetWidth / 2 + elWidth / 2;
    stepContainer.scroll({
      top: 0,
      left: scrollDistance,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    if (currentStep > 0) {
      const els = document.querySelectorAll(".dot");
      updateNavOffset(els[currentStep - 1]);
    }
  }, [currentStep]);

  useEffect(() => {
    const el = document.querySelector(".first-dot");
    const stepContainer = document.querySelector(".step-wizard");
    const padd = stepContainer.offsetWidth / 2 - el.offsetWidth / 2 - 15;
    document.querySelector(".dots-container").style.paddingLeft = padd + "px";
    document.querySelector(".dots-container").style.paddingRight = padd + "px";
  }, []);

  const dots = navbars.map((navbar, idx) => (
    <span
      key={`step-${idx + 1}`}
      className={`${idx === 0 ? "first-dot" : ""} dot ${currentStep === idx + 1 ? "active" : ""}`}
      onClick={e => {
        if(showModal && currentStep !== idx + 1) {
          setShowUnsavedChangesModal(true);
          handleNavSetStep(idx + 1, goToStep)
        } else {
          goToStep(idx + 1);
        }
      }}>
      <FormSubmitStatusIcon
        submissionStatus={submissionStatuses[navbars[idx]]}
        page={`step-${idx + 1}`}
      />
      {navTitles[navbars[idx]]}
    </span>
  ));

  const goBackFast = () => {
    if (showModal) {
      setShowUnsavedChangesModal(true);
      handleNavSetStep(currentStep - 3 <= 0 ? 1 : currentStep - 3)
    } else {
      goToStep(currentStep - 3 <= 0 ? 1 : currentStep - 3);
    }
  };

  const goForwardFast = () => {
    if (showModal) {
      setShowUnsavedChangesModal(true);
      handleNavSetStep(currentStep + 3 >= dots.length ? dots.length : currentStep + 3)
    } else {
      goToStep(currentStep + 3 >= dots.length ? dots.length : currentStep + 3);
    }
  };

  return (
    <div className="topnav-container">
      <div className="arrow-item" onClick={goBackFast}>
        <FontAwesomeIcon icon={faAngleLeft} size="2x" aria-hidden="true" />
      </div>
      <div className={"nav step-wizard"}>
        <div className="dots-container">{dots}</div>
      </div>
      <div className="arrow-item" onClick={goForwardFast}>
        <FontAwesomeIcon icon={faAngleRight} size="2x" aria-hidden="true" />
      </div>
    </div>
  );
};

export default PcpStepWizardNav;

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Col,
  Row,
} from "reactstrap";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IdentificationTooltip from "../../EnrollmentForm/Components/IdentificationTooltip";

const MyAccountAccordian = ({
  accordianContent,
  heading,
  isOpen,
  setIsOpen,
  type,
}) => {
  return (
    <Card>
      <CardHeader id={`${type}-accordian`} className="light-gray-bg">
        <Row
          className="m-0 p-0 he-100 w-100 align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls={`${type}-collapse`}
        >
          <Col>
            <h3
              className="text-pink mb-0 text-left"
              style={{ fontSize: "1rem" }}
            >
              {heading}
              {type === 'myDocuments' && <IdentificationTooltip
                id='myDocumentsTooltip'
                msg='A document reader may have to be used to view documents on an Android device.'
              />}
            </h3>
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col></Col>
              <Col>
                <FontAwesomeIcon
                  icon={isOpen ? faChevronDown : faChevronRight}
                  fixedWidth={false}
                  className="float-right mr-3 mt-1 text-pink fa-2x"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardHeader>
      <Collapse
        isOpen={isOpen}
        data-parent="#myAccountAccordians"
        id={`${type}-collapse`}
        aria-labelledby="contactInfoHeading"
      >
        <CardBody>{accordianContent}</CardBody>
      </Collapse>
    </Card>
  );
};

export default MyAccountAccordian;

import './polyfills'
import React from "react";
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Containers/Main';
import store from './redux/store';
import { Provider } from 'react-redux';
// import { Amplify } from 'aws-amplify';
// import awsconfig from './aws-exports';

// Amplify.configure(awsconfig);

const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component />
            </HashRouter>
        </Provider>
        ,
        rootElement
    );
};

renderApp(Main);

// if (module.hot) {
//     module.hot.accept('./Containers/MyAccount/MyDocuments', () => {
//         const NextApp = require('./Containers/MyAccount/MyDocuments').default;
//         renderApp(NextApp);
//     });
// }
serviceWorker.unregister();


import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const InformedConsentWithdrawal = ({
  centerInfo,
  enrollment,
  currentStep,
  nextStep,
  previousStep,
  saveForm
}) => {
  const [values, setValues] = useState({});
  const { enrollmentConsents } = enrollment;
  const { centerProfile } = centerInfo;

  useEffect(() => {
    if (enrollmentConsents) {
      setValues(enrollmentConsents[0]);
    }
  }, [enrollmentConsents]);

  const handleSubmit = (event, errors) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    const approvedConsents = enrollmentConsents.map(el => {
      return {
        ...el,
        withdrawalAcknowledgement: 1
      };
    });
    saveForm({ enrollmentConsents: approvedConsents, section: "informedConsentWithdrawal" });
    nextStep();
  };

  const updateInput = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value === "true" ? 0 : 1
    });
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Informed Consent & Acknowledgments</h3>
      <AvForm onSubmit={handleSubmit}>
        <h6>
          <strong>Withdrawal From Program</strong>
        </h6>
        <p>
          {centerProfile.countryId === 230
            ? "A minimum of one month’s written notice is required prior to the last day of attendance (Refer to EnrollmentAgreement for specific notice period requirements). If I do not give written notice of withdrawal, I agree to payfull tuition and fees due for the required notice period regardless of my child’s attendance."
            : "Two week’s written notice is required prior to the last day of attendance. If I do not give written notice of withdrawal, I agree to pay full tuition and fees due for the final two weeks regardless of my child’s attendance."}
        </p>
        <p>
          In certain circumstances, it may be necessary for the {centerProfile.countryId === 230 ? 'Centre' : 'center'} to decide to discontinue a
          child's attendance either on a short-term or permanent basis. Such a decision would be
          based on the best interests of the child, other children in the class, and the overall
          operation of the {centerProfile.countryId === 230 ? 'Centre' : 'center'}. The Learning Experience® will make every reasonable effort to
          work with the family of the child(ren) to correct problematic situations before a final
          decision that The Learning Experience® is not a suitable placement for the child. We
          reserve the right to {centerProfile.countryId === 230 ? 'disenroll' : 'disenrol'} any child or terminate services as deemed necessary or
          appropriate at our sole discretion, with or without notice.
        </p>
        <AvField
          type="checkbox"
          name="withdrawalAcknowledgement"
          label="I acknowledge the above statement"
          errorMessage="Please acknowledge the above statement in order to continue."
          onChange={updateInput}
          checked={!!values.withdrawalAcknowledgement}
          required
        />
        <br></br>
        <Button color="secondary" size="lg" block onClick={previousStep}>
          Back
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default InformedConsentWithdrawal;

import React, { Fragment } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const InfantFeedingTimeTooltip = ({id, msg}) => {
  return (
    <Fragment>
      <span
        id={id}
        className="ml-2"
        style={{ color: "#943885", cursor: "pointer" }}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </span>
      <UncontrolledTooltip
        placement="right"
        target={id}
      >
        {msg}
      </UncontrolledTooltip>
    </Fragment>
  );
};

export default InfantFeedingTimeTooltip;

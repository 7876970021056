import React, { Fragment } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HealthTableRows = ({ items, pcpId, showModal, type }) => {
    const activeItems = items.filter((el) => !el.isDeleted);
    return activeItems.map((el, index) => {
      const itemId = el.id || el.temporaryId || null;
      return (
        <tr key={index + 1}>
          <td name={`${type}_name`}>
            {el.other_description ? el.other_description : el[`${type}_name`]}
          </td>
          {(type === "allergy" || type === "medical_condition") &&
            <td name="severity_name">
                {el.severity_name}
            </td>
          }
            <Fragment>
                <td name="incident_procedure">
                    {el.incident_procedure}
                </td>
            </Fragment>
            {type === "allergy" &&
            <Fragment>
                <td name={`is_life_threatening`}>
                    {+el.is_life_threatening ? "Yes" : "No"}
                </td>
            </Fragment>
          }
          <td className="vertical-align-initial" style={{whiteSpace: "nowrap"}}>
            <Button
              id={`editBtn-${itemId}`}
              color="primary"
              size="sm"
              onClick={() => showModal(pcpId, items, 'edit', type, itemId)}
            >
              <FontAwesomeIcon icon={faPen} fixedWidth={false} className={``} />
            </Button>
            <UncontrolledTooltip
              placement="left"
              target={`editBtn-${itemId}`}
              fade={false}
            >
              Edit
            </UncontrolledTooltip>
            <Button
              id={`deleteBtn-${itemId}`}
              color="primary"
              size="sm"
              className="ml-btn"
              onClick={() => showModal(pcpId, items, 'delete', type, itemId, el[`${type}_name`])}
            >
              <FontAwesomeIcon icon={faTrash} fixedWidth={false} className={``} />
            </Button>
            <UncontrolledTooltip
              placement="right"
              target={`deleteBtn-${itemId}`}
              fade={false}
            >
              Delete
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    });
  };

  export default HealthTableRows;
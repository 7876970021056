import React, { useCallback, useEffect, useState } from "react";
import { Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import AddressValidationModal from "../../../SharedComponents/AddressValidationModal";
import {
  compareEnrollmentLocalStorageToDb,
  filterState,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";

const PrimaryContactAddress = ({
  centerInfo,
  enrollment,
  currentStep,
  handleBackBtn,
  IdentificationTooltip,
  nextStep,
  previousStep,
  resetAddressValidationResponse,
  saveForm,
  setCustomErrorMessage,
  setShowErrorModal,
  updateUnsavedChangesWarning,
  verifyAddress
}) => {
  const {
    addressValidationComingFromUnsavedChangesModal,
    addressValidationResponse,
    governmentIssuedTypes,
    guardians
  } = enrollment;
  const { centerProfile, states } = centerInfo;
  const [guardian, setGuardian] = useState({});
  const [startingState, setStartingState] = useState({});
  const [requireIdentificationNumber, setRequireIdentificationNumber] = useState(false);
  const [requireState, setRequireState] = useState(false);
  const [modal, setModal] = useState({});
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const addressLabels = generateAddressLabels(centerProfile.countryId);


  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    if (!_.isEqual(startingState, guardian)) {
      updateUnsavedChangesWarning({
        primaryContact: guardian,
        section: "primaryContactAddress",
        showModal: true
      });
    }
  }, [guardian]);

  useEffect(() => {
    if (guardians && guardians.length) {
      const primaryContact = guardians.find(el => el.guardianTypeId === 1);
      if (primaryContact) {
        const comparedPrimaryContact = compareEnrollmentLocalStorageToDb(
          primaryContact,
          "ppGuardianId",
          "primaryContactAddress"
        );
        setGuardian(comparedPrimaryContact);
        setStartingState(comparedPrimaryContact);
      }
    }
  }, [guardians]);

  useEffect(() => {
    // navsteps are different depending on country id because dental section is either showing/missing
    if (centerProfile.countryId === 232 && currentStep !== 6 || centerProfile.countryId === 230 && currentStep !== 5 || addressValidationComingFromUnsavedChangesModal) return;
    if (addressValidationResponse === "INVALID_ADDRESS") {
      setModal({
        isOpen: true,
        updatedContactInfo: guardian,
        addressValidationResponse,
        handleInvalidAddress
      });
    } else if (addressValidationResponse) {
      const selectedState = states.filter(state => state.id === +guardian.stateId)[0];
      const stateNameOrAbbrev =
        centerProfile.country_id === 230 ? selectedState.name.trim() : selectedState.abbrev.trim();
      if (
        guardian.address1.trim() !== addressValidationResponse.street_line_1.trim() ||
        guardian.city.trim() !== addressValidationResponse.city.trim() ||
        guardian.postalCode.trim() !== addressValidationResponse.postal_code.trim() ||
        stateNameOrAbbrev !== addressValidationResponse.state_code
      ) {
        setModal({
          isOpen: true,
          updatedContactInfo: guardian,
          addressValidationResponse,
          stateNameOrAbbrev
        });
      } else {
        saveForm({
          primaryContact: guardian,
          section: "primaryContactAddress"
        });
        nextStep();
      }
    }
  }, [addressValidationResponse]);

  const handleInvalidAddress = () => {
    resetAddressValidationResponse({ section: "ENROLLMENT" });
    setModal({ ...modal, isOpen: false });
  };

  const saveFromModal = useCallback(
    (isSuggestedAddress = false) => {
      const state = filterState(addressValidationResponse.state_code, states, centerProfile.countryId !== 230, centerProfile.countryId === 230)
      if(isSuggestedAddress) {
        //sometimes county in UK not found in db collection
        if(!state) {
          setCustomErrorMessage('County not found. Please use a different address.')
          setShowErrorModal(true)
          return
        }
      }
      const newGuardianObj = {
        ...guardian,
        ...(isSuggestedAddress && {
          address1: addressValidationResponse.street_line_1,
          address2: addressValidationResponse.street_line_2,
          city: addressValidationResponse.city,
          postalCode: addressValidationResponse.postal_code,
          stateId: state.id
        })
      };
      setCustomErrorMessage('')
      resetAddressValidationResponse({ section: "ENROLLMENT" })
      saveForm({
        primaryContact: newGuardianObj,
        section: "primaryContactAddress"
      });
      setModal({ ...modal, isOpen: false });
      nextStep();
    },
    [modal]
  );

  const handleBlur = e => {
    let { name, value } = e.target;
    if (name === "governmentIssuedTypeId") {
      value = parseInt(value);
      // If user select any Government Issued Identification, set id required
      setRequireIdentificationNumber(value > 0);
      setRequireState(value > 0 ? value === 1 || value === 3 : false);
    }
    utilsHandleEnrollmentFormInputChange(e, setGuardian, "primaryContactAddress", "ppGuardianId");
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    const state = filterState(guardian.stateId, states, false, false)
    verifyAddress({
      ...guardian,
      section: "ENROLLMENT",
      zipCode: guardian.postalCode,
      countryCode: addressLabels.country,
      stateName: state ? state.name : undefined
    });
  };

  const localStoragePrimaryContactAddress =
    (localStorageEnrollmentForm && localStorageEnrollmentForm.primaryContactAddress) || {};
  return (
    <div className="form-container">
      <h3 className="text-center">Primary Contact Address</h3>
      <AddressValidationModal modal={modal} saveFromModal={saveFromModal} />
      <AvForm onSubmit={handleSubmit} className="active">
        <AvField
          id="address1"
          name="address1"
          type="text"
          label="Address *"
          onBlur={handleBlur}
          value={guardian.address1 || localStoragePrimaryContactAddress.address1 || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter an address."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="address2"
          name="address2"
          type="text"
          label="Address 2 (Optional)"
          onBlur={handleBlur}
          value={guardian.address2 || localStoragePrimaryContactAddress.address2 || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="city"
          name="city"
          type="text"
          label={`${addressLabels.city} *`}
          onBlur={handleBlur}
          value={guardian.city || localStoragePrimaryContactAddress.city || ""}
          validate={{
            required: {
              value: true,
              errorMessage: `Please enter a ${addressLabels.city.toLowerCase()}.`
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="stateId"
          name="stateId"
          type="select"
          label={`${addressLabels.state} *`}
          onChange={handleBlur}
          value={guardian.stateId || localStoragePrimaryContactAddress.stateId || ""}
          validate={{
            required: {
              value: true,
              errorMessage: `Please select a ${addressLabels.state.toLowerCase()}.`
            }
          }}>
          <option key="" value="">
          {`Please select a ${addressLabels.state.toLowerCase()}`}
          </option>
          {states.map(state => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="postalCode"
          name="postalCode"
          type="text"
          label={`${addressLabels.zip} Code *`}
          onBlur={handleBlur}
          value={guardian.postalCode || localStoragePrimaryContactAddress.postalCode || ""}
          onKeyPress={e => {
            if (
              !(centerProfile.countryId === 230 ? /[\sa-zA-Z0-9]/.test(e.key) : /[0-9]/.test(e.key))
            )
              e.preventDefault();
          }}
          validate={{
            required: {
              value: true,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            maxLength: {
              value: `${centerProfile.countryId === 230 ? 8 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            minLength: {
              value: `${centerProfile.countryId === 230 ? 6 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            pattern: {
              value: `${
                centerProfile.countryId === 230
                ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                  : "^[0-9]{5}$"
              }`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            }
          }}
        />
        {centerProfile.countryId === 232 && 
          <>
            <AvField
              id="governmentIssuedTypeId"
              name="governmentIssuedTypeId"
              type="select"
              label="Government Issued Identification"
              onChange={handleBlur}
              value={
                guardian.governmentIssuedTypeId ||
                localStoragePrimaryContactAddress.governmentIssuedTypeId ||
                ""
              }
              validate={{
                required: {
                  value: false,
                  errorMessage: "Please enter an identification."
                }
              }}>
              <option key={""} value={""}>
                Please select an identification type
              </option>
              {governmentIssuedTypes.map(el => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </AvField>
            <Label>Identification Number {IdentificationTooltip}</Label>
            <AvField
              id="governmentIssuedId"
              name="governmentIssuedId"
              type="text"
              onBlur={handleBlur}
              value={
                guardian.governmentIssuedId ||
                localStoragePrimaryContactAddress.governmentIssuedId ||
                ""
              }
              validate={{
                required: {
                  value: requireIdentificationNumber,
                  errorMessage: "Please enter an identification number."
                },
                maxLength: { value: 50 }
              }}
            />
            <AvField
              id="governmentIssuedStateId"
              name="governmentIssuedStateId"
              type="select"
              label="State Issuing"
              onChange={handleBlur}
              value={
                guardian.governmentIssuedStateId ||
                localStoragePrimaryContactAddress.governmentIssuedStateId ||
                ""
              }
              validate={{
                required: {
                  value: requireState,
                  errorMessage: "Please enter an issuing state."
                }
              }}>
              <option key="" value="">
                Please select a state
              </option>
              {states.map(state => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </AvField>
          </>
        }
        <Button color="secondary" size="lg" block onClick={e => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default PrimaryContactAddress;

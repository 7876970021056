import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

const DeleteMedicalModal = ({
  deleteMedicalClicked,
  deleteChildMedical,
  medical,
  modalClosed,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteMedical, setDeleteMedical] = useState({});

  useEffect(() => {
    if (deleteMedicalClicked) {
      setIsOpen(true);
    }
  }, [deleteMedicalClicked]);

  useEffect(() => {
    if (Object.keys(medical).length) {
      setDeleteMedical(medical);
    }
  }, [medical]);

  const toggle = () => {
    setIsOpen(!isOpen);
    modalClosed();
    setDeleteMedical({});
  };

  const handleSubmit = (event, errors, values) => {
    deleteChildMedical(deleteMedical);
    toggle();
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} className="testing">
        <AvForm onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>Delete Medical record</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this medical record?
            <br />
            {deleteMedical.type} - {deleteMedical.issue}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary">Delete</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

export default DeleteMedicalModal;

import React, { useEffect, useState } from "react";
import {
  Nav,
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Label,
  Row,
  Col,
} from "reactstrap";
import {
  AvForm,
  AvField
} from "availity-reactstrap-validation";
import _ from "lodash";
import DisplayTab from "../DisplayTab";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  compareEnrollmentLocalStorageToDb,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";

const ChildAddress = ({
  centerInfo,
  enrollment,
  currentStep,
  handleBackBtn,
  IdentificationTooltip,
  nextStep,
  previousStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  let {enrollments} = enrollment;
  const [activeTab, setActiveTab] = useState('');
  const [startingState, setStartingState] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const currentTabIndex = tabs.findIndex(tab  => tab.enrollmentId === activeTab);
  const { centerProfile, states } = centerInfo;
  const addressLabels = generateAddressLabels(centerProfile.countryId)

  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ enrollments: tabs, section: "childAddress", showModal: true })
    }
  }, [tabs])

  useEffect(() => {
    if (enrollments && enrollments.length) {
      const comparedEnrollments = compareEnrollmentLocalStorageToDb(
        enrollments,
        "enrollmentId",
        "childAddress"
      );
      setTabs(comparedEnrollments);
      setStartingState(comparedEnrollments);
      if (!activeTab) setActiveTab(comparedEnrollments[0].enrollmentId);
    }
  }, [enrollments]);

  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setTabs, "childAddress", "enrollmentId");
  };
  
  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const nextTabs = tabs.map(tab => ({
      ...tab,
      error: errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.enrollmentId)
    }));
      const localStorageData = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
      localStorageData.childAddress = nextTabs;
      localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageData));
      setTabs(nextTabs);

    if (errors.length > 0) return
    saveForm({ enrollments: tabs, section: "childAddress" });
    nextStep();
  };

  const handleCopyFromFirstChild = (e) => {
    const currentTab = tabs.find(tab  => tab.enrollmentId === activeTab);
    const firstTab = tabs[0];
    if (!currentTab) return;
    const newTab = {
      ...currentTab,
      primaryResidence: firstTab.primaryResidence,
      address1: firstTab.address1,
      address2: firstTab.address2,
      city: firstTab.city,
      stateId: firstTab.stateId,
      postalCode: firstTab.postalCode,
    }
    const newTabs = [
      ...tabs.slice(0, currentTabIndex),
      newTab,
      ...tabs.slice(currentTabIndex + 1),
    ];
    localStorageEnrollmentForm["childAddress"] = newTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(newTabs);
  }

  const toggleTabs = (id) => {
    if (activeTab !== id) setActiveTab(id);
  };

  const displayTabInputs = (tab, idx) => {
    const {
      address1,
      address2,
      city,
      enrollmentId: id,
      postalCode,
      primaryResidence,
      stateId
    } = tab;

    const tabLocalStorage =
      (localStorageEnrollmentForm &&
        localStorageEnrollmentForm["childAddress"] &&
        localStorageEnrollmentForm["childAddress"][id]) ||
      {};
    return (
      <TabPane key={id} tabId={id}>
        <Label>Describe your child’s living arrangements? *{IdentificationTooltip}</Label>
          <AvField
            id={`primaryResidence-${id}`}
            name={`primaryResidence-${id}`}
            key={`primaryResidence-${id}-${idx}`}
            type="text"
            data-id={id}         
            value={primaryResidence || tabLocalStorage.primaryResidence || ""}
            onBlur={handleBlur}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter a residence.",
              },
            }}
          >
          </AvField>

          <AvField
            id={`address1-${id}`}
            name={`address1-${id}`}
            key={`address1-${id}-${idx}`}
            type="text"
            label="Address *"
            data-id={id}
            onBlur={handleBlur}
            value={address1 || tabLocalStorage.address1 || ""}
            validate={{
              required: {
                value: true,
                errorMessage: "Please enter an address.",
              },
              maxLength: { value: 50 },
            }}
          />
          <AvField
            id={`address2-${id}`}
            name={`address2-${id}`}
            key={`address2-${id}-${idx}`}
            type="text"
            label="Address 2 (Optional)"
            data-id={id}
            onBlur={handleBlur}
            value={address2 || tabLocalStorage.address2 || ""}
            validate={{
              maxLength: { value: 50 },
            }}
          />
          <AvField
            id={`city-${id}`}
            name={`city-${id}`}
            key={`city-${id}-${idx}`}
            type="text"
            label={`${addressLabels.city} *`}
            data-id={id}
            onBlur={handleBlur}
            value={city || localStorage.city || ""}
            validate={{
              required: {
                value: true,
                errorMessage: `Please enter a ${addressLabels.city.toLowerCase()}.`,
              },
              maxLength: { value: 50 },
            }}
          />

          <AvField
            id={`stateId-${id}`}
            name={`stateId-${id}`}
            key={`stateId-${id}-${idx}`}
            type="select"
            label={`${addressLabels.state} *`}
            data-id={id}
            onBlur={handleBlur}
            value={stateId || localStorage.stateId || ""}
            validate={{
              required: {
                value: true,
                errorMessage: `Please select a ${
                  addressLabels.state.toLowerCase()
                }.`
              },
            }}
          >
            <option key="" value=""> {`Please select a ${addressLabels.state.toLowerCase()}`}</option>
            {states &&
            states.map((state) => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}

          </AvField>
          <AvField
            id={`postalCode-${id}`}
            name={`postalCode-${id}`}
            key={`postalCode-${id}-${idx}`}
            type="text"
            label={`${addressLabels.zip} Code *`}
            data-id={id}
            onBlur={handleBlur}
            value={postalCode || tabLocalStorage.postalCode || ""}
            onKeyPress={e => {
              if (
                !(centerProfile.countryId === 230 ? /[\sa-zA-Z0-9]/.test(e.key) : /[0-9]/.test(e.key))
              )
                e.preventDefault();
            }}
            validate={{
              required: {
                value: true,
                errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
              },
              maxLength: {
                value: `${centerProfile.countryId === 230 ? 8 : 5}`,
                errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
              },
              minLength: {
                value: `${centerProfile.countryId === 230 ? 6 : 5}`,
                errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
              },
              pattern: {
                value: `${
                  centerProfile.countryId === 230
                  ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                    : "^[0-9]{5}$"
                }`,
                errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
              }
            }}
          />
      </TabPane>
    );
  };

  return (
    <div className='form-container'>
      <h3 className="text-center">Child Address</h3>
      <Nav tabs>
        {tabs.map((tab) => (
          <DisplayTab
            key={tab.enrollmentId} 
            activeTab={activeTab} 
            tab={tab} 
            tabId={tab.enrollmentId}
            toggleTabs={toggleTabs} 
            tabLabel={tab.firstName}
          />
        ))}
      </Nav>
      <AvForm onSubmit={handleSubmit}>
        {currentTabIndex > 0 && 
        <Row>
          <Col md="12">
            <Button 
              color="primary" 
              size="lg"
              className="mb-3 float-right"
              onClick={handleCopyFromFirstChild} >
              <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
              Copy information from first child
            </Button>
          </Col>
        </Row>}
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, idx) => displayTabInputs(tab, idx))}
        </TabContent>
        <FormGroup>
          <Button color="secondary" size="lg" block onClick={(e) => handleBackBtn(e, previousStep)}>
            Back
          </Button>
          <Button className="mt-2" color="primary" size="lg" block>
            Save and Continue
          </Button>
        </FormGroup>
      </AvForm>
    </div>
  );
};

export default ChildAddress;

import React, { Fragment, useEffect, useState } from "react";
import { TabPane } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import YesNoDropDownOptions from "../../../SharedComponents/YesNoDropdownOptions";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import { compareLocalStorageToDb, utilsHandleInputChange } from "../../../../utils";
import dropdowns from "../../../SharedComponents/dropdowns";
import Select from "react-select";

const deleteAndUndeleteLanguages = tabsCopy => {
  let deletedLanguages = [];
  let { pcpLanguages, updatedPcpLanguages } = tabsCopy;
  if (pcpLanguages.length) {
    pcpLanguages.forEach(language => {
      const languageCopy = { ...language };
      const languageFound = updatedPcpLanguages.some(el => el.language_id === language.language_id);
      if (!languageFound && !language.is_deleted) {
        languageCopy.delete = 1;
        deletedLanguages.push(languageCopy);
      }
      if (languageFound && language.is_deleted) {
        languageCopy.delete = 0;
        deletedLanguages.push(languageCopy);
      }
    });
  }
  return deletedLanguages;
};

const handleLanguageSelect = (e, actionTypes, pcpId, setTabs) => {
  setTabs(prevValue => {
    return {
      ...prevValue,
      [pcpId]: { ...prevValue[pcpId], updatedPcpLanguages: e }
    };
  });
};

const infantAgeDropdown = () => {
  const reg = /^0$/g;

  return dropdowns.infantMonths.map(age => (
    <option key={age.value} value={age.value}>
      {age.label.replace(reg, "N/A")}
    </option>
  ));
};

const DevelopmentalHistory = ({
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [startingState, setStartingState] = useState({});
  const [tabs, setTabs] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});
  const { childrenInfo, developmentalHistory, languages, status } = pcp;
  
  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ developmentalHistory: tabs, section: "developmentalHistory", showModal: true })
    }
  }, [tabs])

  useEffect(() => {
    if (status === "idle" && developmentalHistory && Object.keys(developmentalHistory).length) {
      if (!activeTab) setActiveTab(+Object.keys(developmentalHistory)[0]);
      const comparedDevelopmentalHistory = compareLocalStorageToDb(developmentalHistory);
      setTabs(comparedDevelopmentalHistory);
      setStartingState(comparedDevelopmentalHistory);
      const disabledInputsObj = Object.keys(comparedDevelopmentalHistory).reduce(
        (acc, val) => {
          return {
            ...acc,
            [val]: {
              speech_difficulties: !comparedDevelopmentalHistory[val][
                "is_speech_difficult"
              ],
            },
          };
        },
        {}
      );
      setDisabledInputs((prevValue) => {
        return { ...prevValue, ...disabledInputsObj };
      });
    }
  }, [developmentalHistory]);

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(key => {
      const deletedAndUndeletedLanguages = deleteAndUndeleteLanguages(tabsCopy[key]);
      const newLanguages = tabsCopy[key].updatedPcpLanguages.filter(el => !el.id);
      tabsCopy[key] = {
        ...tabsCopy[key],
        error: false,
        updatedPcpLanguages: [...newLanguages, ...deletedAndUndeletedLanguages],
        childId: childrenInfo[key].pp_child_id
      };
    });
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "developmentalHistory", developmentalHistory: tabsCopy });
    nextStep();
  };

  const displayTabInputs = tab => {
    const {
      communication_of_needs,
      is_crawling,
      developmental_concerns,
      infant_babbling,
      infant_cooing,
      infant_crawling,
      infant_saying_audible_words,
      infant_saying_simple_sentences,
      infant_sitting,
      infant_standing,
      infant_walking_independently,
      infant_walking_with_support,
      is_saying_audible_words,
      is_speaking_simple_language,
      is_speech_difficult,
      speech_difficulties,
      is_walking_with_support,
      is_walking_without_support,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId,
      updatedPcpLanguages
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};
    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader text={childrenInfo[pcpId].transition_classroom_type_name || childrenInfo[pcpId].classroom_type_name} />
        {typeId === 1 && (
          <Fragment>
            <AvField
              name={`infant_sitting-${pcpId}`}
              type="select"
              label="Age child began sitting (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={infant_sitting === 0 ? "0" : infant_sitting || tabLocalStorage.infant_sitting}
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_crawling-${pcpId}`}
              type="select"
              label="Age child began crawling (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_crawling === 0 ? "0" : infant_crawling || tabLocalStorage.infant_crawling
              }
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_standing-${pcpId}`}
              type="select"
              label="Age child began standing (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_standing === 0 ? "0" : infant_standing || tabLocalStorage.infant_standing
              }
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_walking_with_support-${pcpId}`}
              type="select"
              label="Age child began walking with support (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_walking_with_support === 0
                  ? "0"
                  : infant_walking_with_support || tabLocalStorage.infant_walking_with_support
              }
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_walking_independently-${pcpId}`}
              type="select"
              label="Age child began walking independently (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_walking_independently === 0
                  ? "0"
                  : infant_walking_independently || tabLocalStorage.infant_walking_independently
              }
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_cooing-${pcpId}`}
              type="select"
              label="Age child began cooing (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={infant_cooing === 0 ? "0" : infant_cooing || tabLocalStorage.infant_cooing}
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_babbling-${pcpId}`}
              type="select"
              label="Age child began babbling (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_babbling === 0 ? "0" : infant_babbling || tabLocalStorage.infant_babbling
              }
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_saying_audible_words-${pcpId}`}
              type="select"
              label="Age child began saying audible words (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_saying_audible_words === 0
                  ? "0"
                  : infant_saying_audible_words || tabLocalStorage.infant_saying_audible_words
              }
              required>
              {infantAgeDropdown()}
            </AvField>
            <AvField
              name={`infant_saying_simple_sentences-${pcpId}`}
              type="select"
              label="Age child began saying 2 or 3 simple sentences (in months) *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                infant_saying_simple_sentences === 0
                  ? "0"
                  : infant_saying_simple_sentences || tabLocalStorage.infant_saying_simple_sentences
              }
              required>
              {infantAgeDropdown()}
            </AvField>
          </Fragment>
        )}

        {/* Toddlers  thru Preppers*/}
        {typeId === 2 && (
          <Fragment>
            <AvField
              name={`is_crawling-${pcpId}`}
              type="select"
              label="Does your child crawl? *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={is_crawling === 0 ? "0" : is_crawling || tabLocalStorage.is_crawling}
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`is_walking_with_support-${pcpId}`}
              type="select"
              label="Does your child walk with support? *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                is_walking_with_support === 0
                  ? "0"
                  : is_walking_with_support || tabLocalStorage.is_walking_with_support
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`is_walking_without_support-${pcpId}`}
              type="select"
              label="Does your child walk without support? *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                is_walking_without_support === 0
                  ? "0"
                  : is_walking_without_support || tabLocalStorage.is_walking_without_support
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`is_saying_audible_words-${pcpId}`}
              type="select"
              label="Does your child say audible words? *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                is_saying_audible_words === 0
                  ? "0"
                  : is_saying_audible_words || tabLocalStorage.is_saying_audible_words
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`is_speaking_simple_language-${pcpId}`}
              type="select"
              label="Does your child speak in 2 or 3 simple sentences? *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                is_speaking_simple_language === 0
                  ? "0"
                  : is_speaking_simple_language || tabLocalStorage.is_speaking_simple_language
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
          </Fragment>
        )}

        {/* Preschool School Age*/}
        {typeId === 3 && (
          <Fragment>
            <AvField
              name={`is_speech_difficult-${pcpId}`}
              type="select"
              label="Does your child have any speech difficulties? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  { speech_difficulties: e.target.value === "0" },
                  setDisabledInputs
                )
              }
              value={
                is_speech_difficult === 0
                  ? "0"
                  : is_speech_difficult || tabLocalStorage.is_speech_difficult
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`speech_difficulties-${pcpId}`}
              type="text"
              label="If yes, Please explain speech difficulties. *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId]["speech_difficulties"]
                  ? ""
                  : speech_difficulties || tabLocalStorage.speech_difficulties
              }
              disabled={disabledInputs[pcpId]["speech_difficulties"]}
              // required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select a response.",
              //   },
              // }}
            ></AvField>
            <label htmlFor={`languages-${pcpId}`}>
              What languages does your child speak?
            </label>
            <Select
              isMulti
              name={`languages-${pcpId}`}
              className="mb-md"
              onChange={(e, actionTypes) => {
                handleLanguageSelect(e, actionTypes, pcpId, setTabs);
              }}
              placeholder="Select Languages"
              value={updatedPcpLanguages}
              options={languages}
            />
          </Fragment>
        )}
        <AvField
          name={`developmental_concerns-${pcpId}`}
          type="text"
          label="Do you have developmental concerns about your child? *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={developmental_concerns || tabLocalStorage.developmental_concerns}
          required
        />
        <AvField
          name={`communication_of_needs-${pcpId}`}
          type="text"
          label="How does your child communicate their needs? *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={communication_of_needs || tabLocalStorage.communication_of_needs}
          required
        />
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={"Developmental History"}
    />
  );
};

export default DevelopmentalHistory;

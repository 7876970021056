import React from "react";

const styles = {
    textAlign: 'center',
    fontSize: '1.5rem'
}

const PcpFormTypeHeader = ({
  text
}) => {
  return (
    <div style={styles}>
      {text}
    </div>
  );
};

export default PcpFormTypeHeader;

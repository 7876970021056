import React, { Fragment } from "react";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import myAccountIcon from "../../assets/images/my_account_icon.png";
import myLittleLearnerIcon from "../../assets/images/my_little_learner_icon.png";

import { connect } from "react-redux";

const fullWidthRow = {
  flexGrow: 1,
};

class AppSections extends React.Component {
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-sections")}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Nav style={{margin: "0"}}>
            <Row style={fullWidthRow}>
              <Col md="6" style={{marginBottom: "1.5em"}}>
                <Card className="p-2" inverse color="orange" style={{borderRadius: "1rem"}}>
                  <NavItem>
                    <NavLink href="#/myaccount" className="text-white" style={{padding: '0'}}>
                      <CardBody className="sectionBtn">
                        <img className="sectionBtnImg" src={myAccountIcon} alt="My account"></img>
                        <h4 className="text-center font-weight-bold m-0 sectionBtnText">
                            My Account
                        </h4>
                      </CardBody>
                    </NavLink>
                  </NavItem>
                </Card>
              </Col>
              <Col md="6" style={{marginBottom: "1em"}}>
                <Card className="p-2" inverse color="green" style={{borderRadius: "1rem"}}>
                  <NavItem>
                    <NavLink href="#/myLittleLearner" className="text-white" style={{padding: '0'}}>
                      <CardBody className="sectionBtn">
                        <img className="sectionBtnImg" src={myLittleLearnerIcon} alt="My little learner"></img>
                        <h4 className="text-center font-weight-bold m-0 sectionBtnText">
                            My Little Learner
                        </h4>
                      </CardBody>
                    </NavLink>
                  </NavItem>
                </Card>
              </Col>
            </Row>
          </Nav>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  // enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  // closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  // headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  // enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppSections);

import React, { useEffect, useMemo, useState } from "react";
import { Nav, Button, FormGroup, TabContent, TabPane } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import { convertToDropdownOptions } from "../../../SharedComponents/dropdowns";
import DisplayTab from "../DisplayTab";
import {
  compareEnrollmentLocalStorageToDb,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";


const ChildInfo = ({
  activeChildInfoTab,
  currentStep,
  enrollment,
  nextStep,
  saveForm,
  setActiveChildInfoTab,
  updateAddressTabs,
  updateUnsavedChangesWarning
}) => {
  const {enrollments, genders, languages, maritalStatuses } = enrollment;
  const [activeTab, setActiveTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [startingState, setStartingState] = useState([]);
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const english = languages && languages.find(e => e.name.toLowerCase().indexOf("english") > -1);
  const englishId = (english && english.id) || "";
  const moreThanOneApprovedEnrollment = enrollments.some(el => el.approvedTotal > 1);
  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    //compare starting state to current state to see if any changes have been made. If so, set unsavedChangesErrorModal to true
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ enrollments: tabs, section: "childInfo", showModal: true })
    }
  }, [tabs]);

  useEffect(() => {
    if(activeChildInfoTab) {
      setActiveTab(activeChildInfoTab);
      setActiveChildInfoTab(null)
    }
  }, [activeChildInfoTab]);

  useEffect(() => {
    if (enrollments && enrollments.length) {
      let comparedEnrollments = compareEnrollmentLocalStorageToDb(
        enrollments,
        "enrollmentId",
        "childInfo"
      );
      comparedEnrollments = comparedEnrollments.map(el => {
        return {...el, primaryLanguageId: el.primaryLanguageId || englishId}
      });
      setTabs(comparedEnrollments);
      setStartingState(comparedEnrollments);
      if (!activeTab) setActiveTab(comparedEnrollments[0].enrollmentId);
    }
  }, [enrollments]);

  const displayTabInputs = tab => {
    const {
      dob,
      enrollmentId: id,
      firstName,
      genderId,
      lastName,
      maritalStatusId,
      middleName,
      pickupPassword,
      preferredName,
      primaryLanguageId
    } = tab;

    const tabLocalStorage =
      (localStorageEnrollmentForm &&
        localStorageEnrollmentForm["childInfo"] &&
        localStorageEnrollmentForm["childInfo"][id]) ||
      {};

    return (
      <TabPane key={id} tabId={id}>
        <AvField
          id={`firstName-${id}`}
          name={`firstName-${id}`}
          type="text"
          label="First Name *"
          data-id={id}
          onBlur={handleBlur}
          value={firstName || tabLocalStorage.firstName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a first name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`lastName-${id}`}
          name={`lastName-${id}`}
          type="text"
          label="Last Name *"
          data-id={id}
          onBlur={handleBlur}
          value={lastName || tabLocalStorage.lastName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a last name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`middleName-${id}`}
          name={`middleName-${id}`}
          type="text"
          label="Middle Name (Optional)"
          data-id={id}
          onBlur={handleBlur}
          value={middleName || tabLocalStorage.middleName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            }
          }}
        />
        <AvField
          id={`preferredName-${id}`}
          name={`preferredName-${id}`}
          type="text"
          label="Name Child Goes By *"
          data-id={id}
          onBlur={handleBlur}
          value={preferredName || tabLocalStorage.preferredName || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a name that the child goes by."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`pickupPassword-${id}`}
          name={`pickupPassword-${id}`}
          type="text"
          label="Pick-up Password *"
          data-id={id}
          onBlur={handleBlur}
          value={pickupPassword || tabLocalStorage.pickupPassword || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a pick-up password for your child."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`primaryLanguageId-${id}`}
          name={`primaryLanguageId-${id}`}
          type="select"
          label="Primary Language"
          data-id={id}
          onBlur={handleBlur}
          value={primaryLanguageId || tabLocalStorage.primaryLanguageId || englishId}
          validate={{
            required: {
              value: true,
              errorMessage: "Please select the primary language."
            }
          }}>
          {convertToDropdownOptions(languages).map(language => (
            <option key={language.id} value={language.value || language.id}>
              {language.name}
            </option>
          ))}
        </AvField>
        <AvField
          id={`dob-${id}`}
          name={`dob-${id}`}
          type="date"
          label="Date of Birth *"
          data-id={id}
          onBlur={handleBlur}
          value={dob || tabLocalStorage.firstName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            dateRange: {
              start: { value: -12, units: "years" },
              end: { value: 18, units: "months" }
            },
            required: {
              value: true,
              errorMessage: "Please enter a date of birth."
            }
          }}
        />
        <AvField
          id={`genderId-${id}`}
          name={`genderId-${id}`}
          type="select"
          label="Gender *"
          data-id={id}
          onBlur={handleBlur}
          value={genderId || tabLocalStorage.genderId || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please select a gender."
            }
          }}>
          {convertToDropdownOptions(genders).map(gender => (
            <option key={gender.id} value={gender.id}>
              {gender.name}
            </option>
          ))}
        </AvField>
        <AvField
          id={`maritalStatusId-${id}`}
          name={`maritalStatusId-${id}`}
          type="select"
          label="Parent/Guardian Marital Status *"
          data-id={id}
          onBlur={handleBlur}
          value={maritalStatusId || tabLocalStorage.maritalStatusId || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please select a marital status."
            }
          }}>
          {convertToDropdownOptions(maritalStatuses).map(status => (
            <option key={status.id} value={status.id}>
              {status.name}
            </option>
          ))}
        </AvField>
      </TabPane>
    );
  };

  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setTabs, "childInfo", "enrollmentId");
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const nextTabs = tabs.map(tab => ({
      ...tab,
      error: errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.enrollmentId)
    }));
    const localStorageData = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
    localStorageData.childInfo = nextTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageData));
    setTabs(nextTabs);
    if (errors.length > 0) return
    saveForm({ enrollments: tabs, section: "childInfo" });
    nextStep();
  };

  const toggleTabs = id => {
    if (activeTab !== id) setActiveTab(id);
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Child Information</h3>
      <Nav tabs>
        {tabs.map((tab,idx) => (
          <DisplayTab
            key={tab.enrollmentId}
            activeTab={activeTab}
            tab={tab}
            tabId={tab.enrollmentId}
            toggleTabs={toggleTabs}
            tabLabel={tab.firstName || `Child ${idx + 1}`}
          />
        ))}
      </Nav>
      <AvForm onSubmit={handleSubmit}>
        <TabContent activeTab={activeTab}>{tabs.map(tab => displayTabInputs(tab))}</TabContent>
        <FormGroup>
          <Button className="mt-2" color="primary" size="lg" block>
            Save and Continue
          </Button>
        </FormGroup>
      </AvForm>
    </div>
  );
};

export default ChildInfo;

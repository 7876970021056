import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import { Col, Row, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import {
  loadCenterInfo,
  loadGuardian,
  signin,
  getCentersByEmail,
  loginSuccess
} from "../../../redux/actionCreators/auth";
import { loadData } from "../../../redux/actionCreators/enrollment";
import { loadPcpData } from "../../../redux/actionCreators/pcp";
import { useHistory } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import { version } from "../../../utils";
import Spinner from "../../SharedComponents/Spinner";

const Signin = ({
  auth,
  getCentersByEmail,
  loginSuccess,
  loadCenterInfo,
  loadData,
  loadGuardian,
  loadPcpData,
  signin
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCenterId, setSelectedCenterId] = useState(0);
  const [centers, setCenters] = useState([]);
  const thisYear = new Date();
  const history = useHistory();
  const [modal, setModal] = useState({
    show: false,
    title: "",
    message: ""
  });

  useEffect(() => {
    if (auth && auth.token) {
      history.push("/home");
    }
    if (auth && auth.error && password) {
      setPassword("");
      setModal({
        show: true,
        title: "",
        message: auth.error
      });
    }
  }, [auth]);

  useEffect(() => {
    if (centers.length > 0) {
      setSelectedCenterId(centers[0].id);
    }
  }, [centers]);

  function validateForm() {
    return email.length > 0 && password.length > 0 && centers.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const response = await signin({
      email,
      password,
      centerId: selectedCenterId
    });
    if (!response || !response.guardian || !response.token) return;
    const { guardian, token } = response;
    if (guardian && guardian.id && guardian.center_id) {
      const loadGuardianRes = await loadGuardian({
        id: guardian.id,
        token
      });
      if (loadGuardianRes && loadGuardianRes.center_id) {
        const centerInfo = await loadCenterInfo({
          centerId: guardian.center_id
        });
        if (centerInfo && centerInfo.centerProfile.id) {
          await Promise.all([
            loadData({
              familyId: guardian.family_id
            }),
            loadPcpData({
              familyId: guardian.family_id
            })
          ]);
          loginSuccess();
        }
      }
    }
  }

  return (
    <Fragment>
      <div className="h-100 bg-plum-plate bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <div className="app-logo-inverse mx-auto mb-3 mt-3" />
            <div className="modal-dialog w-100 mx-auto mb-3">
              <div className="modal-content">
                <Form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="modal-title text-center">
                      <h4 className="mt-1">
                        <div>Welcome to the Family Information Station</div>
                        <span>Please sign in to your account below.</span>
                      </h4>
                    </div>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Email Address</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            autoFocus
                            value={email}
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value.toLowerCase())}
                            onBlur={e => {
                              if (!e || !e.target || !e.target.value) return;
                              getCentersByEmail({ email }).then(res => {
                                if (res && res.length > 0) setCenters(res);
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Center</Label>
                          <Input
                            type={"select"}
                            value={selectedCenterId}
                            onChange={e => setSelectedCenterId(e.target.value)}
                            disabled={centers.length === 0}>
                            {centers.length &&
                              centers.map(c => (
                                <option value={c.id} key={c.id}>
                                  {c.name}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="divider" />
                    <h6 className="mb-0 account-login">
                      No account?{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => history.push("/verifyEmail")}
                        type="button">
                        Sign up now
                      </button>
                    </h6>
                    <h6 className="mb-0 account-login">
                      Can't login?{" "}
                      <button
                        className="btn btn-link"
                        onClick={() => history.push("/forgotPassword")}
                        type="button">
                        Reset Password
                      </button>
                    </h6>
                  </div>
                  <div className="modal-footer clearfix">
                    {/* <div className="float-left">
                                            <button className="btn-lg btn btn-link" onClick={() => history.push("/resetpassword")}>Recover Password</button>
                                        </div> */}
                    <div className="float-right">
                      <Button color="primary" size="lg" disabled={!validateForm()} type="submit">
                        Sign in
                      </Button>
                    </div>
                  </div>
                </Form>
                <SweetAlert
                  title={modal.title}
                  confirmButtonColor=""
                  show={modal.show}
                  text={modal.message}
                  onConfirm={() => setModal({ show: false })}
                />
              </div>
            </div>
            <div className=" text-center text-white opacity-8">
              Copyright &copy; <Moment format="YYYY">{thisYear}</Moment> The Learning Experience<sup>&reg;</sup>.
              All Rights Reserved
              <br />
              Product {version}
            </div>
          </Col>
        </div>
      </div>
      <Spinner showSpinner={auth.status === "loading"} />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  pcp: state.pcp
});

const mapDispatchToProps = dispatch => ({
  signin: bindActionCreators(signin, dispatch),
  getCentersByEmail: bindActionCreators(getCentersByEmail, dispatch),
  loadData: bindActionCreators(loadData, dispatch),
  loadPcpData: bindActionCreators(loadPcpData, dispatch),
  loadCenterInfo: bindActionCreators(loadCenterInfo, dispatch),
  loadGuardian: bindActionCreators(loadGuardian, dispatch),
  loginSuccess: bindActionCreators(loginSuccess, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null)(Signin);

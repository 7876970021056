import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const InformedConsentMedical = ({
  enrollment,
  currentStep,
  nextStep,
  previousStep,
  saveForm
}) => {
  const [values, setValues] = useState({});
  const { enrollmentConsents } = enrollment;
  useEffect(() => {
    if (enrollmentConsents) {
      setValues(enrollmentConsents[0]);
    }
  }, [enrollmentConsents]);

  const handleSubmit = (event, errors) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    const approvedConsents = enrollmentConsents.map(el => {
      return {
        ...el,
        emergency: 1,
        healthConsultant: 1,
        healthImmunization: 1,
        illness: 1,
        injuries: 1,
        medication: 1,
        studentInsurance: 1
      };
    });
    
    saveForm({ enrollmentConsents: approvedConsents, section: "informedConsentMedical" });
    nextStep();
  };

  const updateInput = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value === "true" ? 0 : 1
    });
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Informed Consent & Acknowledgments</h3>
      <AvForm onSubmit={handleSubmit}>
        <h6>
          <strong>Medical Acknowledgments</strong>
        </h6>
        <ol>
          <li>
            <strong>Medication:</strong> I will provide written permission for center staff to
            administer medication with written instructions from me or the child’s health care
            provider, as permitted by local childcare licensing regulations. I will complete and
            sign authorization forms. I will provide the medication in its original container (with
            the pharmacist’s label for prescriptions). Some centers may not be permitted to
            distribute medication due to local regulations.
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="medication"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            checked={!!values.medication}
            required
          />
          <br></br>
          <li>
            <strong>Health Records & Immunizations:</strong> I will provide the center with updated
            health & immunization information or an exemption for my child (where applicable by law)
            prior to the start of enrollment and will maintain these records accordingly.
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="healthImmunization"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            required
            checked={!!values.healthImmunization}
          />
          <br></br>
          <li>
            <strong>Nurse/Health Consultant:</strong> Childcare centers in some states are required
            to engage the services of a Nurse/Health Consultant to review health policies and
            procedures and children’s records. I agree my child’s records may be reviewed by the
            nurse/health consultant.
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="healthConsultant"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            required
            checked={!!values.healthConsultant}
          />
          <br></br>
          <li>
            <strong>Illness:</strong> If center staff notifies me that my child is ill, I will pick
            up my child as soon as possible and no later than one (1) hour after being contacted. If
            my child contracts a contagious illness, I understand that my child may return only when
            he or she is well, as described in the Parent Handbook.
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="illness"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            required
            checked={!!values.illness}
          />
          <br></br>
          <li>
            <strong>Injuries:</strong> If my child sustains a minor injury during care, I will be
            notified and receive an Accident/Incident Report describing the incident when I pick up
            my child. I will be contacted immediately for all medical/dental emergencies, the injury
            produces swelling, is on the face or hear, or requires medical attention.
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="injuries"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            required
            checked={!!values.injuries}
          />
          <br></br>
          <li>
            <strong>Emergencies:</strong> In case of an emergency, I understand that center staff
            will attempt to contact me immediately. I also authorize center staff to:
            <ol type="a">
              <li>Consult the physician or dentist named above.</li>
              <li>Administer first aid and/or cardiopulmonary resuscitation.</li>
              <li>
                Transport my child via ambulance or other emergency medical service to a local
                hospital or other urgent care facility.
              </li>
              <li>
                Obtain any emergency medical, surgical, or dental treatment deemed necessary by
                medical authorities.
              </li>
              <li>
                Transport my child to a local emergency shelter in the event of an emergency
                evacuation of the center.
              </li>
            </ol>
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="emergency"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            required
            checked={!!values.emergency}
          />
          <br></br>
          <li>
            <strong>Student Accident Insurance</strong> is provided by The Learning Experience. This
            is a secondary insurance that will help defray the cost of out-of-pocket medical
            expenses which are not covered by the family’s primary insurance for injuries occurring
            while the child is in this program.
          </li>
          <br></br>
          <AvField
            type="checkbox"
            name="studentInsurance"
            label="I acknowledge the above statement"
            errorMessage="Please acknowledge the above statement in order to continue."
            onChange={updateInput}
            required
            checked={!!values.studentInsurance}
          />
        </ol>
        <br></br>
        <Button color="secondary" size="lg" block onClick={previousStep}>
          Back
        </Button>
        <Button className="mt-2" color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default InformedConsentMedical;

import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table
} from "reactstrap";
import styled from "styled-components";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentAccordianContent from "../SharedComponents/DocumentAccordianContent";

const TableStyles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    /* display: block; */
    /* max-width: 100%; */
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid black; */
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tbody {

      tr:nth-of-type(2n) {
        background-color: white;
    }
      tr:nth-of-type(2n+1) {
        background-color: rgb(249, 249, 249);
    }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      border-bottom: 3px solid #EC008C !important;
      background: aliceblue;
      color: black;
      font-weight: bold;
    }

    th,
    td {
      margin: 0;
      padding: 0.3rem;
      border-bottom: 1px solid #f4f4f4;;
      border-right: 1px solid #f4f4f4;;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  th,
  td:nth-child(1) {
    width: 1.5%;
  }
  th,
  td:nth-child(2) {
    width: 8%;
  }

  th,
  td:nth-child(3) {
    width: 2%;
  }
  .pagination-section {
    padding: 0.5rem;
  }
`;
const DocumentAccordian = ({
  centerProfile,
  documents,
  heading,
  isOpen,
  setIsOpen,
  showDocument,
  section,
  type,
}) => {

  return (
    <Card>
      <CardHeader
        id={`${type.name}-accordian`}
        className="gray-bg"
        // style={{ height: "2rem" }}
      >
                    <Button
              block
              className="text-white text-left"
              onClick={() => setIsOpen(type, !isOpen)}
              aria-expanded={isOpen}
              aria-controls={`${type.name}-collapse`}
              style={{backgroundColor: "transparent", borderColor: "transparent", fontSize: "1rem"}}
            >
        <Row style={{flexGrow: "1", flexWrap: 'nowrap'}}>
          <Col xs={10}>
              {heading}
          </Col>
          <Col 
            xs={2}
            style={{display: "flex", justifyContent: "center", alignItems: "center"}}    
          >
             <FontAwesomeIcon
                  icon={isOpen ? faChevronDown : faChevronRight}
                  fixedWidth={false}
                  style={{margin: "0", color: "white"}}
                  // className="float-right mr-3 mt-1"
            />
          </Col>
        </Row>
        </Button>
      </CardHeader>
      <Collapse
        isOpen={isOpen}
        data-parent="#myAccountAccordians"
        id={`${type.name}-collapse`}
        aria-labelledby="contactInfoHeading"
      >
        <CardBody>
          {((documents && documents.length) || null) && 
          <TableStyles>
            <Row className="tableWrap">
              <Col>
              <Table responsive>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th>Upload Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <DocumentAccordianContent 
                  centerProfile={centerProfile}
                  documents={documents} 
                  showDocument={showDocument} 
                  section={section}
                />
              </tbody>
          </Table>
              </Col>
            </Row>

         </TableStyles>
         }
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default DocumentAccordian;
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, TabPane } from "reactstrap";
import {
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import YesNoDropDownOptions from "../../../SharedComponents/YesNoDropdownOptions";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import "../../../ContainersStyles.css";
import {
  compareLocalStorageToDb,
  updateCheckboxes,
  getActiveItems,
  utilsHandleInputChange
} from "../../../../utils";

const napTimeSlots = [1, 2, 3];

const getUkSleepMethodName =  (methodId, methodName) => {
    switch (methodId) {
      case 1:
        methodName = 'Moses Basket'
        break;
      case 3:
        methodName = 'Cot'
        break;
      default:
        break;
    }
    return methodName;
};

const enableDefaultCheckboxes = comparedSleepingRoutines => {
  return Object.keys(comparedSleepingRoutines).reduce((acc, val) => {
    const sleepPositions = getActiveItems(
      comparedSleepingRoutines,
      val,
      "pcpSleepPositions",
      "sleep_position_id"
    );
    const sleepMethods = getActiveItems(
      comparedSleepingRoutines,
      val,
      "pcpSleepMethods",
      "sleep_method_id"
    );
    return {
      ...acc,
      [val]: { sleepMethods, sleepPositions }
    };
  }, {});
};

const SleepingRoutines = ({
  centerInfo,
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});
  const [defaultCheckboxes, setDefaultCheckboxes] = useState(null);
  const { childrenInfo, sleepingRoutines, sleepMethods, status } = pcp;
  const { centerProfile } = centerInfo;

  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ sleepingRoutines: tabs, section: "sleepingRoutines", showModal: true })
    }
  }, [tabs])

  useEffect(() => {
    if (status === "idle" && sleepingRoutines && Object.keys(sleepingRoutines).length) {
      if (!activeTab) setActiveTab(+Object.keys(sleepingRoutines)[0]);
      const comparedSleepingRoutines = compareLocalStorageToDb(sleepingRoutines);
      setTabs(comparedSleepingRoutines);
      setStartingState(comparedSleepingRoutines);
      setDefaultCheckboxes(enableDefaultCheckboxes(comparedSleepingRoutines));
      const disabledInputsObj = Object.keys(comparedSleepingRoutines).reduce((acc, val) => {
        return {
          ...acc,
          [val]: {
            tired_nap_length: !sleepingRoutines[val]["is_tired_nap_during_day"],
            nap_time: !sleepingRoutines[val]["is_tired_nap_during_day"]
          }
        };
      }, {});
      setDisabledInputs(prevValue => {
        return { ...prevValue, ...disabledInputsObj };
      });
    }
  }, [sleepingRoutines]);

  const organizeNaps = (e, pcpId, type, item, categoryId) => {
    let { name, value } = e.target;
    name = name.slice(0, name.indexOf("-"));
    const naps = tabs[pcpId][type];
    if (item) {
      //update existing entry
      value = naps.reduce((acc, val) => {
        if (val.nap_time_slot === item.nap_time_slot) {
          val = { ...val, [name]: value, is_active: 1 };
          // if the entry is blank
          if (!val.time_from && !val.time_to) {
            // and it doesn't exist in the db, remove it
            if (!val.id) {
              return acc;
            }
            // if it exists in the db, deactivate it
            val = { ...val, is_active: 0 };
          }
        }
        return [...acc, val];
      }, []);
    } else {
      //create new entry
      if (value) {
        value = [
          ...naps,
          {
            [name]: value,
            nap_time_slot: categoryId,
            personal_care_plan_id: pcpId
          }
        ];
      } else {
        value = [...naps];
      }
    }
    return value;
  };

  const handleInputChange = (e, pcpId, type = null, item = null, categoryId = null) => {
    let { name, value } = e.target;
    name = name.slice(0, name.indexOf("-"));

    if (type === "pcpSleepMethods") {
      value = updateCheckboxes(e, pcpId, type, "sleep_method_id", item, tabs);
    }
    if (type === "pcpSleepPositions") {
      value = updateCheckboxes(e, pcpId, type, "sleep_position_id", item, tabs);
    }
    if (type === "pcpNaps") {
      value = organizeNaps(e, pcpId, type, item, categoryId);
    }

    setTabs(prevValue => {
      return {
        ...prevValue,
        [pcpId]: { ...prevValue[pcpId], [type || name]: value }
      };
    });
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "sleepingRoutines", sleepingRoutines: tabs });
    nextStep();
  };

  const displayTabInputs = tab => {
    const {
      bedtime,
      nap_time,
      is_can_rollover,
      is_tired_nap_during_day,
      is_use_sleepsack,
      pcpNaps,
      pcpSleepMethods,
      pcpSleepPositions,
      prenap_routine,
      sleeping_concerns,
      tired_nap_length,
      waketime,
      waking_behavior,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};
    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader text={childrenInfo[pcpId].transition_classroom_type_name || childrenInfo[pcpId].classroom_type_name} />
        {typeId === 3 && (
          <Fragment>
            <AvField
              name={`is_tired_nap_during_day-${pcpId}`}
              type="select"
              label="Does your child become tired or nap during the day? *"
              onChange={e =>
                utilsHandleInputChange(
                  e,
                  pcpId,
                  setTabs,
                  {
                    tired_nap_length: e.target.value === "0",
                    nap_time: e.target.value === "0"
                  },
                  setDisabledInputs
                )
              }
              value={
                is_tired_nap_during_day === 0
                  ? "0"
                  : is_tired_nap_during_day || tabLocalStorage.is_tired_nap_during_day
              }
              required>
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`tired_nap_length-${pcpId}`}
              type="text"
              label="If yes, for how long (In hours)? *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                disabledInputs[pcpId]["tired_nap_length"]
                  ? ""
                  : tired_nap_length || tabLocalStorage.tired_nap_length
              }
              disabled={disabledInputs[pcpId]["tired_nap_length"]}
              required={!disabledInputs[pcpId]["tired_nap_length"]}></AvField>
            <AvField
              name={`nap_time-${pcpId}`}
              type="time"
              label="If yes, at what time? *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={disabledInputs[pcpId]["nap_time"] ? "" : nap_time || tabLocalStorage.nap_time}
              disabled={disabledInputs[pcpId]["nap_time"]}
              required={!disabledInputs[pcpId]["nap_time"]}></AvField>
          </Fragment>
        )}
        <AvField
          name={`prenap_routine-${pcpId}`}
          type="text"
          label="Pre-nap routine/rituals"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={prenap_routine || tabLocalStorage.prenap_routine}
        />
        {typeId !== 3 && (
          <Fragment>
            <div className="mb-sm">Number of naps daily: </div>
            {napTimeSlots.map((slot, idx) => {
              const item = pcpNaps.find(el => el.nap_time_slot === slot);
              return (
                <Row key={idx}>
                  <Col xs="6">
                    <AvField
                      name={`time_from-${pcpId}`}
                      type="time"
                      label="From"
                      onChange={e => handleInputChange(e, pcpId, "pcpNaps", item, slot)}
                      value={item ? item.time_from : ""}
                    />
                  </Col>
                  <Col xs="6">
                    <AvField
                      name={`time_to-${pcpId}`}
                      type="time"
                      label="To"
                      onChange={e => handleInputChange(e, pcpId, "pcpNaps", item, slot)}
                      value={item ? item.time_to : ""}
                    />
                  </Col>
                </Row>
              );
            })}
          </Fragment>
        )}
        {typeId === 1 && (
          <Fragment>
            <AvCheckboxGroup
              inline
              name={`sleepPositions-${pcpId}`}
              label="Preferred Sleeping Position (All children will be placed on their backs to sleep) *"
              defaultValue={defaultCheckboxes[pcpId]["sleepPositions"]}
              required>
              {pcp.sleepPositions &&
                pcp.sleepPositions.map(position => {
                  const item = pcpSleepPositions.find(el => el.sleep_position_id === position.id);
                  return (
                    <AvCheckbox
                      key={position.id}
                      label={position.name}
                      value={position.id}
                      onChange={e => handleInputChange(e, pcpId, "pcpSleepPositions", item)}
                    />
                  );
                })}
            </AvCheckboxGroup>
            <AvField
              name={`is_can_rollover-${pcpId}`}
              type="select"
              label="Can your child roll-over *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                is_can_rollover === 0 ? "0" : is_can_rollover || tabLocalStorage.is_can_rollover
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select an option.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
            <AvField
              name={`is_use_sleepsack-${pcpId}`}
              type="select"
              label="Does your child use a sleep sack? *"
              onChange={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={
                is_use_sleepsack === 0 ? "0" : is_use_sleepsack || tabLocalStorage.is_use_sleepsack
              }
              required
              // validate={{
              //   required: {
              //     value: true,
              //     errorMessage: "Please select an option.",
              //   },
              // }}
            >
              <YesNoDropDownOptions />
            </AvField>
          </Fragment>
        )}
        {typeId !== 1 && (
          <Fragment>
            <AvField
              name={`bedtime-${pcpId}`}
              type="time"
              label="What time does your child go to bed at night? *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={bedtime || tabLocalStorage.bedtime}
              required
            />
            <AvField
              name={`waketime-${pcpId}`}
              type="time"
              label="What time does your child wake in the morning? *"
              onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
              value={waketime || tabLocalStorage.waketime}
              required
            />
          </Fragment>
        )}
        <AvCheckboxGroup
          inline
          name={`sleepMethods-${pcpId}`}
          label="At home child sleeps in (check all that apply) *"
          defaultValue={defaultCheckboxes[pcpId]["sleepMethods"]}
          required>
          {sleepMethods &&
            sleepMethods
              .filter(
                el =>
                  (typeId === 1 && el.id !== 4) ||
                  (typeId === 2 && el.id !== 1) ||
                  (typeId === 3 && el.id === 2) ||
                  (typeId === 3 && el.id === 4)
              )
              .map(method => {
                const item = pcpSleepMethods.find(el => el.sleep_method_id === method.id);
                return (
                  <AvCheckbox
                    key={method.id}
                    label={centerProfile.countryId === 230 && typeId === 1 ? getUkSleepMethodName(method.id, method.name) : method.name}
                    value={method.id}
                    onChange={e => handleInputChange(e, pcpId, "pcpSleepMethods", item)}
                  />
                );
              })}
        </AvCheckboxGroup>
        <AvField
          name={`waking_behavior-${pcpId}`}
          type="text"
          label="Child's typical waking behavior/routine *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={waking_behavior || tabLocalStorage.waking_behavior}
          required
        />
        <AvField
          name={`sleeping_concerns-${pcpId}`}
          type="text"
          label="Special sleeping concerns"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={sleeping_concerns || tabLocalStorage.sleeping_concerns}
        />
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={"Sleeping Routines"}
    />
  );
};

export default SleepingRoutines;

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

import SectionHeaderContent from "./SectionHeaderContent";

const SectionAccordian = ({ 
  child,
  children,
  sectionAccordianIsOpen,
  updateSectionAccordians,
  section,
}) => {
  return (
    <Card>
      <CardHeader
        id={`${section}-${child.id}-accordian`}
        className="light-gray-bg"
        onClick={() => updateSectionAccordians(section, !sectionAccordianIsOpen)}
      >
        <SectionHeaderContent
          child={child} 
          section={section} 
          sectionAccordianIsOpen={sectionAccordianIsOpen} 
        />      
      </CardHeader>
      <Collapse
        isOpen={sectionAccordianIsOpen}
        data-parent="#sectionAccordians"
        id={`${section}-${child.id}-collapse`}
        aria-labelledby="contactInfoHeading"
      >
        <CardBody>
          {children}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default SectionAccordian;


import React, { Fragment } from "react";

const units = {
    am: {
        value: [ "01", "01", "02", "02", "03", "03", "04", "04", "05", "05", "06", "06", "07", "07", "08", "08", "09", "09", "10", "10", "11", "11"],
    },
    pm: {
        display: [ "12","12", "01", "01", "02", "02", "03", "03", "04", "04", "05", "05", "06", "06", "07", "07", "08", "08", "09", "09", "10", "10", "11", "11"],
        value: [ "12","12", "13", "13", "14", "14", "15", "15", "16", "16", "17", "17", "18", "18", "19", "19", "20", "20", "21", "21", "22", "22", "23", "23"],
    },
    minutes: ["00", "30"]
}
const TimePickerDropDownOptions = ({type}) => {
    return (
      <Fragment>
        <option key="0" value="">
          Select Time
        </option>
        {units[type].value.map((el, idx)=> {
            const value = `${el}:${units.minutes[idx % 2 === 0 ? 0 : 1]}`;
            const display = `${type === 'pm' ? units[type].display[idx] : el}:${units.minutes[idx % 2 === 0 ? 0 : 1]}`;
            return (
                <option key={idx + 1} value={value}>
                    {display}
                </option>
            )
        })}
      </Fragment>
    );
  };

  export default TimePickerDropDownOptions;
import React, { Fragment } from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import { useSelector } from "react-redux";
import Spinner from "../SharedComponents/Spinner";
import PcpStepWizard from "./Components/PcpStepWizard";

const PcpForm = () => {
  const pcpStatus = useSelector((state) => state.pcp.status)
  return(
    <Fragment>
    <AppHeader />
    <div className="app-main">
      <div className="app-main__outer">
        <div className="app-main__inner">
            <PcpStepWizard />
        </div>
        <AppFooter />
      </div>
    </div>
    <Spinner showSpinner={pcpStatus === "loading"} />
  </Fragment>
  )
};

export default PcpForm;

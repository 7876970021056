import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import {
  compareEnrollmentLocalStorageToDb,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";

const PrimaryContactInfo = ({
  centerInfo,
  currentStep,
  enrollment,
  handleBackBtn,
  nextStep,
  previousStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const { enrollments, guardians, languages, relationships } = enrollment;
  const filteredRelationships = relationships.filter(el => [3, 5, 6].includes(el.id));
  const moreThanOneApprovedEnrollment = enrollments.some(el => el.approvedTotal > 1);
  const english = languages.find(e => e.name.toLowerCase().indexOf("english") > -1);
  const englishId = (english && english.id) || "";
  const [guardian, setGuardian] = useState({});
  const [startingState, setStartingState] = useState({});
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const { centerProfile, states } = centerInfo;
  const addressLabels = generateAddressLabels(centerProfile.countryId);

  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    if (!_.isEqual(startingState, guardian)) {
      updateUnsavedChangesWarning({
        primaryContact: guardian,
        section: "primaryContactInfo",
        showModal: true
      });
    }
  }, [guardian]);

  useEffect(() => {
    if (guardians && guardians.length) {
      const primaryContact = guardians.find(el => el.guardianTypeId === 1);
      if (primaryContact) {
        let comparedPrimaryContact = compareEnrollmentLocalStorageToDb(
          primaryContact,
          "ppGuardianId",
          "primaryContactInfo"
        );
        comparedPrimaryContact = {
          ...comparedPrimaryContact,
          primaryLanguageId: comparedPrimaryContact.primaryLanguageId || englishId
        };
        setGuardian(comparedPrimaryContact);
        setStartingState(comparedPrimaryContact);
      }
    }
  }, [guardians]);

  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setGuardian, "primaryContactInfo", "ppGuardianId");
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    saveForm({
      primaryContact: guardian,
      section: "primaryContactInfo"
    });
    nextStep();
  };

  const localStoragePrimaryContactInfo =
  (localStorageEnrollmentForm && localStorageEnrollmentForm.primaryContactInfo) || {};
  return (
    <div className="form-container">
      <h3 className="text-center">Primary Contact Information</h3>
      <AvForm onSubmit={handleSubmit} className="active">
        <AvField
          id="firstName"
          name="firstName"
          type="text"
          label="First Name *"
          onBlur={handleBlur}
          // onChange={handleInputChange}
          value={guardian.firstName || localStoragePrimaryContactInfo.firstName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a first name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="lastName"
          name="lastName"
          type="text"
          label="Last Name *"
          onBlur={handleBlur}
          // onChange={handleInputChange}
          value={guardian.lastName || localStoragePrimaryContactInfo.lastName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a last name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="middleName"
          name="middleName"
          type="text"
          onBlur={handleBlur}
          // onChange={handleInputChange}
          value={guardian.middleName || localStoragePrimaryContactInfo.middleName || ""}
          label="Middle Name"
          validate={{
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="relationshipId"
          name="relationshipId"
          type="select"
          label="Relationship *"
          onBlur={handleBlur}
          // onChange={handleInputChange}
          value={guardian.relationshipId || localStoragePrimaryContactInfo.relationshipId || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a relationship."
            }
          }}>
          <option key="" value="">
            Please select a relationship
          </option>
          {filteredRelationships.map(e => (
            <option key={e.id} value={e.id}>
              {e.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="primaryLanguageId"
          name="primaryLanguageId"
          type="select"
          label="Primary Language *"
          onChange={handleBlur}
          value={
            guardian.primaryLanguageId ||
            localStoragePrimaryContactInfo.primaryLanguageId ||
            englishId
          }
          validate={{
            required: {
              value: true,
              errorMessage: "Please select the primary language."
            }
          }}>
          <option key="" value="">
            Please select a language
          </option>
          {languages.map(language => (
            <option key={language.id} value={language.value || language.id}>
              {language.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="email"
          name="email"
          type="text"
          label="Preferred Email Address *"
          onBlur={handleBlur}
          value={guardian.email || localStoragePrimaryContactInfo.email || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a preferred e-mail address."
            },
            maxLength: { value: 50 }
          }}
          style={{ textTransform: "lowerCase" }}
        />
        <AvField
          id="primaryPhone"
          name="primaryPhone"
          type="text"
          label="Primary Phone *"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setGuardian, "primaryContactInfo", "ppGuardianId", {countryId: centerProfile.countryId})}
          onKeyPress={e => {
            const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
            if (!regex.test(e.key)) e.preventDefault();
          }}
          value={guardian.primaryPhone || localStoragePrimaryContactInfo.primaryPhone || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a primary phone."
            },
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <FormGroup check style={{marginBottom: "10px"}}>
          <Input
            id="smsConsentPrimary"
            name="smsConsent"
            type="checkbox"
            label="*By entering your phone number, you agree to accept text updates from The Learning Experience. Message and data rates may apply."
            checked={guardian.smsConsent === 1}
            onChange={handleBlur}
          />
          <Label check for="smsConsentSecondary">
            *By entering your phone number, you agree to accept text updates from The Learning
            Experience. Message and data rates may apply.
          </Label>
        </FormGroup>
        <AvField
          id="secondaryPhone"
          name="secondaryPhone"
          type="text"
          label="Secondary Phone"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setGuardian, "primaryContactInfo", "ppGuardianId", {countryId: centerProfile.countryId})}
          onKeyPress={e => {
            const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
            if (!regex.test(e.key)) e.preventDefault();
          }}
          value={guardian.secondaryPhone || localStoragePrimaryContactInfo.secondaryPhone || ""}
          validate={{
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <Row>
          <Col md="8">
            <AvField
              id="workPhone"
              name="workPhone"
              type="text"
              label="Business Phone"
              onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setGuardian, "primaryContactInfo", "ppGuardianId", {countryId: centerProfile.countryId})}
              onKeyPress={e => {
                const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
                if (!regex.test(e.key)) e.preventDefault();
              }}
              value={guardian.workPhone || localStoragePrimaryContactInfo.workPhone || ""}
              validate={{
                minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
                maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
              }}
            />
          </Col>
          <Col md="4">
            <AvField
              id="workPhoneExtension"
              name="workPhoneExtension"
              type="text"
              label="Extension"
              onBlur={handleBlur}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) e.preventDefault();
              }}
              value={guardian.workPhoneExtension || localStoragePrimaryContactInfo.workPhone || ""}
              validate={{
                maxLength: { value: 50 }
              }}
            />
          </Col>
        </Row>
        <AvField
          id="employer"
          name="employer"
          type="text"
          label="Employer"
          onBlur={handleBlur}
          value={guardian.employer || localStoragePrimaryContactInfo.employer || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerAddress1"
          name="employerAddress1"
          type="text"
          label="Employer's Address"
          onBlur={handleBlur}
          value={guardian.employerAddress1 || localStoragePrimaryContactInfo.employerAddress1 || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerAddress2"
          name="employerAddress2"
          type="text"
          label="Employer's Address 2"
          onBlur={handleBlur}
          value={guardian.employerAddress2 || localStoragePrimaryContactInfo.employerAddress2 || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerCity"
          name="employerCity"
          type="text"
          label={`Employer's ${addressLabels.city}`}
          onBlur={handleBlur}
          value={guardian.employerCity || localStoragePrimaryContactInfo.employerCity || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerStateId"
          name="employerStateId"
          type="select"
          label={`Employer's ${addressLabels.state} *`}
          onChange={handleBlur}
          value={guardian.employerStateId || localStoragePrimaryContactInfo.employerStateId || ""}>
          <option key="" value="">
          {`Please select a ${addressLabels.state.toLowerCase()}`}
          </option>
          {states &&
            states.map(state => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
        </AvField>
        <AvField
          id="employerPostalCode"
          name="employerPostalCode"
          type="text"
          label={`Employer's ${addressLabels.zip} Code`}
          onBlur={handleBlur}
          value={
            guardian.employerPostalCode || localStoragePrimaryContactInfo.employerPostalCode || ""
          }
          onKeyPress={e => {
            if (!/[0-9]/.test(e.key)) e.preventDefault();
          }}
          validate={{
            maxLength: {
              value: `${centerProfile.countryId === 230 ? 8 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            minLength: {
              value: `${centerProfile.countryId === 230 ? 6 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            pattern: {
              value: `${
                centerProfile.countryId === 230
                ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                  : "^[0-9]{5}$"
              }`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            }
          }}
        />
        <Button color="secondary" size="lg" block onClick={e => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default PrimaryContactInfo;

import React, { Fragment } from "react";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Row, Col, Card, CardImg} from "reactstrap";
import familyInformationStationLogo from "../../../assets/images/family_information_station_logo.png"

import { connect } from "react-redux";

const fullWidthRow = {
  flexGrow: 1,
//   margin: "5px 25px 15px 25px"
};

const FamilyInfoTitle = ({}) => {
    return (
        <ReactCSSTransitionGroup
        component="div"
        className={cx("app-title")}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
        style={{marginBottom: '30px'}}
        >
            <Row style={fullWidthRow}>
                <Col>
                    <Card className="" inverse style={{boxShadow: 'none', flexDirection: "row", justifyContent: "center"}}>
                    <CardImg className="familyInfoIcon" src={familyInformationStationLogo} alt="Card image cap" />
                    </Card>
                </Col>
            </Row>
        </ReactCSSTransitionGroup>
    )
}

const mapStateToProps = (state) => ({
  // enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  // closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  // headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  // enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyInfoTitle);

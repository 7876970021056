import produce from "immer";
import * as actionTypes from "../actionTypes";
import initialState from "./initialState";

export const myLittleLearner = produce(
  (state = initialState.myLittleLearner, action) => {
    switch (action.type) {
      case actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__SUCCESS:
        return {
          ...state,
          ...action.payload,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };

      // CHILD PERSONAL INFO ACTIONS START
      case actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__SUCCESS:
        return {
          ...state,
          status: "photo upload success",
        };
      case actionTypes.MYLITTLELEARNER_SAVE_CHILD_IMAGE__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__SUCCESS:
        return {
          ...state,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__SUCCESS:
        return {
          ...state,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      // CHILD PERSONAL INFO ACTIONS END

      // // EMERGENCY CONTACT ACTIONS START
      case actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__SUCCESS:
        return {
          ...state,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__SUCCESS:
        return {
          ...state,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__SUCCESS:
        return {
          ...state,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__SUCCESS:
        return {
          ...state,
          emergencyContacts: action.payload,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__SUCCESS:
        return {
          ...state,
          childMedical: action.payload,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_LOAD_CHILD_MEDICAL__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      case actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__START:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__SUCCESS:
        return {
          ...state,
          status: "idle",
        };
      case actionTypes.MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE:
        return {
          ...state,
          status: "failed",
          error: action.payload,
        };
      // API CALLS ACTIONS START
      case actionTypes.MYACCOUNT_FETCHING_DATA:
        return {
          ...state,
          status: "loading",
        };
      case actionTypes.MYACCOUNT_RECEIVED_DATA:
        return {
          ...state,
          status: "idle",
        };
      // API CALLS ACTIONS END
      default:
        return state;
    }
  }
);

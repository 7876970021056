import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import {
  compareEnrollmentLocalStorageToDb,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";

const secondaryContactInfo = ({
  centerInfo,
  enrollment,
  currentStep,
  handleBackBtn,
  previousStep,
  nextStep,
  saveForm,
  updateFormStatus,
  updateUnsavedChangesWarning
}) => {
  const { enrollments, guardians, languages, relationships, submissionStatuses } = enrollment;
  const filteredRelationships = relationships.filter(el => [3, 5, 6].includes(el.id));
  const familyId = enrollments[0].familyId;
  const [guardian, setGuardian] = useState({});
  const [startingState, setStartingState] = useState({});
  const [isRequired, setIsRequired] = useState(false);
  const [moreThanOneApprovedEnrollment, setMoreThanOneApprovedEnrollment] = useState(false);
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  // const [smsConsent, setSmsConsent] = useState(true);
  const english = languages && languages.find(e => e.name.toLowerCase().indexOf("english") > -1);
  const englishId = (english && english.id) || "";
  const localStorageSecondaryContactInfo =
    (localStorageEnrollmentForm && localStorageEnrollmentForm.secondaryContactInfo) || {};
  const { centerProfile, states } = centerInfo;
  const addressLabels = generateAddressLabels(centerProfile.countryId);

  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    if (!_.isEqual(startingState, guardian)) {
      updateUnsavedChangesWarning({
        secondaryContact: guardian,
        section: "secondaryContactInfo",
        showModal: true
      });
    }
    setIsRequired(!!guardian.firstName);
  }, [guardian]);

  useEffect(() => {
    if (submissionStatuses.secondaryContactInfo !== true) {
      updateFormStatus({
        section: "secondaryContactInfo",
        value: isRequired ? false : "optional"
      });
    }
  }, [isRequired]);

  useEffect(() => {
    if (guardians && guardians.length) {
      const secondaryContactInfo = guardians.find(el => el.guardianTypeId === 2);
      setMoreThanOneApprovedEnrollment(
        enrollments.some(el => el.approvedTotal > 1) && !!secondaryContactInfo
      );
      let comparedSecondaryContactInfo = compareEnrollmentLocalStorageToDb(
        secondaryContactInfo,
        "ppGuardianId",
        "secondaryContactInfo"
      );
      comparedSecondaryContactInfo = {
        ...comparedSecondaryContactInfo,
        primaryLanguageId: comparedSecondaryContactInfo.primaryLanguageId || englishId,
        coreFamilyId: familyId,
        guardianTypeId: 2
      };
      setGuardian(comparedSecondaryContactInfo);
      setStartingState(comparedSecondaryContactInfo);
      // else {
      //   if (submissionStatus.secondaryContactInfo !== true) {
      //     updateFormStatus({
      //       section: "secondaryContactInfo",
      //       value: comparedSecondaryContactInfo[0] ? false : "optional"
      //     });
      //   }
      // }
    }
  }, [guardians]);

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    if (isRequired) {
      saveForm({
        secondaryContact: guardian,
        section: "secondaryContactInfo"
      });
    }
    nextStep();
  };

  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setGuardian, "secondaryContactInfo", "ppGuardianId", {
      englishId,
      familyId
    });
  };

  const handleClear = e => {
    const newGuardian = {
      ...guardian,
      firstName: null,
      lastName: null,
      middleName: null,
      relationshipId: null,
      email: null,
      primaryPhone: null,
      secondaryPhone: null,
      workPhone: null,
      workPhoneExtension: null,
      employer: null,
      employerAddress1: null,
      employerAddress2: null,
      employerCity: null,
      employerStateId: null,
      employerPostalCode: null,
      smsConsent: 1
    };

    localStorageEnrollmentForm.secondaryContactInfo = newGuardian;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setGuardian(newGuardian);
  };
  
  return (
    <div className="form-container">
      <h3 className="text-center">Secondary Contact Information</h3>
      <AvForm onSubmit={handleSubmit} className="active">
        <AvField
          id="firstName"
          name="firstName"
          type="text"
          label="First Name"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={guardian.firstName || localStorageSecondaryContactInfo.firstName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a first name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="lastName"
          name="lastName"
          type="text"
          label="Last Name"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={guardian.lastName || localStorageSecondaryContactInfo.lastName || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a last name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="middleName"
          name="middleName"
          type="text"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={guardian.middleName || localStorageSecondaryContactInfo.middleName || ""}
          label="Middle Name"
          validate={{
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="relationshipId"
          name="relationshipId"
          type="select"
          label="Relationship"
          // onChange={handleInputChange}
          onChange={handleBlur}
          value={guardian.relationshipId || localStorageSecondaryContactInfo.relationshipId || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a relationship."
            }
          }}>
          <option key="" value="">
            Please select a relationship
          </option>
          {filteredRelationships.map(e => (
            <option key={e.id} value={e.id}>
              {e.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="primaryLanguageId"
          name="primaryLanguageId"
          type="select"
          label="Primary Language"
          // onChange={handleInputChange}
          onChange={handleBlur}
          value={
            guardian.primaryLanguageId ||
            localStorageSecondaryContactInfo.primaryLanguageId ||
            englishId
          }
          validate={{
            required: {
              value: true,
              errorMessage: "Please select the primary language."
            }
          }}>
          <option key="" value="">
            Please select a primary language
          </option>
          {languages.map(language => (
            <option key={language.id} value={language.value || language.id}>
              {language.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="email"
          name="email"
          type="text"
          label="Preferred Email Address"
          onBlur={handleBlur}
          value={guardian.email || localStorageSecondaryContactInfo.email || ""}
          disabled={moreThanOneApprovedEnrollment}
          style={{ textTransform: "lowerCase" }}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a preferred e-mail address."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="primaryPhone"
          name="primaryPhone"
          type="text"
          label="Primary Phone"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setGuardian, "secondaryContactInfo", "ppGuardianId", {
            englishId,
            familyId,
            countryId: centerProfile.countryId
          })}
          onKeyPress={e => {
            const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
            if (!regex.test(e.key)) e.preventDefault();
          }}
          value={guardian.primaryPhone || localStorageSecondaryContactInfo.primaryPhone || ""}
          disabled={moreThanOneApprovedEnrollment}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a primary phone."
            },
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <FormGroup check style={{marginBottom: "10px"}}>
          <Input
            id="smsConsentSecondary"
            name="smsConsent"
            type="checkbox"
            label="*By entering your phone number, you agree to accept text updates from The Learning Experience. Message and data rates may apply."
            checked={[0, 1].includes(guardian.smsConsent) ? !!guardian.smsConsent : true}
            onChange={handleBlur}
          />
          <Label check for="smsConsentSecondary">
            *By entering your phone number, you agree to accept text updates from The Learning
            Experience. Message and data rates may apply.
          </Label>
        </FormGroup>
        <AvField
          id="secondaryPhone"
          name="secondaryPhone"
          type="text"
          label="Secondary Phone"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setGuardian, "secondaryContactInfo", "ppGuardianId", {
            englishId,
            familyId,
            countryId: centerProfile.countryId
          })}
          onKeyPress={e => {
            const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
            if (!regex.test(e.key)) e.preventDefault();
          }}
          value={guardian.secondaryPhone || localStorageSecondaryContactInfo.secondaryPhone || ""}
          validate={{
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <Row>
          <Col md="8">
            <AvField
              id="workPhone"
              name="workPhone"
              type="text"
              label="Business Phone"
              onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setGuardian, "secondaryContactInfo", "ppGuardianId", {
                englishId,
                familyId,
                countryId: centerProfile.countryId
              })}
              onKeyPress={e => {
                const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
                if (!regex.test(e.key)) e.preventDefault();
              }}
              value={guardian.workPhone || localStorageSecondaryContactInfo.workPhone || ""}
              validate={{
                minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
                maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
              }}
            />
          </Col>
          <Col md="4">
            <AvField
              id="workPhoneExtension"
              name="workPhoneExtension"
              type="text"
              label="Extension"
              // onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) e.preventDefault();
              }}
              value={
                guardian.workPhoneExtension ||
                localStorageSecondaryContactInfo.workPhoneExtension ||
                ""
              }
              validate={{
                maxLength: { value: 50 }
              }}
            />
          </Col>
        </Row>
        <AvField
          id="employer"
          name="employer"
          type="text"
          label="Employer"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={guardian.employer || localStorageSecondaryContactInfo.employer || ""}
          validate={{
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerAddress1"
          name="employerAddress1"
          type="text"
          label="Employer's Address"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={
            guardian.employerAddress1 || localStorageSecondaryContactInfo.employerAddress1 || ""
          }
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerAddress2"
          name="employerAddress2"
          type="text"
          label="Employer's Address 2"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={
            guardian.employerAddress2 || localStorageSecondaryContactInfo.employerAddress2 || ""
          }
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerCity"
          name="employerCity"
          type="text"
          label={`Employer's ${addressLabels.city}`}
          onBlur={handleBlur}
          value={guardian.employerCity || localStorageSecondaryContactInfo.employerCity || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="employerStateId"
          name="employerStateId"
          type="select"
          label={`Employer's ${addressLabels.state} *`}
          onChange={handleBlur}
          value={
            guardian.employerStateId || localStorageSecondaryContactInfo.employerStateId || ""
          }>
          <option key="" value="">
          {`Please select a ${addressLabels.state.toLowerCase()}`}
          </option>
          {states.map(state => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="employerPostalCode"
          name="employerPostalCode"
          type="text"
          label={`Employer's ${addressLabels.zip} Code`}
          onBlur={handleBlur}
          value={
            guardian.employerPostalCode || localStorageSecondaryContactInfo.employerPostalCode || ""
          }
          onKeyPress={e => {
            if (!/[0-9]/.test(e.key)) e.preventDefault();
          }}
          validate={{
            maxLength: {
              value: `${centerProfile.countryId === 230 ? 8 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            minLength: {
              value: `${centerProfile.countryId === 230 ? 6 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            pattern: {
              value: `${
                centerProfile.countryId === 230
                ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                  : "^[0-9]{5}$"
              }`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            }
          }}
        />
        <Button color="secondary" size="lg" block onClick={e => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <Button color="danger" size="lg" block onClick={handleClear}>
          Clear
        </Button>
        <Button color="primary" size="lg" block>
          Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default secondaryContactInfo;

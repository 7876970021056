import React, { Fragment, useMemo, useState } from "react";
import styled from "styled-components";
import { UncontrolledTooltip } from "reactstrap";
import { usePagination, useTable } from "react-table";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import EditContactModal from "./EditContactModal";

import {
  faCheckCircle,
  faTimesCircle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteContactModal from "./DeleteContactModal";

const MemoizedEditContactModal = React.memo(EditContactModal);
const MemoizedDeleteContactModal = React.memo(DeleteContactModal);

const TableStyles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    /* display: block; */
    /* max-width: 100%; */
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid black; */
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tbody {
      tr:nth-of-type(2n) {
        background-color: white;
      }
      tr:nth-of-type(2n + 1) {
        background-color: rgb(249, 249, 249);
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      border-bottom: 3px solid #ec008c !important;
      background: aliceblue;
      color: black;
      font-weight: bold;
    }

    th,
    td {
      margin: 0;
      padding: 0.3rem;
      border-bottom: 1px solid #f4f4f4;
      border-right: 1px solid #f4f4f4;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination-section {
    padding: 0.5rem;
  }
`;

const buttonMargin = {
  margin: "0.1rem",
  pointerEvents: "none"
};
const buttonMarginIcons = {
  margin: "0.1rem",
  marginInline: "0.4rem",
};

const icon_centered = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  marginInline:  0,
};

const deleteBtnContainer = {
  display: "inline-block", 
  cursor: "pointer"
};

const ContactsTable = ({
  child,
  deleteRow,
  edit,
  emergencyContacts,
  updatedPageSize,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (d) => {
          return (
            <div>
              {d.first_name} {d.last_name}
            </div>
          );
        },
      },
      {
        Header: "Relationship",
        accessor: "relationship",
      },
      {
        Header: "Primary Phone",
        accessor: "primary_phone",
      },
      {
        Header: "Additional Pick-Up",
        accessor: (d) => {
          return (
            <div className="text-center">
              <FontAwesomeIcon
                icon={d.is_authorized_pickup ? faCheckCircle : faTimesCircle}
                fixedWidth={false}
                className={`fa-2x ${
                  d.is_authorized_pickup ? "text-green" : "text-red"
                }`}
              />
            </div>
          );
        },
      },
      {
        Header: "Emergency Contact",
        accessor: (d) => {
          return (
            <div className="text-center ">
              <FontAwesomeIcon
                icon={d.is_authorized_contact ? faCheckCircle : faTimesCircle}
                fixedWidth={false}
                className={`fa-2x ${
                  d.is_authorized_contact ? "text-green" : "text-red"
                }`}
              />
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: (d) => {
          return (
            <div className="text-center">
             <span id={`editBtnContainer-${d.child_id}-${d.id}`} style={deleteBtnContainer}>

              <Button
                id={`editBtn-${d.child_id}-${d.id}`}
                style={buttonMarginIcons}
                color="primary"
                size="sm"
                onClick={() => edit(d, "Edit")}
                disabled
              >
                <FontAwesomeIcon
                  icon={faPen}
                  fixedWidth={false}
                  style={icon_centered}
                />
              </Button>
              </span>
              <UncontrolledTooltip
                placement="auto"
                target={`editBtnContainer-${d.child_id}-${d.id}`}
              >
                Contact the center to edit
              </UncontrolledTooltip>
              <span id={`deleteBtnContainer-${d.child_id}-${d.id}`} style={deleteBtnContainer}>
              <Button
                id={`deleteBtn-${d.child_id}-${d.id}`}
                style={buttonMargin}
                color="primary"
                size="sm"
                onClick={() => deleteRow(d)}
                disabled
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth={false}
                  style={icon_centered}
                />
              </Button>
              </span>
              <UncontrolledTooltip
                placement="auto"
                target={`deleteBtnContainer-${d.child_id}-${d.id}`}
              >
                Contact the center to delete
              </UncontrolledTooltip>       
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: emergencyContacts,
      initialState: { pageIndex: 0 },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageSize: updatedPageSize,
          }),
          [state, updatedPageSize]
        );
      },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  return (
    <TableStyles>
      <Row className="tableWrap">
        <Col>
          <table {...getTableProps()}>
            <thead>
              {// Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps({
                        className: column.collapse ? "collapse" : "",
                      })}
                    >
                      {// Render the header
                      column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {// Loop over the table rows
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? "collapse" : "",
                          })}
                        >
                          {// Render the cell contents
                          cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      <Container className="pagination-section">
        <Row>
          <Col xs={12} md={6}>
            <Form inline>
              <FormGroup className="mr-3">
                <Label
                  className="mr-2"
                  for={`emergencyContactsPageIndex-${child.id}`}
                >
                  Go to page:
                </Label>
                <Input
                  id={`emergencyContactsPageIndex-${child.id}`}
                  type="number"
                  defaultValue={pageIndex + 1 || 1}
                  onChange={(e) => {
                    let num = e.target.value;
                    const page = num ? +num - 1 : 0;
                    e.target.value = num > 0 ? num : 1;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </FormGroup>
              <div>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </div>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Last
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Next
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              Previous
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              First
            </Button>
          </Col>
        </Row>
      </Container>
    </TableStyles>
  );
};

const EmergencyContacts = ({
  addEmergencyContact,
  centerInfo,
  child,
  deleteEmergencyContact,
  emergencyContacts,
  editEmergencyContact,
  relationships,
  status,
}) => {
  const [updatedPageSize, setUpdatedPageSize] = useState(10);
  const [editContact, setEditContact] = useState({
    editContactClicked: false,
    editContactInfo: {},
    type: null,
  });
  const [deleteContact, setDeleteContact] = useState({
    deleteContactClicked: false,
    deleteContactInfo: {},
  });

  const edit = (contactInfo, type) => {
    setEditContact({
      ...editContact,
      editContactClicked: true,
      editContactInfo: contactInfo,
      type,
    });
  };

  const deleteRow = (contactInfo) => {
    setDeleteContact((prevState) => ({
      ...prevState,
      deleteContactClicked: true,
      deleteContactInfo: contactInfo,
    }));
  };

  const editModalClosed = () => {
    setEditContact((prevState) => ({
      ...prevState,
      editContactClicked: false,
      editContactInfo: {},
    }));
  };

  const deleteModalClosed = () => {
    setDeleteContact((prevState) => ({
      ...prevState,
      deleteContactClicked: false,
      deleteContactInfo: {},
    }));
  };

  return (
    <Fragment>
      <MemoizedEditContactModal
        editContactClicked={editContact.editContactClicked}
        child={child}
        contactInfo={editContact.editContactInfo}
        countryId={centerInfo.countryId}
        modalClosed={editModalClosed}
        editEmergencyContact={editEmergencyContact}
        addEmergencyContact={addEmergencyContact}
        relationships={relationships}
        type={editContact.type}
      />
      <MemoizedDeleteContactModal
        deleteContactClicked={deleteContact.deleteContactClicked}
        deleteEmergencyContact={deleteEmergencyContact}
        contactInfo={deleteContact.deleteContactInfo}
        modalClosed={deleteModalClosed}
      />
      <Row className="mb-3">
        <Col md={2}>
          <Input
            type="select"
            value={updatedPageSize}
            onChange={(e) => {
              setUpdatedPageSize(+e.target.value);
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      {emergencyContacts && !!Object.keys(emergencyContacts).length && (
        <ContactsTable
          child={child}
          emergencyContacts={emergencyContacts}
          updatedPageSize={updatedPageSize}
          edit={edit}
          deleteRow={deleteRow}
          status={status}
        />
      )}
    </Fragment>
  );
};

export default EmergencyContacts;

import React from "react";
import { Button, FormGroup, Nav, TabContent } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import DisplayTab from "./DisplayTab";

const FormContainer = ({
  activeTab,
  childrenInfo,
  displayTabInputs,
  handlBackBtn,
  handleSubmit,
  previousStep,
  setActiveTab,
  tabs,
  title
}) => {
  const toggleTabs = (id) => {
    if (activeTab !== id) setActiveTab(id);
  };

  const beforeSubmit = (event) => {
    event.persist();
  };

  return (
    <div className="form-container">
      <h3 className="text-center">{title}</h3>
      <Nav tabs>
        {!!Object.keys(tabs).length &&
          Object.keys(tabs).map((tabId) => (
            <DisplayTab
              key={tabId}
              activeTab={activeTab}
              child={childrenInfo[tabId]}
              toggleTabs={toggleTabs}
              tab={tabs[tabId]}
            />
          ))}
      </Nav>
      <AvForm onSubmit={handleSubmit} beforeSubmitValidation={beforeSubmit}>
        <TabContent activeTab={activeTab}>
          {!!Object.keys(tabs).length &&
            Object.keys(tabs).map((tabId, idx) => displayTabInputs(tabs[tabId], idx))}
        </TabContent>
        <FormGroup>
          {previousStep && (
            <Button
              className="mt-2"
              color="secondary"
              size="lg"
              block
              onClick={(e)=> handlBackBtn(e, previousStep)}
            >
              Back
            </Button>
          )}
          <Button className="mt-2" color="primary" size="lg" block>
            Save and Continue
          </Button>
        </FormGroup>
      </AvForm>
    </div>
  );
};

export default FormContainer;

import React, { useEffect, useState } from "react";
import { Button, List, ListInlineItem } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

const InformedConsentCommunication = ({
  centerInfo,
  enrollment,
  currentStep,
  nextStep,
  previousStep,
  saveForm
}) => {
  const nutritionPlanValues = {
    breakfast: "",
    am_snack: "",
    lunch: "",
    pm_snack: ""
  };
  centerInfo.centerProfile &&
    Object.keys(nutritionPlanValues).forEach(plan => {
      nutritionPlanValues[plan] =
        centerInfo.centerProfile[plan] && centerInfo.centerProfile[plan]["data"][0] ? "yes" : "no";
    });
  const [values, setValues] = useState({});
  const { enrollmentConsents } = enrollment;

  useEffect(() => {
    if (enrollmentConsents) {
      setValues(enrollmentConsents[0]);
    }
  }, [enrollmentConsents]);

  const handleSubmit = (event, errors) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    const approvedConsents = enrollmentConsents.map(el => {
      return {
        ...el,
        behaviorGuidance: 1,
        communication: 1,
        nutritionPlan: 1
      };
    });
    saveForm({ enrollmentConsents: approvedConsents, section: "informedConsentCommunication" });
    nextStep();
  };

  const updateInput = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value === "true" ? 0 : 1
    });
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Informed Consent & Acknowledgments</h3>
      <AvForm onSubmit={handleSubmit}>
        <h6>
          <strong>Communications</strong>
        </h6>
        <AvField
          type="checkbox"
          name="communication"
          label="I give The Learning Experience permission to
            communicate with me by telephone, text, e-mail, or other means. I
            understand TLE’s privacy policy applies to the information I provide."
          errorMessage="Please acknowledge the above statement in order to continue."
          onChange={updateInput}
          checked={!!values.communication}
          required
        />
        <br></br>
        <h6>
          <strong>Nutrition Plan Agreement</strong>
        </h6>
        <p>The center provides the following meals: </p>
        <List type="inline">
          <ListInlineItem className="mr-3">
            Breakfast <u>__{nutritionPlanValues.breakfast || "__"}__</u>
          </ListInlineItem>
          <ListInlineItem className="mr-3">
            A.M Snack <u>__{nutritionPlanValues.am_snack || "__"}__</u>
          </ListInlineItem>
          <ListInlineItem className="mr-3">
            Lunch <u>__{nutritionPlanValues.lunch || "__"}__</u>
          </ListInlineItem>
          <ListInlineItem className="mr-3">
            P.M. Snack <u>__{nutritionPlanValues.pm_snack || "__"}__</u>
          </ListInlineItem>
          {centerInfo.centerProfile.countryId === 230 && (
            <ListInlineItem className="mr-3">
              Tea <u>__{nutritionPlanValues.tea || "__"}__</u>
            </ListInlineItem>
          )}
        </List>
        <AvField
          type="checkbox"
          name="nutritionPlan"
          label="I acknowledge the above statement"
          errorMessage="Please acknowledge the above statement in order to continue."
          onChange={updateInput}
          checked={!!values.nutritionPlan}
          required
        />
        <br></br>
        <h6>
          <strong>Behavior Guidance</strong>
        </h6>
        <p>
          The Learning Experience’s Behavior Guidance Philosophy & Policy is based upon a positive
          application approach by relating to each group’s need to age-appropriate guidance
          techniques such as redirection, distraction, and diversion. No form of corporal punishment
          is ever tolerated or permitted.{" "}
        </p>
        <AvField
          type="checkbox"
          name="behaviorGuidance"
          label="I acknowledge the above statement"
          errorMessage="Please acknowledge the above statement in order to continue."
          onChange={updateInput}
          checked={!!values.behaviorGuidance}
          required
        />
        <br></br>
        <Button color="secondary" size="lg" block onClick={previousStep}>
          Back
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default InformedConsentCommunication;

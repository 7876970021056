import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
} from "reactstrap";
// import MyDocumentsAccordian from "./MyDocumentsAccordian";
// import EnrollmentAgreement from "./EnrollmentAgreement";
// import Medical from "./Medical";
 import DocumentAccordian from "../../SharedComponents/DocumentAccordian";
 import ViewDocumentModal from "../../SharedComponents/ViewDocumentModal";

const Documents = ({centerInfo, child, documents, documentTypes}) => {
  const [documentAccordianIsOpen, setdocumentAccordianIsOpen] = useState({});
  const [allDocuments, setAllDocuments] = useState({});
  const [documentToView, setDocumentToView] = useState(null);
  const [viewDocumentClicked, setViewDocumentClicked] = useState(false);

  
  const updateDocumentAccordians = (type, status) => {
    setdocumentAccordianIsOpen((prevState) => ({...prevState, [type.name]: status}))
  }

  useEffect(()=> {
    if(documentTypes.length) {
      documentTypes.forEach(type => {
        updateDocumentAccordians(type, true);
        const filteredDocs = documents.filter(el => el.type_id === type.id);
        setAllDocuments((prevState) => ({...prevState, [type.name]: filteredDocs}))
      })
    }
  }, [documents])

  
  const showDocument = (doc) => {
    setDocumentToView(doc)
    setViewDocumentClicked(true);
  }

  const resetViewDocumentClickEvent = (doc) => {
    setViewDocumentClicked(false);
  }

  const displayDocumentAccordian = (type, index) => {
    return (
      <DocumentAccordian
      centerProfile={centerInfo}
      key={index + 1}
      heading={`${type.name}${type.note ? " ("+ type.note + ")" : ""}`}
      isOpen={documentAccordianIsOpen[type.name]}
      setIsOpen={updateDocumentAccordians}
      type={type}
      documents={allDocuments[type.name]}
      showDocument={showDocument}
      section={'myLittleLearner'}
      />
      )
    }

  return (
    <Row className="my-5">
      <Col className="mx-2">
      <ViewDocumentModal centerProfile={centerInfo} documentToView={documentToView} viewDocumentClicked={viewDocumentClicked} resetViewDocumentClickEvent={resetViewDocumentClickEvent} />
        {documentTypes && documentTypes.map((type, index) => displayDocumentAccordian(type, index))}
      </Col>
    </Row>
  );
};

export default Documents;

const initialState = {
  // addressValidation: {
  //   addressValidationResponse: null,
  //   addressValidationStatus: "idle",
  //   error: "",
  // },
  auth: {
    guardian: {},
    error: "",
    pingResult: null,
    status: "idle",
    token: "",
    showBafIcon: false
  },
  enrollment: {
    addressValidationComingFromUnsavedChangesModal: false,
    addressValidationResponse: null,
    dentalContacts: [],
    guardians: [],
    emergencyContacts: [],
    enrollments: [],
    enrollmentConsents: [],
    enrollmentStatusIds: [],
    genders: [],
    governmentIssuedTypes: [],
    languages: [],
    maritalStatuses: [],
    medicalContacts: [],
    navigation: [],
    photoConsents: [],
    relationships: [],
    redirectTo: '',
    showModal: false,
    states: [],
    status: 'idle',
    submissionStatuses: {},
    error: "",
    unsavedChanges: null,
  },
  pcp: {
    additionalInfo: {},
    childrenInfo: {},
    comfortingChild: {},
    developmentalHistory: {},
    eatingMethods: [],
    error: "",
    ethnicities: [],
    eyeColors: [],
    feedingLocations: [],
    feedingMethods: [],
    genders: [],
    hairColors: [],
    importantCareInfo: {},
    infantFeeding: [],
    languages: [],
    medicalAllergy: [],
    medicalSeverity: [],
    navigation: [],
    nutritionPracticesAndRoutines: {},
    pcpStatusIds: [],
    personalities: [],
    playPreferences: [],
    redirectTo: '',
    showModal: false,
    sleepMethods: [],
    sleepPositions: [],
    sleepingRoutines: {},
    socialRelationships: {},
    submissionStatuses: {},
    status: 'idle',
    toiletMethods: [],
    toiletingRoutines: {},
    toiletTraining: [],
    unsavedChanges: null,
  },
  centerInfo: {
    status: "idle",
    centerProfile: [],
    centerClassrooms: [],
    states: [],
    error: ""
  },
  myAccount: {
    addressValidationResponse: null,
    children: [],
    contactInfo: [],
    documents: [],
    documentTypes: [],
    error: "",
    imageSrc: "",
    primaryPaymentMethod: [],
    receivables: {},
    status: "idle",
  },
  myLittleLearner: {
    children: [],
    childMedical: [],
    documents: [],
    documentTypes: [],
    emergencyContacts: [],
    error: "",
    immunizations: [],
    medicalAllergy: [],
    medicalCondition: [],
    medicalDietaryPreferences: [],
    medicalSeverity: [],
    relationships: [],
    schedule: [],
    status: "idle",
  },
};

export default initialState;

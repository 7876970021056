import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { Tooltip, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import { usePagination, useTable } from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  Button,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

import {
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const TableStyles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    /* display: block; */
    /* max-width: 100%; */
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid black; */
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tbody {
      tr:nth-of-type(2n) {
        background-color: white;
      }
      tr:nth-of-type(2n + 1) {
        background-color: rgb(249, 249, 249);
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      border-bottom: 3px solid #ec008c !important;
      background: aliceblue;
      color: black;
      font-weight: bold;
    }

    th,
    td {
      margin: 0;
      padding: 0.3rem;
      border-bottom: 1px solid #f4f4f4;
      border-right: 1px solid #f4f4f4;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination-section {
    padding: 0.5rem;
  }
`;

const buttonMargin = {
  margin: "0.1rem"
}

const ImmunizationsTable = ({
  child,
  deleteRow,
  edit,
  immunizations,
  setCurrentPageSize,
  updatedPageSize,
}) => {

  const data = useMemo(() => immunizations.immunizations);
  const columns = useMemo(
    () => [
      {
        Header: "Shot",
        accessor: "immunization",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Last",
        accessor: "last",
      },
      {
        Header: "#Received",
        accessor: "received",
      },
      {
        Header: "#Required",
        accessor: "required",
      },
      {
        Header: "Next Due",
        accessor: "next_due",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageSize: updatedPageSize,
          }),
          [state, updatedPageSize]
        );
      },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <TableStyles>
      <Row className="tableWrap">
        <Col>
          <table {...getTableProps()}>
            <thead>
              {// Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps({
                        className: column.collapse ? "collapse" : "",
                      })}
                      // style={{
                      //   borderBottom: "solid 3px red",
                      //   background: "aliceblue",
                      //   color: "black",
                      //   fontWeight: "bold",
                      // }}
                    >
                      {// Render the header
                      column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {// Loop over the table rows
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? "collapse" : "",
                          })}
                          // style={{
                          //   padding: "4px",
                          //   border: "solid 1px gray",
                          //   background: "rgb(249, 249, 249)",
                          // }}
                        >
                          {// Render the cell contents
                          cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      <Container className="pagination-section">
        <Row>
          <Col xs={12} md={6}>
            <Form inline>
              <FormGroup className="mr-3">
                <Label className="mr-2" for={`immunizationsPageIndex-${child.id}`}>
                  Go to page:
                </Label>
                <Input
                  id={`immunizationsPageIndex-${child.id}`}
                  type="number"
                  defaultValue={pageIndex + 1 || 1}
                  onChange={(e) => {
                    let num = e.target.value;
                    const page = num ? +num - 1 : 0;
                    e.target.value = num > 0 ? num : 1;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </FormGroup>
              <div>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </div>
              {/* <FormGroup className="ml-1">
                <Input
                  type="select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </Input>
              </FormGroup> */}
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Last
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Next
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              Previous
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              First
            </Button>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <span>
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </span>


          </Col>
        </Row> */}
      </Container>
    </TableStyles>
  );
};

const Immunizations = ({
  centerInfo,
  child,
  immunizations
}) => {
  // const [currentPageSize, setCurrentPageSize] = useState(10);
  const [updatedPageSize, setUpdatedPageSize] = useState(10);
  const [editContact, setEditContact] = useState({
    editContactClicked: false,
    editContactInfo: {},
    type: null,
  });

  return (
    <Fragment>
      {immunizations && Object.keys(immunizations).length && (
        <Fragment>
          <Row className="mb-3">
            <Col>
              <Row>
                <Col md={4}>
                  <Input
                    type="select"
                    value={updatedPageSize}
                    onChange={(e) => {
                      setUpdatedPageSize(+e.target.value);
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Col>
            <Col>
            </Col>
          </Row>
          <ImmunizationsTable
            child={child}
            updatedPageSize={updatedPageSize}
            immunizations={immunizations}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Immunizations;

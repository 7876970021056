import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import placeholderImage from '../../assets/images/drag-drop-upload.jpg';

const DropZoneErrorMsg = ({fileTooLarge}) => {
  return <p className="file-error">{fileTooLarge ? `File is too large` : `Please select a file.`}</p>;
};
const thumbsContainer = {
  display: "flex",
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  justifyContent: 'center',
  alignItems: 'center'
};

const thumb = {
  display: "inline-flex",
  //   borderRadius: 2,
  //   border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 175,
  height: 175,
  padding: 4,
  boxSizing: "border-box",
  // flexGrow: 1,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  // flexGrow: 1,
  //   overflow: 'hidden'
};

const img = {
  // display: "block",
  width: "100%",
  height: "100%",
};

const maxSize = 10485760;

const ImageUploader = ({
  fileObj,
  handleDrop,
  updateFileObj
}) => {
  const {files, hasError, isModalOpen} = fileObj;
  const {
    isDragAccept,
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: handleDrop,
    maxSize
  });

  const additionalClass = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : isDragActive
    ? "active"
    : "";

  let thumbs;
  if (files && files.length) {
    thumbs = files.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={img} alt="" />
        </div>
      </div>
    ));
  } 
  else {
    thumbs = (
      <img
        alt=""
        src={placeholderImage}
        style={img}
      />
    );
  }

  const fileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
  if(!hasError && fileTooLarge && isModalOpen) {
    updateFileObj([], [], false, true)
  }

  return (
    <section className="container">
      <div {...getRootProps({ className: `dropzone ${additionalClass}` })}>
        <input {...getInputProps()} />
        <p>Drag image here or click to select files</p>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </div>
      {hasError ? <DropZoneErrorMsg fileTooLarge={fileTooLarge}/> : null}
    </section>
  );
};

export default ImageUploader;

import React, { useEffect, useState } from "react";
import { Button, TabPane } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import { compareLocalStorageToDb, utilsHandleInputChange } from "../../../../utils";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import "../../../ContainersStyles.css";

const copyFirstChild = (pcpId, setTabs) => {
  setTabs(prevState => {
    const firstChildId = Object.keys(prevState)[0];
    const copiedFields = {
      additional_information: prevState[firstChildId].additional_information,
      experience_expectation: prevState[firstChildId].experience_expectation,
      parenting_philosophy: prevState[firstChildId].parenting_philosophy
    };
    return {
      ...prevState,
      [pcpId]: { ...prevState[pcpId], ...copiedFields }
    };
  });
};

const AdditionalInfo = ({
  centerInfo,
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const { childrenInfo, additionalInfo, status } = pcp;
  const { centerProfile } = centerInfo;

  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if (!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({
        additionalInfo: tabs,
        section: "additionalInfo",
        showModal: true
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (status === "idle" && additionalInfo && Object.keys(additionalInfo).length) {
      if (!activeTab) setActiveTab(+Object.keys(additionalInfo)[0]);
      const comparedAdditionalInfo = compareLocalStorageToDb(additionalInfo);
      setTabs(comparedAdditionalInfo);
      setStartingState(comparedAdditionalInfo);
    }
  }, [additionalInfo]);

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "additionalInfo", additionalInfo: tabs });
    nextStep();
  };

  const displayTabInputs = (tab, idx) => {
    const {
      additional_information,
      experience_expectation,
      parenting_philosophy,
      personal_care_plan_id: pcpId
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};

    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader
          text={
            childrenInfo[pcpId].transition_classroom_type_name ||
            childrenInfo[pcpId].classroom_type_name
          }
        />
        {idx > 0 && (
          <div id={`copyFirstChildAdditionalInfo-${pcpId}`}>
            <Button
              className="mb-3"
              color="primary"
              size="lg"
              onClick={() => copyFirstChild(pcpId, setTabs)}>
              Copy information from first child
            </Button>
          </div>
        )}
        {centerProfile.countryId !== 230 && (
          <AvField
            name={`parenting_philosophy-${pcpId}`}
            type="text"
            label="To help us care for your child as an individual, please explain your parenting philosophy."
            onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
            value={parenting_philosophy || tabLocalStorage.parenting_philosophy}
          />
        )}
        <AvField
          name={`additional_information-${pcpId}`}
          type="text"
          label="Is there any additional information you feel is important for the staff to know about your child or family?"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={additional_information || tabLocalStorage.additional_information}
        />
        <AvField
          name={`experience_expectation-${pcpId}`}
          type="text"
          label="What do you as a family, hope to get out of this childcare experience?"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={experience_expectation || tabLocalStorage.experience_expectation}
        />
        <div className="mb-sm">
          *Sections of this Personal Care Plan will be updated every month or sooner if requested by
          a parent/guardian.
        </div>
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={"Additional Pertinent Information"}
    />
  );
};

export default AdditionalInfo;

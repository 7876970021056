import React, { Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const ErrorModal = ({
  customErrorMessage,
  errorMessage,
  showErrorModal,
  errorModalClosed,
}) => {
  const toggle = () => {
    // setIsOpen(!showErrorModal);
    errorModalClosed();
    // TODO: SET STATUS TO IDLE
  };

  return (
    <Fragment>
      <Modal isOpen={showErrorModal} toggle={toggle} className="error">
          <ModalHeader toggle={toggle}>Error</ModalHeader>
          <ModalBody>
              {errorMessage || customErrorMessage}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Ok</Button>
          </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ErrorModal;

import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, Nav, TabContent, TabPane } from "reactstrap";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import DisplayTab from "../DisplayTab";

const InformedConsentPhoto = ({
  enrollment,
  currentStep,
  nextStep,
  previousStep,
  saveForm,
}) => {
  const { enrollmentConsents, photoConsents } = enrollment;
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (enrollmentConsents && enrollmentConsents.length > 0) {
      setTabs(enrollmentConsents);
      if (!activeTab) setActiveTab(enrollmentConsents[0].id);
    }
  }, [enrollmentConsents]);

  const handleSubmit = (event, errors) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) {
      const nextTabs = tabs.map(tab => ({
        ...tab,
        ...(errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.id) && { error: true })
      }));
      setTabs(nextTabs);
      return;
    }
    saveForm({ enrollmentConsents: tabs, section: "informedConsentPhoto" });
    nextStep();
  };

  const toggleTabs = id => {
    if (activeTab !== id) setActiveTab(id);
  };

  const updateInput = e => {
    let { name, value } = e.target;
    const id = +name.slice(name.indexOf("-") + 1);
    name = name.slice(0, name.indexOf("-"));
    const nextTabs = tabs.map(el => {
      if (el.id === id) {
        return {
          ...el,
          [name]: value
        };
      }
      return el;
    });
    setTabs(nextTabs);
  };

  const displayTabInputs = (tab, idx) => {
    const { id, photoConsentId } = tab;
    return (
      <TabPane key={id} tabId={id} style={{color: "#495057"}}>
        <h6>
          <Label>Photography Permission</Label>
        </h6>
        <p>
          The Learning Experience takes care that any use, display, or dissemination of photographs
          or videos of children is accomplished in a thoughtful and safe manner. The Learning
          Experience regularly takes photographs of children enrolled. They may be shared with you
          and other families in a variety of ways: through Show n Tell, CORAdvantage, on a posting
          in the center or in a parent newsletter. They may also be used to better communicate with
          families, to illustrate the daily curriculum, to chronicle a child’s development, or to
          document center activities. Additionally, they may be used for other center, general
          business, and marketing purposes, including online and social media. The Learning
          Experience retains all rights, title, and interest in these materials and may use and
          disseminate them in a variety of ways, in its sole judgement. Please note, centers are not
          permitted to allow partial permission for photography usage.
        </p>
        <p>
          This permission is not inclusive of third-party vendors contracted to take school year
          pictures.
        </p>

        <AvRadioGroup
          className="photoPermissionRadio"
          name={`photoConsentId-${id}`}
          errorMessage="Please make a selection in order to continue."
          value={+photoConsentId}
          required>
        {photoConsents.map((el, idx) => {
                   return  <AvRadio
                    key={idx}
                    label={el.description}
                    value={el.id}
                    onChange={updateInput}
                  />
        })}
        </AvRadioGroup>
      </TabPane>
    );
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Informed Consent & Acknowledgments</h3>
      <Nav tabs>
        {tabs.map(tab => (
          <DisplayTab
            key={tab.id}
            activeTab={activeTab}
            tab={tab}
            tabId={tab.id}
            toggleTabs={toggleTabs}
            tabLabel={tab.firstName}
          />
        ))}
      </Nav>
      <AvForm onSubmit={handleSubmit}>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, idx) => displayTabInputs(tab, idx))}
        </TabContent>
        <FormGroup>
          <Button color="secondary" size="lg" block onClick={previousStep}>
            Back
          </Button>
          <Button color="primary" size="lg" block>
            Save and Continue
          </Button>
        </FormGroup>
      </AvForm>
    </div>
  );


};

export default InformedConsentPhoto;

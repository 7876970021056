import React, { Fragment } from "react";
import classnames from "classnames";
import { NavItem, NavLink } from "reactstrap";
const DisplayTab = ({ activeTab, tab, tabId, toggleTabs, showX, deleteTab, tabLabel }) => {
  return (
    <Fragment>
      <NavItem className="tab-nav">
          <NavLink
            className={classnames({ active: activeTab === tabId, error: tab.error})}
            onClick={() => {
              toggleTabs(tabId);
            }}
          >
            {tabLabel}
            {showX && 
              <button 
                className="tab-xbutton"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTab(tabId);
                }}>X</button>
            }
          </NavLink>
      </NavItem>
    </Fragment>
  );
};

export default DisplayTab;
import React, { Fragment } from "react";
import AppHeader from "../../Layout/AppHeader";
import AppFooter from "../../Layout/AppFooter";
import AppSections from "../../Layout/AppSections";
import FamilyInfoTitle from "./Components/FamilyInfoTitle";
import ToDos from "./Components/ToDos";
import Resources from "./Components/Resources";
import Characters from "./Components/Characters";

const Home = ({ auth, enrollment, pcp }) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <div className="app-main__outer">
        <div className="app-main__inner">
          <FamilyInfoTitle />
          <AppSections />
          <ToDos auth={auth} enrollment={enrollment} pcp={pcp}/>
          <Resources />
          <Characters />
        </div>
        <AppFooter />
      </div>
    </div>
  </Fragment>
);

export default Home;

import produce from "immer";
import * as actionTypes from "../actionTypes";
import initialState from "./initialState";

export const enrollment = produce((state = initialState.enrollment, action) => {
  switch (action.type) {
    case actionTypes.ENROLLMENT_LOADDATA__START:
      return {
        ...state,
        status: "loading",
      }
    case actionTypes.ENROLLMENT_LOADDATA__SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "idle",
      };
    case actionTypes.ENROLLMENT_LOADDATA__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__SUCCESS:
      return {
        ...state,
        enrollmentStatusIds: action.payload,
        redirectTo: '/home',
        status: "idle",
      };
    case actionTypes.ENROLLMENT_LOAD_SUBMISSION_STATUS__FAILURE:
      return {
        ...state,
        status: "failed", 
        error: action.payload,
      };
      case actionTypes.ENROLLMENT_DELETEGUARDIAN__FAILURE:
        return {
          ...state,
          status: "failed", 
          error: action.payload,
        };
    case actionTypes.ENROLLMENT_UPDATE_FORM_STATUS:
      return {
        ...state,
        submissionStatuses: {...state.submissionStatuses, ...action.payload}
      };
    case actionTypes.ENROLLMENT_SAVEDATA__START:
      return {
        ...state,
        status: "loading",
      }
    case actionTypes.ENROLLMENT_SAVEDATA__SUCCESS: {
      const isLastStep = action.payload === 'signature';
      // clear local storage
      if (isLastStep) {
        localStorage.removeItem("enrollmentFormData");
      }
      return {
        ...state,  
        status: isLastStep ? 'submitted' : 'saved',
        unsavedChanges: null,
        showModal: false,
        addressValidationComingFromModal: false
      };
    }
    case actionTypes.ENROLLMENT_SAVEDATA__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case actionTypes.ENROLLMENT_VERIFY_ADDRESS__START:
      return {
        ...state,
        status: "loading",
      };
    case actionTypes.ENROLLMENT_VERIFY_ADDRESS__SUCCESS:
      return {
        ...state,
        status: "idle",
        addressValidationResponse: action.payload
      };
    case actionTypes.ENROLLMENT_VERIFY_ADDRESS__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    case actionTypes.ENROLLMENT_RESET_ADDRESS_VALIDATION:
      return {
        ...state,
        status: "idle",
        addressValidationResponse: null
        
      };
    case actionTypes.ENROLLMENT_UPDATE_UNSAVED_CHANGES_WARNING:
      return {
        ...state,
        unsavedChanges: action.payload,
        showModal: action.payload.showModal
      };
    case actionTypes.ENROLLMENT_UPDATE_ADDRESS_VALIDATION_COMING_FROM_UNSAVED_CHANGES_MODAL:
      return {
        ...state,
        addressValidationComingFromUnsavedChangesModal: action.payload
      };
    default:
      return state;
  }
});

import React, { Fragment, useEffect, useState } from "react";
import { Table, TabPane } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import FormContainer from "../FormContainer";
import YesNoDropDownOptions from "../../../SharedComponents/YesNoDropdownOptions";
import PcpFormTypeHeader from "../../../SharedComponents/PcpFormTypeHeader";
import { compareLocalStorageToDb, utilsHandleInputChange } from "../../../../utils";
import "../../../ContainersStyles.css";

const ComfortingChild = ({
  centerInfo,
  handleBackBtn,
  localStoragePCPForm,
  pcp,
  currentStep,
  previousStep,
  nextStep,
  saveForm,
  updateUnsavedChangesWarning
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});
  const [startingState, setStartingState] = useState({});
  const [disabledInputs, setDisabledInputs] = useState({});
  const { childrenInfo, comfortingChild, status } = pcp;
  const { centerProfile } = centerInfo;

  useEffect(() => {
    // setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {})
    if (!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({
        comfortingChild: tabs,
        section: "comfortingChild",
        showModal: true
      });
    }
  }, [tabs]);

  useEffect(() => {
    if (status === "idle" && comfortingChild && Object.keys(comfortingChild).length) {
      if (!activeTab) setActiveTab(+Object.keys(comfortingChild)[0]);
      const comparedComfortingChild = compareLocalStorageToDb(comfortingChild);
      setTabs(comparedComfortingChild);
      setStartingState(comparedComfortingChild);
      const disabledInputsObj = Object.keys(comparedComfortingChild).reduce((acc, val) => {
        return {
          ...acc,
          [val]: {
            pacifier_usage_explanation:
              !comparedComfortingChild[val]["is_pacifier_usage"] ||
              comparedComfortingChild[val]["is_pacifier_usage"] === "0"
          }
        };
      }, {});
      setDisabledInputs(prevValue => {
        return { ...prevValue, ...disabledInputsObj };
      });
    }
  }, [comfortingChild]);

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const tabsCopy = { ...tabs };
    Object.keys(tabsCopy).forEach(
      key =>
        (tabsCopy[key] = {
          ...tabsCopy[key],
          error: false
        })
    );
    if (errors.length > 0) {
      errors.forEach(error => {
        const index = error.slice(error.lastIndexOf("-") + 1);
        tabsCopy[index].error = true;
      });
      setTabs(tabsCopy);
      return;
    } else {
      setTabs(tabsCopy);
    }
    saveForm({ section: "comfortingChild", comfortingChild: tabs });
    nextStep();
  };

  const displayTabInputs = tab => {
    const {
      child_held_position,
      comfort,
      is_pacifier_usage,
      pacifier_usage_explanation,
      security_object,
      security_object_name,
      personal_care_plan_id: pcpId,
      personal_care_plan_type_id: typeId
    } = tab;

    const tabLocalStorage = (localStoragePCPForm && localStoragePCPForm[pcpId]) || {};

    return (
      <TabPane key={pcpId} tabId={pcpId}>
        <PcpFormTypeHeader
          text={
            childrenInfo[pcpId].transition_classroom_type_name ||
            childrenInfo[pcpId].classroom_type_name
          }
        />
        <AvField
          name={`child_held_position-${pcpId}`}
          type="text"
          label="Position child prefers to be held *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={child_held_position || tabLocalStorage.child_held_position}
          required
        />
        <AvField
          name={`security_object-${pcpId}`}
          type="text"
          label={ `${centerProfile.countryId === 230 ? 'Comfort' : 'Security'} Object (if any)`}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={security_object || tabLocalStorage.security_object}
        />
        <AvField
          name={`security_object_name-${pcpId}`}
          type="text"
          label="Name child uses for object when needed"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={security_object_name || tabLocalStorage.security_object_name}
        />
        <AvField
          name={`is_pacifier_usage-${pcpId}`}
          type="select"
          label={ `Does your child use a ${centerProfile.countryId === 230 ? 'dummy' : 'pacifier'}? *`}
          onChange={e =>
            utilsHandleInputChange(
              e,
              pcpId,
              setTabs,
              { pacifier_usage_explanation: e.target.value === "0" },
              setDisabledInputs
            )
          }
          value={
            is_pacifier_usage === 0 ? "0" : is_pacifier_usage || tabLocalStorage.is_pacifier_usage
          }
          required
          // validate={{
          //   required: {
          //     value: true,
          //     errorMessage: "Please select a response.",
          //   },
          // }}
        >
          <YesNoDropDownOptions />
        </AvField>
        <AvField
          name={`pacifier_usage_explanation-${pcpId}`}
          type="text"
          label={ `If yes, when does your child use a ${centerProfile.countryId === 230 ? 'dummy' : 'pacifier'}? *`}
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={
            disabledInputs[pcpId]["pacifier_usage_explanation"]
              ? ""
              : pacifier_usage_explanation || tabLocalStorage.pacifier_usage_explanation
          }
          disabled={disabledInputs[pcpId]["pacifier_usage_explanation"]}
          required={!disabledInputs[pcpId]["pacifier_usage_explanation"]}></AvField>
        <AvField
          name={`comfort-${pcpId}`}
          type="text"
          label="Describe how adults can comfort your child *"
          onBlur={e => utilsHandleInputChange(e, pcpId, setTabs)}
          value={comfort || tabLocalStorage.comfort}
          required></AvField>
      </TabPane>
    );
  };

  return (
    <FormContainer
      activeTab={activeTab}
      childrenInfo={childrenInfo}
      displayTabInputs={displayTabInputs}
      handlBackBtn={handleBackBtn}
      handleSubmit={handleSubmit}
      previousStep={previousStep}
      setActiveTab={setActiveTab}
      tabs={tabs}
      title={"Comforting Child"}
    />
  );
};

export default ComfortingChild;

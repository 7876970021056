export const PING__SUCCESS = "auth/PING__SUCCESS";

export const VERIFY_EMAIL__START = "auth/VERIFY_EMAIL__START";
export const VERIFY_EMAIL__SUCCESS = "auth/VERIFY_EMAIL__SUCCESS";
export const VERIFY_EMAIL__FAILURE = "auth/VERIFY_EMAIL__FAILURE";

export const SIGNIN__START = "auth/SIGNIN__START";
export const SIGNIN__SUCCESS = "auth/SIGNIN__SUCCESS";
export const SIGNIN__FAILURE = "auth/SIGNIN__FAILURE";

export const SIGNUP__START = "auth/SIGNUP__START";
export const SIGNUP__SUCCESS = "auth/SIGNUP__SUCCESS";
export const SIGNUP__FAILURE = "auth/SIGNUP__FAILURE";

export const LOGIN__SUCCESS = "auth/LOGIN__SUCCESS";

export const SIGNOUT = "auth/SIGNOUT";
export const UPDATE_AUTH_STATE = "auth/UPDATE_AUTH_STATE";
export const RESET_STORE = "store/RESET";

export const PCP_LOAD_DATA__START = "pcp/PCP_LOAD_DATA__START";
export const PCP_LOAD_DATA__SUCCESS = "pcp/PCP_LOAD_DATA__SUCCESS";
export const PCP_LOAD_DATA__FAILURE = "pcp/PCP_LOAD_DATA__FAILURE";

export const PCP_LOAD_SUBMISSION_STATUSES__START = "pcp/PCP_LOAD_SUBMISSION_STATUSES__START";
export const PCP_LOAD_SUBMISSION_STATUSES__SUCCESS = "pcp/PCP_LOAD_SUBMISSION_STATUSES__SUCCESS";
export const PCP_LOAD_SUBMISSION_STATUSES__FAILURE = "pcp/PCP_LOAD_SUBMISSION_STATUSES__FAILURE";

export const PCP_PATCH_DATA = "pcp/PCP_PATCHDATA";
export const PCP_SAVE_DATA__START = "pcp/PCP_SAVE_DATA__START";
export const PCP_SAVE_DATA__SUCCESS = "pcp/PCP_SAVE_DATA__SUCCESS";
export const PCP_SAVE_DATA__FAILURE = "pcp/PCP_SAVE_DATA__FAILURE";

export const PCP_UPDATE_UNSAVED_CHANGES_WARNING = "pcp/PCP_UPDATE_UNSAVED_CHANGES_WARNING";

export const ENROLLMENT_LOADDATA__START =
  "enrollment/ENROLLMENT_LOADDATA__START";
export const ENROLLMENT_LOADDATA__SUCCESS =
  "enrollment/ENROLLMENT_LOADDATA__SUCCESS";
export const ENROLLMENT_LOADDATA__FAILURE =
  "enrollment/ENROLLMENT_LOADDATA__FAILURE";

export const ENROLLMENT_UPDATE_FORM_STATUS = "enrollment/ENROLLMENT_UPDATE_FORM_STATUS";
export const ENROLLMENT_DELETEGUARDIAN__SUCCESS = "enrollment/ENROLLMENT_DELETEGUARDIAN__SUCCESS";
export const ENROLLMENT_DELETEGUARDIAN__FAILURE = "enrollment/ENROLLMENT_DELETEGUARDIAN__FAILURE";
export const ENROLLMENT_SAVEDATA__START = "enrollment/ENROLLMENT_SAVEDATA__START";
export const ENROLLMENT_SAVEDATA__SUCCESS = "enrollment/ENROLLMENT_SAVEDATA__SUCCESS";
export const ENROLLMENT_SAVEDATA__FAILURE = "enrollment/ENROLLMENT_SAVEDATA__FAILURE";

export const AUTH_LOAD_GUARDIAN__START =
  "auth/AUTH_LOAD_GUARDIAN__START";
export const AUTH_LOAD_GUARDIAN__SUCCESS =
  "auth/AUTH_LOAD_GUARDIAN__SUCCESS";
export const AUTH_LOAD_GUARDIAN__FAILURE =
  "auth/AUTH_LOAD_GUARDIAN__FAILURE";

export const CENTERINFO_LOAD_DATA__START =
  "centerInfo/CENTERINFO_LOAD_DATA__START";
export const CENTERINFO_LOAD_DATA__SUCCESS =
  "centerInfo/CENTERINFO_LOAD_DATA__SUCCESS";
export const CENTERINFO_LOAD_DATA__FAILURE =
  "centerInfo/CENTERINFO_LOAD_DATA__FAILURE";


export const ENROLLMENT_LOAD_SUBMISSION_STATUS__START = "enrollment/ENROLLMENT_LOAD_SUBMISSION_STATUS__START";
export const ENROLLMENT_LOAD_SUBMISSION_STATUS__SUCCESS = "enrollment/ENROLLMENT_LOAD_SUBMISSION_STATUS__SUCCESS";
export const ENROLLMENT_LOAD_SUBMISSION_STATUS__FAILURE = "enrollment/ENROLLMENT_LOAD_SUBMISSION_STATUS__FAILURE";

export const ENROLLMENT_UPDATE_UNSAVED_CHANGES_WARNING = "enrollment/ENROLLMENT_UPDATE_UNSAVED_CHANGES_WARNING";
export const ENROLLMENT_UPDATE_ADDRESS_VALIDATION_COMING_FROM_UNSAVED_CHANGES_MODAL = "enrollment/ENROLLMENT_UPDATE_ADDRESS_VALIDATION_COMING_FROM_MODAL";

export const SET_REQUEST_ERROR = "error/SET_REQUEST_ERROR";
export const SET_ERROR = "error/SET_ERROR";
export const CLEAR_ERROR = "error/CLEAR_ERROR";

export const MYACCOUNT_LOAD_MY_ACCOUNT__START =
  "myAccount/MYACCOUNT_LOAD_MY_ACCOUNT__START";
export const MYACCOUNT_LOAD_MY_ACCOUNT__SUCCESS =
  "myAccount/MYACCOUNT_LOAD_MY_ACCOUNT__SUCCESS";
export const MYACCOUNT_LOAD_MY_ACCOUNT__FAILURE =
  "myAccount/MYACCOUNT_LOAD_MY_ACCOUNT__FAILURE";

export const MYACCOUNT_LOAD_CONTACT_INFO__START =
  "myAccount/MYACCOUNT_LOAD_CONTACT_INFO__START";
export const MYACCOUNT_LOAD_CONTACT_INFO__SUCCESS =
  "myAccount/MYACCOUNT_LOAD_CONTACT_INFO__SUCCESS";
export const MYACCOUNT_LOAD_CONTACT_INFO__FAILURE =
  "myAccount/MYACCOUNT_LOAD_CONTACT_INFO__FAILURE";

export const MYACCOUNT_SAVE_CONTACT_INFO__START =
  "myAccount/MYACCOUNT_SAVE_CONTACT_INFO__START";
export const MYACCOUNT_SAVE_CONTACT_INFO__SUCCESS =
  "myAccount/MYACCOUNT_SAVE_CONTACT_INFO__SUCCESS";
export const MYACCOUNT_SAVE_CONTACT_INFO__FAILURE =
  "myAccount/MYACCOUNT_SAVE_CONTACT_INFO__FAILURE";

export const MYACCOUNT_GET_IMAGE__START =
  "myAccount/MYACCOUNT_GET_IMAGE__START";
export const MYACCOUNT_GET_IMAGE__SUCCESS =
  "myAccount/MYACCOUNT_GET_IMAGE__SUCCESS";
export const MYACCOUNT_GET_IMAGE__FAILURE =
  "myAccount/MYACCOUNT_GET_IMAGE__FAILURE";

export const MYACCOUNT_LOAD_DOCUMENTS__START =
  "myAccount/MYACCOUNT_LOAD_DOCUMENTS__START";
export const MYACCOUNT_LOAD_DOCUMENTS__SUCCESS =
  "myAccount/MYACCOUNT_LOAD_DOCUMENTS__SUCCESS";
export const MYACCOUNT_LOAD_DOCUMENTS__FAILURE =
  "myAccount/MYACCOUNT_LOAD_DOCUMENTS__FAILURE";

export const MYACCOUNT_LOAD_DOCUMENT_TYPES__START =
  "myAccount/MYACCOUNT_LOAD_DOCUMENT_TYPES__START";
export const MYACCOUNT_LOAD_DOCUMENT_TYPES__SUCCESS =
  "myAccount/MYACCOUNT_LOAD_DOCUMENT_TYPES__SUCCESS";
export const MYACCOUNT_LOAD_DOCUMENT_TYPES__FAILURE =
  "myAccount/MYACCOUNT_LOAD_DOCUMENT_TYPES__FAILURE";

export const MYACCOUNT_SAVE_DOCUMENT__START =
  "myAccount/MYACCOUNT_SAVE_DOCUMENT__START";
export const MYACCOUNT_SAVE_DOCUMENT__SUCCESS =
  "myAccount/MYACCOUNT_SAVE_DOCUMENT__SUCCESS";
export const MYACCOUNT_SAVE_DOCUMENT__FAILURE =
  "myAccount/MYACCOUNT_SAVE_DOCUMENT__FAILURE";

export const MYACCOUNT_VERIFY_ADDRESS__START =
  "myAccount/MYACCOUNT_VERIFY_ADDRESS__START";
export const MYACCOUNT_VERIFY_ADDRESS__SUCCESS =
  "myAccount/MYACCOUNT_VERIFY_ADDRESS__SUCCESS";
export const MYACCOUNT_VERIFY_ADDRESS__FAILURE =
  "myAccount/MYACCOUNT_VERIFY_ADDRESS__FAILURE";

export const MYACCOUNT_FETCHING_DATA = "myAccount/MYACCOUNT_FETCHING_DATA";
export const MYACCOUNT_RECEIVED_DATA = "myAccount/MYACCOUNT_RECEIVED_DATA";

export const MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__START =
  "myLittleLearner/MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__START";
export const MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__SUCCESS";
export const MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_LOAD_MY_LITTLE_LEARNER__FAILURE";

export const MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__START =
  "myLittleLearner/MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__START";
export const MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__SUCCESS";
export const MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_LOAD_CHILD_PERSONAL_INFO__FAILURE";

export const MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__START =
  "myLittleLearner/MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__START";
export const MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__SUCCESS";
export const MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_SAVE_CHILD_PERSONAL_INFO__FAILURE";

export const MYLITTLELEARNER_SAVE_CHILD_IMAGE__START =
  "myLittleLearner/MYLITTLELEARNER_SAVE_CHILD_IMAGE__START";
export const MYLITTLELEARNER_SAVE_CHILD_IMAGE__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_SAVE_CHILD_IMAGE__SUCCESS";
export const MYLITTLELEARNER_SAVE_CHILD_IMAGE__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_SAVE_CHILD_IMAGE__FAILURE";

export const MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__START =
  "myLittleLearner/MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__START";
export const MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__SUCCESS";
export const MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_SAVE_EMERGENCY_CONTACT__FAILURE";

export const MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__START =
  "myLittleLearner/MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__START";
export const MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__SUCCESS";
export const MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_EDIT_EMERGENCY_CONTACT__FAILURE";

export const MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__START =
  "myLittleLearner/MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__START";
export const MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__SUCCESS";
export const MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_DELETE_EMERGENCY_CONTACT__FAILURE";

export const MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__START =
  "myLittleLearner/MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__START";
export const MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__SUCCESS";
export const MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_LOAD_EMERGENCY_CONTACTS__FAILURE";

export const MYLITTLELEARNER_LOAD_CHILD_MEDICAL__START =
  "myLittleLearner/MYLITTLELEARNER_LOAD_CHILD_MEDICAL__START";
export const MYLITTLELEARNER_LOAD_CHILD_MEDICAL__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_LOAD_CHILD_MEDICAL__SUCCESS";
export const MYLITTLELEARNER_LOAD_CHILD_MEDICAL__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_LOAD_CHILD_MEDICAL__FAILURE";

export const MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__START =
  "myLittleLearner/MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__START";
export const MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__SUCCESS =
  "myLittleLearner/MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__SUCCESS";
export const MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE =
  "myLittleLearner/MYLITTLELEARNER_UPDATE_CHILD_MEDICAL__FAILURE";

export const MYLITTLELEARNER_FETCHING_DATA =
  "myLittleLearner/MYLITTLELEARNER_FETCHING_DATA";
export const MYLITTLELEARNER_RECEIVED_DATA =
  "myLittleLearner/MYLITTLELEARNER_RECEIVED_DATA";

export const ENROLLMENT_VERIFY_ADDRESS__START =
  "addressValidation/ENROLLMENT_VERIFY_ADDRESS__START";
export const ENROLLMENT_VERIFY_ADDRESS__SUCCESS =
  "addressValidation/ENROLLMENT_VERIFY_ADDRESS__SUCCESS";
export const ENROLLMENT_VERIFY_ADDRESS__FAILURE =
  "addressValidation/ENROLLMENT_VERIFY_ADDRESS__FAILURE";

export const ENROLLMENT_RESET_ADDRESS_VALIDATION =
  "addressValidation/ENROLLMENT_RESET_ADDRESS_VALIDATION";

  
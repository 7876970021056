import React, { useCallback, useEffect, useState } from "react";
import { Button, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import AddressValidationModal from "../../../SharedComponents/AddressValidationModal";
import { convertToDropdownOptions } from "../../../SharedComponents/dropdowns";
import {
  compareEnrollmentLocalStorageToDb,
  filterState,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";



const SecondaryContactAddress = ({
  enrollment,
  currentStep,
  handleBackBtn,
  IdentificationTooltip,
  nextStep,
  previousStep,
  resetAddressValidationResponse,
  saveForm,
  setCustomErrorMessage,
  setShowErrorModal,
  updateFormStatus,
  updateUnsavedChangesWarning,
  verifyAddress,
  centerInfo
}) => {

  const {
    addressValidationComingFromUnsavedChangesModal,
    addressValidationResponse,
    enrollments,
    governmentIssuedTypes,
    guardians,
    submissionStatuses
  } = enrollment;

  const familyId = enrollments[0].familyId;
  const { centerProfile, states } = centerInfo;
  const [guardian, setGuardian] = useState({});
  const [startingState, setStartingState] = useState({});
  const [modal, setModal] = useState({});
  const [requireIdentificationNumber, setRequireIdentificationNumber] = useState(false);
  const [requireState, setRequireState] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const localStorageSecondaryContactAddress =
    (localStorageEnrollmentForm && localStorageEnrollmentForm.secondaryContactAddress) || {};
  const addressLabels = generateAddressLabels(centerProfile.countryId)

  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    if (!_.isEqual(startingState, guardian)) {
      updateUnsavedChangesWarning({
        secondaryContact: guardian,
        section: "secondaryContactAddress",
        showModal: true
      });
    }
    setIsRequired(!!guardian.firstName);
  }, [guardian]);

  useEffect(() => {
    if (submissionStatuses.secondaryContactAddress !== true) {
      updateFormStatus({
        section: "secondaryContactAddress",
        value: isRequired ? false : "optional"
      });
    }
  }, [isRequired]);

  useEffect(() => {
    if (guardians && guardians.length > 0) {
      const secondaryContactInfo = guardians.find(el => el.guardianTypeId === 2);
      let comparedSecondaryContactAddress = compareEnrollmentLocalStorageToDb(
        secondaryContactInfo,
        "ppGuardianId",
        "secondaryContactAddress"
      );
      comparedSecondaryContactAddress = {
        ...comparedSecondaryContactAddress,
        address2: comparedSecondaryContactAddress.address2 || "",
        coreFamilyId: familyId,
        guardianTypeId: 2
      };
      setGuardian(comparedSecondaryContactAddress);
      setStartingState(comparedSecondaryContactAddress);
    }
  }, [guardians]);

  const saveFromModal = useCallback(
    (isSuggestedAddress = false) => {
      const state = filterState(addressValidationResponse.state_code, states, centerProfile.countryId !== 230, centerProfile.countryId === 230)
      if(isSuggestedAddress) {
        //sometimes county in UK not found in db collection
        if(!state) {
          setCustomErrorMessage('County not found. Please use a different address.')
          setShowErrorModal(true)
          return
        }
      }
      const updatedGuardian = {
        ...guardian,
        ...(isSuggestedAddress && {
          address1: addressValidationResponse.street_line_1,
          address2: addressValidationResponse.street_line_2,
          city: addressValidationResponse.city,
          postalCode: addressValidationResponse.postal_code,
          stateId: state.id
        })
      };
      setCustomErrorMessage('')
      resetAddressValidationResponse({ section: "ENROLLMENT" });
      saveForm({
        secondaryContact: updatedGuardian,
        section: "secondaryContactAddress"
      });
      setModal({ ...modal, isOpen: false });
      nextStep();
    },
    [modal]
  );

  useEffect(() => {
    // navsteps are different depending on country id because dental section is either showing/missing
    if (
      (centerProfile.countryId === 232 && currentStep !== 8) ||
      (centerProfile.countryId === 230 && currentStep !== 7) ||
      addressValidationComingFromUnsavedChangesModal
    )
      return;
    if (addressValidationResponse) {
      if (addressValidationResponse === "INVALID_ADDRESS") {
        setModal({
          isOpen: true,
          updatedContactInfo: guardian,
          addressValidationResponse,
          handleInvalidAddress
        });
      } else {
        const selectedState = states.filter(state => state.id === +guardian.stateId)[0];
        const stateNameOrAbbrev =
          centerProfile.country_id === 230
            ? selectedState.name.trim()
            : selectedState.abbrev.trim();
        if (
          guardian.address1.trim() !== addressValidationResponse.street_line_1.trim() ||
          guardian.city.trim() !== addressValidationResponse.city.trim() ||
          guardian.postalCode.trim() !== addressValidationResponse.postal_code.trim() ||
          stateNameOrAbbrev !== addressValidationResponse.state_code
        ) {
          setModal({
            isOpen: true,
            updatedContactInfo: guardian,
            addressValidationResponse,
            stateNameOrAbbrev
          });
        } else {
          saveForm({
            secondaryContact: guardian,
            section: "secondaryContactAddress"
          });
          nextStep();
        }
      }
    }
  }, [addressValidationResponse]);

  const handleInvalidAddress = () => {
    resetAddressValidationResponse({ section: "ENROLLMENT" });
    setModal({ ...modal, isOpen: false });
  };

  const handleBlur = e => {
    let { name, value } = e.target;
    if (name === "governmentIssuedTypeId") {
      value = parseInt(value);
      // If user select any Government Issued Identification, set id required
      setRequireIdentificationNumber(value > 0);
      setRequireState(value > 0 ? value === 1 || value === 3 : false);
    }
    utilsHandleEnrollmentFormInputChange(
      e,
      setGuardian,
      "secondaryContactAddress",
      "ppGuardianId",
      {
        familyId
      }
    );
  };

  const handleClear = e => {
    const newGuardian = {
      ...guardian,
      address1: null,
      address2: null,
      city: null,
      stateId: null,
      postalCode: null,
      governmentIssuedTypeId: null,
      governmentIssuedId: null,
      governmentIssuedStateId: null
    };

    localStorageEnrollmentForm.secondaryContactAddress = newGuardian;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setGuardian(newGuardian);
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    if (errors.length > 0) return;
    if (!isRequired) {
      nextStep();
      return;
    }
    verifyAddress({
      ...guardian,
      section: "ENROLLMENT",
      zipCode: guardian.postalCode,
      countryCode: addressLabels.country,
      stateName: filterState(guardian.stateId, states).name
    });
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Secondary Contact Address</h3>
      <AddressValidationModal modal={modal} saveFromModal={saveFromModal} />
      <AvForm onSubmit={handleSubmit} className="active">
        <AvField
          id="address1"
          name="address1"
          type="text"
          label="Address"
          onBlur={handleBlur}
          value={guardian.address1 || localStorageSecondaryContactAddress.address1 || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter an address."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="address2"
          name="address2"
          type="text"
          label="Address 2 (Optional)"
          onBlur={handleBlur}
          value={guardian.address2 || localStorageSecondaryContactAddress.address2 || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="city"
          name="city"
          type="text"
          label={`${addressLabels.city} *`}
          onBlur={handleBlur}
          value={guardian.city || localStorageSecondaryContactAddress.city || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: `Please enter a ${addressLabels.city.toLowerCase()}.`
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id="stateId"
          name="stateId"
          type="select"
          label={`${addressLabels.state} *`}
          onChange={handleBlur}
          value={guardian.stateId || localStorageSecondaryContactAddress.stateId || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: `Please select a ${
                addressLabels.state.toLowerCase()
              }.`
            }
          }}>
          <option key="" value="">
            {`Please select a ${addressLabels.state.toLowerCase()}`}
          </option>
          {states.map(state => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </AvField>
        <AvField
          id="postalCode"
          name="postalCode"
          type="text"
          label={`${addressLabels.zip} Code *`}
          onBlur={handleBlur}
          value={guardian.postalCode || localStorageSecondaryContactAddress.postalCode || ""}
          onKeyPress={e => {
            if (
              !(centerProfile.countryId === 230 ? /[\sa-zA-Z0-9]/.test(e.key) : /[0-9]/.test(e.key))
            )
              e.preventDefault();
          }}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            maxLength: {
              value: `${centerProfile.countryId === 230 ? 8 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            minLength: {
              value: `${centerProfile.countryId === 230 ? 6 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            pattern: {
              value: `${
                centerProfile.countryId === 230
                ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                  : "^[0-9]{5}$"
              }`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            }
          }}
        />
        {centerProfile.countryId === 232 && (
          <>
            <AvField
              id="governmentIssuedTypeId"
              name="governmentIssuedTypeId"
              type="select"
              label="Government Issued Identification"
              onChange={handleBlur}
              value={
                guardian.governmentIssuedTypeId ||
                localStorageSecondaryContactAddress.governmentIssuedTypeId ||
                ""
              }
              validate={{
                required: {
                  value: false,
                  errorMessage: "Please enter an identification."
                }
              }}>
              {convertToDropdownOptions(governmentIssuedTypes).map(e => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </AvField>
            <Label>Identification Number {IdentificationTooltip}</Label>
            <AvField
              id="governmentIssuedId"
              name="governmentIssuedId"
              type="text"
              onBlur={handleBlur}
              value={
                guardian.governmentIssuedId ||
                localStorageSecondaryContactAddress.governmentIssuedId ||
                ""
              }
              validate={{
                required: {
                  value: requireIdentificationNumber,
                  errorMessage: "Please enter an identification number."
                },
                maxLength: { value: 50 }
              }}
            />
            <AvField
              id="governmentIssuedStateId"
              name="governmentIssuedStateId"
              type="select"
              label="State Issuing"
              onChange={handleBlur}
              value={
                guardian.governmentIssuedStateId ||
                localStorageSecondaryContactAddress.governmentIssuedStateId ||
                ""
              }
              validate={{
                required: {
                  value: requireState,
                  errorMessage: "Please enter an issuing state."
                }
              }}>
              <option key="" value="">
                Please select a state
              </option>
              {states.map(state => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </AvField>
          </>
        )}
        <Button color="secondary" size="lg" block onClick={e => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <Button color="danger" size="lg" block onClick={handleClear}>
          Clear
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
    </div>
  );
};

export default SecondaryContactAddress;

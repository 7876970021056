import React, { useCallback, useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChildAddress from "./Forms/ChildAddress";
import ChildDental from "./Forms/ChildDental";
import ChildMedical from "./Forms/ChildMedical";
import ChildInfo from "./Forms/ChildInfo";
import EmergencyContacts from "./Forms/EmergencyContacts";
import EmergencyRelease from "./Forms/EmergencyRelease";
// import FamilyMembers from "./Forms/FamilyMembers";
import InformedConsentMedical from "./Forms/InformedConsentMedical";
import InformedConsentPermissions from "./Forms/InformedConsentPermissions";
import InformedConsentPhoto from "./Forms/InformedConsentPhoto";
import InformedConsentCommunication from "./Forms/InformedConsentCommunication";
import InformedConsentWithdrawal from "./Forms/InformedConsentWithdrawal";
import PrimaryContactInfo from "./Forms/PrimaryContactInfo";
import PrimaryContactAddress from "./Forms/PrimaryContactAddress";
import SecondaryContactInfo from "./Forms/SecondaryContactInfo";
import SecondaryContactAddress from "./Forms/SecondaryContactAddress";
import Signature from "./Forms/Signature";
import EnrollmentStepWizard from "react-step-wizard";
import EnrollmentStepWizardNav from "./EnrollmentStepWizardNav";
import IdentificationTooltip from "./IdentificationTooltip";
import { loadGuardian } from "../../../redux/actionCreators/auth";
import {
  resetAddressValidationResponse,
  verifyAddress
} from "../../../redux/actionCreators/addressValidation";
import {
  loadData,
  loadEnrollmentSubmissionStatuses,
  updateFormStatus,
  deleteGuardian,
  saveData,
  updateAddressValidationComingFromUnsavedChangesModal,
  updateUnsavedChangesWarning
} from "../../../redux/actionCreators/enrollment";

import ErrorModal from "../../SharedComponents/ErrorModal";
import AddressValidationModal from "../../SharedComponents/AddressValidationModal";
import UnsavedChangesModal from "../../SharedComponents/UnsavedChangesModal";
import "../../../Containers/ContainersStyles.css";
import { filterState } from "../../../utils";

const Stepper = ({
  auth,
  enrollment,
  centerInfo,
  deleteGuardian,
  loadData,
  loadEnrollmentSubmissionStatuses,
  pcp,
  resetAddressValidationResponse,
  saveData,
  updateFormStatus,
  updateAddressValidationComingFromUnsavedChangesModal,
  updateUnsavedChangesWarning,
  verifyAddress
}) => {
  const { states } = centerInfo;
  const {
    addressValidationResponse,
    dentalContacts,
    error,
    redirectTo,
    showModal,
    status,
    submissionStatuses,
    unsavedChanges
  } = enrollment;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState('');
  const [activeChildInfoTab, setActiveChildInfoTab] = useState(null);
  const [addressValidationModal, setAddressValidationModal] = useState({});
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [stepObj, setStepObj] = useState({});
  const [step, setStep] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (status === "submitted") {
      loadEnrollmentSubmissionStatuses({ familyId: auth.guardian.family_id });
    }
    if (status === "saved") {
      loadData({
        familyId: auth.guardian.family_id
      });
    }
  }, [status]);

  const errorModalClosed = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    loadData({
      familyId: auth.guardian.family_id
    });
  }, []);

  const saveForm = payload => {
    saveData(payload);
  };

  const onStepChange = stats => {};

  const handleBackBtn = (e, previousStep) => {
    if (showModal) {
      setShowUnsavedChangesModal(true);
      setStepObj({ previousStep });
    } else {
      previousStep();
    }
  };

  const handleNavSetStep = (step, goToStep) => {
    setStep(step);
    setStepObj({ goToStep });
  };

  const handleAddressSaveInUnsavedChangesModal = data => {
    const handleInvalidAddress = () => {
      resetAddressValidationResponse({ section: "ENROLLMENT" });
      setAddressValidationModal({ ...data, isOpen: false });
    };
    data.handleInvalidAddress = handleInvalidAddress;
    setAddressValidationModal(data);
  };

  const jumpToSectionFromModal = () => {
    if (stepObj.previousStep) {
      stepObj.previousStep();
    } else {
      stepObj.goToStep(step);
    }
  };

  const toggleUnsavedChangesModal = show => {
    setShowUnsavedChangesModal(show);
  };

  const saveFromAddressModal = useCallback(
    (isSuggestedAddress = false) => {

      const state = filterState(addressValidationResponse.state_code, states, centerInfo.centerProfile.countryId !== 230, centerInfo.centerProfile.countryId === 230)
      if(isSuggestedAddress) {
        //sometimes county in UK not found in db collection
        if(!state) {
          setCustomErrorMessage('County not found. Please use a different address.')
          setShowErrorModal(true)
          return
        }
      }
      const newGuardianObj = {
        ...(unsavedChanges.primaryContact || unsavedChanges.secondaryContact),
        ...(isSuggestedAddress && {
          address1: addressValidationResponse.street_line_1,
          address2: addressValidationResponse.street_line_2,
          city: addressValidationResponse.city,
          postalCode: addressValidationResponse.postal_code,
          stateId: state.id
        })
      };
      saveForm({
        ...unsavedChanges,
        ...(unsavedChanges.primaryContact
          ? { primaryContact: newGuardianObj }
          : { secondaryContact: newGuardianObj }),
        missingFieldFound: addressValidationModal.missingFieldFound
      });
      setCustomErrorMessage('')
      setAddressValidationModal({ ...addressValidationModal, isOpen: false });
      jumpToSectionFromModal();
    },
    [addressValidationModal]
  );

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="wizard-container">
          <EnrollmentStepWizard
            onStepChange={onStepChange}
            nav={
              <EnrollmentStepWizardNav
                enrollment={enrollment}
                handleNavSetStep={handleNavSetStep}
                setShowUnsavedChangesModal={setShowUnsavedChangesModal}
                submissionStatuses={submissionStatuses}
              />
            }>
            <ChildInfo
              activeChildInfoTab={activeChildInfoTab}
              enrollment={enrollment}
              saveForm={saveForm}
              setActiveChildInfoTab={setActiveChildInfoTab}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />

            <ChildAddress
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              IdentificationTooltip={
                <IdentificationTooltip
                  id="tooltip4"
                  msg="Describe where the child's primary residence is: lives with both parents, lives with one parent fulltime, splits time between both parents, etc."
                />
              }
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <ChildMedical
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateFormStatus={updateFormStatus}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              verifyAddress={verifyAddress}
            />
            {dentalContacts.length ? (
              <ChildDental
                centerInfo={centerInfo}
                enrollment={enrollment}
                handleBackBtn={handleBackBtn}
                updateFormStatus={updateFormStatus}
                saveForm={saveForm}
                updateUnsavedChangesWarning={updateUnsavedChangesWarning}
                verifyAddress={verifyAddress}
              />
            ) : null}
            <PrimaryContactInfo
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <PrimaryContactAddress
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              setCustomErrorMessage={setCustomErrorMessage}
              setShowErrorModal={setShowErrorModal}
              IdentificationTooltip={
                <IdentificationTooltip
                  id="tooltip1"
                  msg="Driver's license number, state issued ID number, passport ID, etc."
                />
              }
              resetAddressValidationResponse={resetAddressValidationResponse}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              verifyAddress={verifyAddress}
            />
            <SecondaryContactInfo
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateFormStatus={updateFormStatus}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <SecondaryContactAddress
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              resetAddressValidationResponse={resetAddressValidationResponse}
              saveForm={saveForm}
              setCustomErrorMessage={setCustomErrorMessage}
              setShowErrorModal={setShowErrorModal}
              IdentificationTooltip={
                <IdentificationTooltip
                  id="tooltip2"
                  msg="Driver's license number, state issued ID number, passport ID, etc."
                />
              }
              updateFormStatus={updateFormStatus}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              verifyAddress={verifyAddress}
            />
            <EmergencyRelease
              centerInfo={centerInfo}
              saveForm={saveForm}
              enrollment={enrollment}
              setActiveChildInfoTab={setActiveChildInfoTab}
            />
            <EmergencyContacts
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              IdentificationTooltip={
                <IdentificationTooltip
                  id="tooltip3"
                  msg="Driver's license number, state issued ID number, passport ID, etc."
                />
              }
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
              deleteGuardian={deleteGuardian}
            />
            <InformedConsentMedical
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <InformedConsentPermissions
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <InformedConsentPhoto
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <InformedConsentCommunication
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <InformedConsentWithdrawal
              centerInfo={centerInfo}
              enrollment={enrollment}
              handleBackBtn={handleBackBtn}
              saveForm={saveForm}
              updateUnsavedChangesWarning={updateUnsavedChangesWarning}
            />
            <Signature
              centerInfo={centerInfo}
              enrollment={enrollment}
              saveForm={saveForm}
              submissionStatuses={submissionStatuses}
            />
          </EnrollmentStepWizard>
        </div>
      </div>
      <AddressValidationModal modal={addressValidationModal} saveFromModal={saveFromAddressModal} />
      <ErrorModal
        customErrorMessage={customErrorMessage}
        errorMessage={error}
        showErrorModal={showErrorModal}
        errorModalClosed={errorModalClosed}
      />
      <UnsavedChangesModal
        centerInfo={centerInfo}
        enrollment={enrollment}
        pcp={pcp}
        handleAddressSaveInUnsavedChangesModal={handleAddressSaveInUnsavedChangesModal}
        jumpToSectionFromModal={jumpToSectionFromModal}
        resetAddressValidationResponse={resetAddressValidationResponse}
        saveForm={saveForm}
        showUnsavedChangesModal={showUnsavedChangesModal}
        toggleUnsavedChangesModal={toggleUnsavedChangesModal}
        unsavedChanges={unsavedChanges}
        updateAddressValidationComingFromUnsavedChangesModal={
          updateAddressValidationComingFromUnsavedChangesModal
        }
        updateUnsavedChangesWarning={updateUnsavedChangesWarning}
        verifyAddress={verifyAddress}
      />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  auth: state.auth,
  enrollment: state.enrollment,
  centerInfo: state.centerInfo,
  pcp: state.pcp
});

const mapDispatchToProps = dispatch => ({
  loadGuardian: bindActionCreators(loadGuardian, dispatch),
  loadData: bindActionCreators(loadData, dispatch),
  loadEnrollmentSubmissionStatuses: bindActionCreators(loadEnrollmentSubmissionStatuses, dispatch),
  updateFormStatus: bindActionCreators(updateFormStatus, dispatch),
  deleteGuardian: bindActionCreators(deleteGuardian, dispatch),
  resetAddressValidationResponse: bindActionCreators(resetAddressValidationResponse, dispatch),
  saveData: bindActionCreators(saveData, dispatch),
  updateAddressValidationComingFromUnsavedChangesModal: bindActionCreators(
    updateAddressValidationComingFromUnsavedChangesModal,
    dispatch
  ),
  updateUnsavedChangesWarning: bindActionCreators(updateUnsavedChangesWarning, dispatch),
  verifyAddress: bindActionCreators(verifyAddress, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null)(Stepper);

import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const DropZoneErrorMsg = () => {
  return <p className="file-error">Please select a file.</p>;
};

const DocumentUploader = ({ files, fileNames, handleDrop, showErrorMsg }) => {
  const {
    isDragAccept,
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop: handleDrop,
    // accept: 'image/png',
    // minSize: 0,
    // maxSize,
    multiple: false,
  });

  const additionalClass = isDragAccept
    ? "accept"
    : isDragReject
    ? "reject"
    : isDragActive
    ? "active"
    : "";
  return (
    <section>
      <div {...getRootProps({ className: `dropzone ${additionalClass}` })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        {fileNames.map((fileName) => (
          <p key={fileName}>{fileName}</p>
        ))}
      </div>
      {showErrorMsg ? <DropZoneErrorMsg /> : null}
    </section>
  );
};


export default DocumentUploader;
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Row, Col, Card, CardBody } from "reactstrap";
import bubbles from "../../../assets/images/bubbles.png"
import graceCharity from "../../../assets/images/grace_charity.png"
import flexi from "../../../assets/images/flexi.png"
import lionstein from "../../../assets/images/lionstein.png"
import phoebe from "../../../assets/images/phoebe.png"
import twoPlusToucan from "../../../assets/images/two_plus_toucan.png"
import penny from "../../../assets/images/penny.png"
import '../carouselOverrides.css'

const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Characters = (props) => {
  return (
    <Row>
    <Col>
      <Card className="mb-3">
        <CardBody>
          <h5 className="text-primary characterTitle">
            Get to know our characters
          </h5>
          <Slider {...settings}>
          <div className='characterContainer'>
              <img src={bubbles} alt={'test'} className="characterImg" />
              <p className='characterName'>Bubbles the Elephant</p>
              <p className='characterDescription'>Our lovable friend who takes you on a daily adventure that makes kids happy to learn, play, and grow.</p>
          </div>
          <div className='characterContainer'>
              <img src={graceCharity} alt={'test'} className="characterImg" />
              <p className='characterName'>Grace the Greyhound & Charity Chihuahua</p>
              <p className='characterDescription'>Our dynamic doggy duo introduces the importance of being generous and giving to others through the learning of philanthropy.</p>
          </div>
          <div className='characterContainer'>
              <img src={flexi} alt={'test'} className="characterImg" />
              <p className='characterName'>Flexi Flamingo</p>
              <p className='characterDescription'>Our passionate fitness instructor teaches the importance of an active lifestyle and healthy food choices.</p>
          </div>
          <div className='characterContainer'>
              <img src={lionstein} alt={'test'} className="characterImg" />
              <p className='characterName'>Lionstein</p>
              <p className='characterDescription'>Our wise and creative scientist who makes science fun and exciting.</p>
          </div>
          <div className='characterContainer'>
              <img src={phoebe} alt={'test'} className="characterImg" />
              <p className='characterName'>Phoebe Phonics</p>
              <p className='characterDescription'>Our literature loving llama makes reading fun and takes our children on imaginative adventures through books.</p>
          </div>
          <div className='characterContainer'>
              <img src={twoPlusToucan} alt={'test'} className="characterImg" />
              <p className='characterName'>Two Plus Toucan</p>
              <p className='characterDescription'>Our creative mathematician helps explore the joy of math.</p>
          </div>
          <div className='characterContainer'>
              <img src={penny} alt={'test'} className="characterImg" />
              <p className='characterName'>Penny Polite</p>
              <p className='characterDescription'>Our well-mannered etiquette coach demonstrates the importance of being helpful, honest, and kind.</p>
          </div>
        </Slider>
        </CardBody>
      </Card>
    </Col>
  </Row>
  );
}

export default Characters;
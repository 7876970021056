import React, { useEffect, useState } from "react";
import {
  Nav,
  Button,
  FormGroup,
  Label,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import DisplayTab from "../DisplayTab";
import {
  compareEnrollmentLocalStorageToDb,
  formatPhoneNumber,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";
import { convertToDropdownOptions } from "../../../SharedComponents/dropdowns";
import ConfirmationModal from "../../../SharedComponents/ConfirmationModal";
import Select from "react-select";

const addGuardian = (id, enrollmentIds) => ({
  id,
  firstName: "New Contact",
  enrollmentIds
});

const getAndSetLocalStorage = value => {
  localStorage.setItem(
    "enrollmentFormData",
    JSON.stringify({
      ...(JSON.parse(localStorage.getItem("enrollmentFormData")) || {}),
      emergencyContacts: value
    })
  );
};

const EmergencyContactInfo = ({
  centerInfo,
  enrollment,
  currentStep,
  handleBackBtn,
  IdentificationTooltip,
  nextStep,
  previousStep,
  saveForm,
  deleteGuardian,
  updateUnsavedChangesWarning
}) => {
  const {
    emergencyContacts,
    enrollments,
    governmentIssuedTypes,
    relationships,
  } = enrollment;
  const children = enrollments.map(el => ({
    id: el.enrollmentId,
    label: el.firstName,
    value: el.firstName
  }));
  const enrollmentIds = enrollments.map(el => el.enrollmentId);
  const [activeTab, setActiveTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [startingState, setStartingState] = useState([]);
  const [removeTabId, setRemoveTabId] = useState("");
  const [modal, setModal] = useState({
    open: false,
    message: ""
  });
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const { centerProfile, states } = centerInfo;
  const addressLabels = generateAddressLabels(centerProfile.countryId);


  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({
        emergencyContacts: tabs, 
        section: "emergencyContacts",
        showModal: true,
      })
    }
  }, [tabs]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
    const cachedEmergencyContacts = localStorageData.emergencyContacts || [];
    if ((emergencyContacts && emergencyContacts.length) || cachedEmergencyContacts.length) {
      const cachedContactsNotInDb = cachedEmergencyContacts.filter(el => !el.ppGuardianId);
      const allEmergencyContacts = [...emergencyContacts, ...cachedContactsNotInDb].reduce(
        (allEmergencyContactsAcc, guardian, idx, accArray) => {
          //only return a single entry per guardian id
          if (
            !guardian.ppGuardianId ||
            !allEmergencyContactsAcc.some(el => el.ppGuardianId === guardian.ppGuardianId)
          ) {
            let contactInEmergency;
            // if guardian is not in db, return existing contactInEmergency array in local storage
            if (!guardian.ppGuardianId) {
              contactInEmergency = guardian.contactInEmergency || [];
            } else {
              // if the guardian is an authorized contact for that child, return an entry
              contactInEmergency = accArray.reduce((contactInEmergencyAcc, val) => {
                if (val.ppGuardianId === guardian.ppGuardianId && val.isAuthorizedContact) {
                  contactInEmergencyAcc.push({
                    id: val.enrollmentId,
                    label: val.childFirstName,
                    value: val.childFirstName
                  });
                }
                return contactInEmergencyAcc;
              }, []);
            }
            return [
              ...allEmergencyContactsAcc,
              {
                ...guardian,
                id: idx + 1,
                contactInEmergency,
                enrollmentIds
              }
            ];
          }
          return allEmergencyContactsAcc;
        },
        []
      );

      setTabs(allEmergencyContacts);
      setStartingState(allEmergencyContacts)
      setActiveTab(allEmergencyContacts[0].id);
    }
  }, [emergencyContacts]);

  const addTab = () => {
    const newTabs = [...tabs];
    const newGuardian = addGuardian(newTabs.length + 1, enrollmentIds);
    newTabs.push(newGuardian);
    setTabs(newTabs);
    setActiveTab(newGuardian.id);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  const closeModal = () => {
    setModal({
      open: false,
      message: ""
    });
  };
  const handleDeleteTab = tabId => {
    const currentGuardian = tabs.find(tab => tab.id === tabId);
    setRemoveTabId(tabId);
    let name = "New Contact";
    if (currentGuardian.firstName || currentGuardian.lastName)
      name = `${currentGuardian.firstName || ""} ${currentGuardian.lastName || ""}`;
    setModal({
      open: true,
      message: `Do you want to delete this contact - ${name}?`
    });
  };
  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setTabs, "emergencyContacts", "id");
  };

  const handleSubmit = (event, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const nextTabs = tabs.map(tab => ({
      ...tab,
      error: errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.id)
    }));
    const localStorageData = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
    localStorageData.emergencyContacts = nextTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageData));
    setTabs(nextTabs);
    if (errors.length > 0) return;
    saveForm({ emergencyContacts: tabs, section: "emergencyContacts" });
    getAndSetLocalStorage([]);
    nextStep();
  };

  const onConfirmationModalYes = () => {
    closeModal();
    const guardian = tabs.find(tab => tab.id === removeTabId);
    if (!guardian) return;
    if (!guardian.ppGuardianId) {
      const newTabs = tabs.filter(tab => tab.id !== removeTabId);
      getAndSetLocalStorage(newTabs);
      setTabs(newTabs);
      setActiveTab((newTabs[0] && newTabs[0].id) || "");
    }
    deleteGuardian({ guardian });
    // setActiveTab(tabs[0].id);
  };
  const toggleTabs = id => {
    if (activeTab !== id) setActiveTab(id);
  };

  const displayTabInputs = tab => {
    const {
      address1,
      address2,
      city,
      contactInEmergency,
      firstName,
      governmentIssuedId,
      governmentIssuedStateId,
      governmentIssuedTypeId,
      id,
      lastName,
      postalCode,
      primaryPhone,
      relationshipId,
      secondaryPhone,
      stateId
    } = tab;

    const tabLocalStorage =
      (localStorageEnrollmentForm["emergencyContacts"] &&
        localStorageEnrollmentForm["emergencyContacts"].find(el => el.id === id)) ||
      {};

    return (
      <TabPane key={id} tabId={id}>
        <AvField
          id={`firstName-${id}`}
          name={`firstName-${id}`}
          type="text"
          // data-id={tab.id}
          label="First Name *"
          // onChange={handleInputChange}
          // onBlur={e => handleBlur(e, tab.ppGuardianId)}
          onBlur={handleBlur}
          value={firstName || tabLocalStorage.firstName || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a first name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`lastName-${id}`}
          name={`lastName-${id}`}
          type="text"
          // data-id={id}
          label="Last Name *"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          // onBlur={e => handleBlur(e, ppGuardianId)}
          value={lastName || tabLocalStorage.lastName || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a last name."
            },
            pattern: {
              value: "^[A-Za-z .-]+$",
              errorMessage: "Please enter valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`relationshipId-${id}`}
          name={`relationshipId-${id}`}
          type="select"
          // data-id={id}
          label="Relationship *"
          // onChange={handleInputChange}
          // onChange={e => handleBlur(e, ppGuardianId)}
          onChange={handleBlur}
          value={relationshipId || tabLocalStorage.relationshipId || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a relationship."
            }
          }}>
          {convertToDropdownOptions(relationships).map(e => (
            <option key={e.id} value={e.id}>
              {e.name}
            </option>
          ))}
        </AvField>
        <AvField
          id={`primaryPhone-${id}`}
          name={`primaryPhone-${id}`}
          type="text"
          // data-id={id}
          label="Primary Phone *"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setTabs, "emergencyContacts", "id", {countryId: centerProfile.countryId})}
          onKeyPress={e => {
            const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
            if (!regex.test(e.key)) e.preventDefault();
          }}
          value={primaryPhone || tabLocalStorage.primaryPhone || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a primary phone."
            },
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <AvField
          id={`secondaryPhone-${id}`}
          name={`secondaryPhone-${id}`}
          type="text"
          label="Secondary Phone"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setTabs, "emergencyContacts", "id", {countryId: centerInfo.centerProfile.countryId})}
          onKeyPress={e => {
            if (!/[0-9]/.test(e.key)) e.preventDefault();
          }}
          value={secondaryPhone || tabLocalStorage.secondaryPhone || ""}
          validate={{
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <AvField
          id={`address1-${id}`}
          name={`address1-${id}`}
          type="text"
          // data-id={id}
          label="Address *"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={address1 || tabLocalStorage.address1 || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter an address."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`address2-${id}`}
          name={`address2-${id}`}
          type="text"
          // data-id={id}
          label="Address 2 (Optional)"
          // onChange={handleInputChange}
          onBlur={handleBlur}
          value={address2 || tabLocalStorage.address2 || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`city-${id}`}
          name={`city-${id}`}
          type="text"
          label={`${addressLabels.city} *`}
          onBlur={handleBlur}
          value={city || tabLocalStorage.city || ""}
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter a city."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`stateId-${id}`}
          name={`stateId-${id}`}
          type="select"
          label={`${addressLabels.state} *`}
          onChange={handleBlur}
          value={stateId || tabLocalStorage.stateId || ""}
          validate={{
            required: {
              value: true,
              errorMessage: `Please select a ${addressLabels.state.toLowerCase()}.`
            }
          }}>
          <option key="" value="">
          {`Please select a ${addressLabels.state.toLowerCase()}`}
          </option>
          {states.map(state => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </AvField>
        <AvField
          id={`postalCode-${id}`}
          name={`postalCode-${id}`}
          type="text"
          label="Postal Code *"
          onBlur={handleBlur}
          value={postalCode || tabLocalStorage.postalCode || ""}
          onKeyPress={e => {
            if (
              !(centerProfile.countryId === 230 ? /[\sa-zA-Z0-9]/.test(e.key) : /[0-9]/.test(e.key))
            )
              e.preventDefault();
          }}
          validate={{
            required: {
              value: true,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            maxLength: {
              value: `${centerProfile.countryId === 230 ? 8 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            minLength: {
              value: `${centerProfile.countryId === 230 ? 6 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            pattern: {
              value: `${
                centerProfile.countryId === 230
                ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                  : "^[0-9]{5}$"
              }`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            }
          }}
        />
        {centerProfile.countryId === 232 && (
          <>
            <AvField
              id={`governmentIssuedTypeId-${id}`}
              name={`governmentIssuedTypeId-${id}`}
              type="select"
              // data-id={id}
              label="Government Issued Identification"
              // onChange={handleInputChange}
              // onChange={e => handleBlur(e, ppGuardianId)}
              onChange={handleBlur}
              value={governmentIssuedTypeId || tabLocalStorage.governmentIssuedTypeId || ""}>
              {convertToDropdownOptions(governmentIssuedTypes).map(e => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </AvField>
            <Label>Identification Number {IdentificationTooltip}</Label>
            <AvField
              id={`governmentIssuedId-${id}`}
              name={`governmentIssuedId-${id}`}
              type="text"
              // data-id={id}
              // onChange={handleInputChange}
              onBlur={handleBlur}
              value={governmentIssuedId || tabLocalStorage.governmentIssuedId || ""}
              validate={{
                maxLength: { value: 50 }
              }}
            />
            <AvField
              id={`governmentIssuedStateId-${id}`}
              name={`governmentIssuedStateId-${id}`}
              type="select"
              label="State Issuing"
              // data-id={id}
              // onChange={handleInputChange}
              onChange={handleBlur}
              value={governmentIssuedStateId || tabLocalStorage.governmentIssuedStateId || ""}>
              <option key="" value="">
                Please select a state
              </option>
              {states.map(state => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </AvField>
          </>
        )}
        <label htmlFor={`languages-${id}`}>Contact in emergency</label>
        <Select
          isMulti
          name={`isAuthorizedContact-${id}`}
          className="mb-md"
          onChange={(e, actionTypes) => {
            utilsHandleEnrollmentFormInputChange(e, setTabs, "emergencyContacts", "id", {
              id: id,
              isAuthorizedContact: true
              // ppGuardianId: ppGuardianId
            });
            // handleEmergencyContactSelect(e, actionTypes, id, setTabs);
          }}
          placeholder="Select Children"
          value={contactInEmergency}
          options={children}
        />
      </TabPane>
    );
  };

  return (
    <div className="form-container">
      <h3 className="text-center">Emergency & Release Contacts</h3>
      <h6>
        The following people added (in addition to the parent/guardians) are authorized to pick up
        my child. The primary and secondary guardians are automatically added as an authorized pick
        up and do not need to be entered in this section again.
      </h6>
      <p>&nbsp;</p>
      {tabs.length > 0 && (
        <Nav tabs>
          {tabs.map(tab => (
            <DisplayTab
              key={tab.id}
              activeTab={activeTab}
              tab={tab}
              tabId={tab.id}
              tabLabel={tab.firstName}
              toggleTabs={toggleTabs}
              showX={!tab.coreGuardianId}
              deleteTab={handleDeleteTab}
            />
          ))}
        </Nav>
      )}
      <AvForm onSubmit={handleSubmit}>
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, idx) => displayTabInputs(tab, idx))}
        </TabContent>
        <div id="addEmergencyContactBtn" style={{ marginBottom: "0.5rem" }}>
          <Button
            color="warning"
            size="lg"
            block
            onClick={addTab}
            style={{ pointerEvents: tabs.length >= 4 ? "none" : "auto" }}
            disabled={tabs.length >= 4}>
            Add Emergency Contact
          </Button>
          {tabs.length >= 4 && (
            <UncontrolledTooltip placement="top" target={`addEmergencyContactBtn`}>
              No More Than 4 Emergency Contacts Allowed
            </UncontrolledTooltip>
          )}
        </div>
        <Button color="secondary" size="lg" block onClick={(e) => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <FormGroup>
          <div id="submitBtn" style={{ marginBottom: "0.5rem" }}>
            <Button
              className="mt-2"
              color="primary"
              size="lg"
              block
              style={{ pointerEvents: tabs.length < 1 ? "none" : "auto" }}
              disabled={tabs.length < 1}>
              Save and Continue
            </Button>
            {tabs.length < 1 && (
              <UncontrolledTooltip placement="top" target={`submitBtn`}>
                At least 1 Emergency Contact required
              </UncontrolledTooltip>
            )}
          </div>
        </FormGroup>
      </AvForm>
      <ConfirmationModal
        message={modal.message}
        showModal={modal.open}
        onYes={onConfirmationModalYes}
        onNo={closeModal}
        onClose={closeModal}
      />
    </div>
  );
};

export default EmergencyContactInfo;

import React, { Fragment } from "react";
import classnames from "classnames";
import { NavItem, NavLink } from "reactstrap";
const DisplayTab = ({ activeTab, child, toggleTabs, isAddressForm, tab }) => {
  return (
    <Fragment>
      <NavItem>
          <NavLink
            className={classnames({ active: +activeTab === +child.personal_care_plan_id, error: tab.error})}
            onClick={() => {
              toggleTabs(+child.personal_care_plan_id);
            }}
          >
            {child.first_name}
          </NavLink>
        {/* )} */}
      </NavItem>
    </Fragment>
  );
};

export default DisplayTab;

import React, { useEffect, useState } from "react";
import { Nav, Button, TabContent, TabPane, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import DisplayTab from "../DisplayTab";
import { format } from "date-fns";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../../../SharedComponents/ConfirmationModal";
import {
  compareEnrollmentLocalStorageToDb,
  generateAddressLabels,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";

const ChildMedical = ({
  centerInfo,
  currentStep,
  enrollment,
  handleBackBtn,
  nextStep,
  previousStep,
  saveForm,
  updateFormStatus,
  updateUnsavedChangesWarning
}) => {
  const { enrollments, medicalContacts, submissionStatuses } = enrollment;
  const [activeTab, setActiveTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [startingState, setStartingState] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const currentTabIndex = tabs.findIndex(tab => tab.enrollmentId === activeTab);
  const [isModalOpen, setModalOpen] = useState(false);
  const [nextTabIndex, setNextTabIndex] = useState(-1);
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);
  const { centerProfile, states } = centerInfo;
  const addressLabels = generateAddressLabels(centerProfile.countryId);
  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    setIsRequired(tabs.some(el => el.officeName));
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ medicalContacts: tabs, section: "childMedical", showModal: true })
    }
  }, [tabs]);

  useEffect(() => {
    if (submissionStatuses.childMedical !== true) {
      updateFormStatus({
        section: "childMedical",
        value: isRequired ? false : "optional"
      });
    }
  }, [isRequired]);

  useEffect(() => {
    const comparedMedicalContacts = compareEnrollmentLocalStorageToDb(
      medicalContacts,
      "enrollmentId",
      "childMedical"
    );
    setTabs(comparedMedicalContacts);
    setStartingState(comparedMedicalContacts);
    if (!activeTab) setActiveTab(comparedMedicalContacts[0].enrollmentId);
  }, [medicalContacts]);

  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setTabs, "childMedical", "enrollmentId");
  };

  const handleClear = e => {
    const currentTab = tabs.find(tab => tab.enrollmentId === activeTab);
    if (!currentTab) return;
    const newTab = {
      ...currentTab,
      insuranceProviderName: null,
      policyNumber: null,
      officeName: null,
      phone: null,
      address1: null,
      address2: null,
      city: null,
      stateId: null,
      postalCode: null,
      lastPhysicalDate: null
    };
    const newTabs = [...tabs.slice(0, currentTabIndex), newTab, ...tabs.slice(currentTabIndex + 1)];
    localStorageEnrollmentForm["childMedical"] = newTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(newTabs);
  };

  const handleCopyFromFirstChild = e => {
    const currentTab = tabs.find(tab => tab.enrollmentId === activeTab);
    const firstTab = tabs[0];
    if (!currentTab) return;
    const newTab = {
      ...currentTab,
      insuranceProviderName: firstTab.insuranceProviderName,
      policyNumber: firstTab.policyNumber,
      officeName: firstTab.officeName,
      phone: firstTab.phone,
      address1: firstTab.address1,
      address2: firstTab.address2,
      city: firstTab.city,
      stateId: firstTab.stateId,
      postalCode: firstTab.postalCode
    };
    const newTabs = [...tabs.slice(0, currentTabIndex), newTab, ...tabs.slice(currentTabIndex + 1)];
    localStorageEnrollmentForm["childMedical"] = newTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(newTabs);
  };

  const toggleTabs = id => {
    if (activeTab !== id) setActiveTab(id);
  };

  const displayTabInputs = (tab, idx) => {
    const {
      address1,
      address2,
      city,
      dobFormatted,
      insuranceProviderName,
      lastPhysicalDate,
      officeName,
      policyNumber,
      postalCode,
      phone,
      enrollmentId: id,
      stateId
    } = tab;

    const tabLocalStorage =
      (localStorageEnrollmentForm &&
        localStorageEnrollmentForm["childMedical"] &&
        localStorageEnrollmentForm["childMedical"][id]) ||
      {};
    return (
      <TabPane key={id} tabId={id}>
        <AvField
          id={`insuranceProviderName-${id}`}
          name={`insuranceProviderName-${id}`}
          label="Health Insurance Provider"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={insuranceProviderName || tabLocalStorage.insuranceProviderName || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a provider name."
            },
            pattern: {
              value: "^[A-Za-z .]+$",
              errorMessage: "Please enter a valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`policyNumber-${id}`}
          name={`policyNumber-${id}`}
          label="Health Insurance Policy Number"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={policyNumber || tabLocalStorage.policyNumber || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a policy number."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`officeName-${id}`}
          name={`officeName-${id}`}
          label="Physician/Clinic Name"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={officeName || tabLocalStorage.officeName || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter an office name."
            },
            pattern: {
              value: "^[A-Za-z .]+$",
              errorMessage: "Please enter a valid name"
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`phone-${id}`}
          name={`phone-${id}`}
          type="text"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setTabs, "childMedical", "enrollmentId", {countryId: centerProfile.countryId})}
          onKeyPress={e => {
            const regex = centerProfile.countryId === 230 ? /[0-9 ]/ : /[0-9]/;
            if (!regex.test(e.key)) e.preventDefault();
          }}
          value={phone || tabLocalStorage.phone || ""}
          label="Phone"
          data-id={id}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a phone number."
            },
            minLength: {value: centerProfile.countryId === 230 ? 9 : 14},
            maxLength: { value: centerProfile.countryId === 230 ? 12 : 14 }
          }}
        />
        <AvField
          id={`address1-${id}`}
          name={`address1-${id}`}
          type="text"
          onBlur={handleBlur}
          value={address1 || tabLocalStorage.address1 || ""}
          label="Address"
          data-id={id}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter an address."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`address2-${id}`}
          name={`address2-${id}`}
          type="text"
          onBlur={handleBlur}
          value={address2 || tabLocalStorage.address2 || ""}
          label="Address 2 (Optional)"
          data-id={id}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`city-${id}`}
          name={`city-${id}`}
          type="text"
          onBlur={handleBlur}
          value={city || tabLocalStorage.city || ""}
          label={`${addressLabels.state} *`}
          data-id={id}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: `Please enter a ${addressLabels.city.toLowerCase()}.`
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`stateId-${id}`}
          name={`stateId-${id}`}
          type="select"
          onBlur={handleBlur}
          value={stateId || tabLocalStorage.stateId || ""}
          label={`${addressLabels.state} *`}
          data-id={id}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: `Please select a ${addressLabels.state.toLowerCase()}.`
            }
          }}>
          <option key="" value="">
            {`Please select a ${addressLabels.state.toLowerCase()}`}
          </option>
          {states.map(state => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </AvField>
        <AvField
          id={`postalCode-${id}`}
          name={`postalCode-${id}`}
          type="text"
          onBlur={handleBlur}
          value={postalCode || tabLocalStorage.postalCode || ""}
          label={`${addressLabels.zip} Code *`}
          data-id={id}
          onKeyPress={e => {
            if (
              !(centerProfile.countryId === 230 ? /[\sa-zA-Z0-9]/.test(e.key) : /[0-9]/.test(e.key))
            )
              e.preventDefault();
          }}
          validate={{
            required: {
              value: true,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            maxLength: {
              value: `${centerProfile.countryId === 230 ? 8 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            minLength: {
              value: `${centerProfile.countryId === 230 ? 6 : 5}`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            },
            pattern: {
              value: `${
                centerProfile.countryId === 230
                ? "^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$"
                  : "^[0-9]{5}$"
              }`,
              errorMessage: `Enter a valid ${addressLabels.zip.toLowerCase()} code.`
            }
          }}
        />
        <AvField
          id={`lastPhysicalDate-${id}`}
          name={`lastPhysicalDate-${id}`}
          type="date"
          onBlur={handleBlur}
          value={lastPhysicalDate || tabLocalStorage.lastPhysicalDate || ""}
          label="Date of Child's Last Physical"
          data-id={id}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a physical date."
            },
            dateRange: {
              format: "MM/DD/YYYY",
              start: { value: dobFormatted },
              end: { value: format(new Date(), "MM/dd/yyyy") }
            }
          }}
        />
      </TabPane>
    );
  };

  const isContactEmpty = data => {
    return (
      !data ||
      (!data.officeName && !data.insuranceProviderName && !data.policyNumber && !data.phone)
    );
  };

  const handleSubmit = (e, errors, values) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const nextTabs = tabs.map(tab => ({
      ...tab,
      error: errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.enrollmentId)
    }));
    const localStorageEnrollmentForm = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
    localStorageEnrollmentForm.childMedical = nextTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(nextTabs);
    if (errors.length > 0) return;
    // find next contact that's not filled yet
    for (let i = currentTabIndex + 1; i < tabs.length; i++) {
      if (isContactEmpty(tabs[i])) {
        setNextTabIndex(i);
        setModalOpen(true);
        return;
      }
    }

    if (isRequired || (!isRequired && tabs.some(el => el.ppMedicalContactId))) {
      saveForm({
        medicalContacts: tabs,
        section: "childMedical"
      });
    }
    nextStep();
  };

  const onConfirmationModalYes = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 50);
    setModalOpen(false);
    setActiveTab(tabs[nextTabIndex].enrollmentId);
  };

  const onConfirmationModalNo = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 50);
    setModalOpen(false);
    saveForm({ medicalContacts: tabs, section: "childMedical" });
    nextStep();
  };

  const onConfirmationModalClose = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 50);
    setModalOpen(false);
  };

  return (
    <div className="form-container">
      <h3 className="text-center">My Child's Medical Care Provider</h3>
      <Nav tabs>
        {tabs.map(tab => (
          <DisplayTab
            key={tab.enrollmentId}
            activeTab={activeTab}
            tab={tab}
            tabId={tab.enrollmentId}
            toggleTabs={toggleTabs}
            tabLabel={tab.childName}
          />
        ))}
      </Nav>
      <AvForm onSubmit={handleSubmit}>
        {currentTabIndex > 0 && (
          <Row>
            <Col md="12">
              <Button
                color="primary"
                size="lg"
                className="mb-3 float-right"
                onClick={handleCopyFromFirstChild}>
                <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
                Copy information from first child
              </Button>
            </Col>
          </Row>
        )}
        <TabContent activeTab={activeTab}>
          {tabs.map((tab, idx) => displayTabInputs(tab, idx))}
        </TabContent>
        <Button color="secondary" size="lg" block onClick={(e) => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <Button color="danger" size="lg" block onClick={handleClear}>
          Clear
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
      <ConfirmationModal
        message="Do you want to enter Medical Care Provider information for the next child?"
        showModal={isModalOpen}
        onYes={onConfirmationModalYes}
        onNo={onConfirmationModalNo}
        onClose={onConfirmationModalClose}
      />
    </div>
  );
};

export default ChildMedical;

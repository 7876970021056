import produce from "immer";
import * as actionTypes from "../actionTypes";
import initialState from "./initialState";

export const auth = produce((state = initialState.auth, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN__START:
    case actionTypes.SIGNUP__START:
    case actionTypes.VERIFY_EMAIL__START:
      return {
        ...state,
        // isLoading: true,
        error: "",
        status: "loading"
      };

    case actionTypes.SIGNIN__SUCCESS:
    case actionTypes.SIGNUP__SUCCESS: {
      const { token, guardian, showBafIcon } = action.payload;
      return {
        ...state,
        // isLoading: false,
        showBafIcon,
        error: "",
        token,
        guardian,
        // status: "idle"
      };
    }
    case actionTypes.SIGNIN__FAILURE:
    case actionTypes.SIGNUP__FAILURE:
    case actionTypes.VERIFY_EMAIL__FAILURE:
      return {
        ...state,
        // isLoading: false,
        error: action.payload,
        guardian: {},
        status: "idle",
        token: "",
      };

    case actionTypes.SIGNOUT:
      return {
        ...state,
        // isLoading: false,
        error: "",
        guardian: {},
        status: "idle",
        token: ""
      };
    case actionTypes.LOGIN__SUCCESS:
      return {
        ...state,
        status: "succeeded",
        error: ""
      };
    case actionTypes.UPDATE_AUTH_STATE:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.AUTH_LOAD_GUARDIAN__START:
      return {
        ...state,
        error: "",
        status: "loading"
      };
    case actionTypes.AUTH_LOAD_GUARDIAN__SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: "",
        // status: "idle",
      };
    case actionTypes.AUTH_LOAD_GUARDIAN__FAILURE:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    // case actionTypes.ENROLLMENT_LOAD_CENTER_INFO__SUCCESS:
    //   return {
    //     ...state,
    //     centerInfo: action.payload
    //   }
    // case actionTypes.ENROLLMENT_LOAD_CENTER_INFO__FAILURE:
    //   return state
    case actionTypes.PING__SUCCESS:
      return {
        ...state,
        pingResult: action.payload,
        error: "",
        // status: "idle",
      };
    case actionTypes.VERIFY_EMAIL__SUCCESS:
      return {
        ...state,
        error: "",
        status: "idle",
      };
    default:
      return state;
  }
});

import React, { useEffect, useState } from "react";
import { Nav, Button, TabContent, TabPane, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from "lodash";
import DisplayTab from "../DisplayTab";
import { formatPhoneNumber } from "../../../../utils";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../../../SharedComponents/ConfirmationModal";
import {
  compareEnrollmentLocalStorageToDb,
  utilsHandleEnrollmentFormInputChange
} from "../../../../utils";

const ChildDental = ({
  centerInfo,
  currentStep,
  enrollment,
  handleBackBtn,
  nextStep,
  previousStep,
  saveForm,
  updateFormStatus,
  updateUnsavedChangesWarning
}) => {
  const { dentalContacts, enrollments, submissionStatuses } = enrollment;
  const { centerProfile } = centerInfo;
  const [activeTab, setActiveTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [startingState, setStartingState] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const currentTabIndex = tabs.findIndex(tab => tab.enrollmentId === activeTab);
  const [isModalOpen, setModalOpen] = useState(false);
  const [nextTabIndex, setNextTabIndex] = useState(-1);
  const [localStorageEnrollmentForm, setLocalStorageEnrollmentForm] = useState(null);

  useEffect(() => {
    setLocalStorageEnrollmentForm(JSON.parse(localStorage.getItem("enrollmentFormData")) || {});
    setIsRequired(tabs.some(el => el.officeName));
    if(!_.isEqual(startingState, tabs)) {
      updateUnsavedChangesWarning({ dentalContacts: tabs, section: "childDental", showModal: true })
    }
  }, [tabs]);

  useEffect(() => {
    if (submissionStatuses.childDental !== true) {
      updateFormStatus({
        section: "childDental",
        value: isRequired ? false : "optional"
      });
    }
  }, [isRequired]);

  useEffect(() => {
    const comparedDentalContacts = compareEnrollmentLocalStorageToDb(
      dentalContacts,
      "enrollmentId",
      "childDental"
    );
    setTabs(comparedDentalContacts);
    setStartingState(comparedDentalContacts);
    if (!activeTab) setActiveTab(comparedDentalContacts[0].enrollmentId);
  }, [dentalContacts]);

  const handleBlur = e => {
    utilsHandleEnrollmentFormInputChange(e, setTabs, "childDental", "enrollmentId");
  };

  const isContactEmpty = data => !data || (!data.officeName && !data.phone);

  const handleSubmit = (event, errors, values) => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    const nextTabs = tabs.map(tab => ({
      ...tab,
      error: errors.some(el => +el.slice(el.lastIndexOf("-") + 1) === tab.enrollmentId)
    }));
    const localStorageEnrollmentForm = JSON.parse(localStorage.getItem("enrollmentFormData")) || {};
    localStorageEnrollmentForm.childDental = nextTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(nextTabs);
    if (errors.length > 0) return;
    // find next contact that's not filled yet
    for (let i = currentTabIndex + 1; i < tabs.length; i++) {
      if (isContactEmpty(tabs[i])) {
        setNextTabIndex(i);
        setModalOpen(true);
        return;
      }
    }

    if(isRequired || (!isRequired && tabs.some(el => el.ppMedicalContactId))){
      saveForm({ dentalContacts: tabs, section: "childDental" });
    }
    nextStep();
  };

  const onConfirmationModalYes = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 50);
    setModalOpen(false);
    setActiveTab(tabs[nextTabIndex].enrollmentId);
  };

  const onConfirmationModalNo = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 50);
    setModalOpen(false);
    saveForm({ dentalContacts: tabs, section: "childDental" });
    nextStep();
  };

  const onConfirmationModalClose = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 50);
    setModalOpen(false);
  };

  const handleClear = e => {
    const currentTab = tabs.find(tab => tab.enrollmentId === activeTab);
    if (!currentTab) return;
    const newTab = {
      ...currentTab,
      officeName: null,
      phone: null,
      address1: null,
      address2: null,
      city: null,
      stateId: null,
      postalCode: null
    };
    const newTabs = [...tabs.slice(0, currentTabIndex), newTab, ...tabs.slice(currentTabIndex + 1)];
    localStorageEnrollmentForm["childDental"] = newTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(newTabs);
  };

  const handleCopyFromFirstChild = e => {
    const currentTab = tabs.find(tab => tab.enrollmentId === activeTab);
    const firstTab = tabs[0];
    if (!currentTab) return;
    const newTab = {
      ...currentTab,
      officeName: firstTab.officeName,
      phone: firstTab.phone,
      address1: firstTab.address1,
      address2: firstTab.address2,
      city: firstTab.city,
      stateId: firstTab.stateId,
      postalCode: firstTab.postalCode
    };
    const newTabs = [...tabs.slice(0, currentTabIndex), newTab, ...tabs.slice(currentTabIndex + 1)];
    localStorageEnrollmentForm["childDental"] = newTabs;
    localStorage.setItem("enrollmentFormData", JSON.stringify(localStorageEnrollmentForm));
    setTabs(newTabs);
  };

  const toggleTabs = id => {
    if (activeTab !== id) setActiveTab(id);
  };

  const displayTabInputs = tab => {
    const {
      address1,
      address2,
      city,
      officeName,
      postalCode,
      phone,
      enrollmentId: id,
      stateId
    } = tab;

    const tabLocalStorage =
      (localStorageEnrollmentForm &&
        localStorageEnrollmentForm["childDental"] &&
        localStorageEnrollmentForm["childDental"][id]) ||
      {};

    return (
      <TabPane key={id} tabId={id}>
        <AvField
          id={`officeName-${id}`}
          name={`officeName-${id}`}
          label="Dentist Name"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={officeName || tabLocalStorage.officeName || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a dentist name."
            },
            maxLength: { value: 50 },
            pattern: {
              value: "^[A-Za-z .]+$",
              errorMessage: "Please enter valid name"
            }
          }}
        />
        <AvField
          id={`phone-${id}`}
          name={`phone-${id}`}
          label="Phone"
          type="text"
          onChange={(e) => utilsHandleEnrollmentFormInputChange(e, setTabs, "childDental", "enrollmentId", {countryId: centerProfile.countryId})}
          onKeyPress={e => {
            if (!/[0-9]/.test(e.key)) e.preventDefault();
          }}
          value={phone || tabLocalStorage.phone || ""}
          data-id={id}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a phone number."
            },
            minLength: { value: 14 },
            maxLength: { value: 14 }
          }}
        />
        <AvField
          id={`address1-${id}`}
          name={`address1-${id}`}
          label="Address"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={address1 || tabLocalStorage.address1 || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter an address."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`address2-${id}`}
          name={`address2-${id}`}
          label="Address 2 (Optional)"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={address2 || tabLocalStorage.address2 || ""}
          validate={{
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`city-${id}`}
          name={`city-${id}`}
          label="City"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={city || tabLocalStorage.city || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a city."
            },
            maxLength: { value: 50 }
          }}
        />
        <AvField
          id={`stateId-${id}`}
          name={`stateId-${id}`}
          label="State"
          data-id={id}
          type="select"
          onBlur={handleBlur}
          value={stateId || tabLocalStorage.stateId || ""}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please select a state."
            }
          }}>
          <option key="" value="">
            Please select a state
          </option>
          {centerInfo.states.map(state => (
            <option key={state.id} value={state.id}>
              {state.name}
            </option>
          ))}
        </AvField>
        <AvField
          id={`postalCode-${id}`}
          name={`postalCode-${id}`}
          label="Postal Code"
          data-id={id}
          type="text"
          onBlur={handleBlur}
          value={postalCode || tabLocalStorage.postalCode || ""}
          onKeyPress={e => {
            if (!/[0-9]/.test(e.key)) e.preventDefault();
          }}
          validate={{
            required: {
              value: !!isRequired,
              errorMessage: "Please enter a postal code."
            },
            maxLength: { value: 5, errorMessage: "Enter a valid postal code." },
            minLength: { value: 5, errorMessage: "Enter a valid postal code." },
            pattern: { value: "^[0-9]{5}$", errorMessage: "Enter a valid postal code." }
          }}
        />
      </TabPane>
    );
  };

  return (
    <div className="form-container">
      <h3 className="text-center">My Child's Dental Provider</h3>
      {tabs.length > 0 && (
        <Nav tabs>
          {tabs.map(tab => (
            <DisplayTab
              key={tab.enrollmentId}
              activeTab={activeTab}
              tabId={tab.enrollmentId}
              tab={tab}
              toggleTabs={toggleTabs}
              tabLabel={tab.childName}
            />
          ))}
        </Nav>
      )}
      <AvForm onSubmit={handleSubmit}>
        {currentTabIndex > 0 && (
          <Row>
            <Col md="12">
              <Button
                color="primary"
                size="lg"
                className="mb-3 float-right"
                onClick={handleCopyFromFirstChild}>
                <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
                Copy information from first child
              </Button>
            </Col>
          </Row>
        )}
        <TabContent activeTab={activeTab}>{tabs.map(tab => displayTabInputs(tab))}</TabContent>
        <Button color="secondary" size="lg" block onClick={(e) => handleBackBtn(e, previousStep)}>
          Back
        </Button>
        <Button color="danger" size="lg" block onClick={handleClear}>
          Clear
        </Button>
        <Button color="primary" size="lg" block>
          Save and Continue
        </Button>
      </AvForm>
      <ConfirmationModal
        message="Do you want to enter Medical Care Provider information for the next child?"
        showModal={isModalOpen}
        onYes={onConfirmationModalYes}
        onNo={onConfirmationModalNo}
        onClose={onConfirmationModalClose}
      />
    </div>
  );
};

export default ChildDental;


const dropdowns = {
            children: [
                { value:"", label: "Select a child"},
                { value: "1", label: "Child 1" },
                { value: "2", label: "Child 2"},
                { value: "0", label: "All"},
            ],
            documentTypes: [
                { value:"", label: "Select a document type"},
                { value: "1", label: "type 1" },
                { value: "2", label: "type 2"},
                { value: "3", label: "type 3"},
            ],
            genders: [
                { value:"", label: "Select a gender"},
                { value: "1", label: "Male" },
                { value: "2", label: "Female"}
            ],
            identifications: [
                { value: "", label: "Select an identification"},
                { value: "1", label: "Driver's License"},
                { value: "2", label: "Passport"}
            ],
            infantMonths: [
                { value: "", label: "Select an age"},
                { value: "0", label: "0"},
                { value: "1", label: "1"},
                { value: "2", label: "2"},
                { value: "3", label: "3"},
                { value: "4", label: "4"},
                { value: "5", label: "5"},
                { value: "6", label: "6"},
                { value: "7", label: "7"},
                { value: "8", label: "8"},
                { value: "9", label: "9"},
                { value: "10", label: "10"},
                { value: "11", label: "11"},
                { value: "12", label: "12"},
                { value: "13", label: "13"},
                { value: "14", label: "14"},
                { value: "15", label: "15"},
                { value: "16", label: "16"},
                { value: "17", label: "17"},
                { value: "18", label: "18"},
                { value: "19", label: "19"},
                { value: "20", label: "20"},
                { value: "21", label: "21"},
                { value: "22", label: "22"},
                { value: "23", label: "23"},
                { value: "24", label: "24"}
        
            ],
            languages: [
                { value:"", label: "Select a language"},
                { value: "1", label: "English"},
                { value: "2", label: "Spanish"},
                { value: "3", label: "Portuguese"},
                { value: "4", label: "Creole"},
                { value: "5", label: "Italian"}
            ],
            maritalStatus: [
                { value:"", label: "Select a marital status"},
                { value: "1", label: "Married"},
                { value: "2", label: "Single"},
                { value: "3", label: "Divorced"}
            ],
            primaryResidence: [
                { value: "", label: "Select a residence" },
                { value: "1", label: "With Both Parents" },
                { value: "2", label: "With Mother" },
                { value: "3", label: "With Father" },
                { value: "4", label: "With Grandparent" },
                { value: "5", label: "With Other Relative" }
              ],
            states: [
                { value: "", label: "Select a state" },
                { value: "1", label: "Florida" },
                { value: "2", label: "California" },
                { value: "3", label: "New York" }
              ]
}

export const convertToDropdownOptions = (options) => (
  [{
    id: '',
    name: 'Please select'
  },
  ...options,
]);

export default dropdowns;
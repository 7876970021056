import React, { Fragment, useMemo, useState } from "react";
import styled from "styled-components";
import { UncontrolledTooltip } from "reactstrap";
import { usePagination, useTable } from "react-table";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditMedicalModal from "./EditMedicalModal";
import DeleteMedicalModal from "./DeleteMedicalModal";

const MemoizedEditMedicalModal = React.memo(EditMedicalModal);
const MemoizedDeleteMedicalModal = React.memo(DeleteMedicalModal);

const TableStyles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    /* display: block; */
    /* max-width: 100%; */
    overflow-x: scroll;
    overflow-y: hidden;
    /* border-bottom: 1px solid black; */
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tbody {
      tr:nth-of-type(2n) {
        background-color: white;
      }
      tr:nth-of-type(2n + 1) {
        background-color: rgb(249, 249, 249);
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      border-bottom: 3px solid #ec008c !important;
      background: aliceblue;
      color: black;
      font-weight: bold;
    }

    th,
    td {
      margin: 0;
      padding: 0.3rem;
      border-bottom: 1px solid #f4f4f4;
      border-right: 1px solid #f4f4f4;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination-section {
    padding: 0.5rem;
  }
`;

const buttonMargin = {
  margin: "0.1rem",
  pointerEvents: "none",
};

const buttonContainer = {
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
};

const ContactsTable = ({
  child,
  deleteRow,
  edit,
  childMedical,
  updatedPageSize,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: "Severity",
        accessor: "severity",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Name",
        accessor: "issue",
      },
      {
        Header: "Treatment and Notes",
        accessor: "treatment",
      },
      {
        Header: "Action",
        accessor: (d, rowId) => {
          return (
            <div style={buttonContainer}>
              <div id={`editMedicalBtnContainer-${d.childId}-${rowId}`}>
                <Button
                  id={`editBtn-${d.childId}-${rowId}`}
                  style={buttonMargin}
                  color="primary"
                  size="sm"
                  disabled
                  // onClick={() => edit(d, "Edit")}
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth={false} />
                </Button>
                <UncontrolledTooltip
                  className="testingYo"
                  placement="auto"
                  target={`editMedicalBtnContainer-${d.childId}-${rowId}`}
                >
                  Contact the center to edit
                </UncontrolledTooltip>
              </div>
              <div id={`deleteMedicalBtnContainer-${d.childId}-${rowId}`}>
                <Button
                  id={`deleteBtn-${d.childId}-${rowId}`}
                  style={buttonMargin}
                  color="primary"
                  size="sm"
                  disabled
                  // onClick={() => deleteRow(d)}
                >
                  <FontAwesomeIcon icon={faTrash} fixedWidth={false} />
                </Button>
                <UncontrolledTooltip
                  placement="auto"
                  target={`deleteMedicalBtnContainer-${d.childId}-${rowId}`}
                >
                  Contact the center to delete
                </UncontrolledTooltip>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: childMedical,
      initialState: { pageIndex: 0 },
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageSize: updatedPageSize,
          }),
          [state, updatedPageSize]
        );
      },
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  return (
    <TableStyles>
      <Row className="tableWrap">
        <Col>
          <table {...getTableProps()}>
            <thead>
              {// Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps({
                        className: column.collapse ? "collapse" : "",
                      })}
                    >
                      {// Render the header
                      column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {// Loop over the table rows
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? "collapse" : "",
                          })}
                        >
                          {// Render the cell contents
                          cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      <Container className="pagination-section">
        <Row>
          <Col xs={12} md={6}>
            <Form inline>
              <FormGroup className="mr-3">
                <Label
                  className="mr-2"
                  for={`childMedicalPageIndex-${child.id}`}
                >
                  Go to page:
                </Label>
                <Input
                  id={`childMedicalPageIndex-${child.id}`}
                  type="number"
                  defaultValue={pageIndex + 1 || 1}
                  onChange={(e) => {
                    let num = e.target.value;
                    const page = num ? +num - 1 : 0;
                    e.target.value = num > 0 ? num : 1;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </FormGroup>
              <div>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </div>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Last
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ margin: "0.1rem" }}
            >
              Next
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              Previous
            </Button>
            <Button
              className="float-right"
              color="primary"
              size="lg"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={{ margin: "0.1rem" }}
            >
              First
            </Button>
          </Col>
        </Row>
      </Container>
    </TableStyles>
  );
};

const ChildMedical = ({
  child,
  childMedical,
  deleteChildMedical,
  medicalAllergy,
  medicalCondition,
  medicalDietaryPreferences,
  medicalSeverity,
  status,
  updateChildMedical,
}) => {
  const [updatedPageSize, setUpdatedPageSize] = useState(10);
  const [editMedical, setEditMedical] = useState({
    editMedicalClicked: false,
    editMedicalInfo: {},
    type: null,
  });
  const [deleteMedical, setDeleteMedical] = useState({
    deleteMedicalClicked: false,
    deleteMedicalInfo: {},
  });

  const edit = (data, type) => {
    setEditMedical({
      ...editMedical,
      editMedicalClicked: true,
      editMedicalInfo: data,
      type,
    });
  };

  const deleteRow = (data) => {
    setDeleteMedical((prevState) => ({
      ...prevState,
      deleteMedicalClicked: true,
      deleteMedicalInfo: data,
    }));
  };

  const editModalClosed = () => {
    setEditMedical((prevState) => ({
      ...prevState,
      editMedicalClicked: false,
      editMedicalInfo: {},
    }));
  };

  const deleteModalClosed = () => {
    setDeleteMedical((prevState) => ({
      ...prevState,
      deleteMedicalClicked: false,
      deleteMedicalInfo: {},
    }));
  };

  return (
    <Fragment>
      {childMedical && !!Object.keys(childMedical).length && (
        <Fragment>
          <MemoizedEditMedicalModal
            editMedicalClicked={editMedical.editMedicalClicked}
            child={child}
            medical={editMedical.editMedicalInfo}
            medicalAllergy={medicalAllergy}
            medicalCondition={medicalCondition}
            medicalDietaryPreferences={medicalDietaryPreferences}
            medicalSeverity={medicalSeverity}
            modalClosed={editModalClosed}
            editMedical={editMedical}
            updateChildMedical={updateChildMedical}
            type={editMedical.type}
          />
          <MemoizedDeleteMedicalModal
            deleteMedicalClicked={deleteMedical.deleteMedicalClicked}
            deleteChildMedical={deleteChildMedical}
            medical={deleteMedical.deleteMedicalInfo}
            modalClosed={deleteModalClosed}
          />
          <Row className="mb-3">
            <Col>
              <Row>
                <Col md={3} style={{ display: "flex", alignItems: "center" }}>
                  Show&nbsp;
                  <Input
                    type="select"
                    value={updatedPageSize}
                    onChange={(e) => {
                      setUpdatedPageSize(+e.target.value);
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Col>
          </Row>
          <ContactsTable
            child={child}
            childMedical={childMedical}
            updatedPageSize={updatedPageSize}
            edit={edit}
            deleteRow={deleteRow}
            status={status}
          />
          <Row>
            <Col>
              {/* <Button
                className="mb-3"
                color="primary"
                size="lg"
                onClick={() => {
                  edit({}, "Add");
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  fixedWidth={false}
                  className="mr-1"
                />
                New Allergy/Medical Condition
              </Button> */}
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ChildMedical;

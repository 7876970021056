import React, { Fragment, useEffect, useState } from "react";
import { Button, Table, TabPane, UncontrolledTooltip } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HealthTableRows from "./HealthTableRows";

const HealthTable = ({items, pcpId, setTabs, showModal, type}) => {
    const types = {
        allergy: {
          buttonTitle: 'Add Allergy',
          title: 'List of allergies and known reactions',
          tableHeaders: () => {
            return (
                <tr>
                    <th>Allergy</th>
                    <th>Severity</th>
                    <th>Treatment & Notes</th>
                    <th>Is Life Threatening</th>
                    <th>Actions</th>
                </tr>
            )
          }
        }, 
        dietary_preference: {
          buttonTitle: 'Add Dietary Preference',
          title: 'List of dietary preferences',
          tableHeaders: () => {
            return (
                <tr>
                    <th>Dietary Preference</th>
                    <th>Notes</th>
                    <th>Actions</th>
                </tr>
            )
          }
        }, 
        medical_condition: {
          buttonTitle: 'Add Medical Condition',
          title: 'List of medical conditions',
          tableHeaders: () => {
            return (
                <tr>
                    <th>Medical Condition</th>
                    <th>Severity</th>
                    <th>Treatment & Notes</th>
                    <th>Actions</th>
                </tr>
            )
          }
        }
      }
      return (
        <Fragment>
        <div className="mb-sm mt-md">{types[type].title}</div>
        <Table responsive>
          <thead>
            {types[type].tableHeaders()}
          </thead>
          <tbody>
            <HealthTableRows
              pcpId={pcpId}
              items={items}
              showModal={showModal}
              setTabs={setTabs}
              type={type}
            />
          </tbody>
        </Table>
        <Button
          className="mb-3"
          color="primary"
          size="lg"
          onClick={(e) => {
            showModal(pcpId, items, 'add', type)
          }}
        >
            <FontAwesomeIcon icon={faPlus} fixedWidth={false} className="mr-1" />
            {types[type].buttonTitle}
        </Button>
        </Fragment>
    )
}
export default HealthTable;